import { Box, Flex } from '@chakra-ui/react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChatContext } from '~src/chat/chat-context';
import {
  ChatterRole,
  ChatterStatus,
  ChatRoomStatus,
} from '~src/chat/copied-schemas/backend-api-types';
import { HourGlassIcon } from '~src/components/KuuraIcon/icons/HourGlassIcon';
import { UserIcon } from '~src/components/KuuraIcon/icons/UserIcon';
import { chat, translations } from '~src/configurations';
import { DoctorOfflineModal } from './DoctorOfflineModal';

const DoctorChatStatus = React.memo(() => {
  const { chatRooms, addSystemMessage, activeRoomId } = useContext(ChatContext);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [showDoctorOfflineModal, setShowDoctorOfflineModal] =
    useState<boolean>(false);
  const [isDoctorStatusMessageShown, setIsDoctorStatusMessageShown] =
    useState<boolean>(false);
  const { t } = useTranslation();

  const { doctorOfflineMsgDelay, doctorOfflineMsgMode } = chat;

  const activeRoom = chatRooms?.find((room) => room.roomId === activeRoomId);
  const doctorData = activeRoom?.members.find(
    (member) => member.role === ChatterRole.Doctor,
  );
  const isDoctorOnline = [ChatterStatus.Online, ChatterStatus.Writing].includes(
    doctorData?.status || ChatterStatus.Unknown,
  );
  const isRoomOpen = activeRoom?.status === ChatRoomStatus.Open;

  const shouldDoctorOfflineMsgBeShown =
    !isDoctorOnline &&
    isRoomOpen &&
    doctorOfflineMsgDelay !== -1 &&
    !isDoctorStatusMessageShown;
  const doctorOfflineMsg = t(
    'chat.discussion.doctorOfflineModal.docOfflineSystemMessage',
  );

  // show doctor offline message selected way (modal/systemMessage) after delay set in configurations
  useEffect(() => {
    if (shouldDoctorOfflineMsgBeShown && !timeoutRef.current) {
      timeoutRef.current = setTimeout(() => {
        doctorOfflineMsgMode === 'modal'
          ? setShowDoctorOfflineModal(true)
          : addSystemMessage({
              message: doctorOfflineMsg,
              roomId: activeRoomId || '',
            });
        setIsDoctorStatusMessageShown(true);
        timeoutRef.current = null;
      }, (doctorOfflineMsgDelay || 0) * 60 * 1000); // minutes to milliseconds
    }
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
    };
  }, [
    activeRoomId,
    addSystemMessage,
    doctorOfflineMsg,
    doctorOfflineMsgDelay,
    doctorOfflineMsgMode,
    shouldDoctorOfflineMsgBeShown,
  ]);

  const { supportedLanguages } = translations;
  const isLanguageSwitchedVisible = supportedLanguages.length > 1;

  return (
    <Flex
      flexDirection="row"
      alignContent="center"
      gap={2}
      h="auto"
      alignItems="center"
      pl={isLanguageSwitchedVisible ? ['70px', '70px', '130px'] : 0}
    >
      <DoctorOfflineModal
        isOpen={showDoctorOfflineModal}
        onClose={() => setShowDoctorOfflineModal(false)}
      />
      {!doctorData && (
        <>
          <HourGlassIcon width={6} height={6} color="primary.500" />
          <Box color="primary.500" fontSize="md" fontWeight={700}>
            Olet jonossa
          </Box>
        </>
      )}
      {doctorData && (
        <>
          <UserIcon width={6} height={6} color="primary.500" />
          <Flex flexDirection="column" gap={0} pt={2}>
            <Box
              color="primary.500"
              fontSize="md"
              fontWeight={700}
              lineHeight="20px"
            >
              Lääkäri {doctorData.firstName} {doctorData.lastName}
            </Box>
            <Flex
              color="white"
              fontSize="10px"
              fontWeight={700}
              textAlign="center"
              bg={isDoctorOnline ? '#00B027' : '#8298A5'}
              borderRadius={16}
              px="5px"
              justifyContent="center"
              alignItems="center"
              w="fit-content"
              cursor={!isDoctorOnline ? 'pointer' : 'default'}
              onClick={() => !isDoctorOnline && setShowDoctorOfflineModal(true)}
            >
              {isDoctorOnline ? 'PAIKALLA' : 'EI PAIKALLA'}
            </Flex>
          </Flex>
        </>
      )}
    </Flex>
  );
});

export { DoctorChatStatus };
