import type { IConfiguration, LanguageResources } from '~/src/types';
import { Locale, Configuration } from '~/src/types';
import colors from '~/src/theme/foundations/colors';
import appLogo from '~/src/images/logo-default.png';
import { localizations } from '~/src/localizations';
import Home from '~/src/screens/Home/Home';
import { GetStartMenuQuery, MenuItemAction } from '~src/api';

const resources: LanguageResources = localizations[Configuration.Default] ?? {};

// If any language is missing add them as an empty language
for (const lng of Object.values(Locale)) {
  if (!(lng in resources)) resources[lng] = {};
}
const startMenu: GetStartMenuQuery = {
  startMenu: [
    {
      translationKey: 'Auth example',
      action: MenuItemAction.Question,
      actionParameter: 'authentication-example',
    },
    {
      translationKey: 'kuvatesti',
      action: MenuItemAction.Question,
      actionParameter: 'kuvatesti',
    },
    {
      translationKey: 'Esimerkki: Potilaan siirto d-alustalle kuvan kera',
      action: MenuItemAction.Question,
      actionParameter: 'send-to-d-plat-example',
    },
    {
      translationKey: 'testi-toka',
      action: MenuItemAction.Question,
      actionParameter: 'testi-toka',
    },
  ],
};
// Now resources contain all languages
const fallbackResources = resources as Required<LanguageResources>;

const configuration: IConfiguration = {
  startMenu: startMenu,
  searchEnabled: true,
  colors,
  header: {
    appLogo,
    appLogoTranslated: {},
    appLogoWidth: '6rem',
    appLogoPaddingTop: '0px',
    showInfoToggle: true,
    progressBarVisible: true,
    returnLinkVisible: false,
  },
  flowInit: {
    cardShadow: 'base',
    cardStackPadding: [6, 8, 12],
    cardBorderRadius: '24px',
    maxWidth: undefined,
    titleInsideCard: false,
  },
  flowitemConf: {
    showIntro: false,
    introMaxWidth: 'container.md',
    introBgColor: '#FFF',
    introColor: '',
  },
  translations: {
    // Default configuration supports all languages
    supportedLanguages: Object.values(Locale),
    resources: {},
    fallbackLanguage: Locale.FI,
    fallbackResources,
  },
  chat: {
    enableChat: true,
    isDevelopment: false,
    doctorOfflineMsgDelay: 5,
    doctorOfflineMsgMode: 'systemMessage',
  },
  homeScreen: Home,
  styles: {
    inputBorderColor: '#8D94A0',
    searchBorderWidth: '1px',
    pageBgColor: '#FFFFFF',
  },
};

export default configuration;
