export type ChatUser = {
  givenNames: string;
  lastName: string;
  hetu: string;
  userGuid: string;
  status: ChatStatus;
  chatRoomId?: string;
  userSessionIdTemp?: string | null;
};

export enum ChatStatus {
  LoggedIn = 'loggedIn',
  LoggedOut = 'loggedOut',
  SessionExpired = 'sessionExpired',
}

export type ChatSettings = object; //{};
