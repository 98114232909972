import { Container, SlideFade, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom-v5-compat';
import { DevGuestSessionParams, useGetAuthenticationQuery } from '~src/api';
import { useEnterToChatRoom } from '~src/chat/useEnterToChatroom';
import { useSetChatRoomId } from '~src/chat/useSetChatRoomId';
import { AuthenticationStatus } from '~src/components/Authentication/Authentication';
import { chat } from '~src/configurations';
import Card, { CardContentStack } from '../../Card';
import Loading from '../../Loading';
import { Dev_ChatUserOverrideModal } from './Dev_ChatUserOverrideModal';

/**
 * Authentication callback component for digitk chat authentication
 */
const ChatAuthCallback: React.FC = () => {
  const { chatRoomId } = useSetChatRoomId();
  const { answerId } = useParams<{
    answerId: string;
  }>();
  const reTryUrl = `/chat/login/${chatRoomId}?retry=true`;
  const isDev = !!chat.isDevelopment;
  const [showDevUserModal, setShowDevUserModal] = useState<boolean>(false);
  const {
    jumpInToRoom,
    createGuestSessionParams: { loading: isSessionLoading },
  } = useEnterToChatRoom(chatRoomId || '');

  const [amountOfRefetches, setAmountOfRefetches] = React.useState<number>(0);
  const { t } = useTranslation();
  const history = useHistory();
  const [searchParams] = useSearchParams();
  const kuuraAuthId = searchParams.get('state') || false;
  const isSlide = history.action === 'PUSH';

  const { data, refetch, loading } = useGetAuthenticationQuery({
    skip: !answerId || !kuuraAuthId,
    variables: {
      id: answerId,
      kuuraAuthId: Number(kuuraAuthId),
    },
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      const { status = AuthenticationStatus.ERROR } = data.authentication;
      handleRedirect(status as AuthenticationStatus);
    },
    onError() {
      handleRedirect(AuthenticationStatus.ERROR);
    },
  });
  const { hetu, givenNames, lastName } = data?.authentication || {};

  const onSuccess = () =>
    isDev ? setShowDevUserModal(true) : jumpInToRoom(answerId);

  if (!answerId || !chatRoomId || !kuuraAuthId) {
    history.push('/chat/login');
    return null;
  }

  function handleRedirect(status: AuthenticationStatus) {
    switch (status) {
      case AuthenticationStatus.SUCCESS:
        onSuccess();
        break;
      case AuthenticationStatus.ERROR:
      case AuthenticationStatus.UNKNOWN:
        history.push(reTryUrl);
        break;
      case AuthenticationStatus.INIT:
        // If kuura auth callback to backend is not yet completed, try refetch status 5 times with 1s interval
        if (amountOfRefetches < 5) {
          setTimeout(() => {
            setAmountOfRefetches(amountOfRefetches + 1);
            refetch();
          }, 1000);
        } else {
          history.push(reTryUrl);
        }
        break;
      default:
        history.push('/');
        break;
    }
  }

  return (
    <>
      <Container
        key={answerId}
        px={[4, 8, 16]}
        py={4}
        sx={{
          '.chakra-offset-slide > *': {
            overflow: 'visible',
          },
        }}
      >
        <SlideFade in={true} offsetX={isSlide ? 300 : 0} offsetY={0}>
          <Card>
            <CardContentStack mb={[4, null, 8]}>
              <Loading />
              <Text>{t('authentication.callback.loading')}</Text>
            </CardContentStack>
          </Card>
        </SlideFade>
      </Container>
      {isDev && showDevUserModal && (
        <Dev_ChatUserOverrideModal
          loading={loading || !!isSessionLoading}
          onConfirm={(props?: DevGuestSessionParams) =>
            jumpInToRoom(answerId, props)
          }
          defaultUser={{
            hetu,
            givenNames,
            lastName,
          }}
        />
      )}
    </>
  );
};

export { ChatAuthCallback };
