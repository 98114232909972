import React from 'react';

import Discussion from '~/src/components/Chat/Discussion';
import ChatHeader from '~/src/components/Chat/ChatHeader';
import { mainContentId } from '~/src/constants';
import { useSetChatRoomId } from '~src/chat/useSetChatRoomId';
import { useBrowserTitleNewMessageIndicator } from '~src/chat/useBrowserTitleNewMessageIndicator';

const ChatDiscussion: React.FC = () => {
  useSetChatRoomId();
  useBrowserTitleNewMessageIndicator();

  return (
    <>
      <ChatHeader />
      <main id={mainContentId} style={{ marginBottom: -32 }}>
        <Discussion />
      </main>
    </>
  );
};

export default ChatDiscussion;
