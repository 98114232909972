import type { IConfigurationPartial } from '~/src/types';
import { Locale, Configuration } from '~/src/types';
import { localizations } from '~/src/localizations';
import colors from '~/src/theme/foundations/colorsDokport';
import appLogo from '~/src/images/logo-dokport.png';
import HomeDokport from '~src/screens/Home/HomeDokport';

const configuration: IConfigurationPartial = {
  searchEnabled: false,
  chat: {
    enableChat: true,
    doctorOfflineMsgDelay: 5, // minutes
  },
  colors,
  header: {
    appLogo: appLogo,
    appLogoPaddingTop: '1em',
    appLogoWidth: '120px',
  },
  flowInit: {
    cardStackPadding: [1, 2, 3],
    maxWidth: 'container.md',
    titleInsideCard: true,
  },
  translations: {
    supportedLanguages: [Locale.FI], //, Locale.EN
    resources: localizations[Configuration.Dokport] ?? {},
  },
  styles: {
    pageBgColor: '#EDEDE8',
    cardBgColor: 'white',
    progressBarBgColor: 'primary.50',
  },
  fonts: {
    heading:
      'Metropolis, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Open Sans, Helvetica Neue, sans-serif',
    body: 'Open Sans, Helvetica Neue, sans-serif',
  },
  homeScreen: HomeDokport,
  siteTokenVerification: {
    dummyTokens: [
      'lwfYOYtErFkYg3x3JgKmo2XiwVxOHicCkC8qfMfVs5soJPOTc5gT5PemT4Ug1tWW',
      'fU57hFLZSyc5s0q8lgOJ4JIguKK87Gga9k4h2at9ZEzv6COZ7qmZLvfgoAdYhoF9',
      'RI4tvaso4CFvVvMjKmZuQGHVX3RBogrSTgAOixPdWSuzKmORSobEvwYJoYsALt9c',
      'qpUFFKz3j2OGXiJo2wZPWQvVFY4uTqhghQzIRD03bk4p2IRlqCgovKbT5LA2v6PL',
      'HGKpOgb6QFf7WVe3Aa0f1UiHyBzU5ELpVlpLhUbY0uvxTV9x6wBCD3IAR2qOUTP6',
    ],
    siteToRedirect: 'https://www.dokport.com/',
    tokenExpHours: 24,
  },
};

export default configuration;
