import { useContext } from 'react';
import { ChatContext } from '~src/chat/chat-context';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const useBrowserTitleNewMessageIndicator = () => {
  const { getChatRoomById, activeRoomId } = useContext(ChatContext);
  const [originalTitle, setOriginalTitle] = useState('');
  const { t } = useTranslation();
  const [showOriginal, setShowOriginal] = useState(true);

  const unreadCount =
    getChatRoomById({ roomId: activeRoomId })?.unreadMessageCount || 0;

  const blinkInterval = 1500;

  useEffect(() => {
    setOriginalTitle(document.title);
  }, []);

  useEffect(() => {
    // Create interval to switch title between original and unread message notification msg
    let interval: NodeJS.Timeout | undefined;
    if (unreadCount > 0) {
      interval = setInterval(() => {
        setShowOriginal((prev) => !prev);
      }, blinkInterval);
    } else {
      setShowOriginal(true);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [unreadCount, blinkInterval]);

  useEffect(() => {
    const title =
      showOriginal || unreadCount === 0
        ? originalTitle
        : t('chat.discussion.unreadMessageTitle', { unreadCount });

    document.title = title;
  }, [showOriginal, unreadCount, originalTitle, t]);
};

export { useBrowserTitleNewMessageIndicator };
