// SessionExpiredModal is displayed when the chat status is set to
// `ChatStatus.SessionExpired` and `userSessionIdTemp` has a value. This occurs
// after 30 minutes of user inactivity, when the backend sends an
// "invalidSession" error message.
//
// When this happens:
// - The ChatContext is cleared except for setting:
//   - `status` to `ChatStatus.SessionExpired`
//   - `userSessionIdTemp` to `userSessionId`
//
// The `userSessionIdTemp` is necessary for creating a new guest session if the
// user clicks "Jatka" in the SessionExpiredModal.
//
// Using `userSessionIdTemp` instead of the main `userSessionId` helps to
// prevent conflicts with other session logic. For example:
// - During survey flow, `userSessionId` is stored in localStorage to
//   automatically create a new guest session if found. To avoid interference,
//   the main session data is cleared while temporarily retaining the session
//   ID in `userSessionIdTemp`.

import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  Heading,
  AlertDialogBody,
  Text,
  Button,
  Flex,
} from '@chakra-ui/react';
import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import { ChatContext, CHAT_LOGOUT_URL_PATH } from '~src/chat/chat-context';
import { ChatStatus } from '~src/chat/chat-types';
import { useEnterToChatRoom } from '~src/chat/useEnterToChatroom';
import { chat } from '~src/configurations';

const SessionExpiredModal = () => {
  const {
    clearChatContext,
    userInfo: { chatRoomId, userSessionIdTemp, hetu, givenNames, lastName },
  } = useContext(ChatContext);
  const cancelRef: React.RefObject<any> = useRef();
  const history = useHistory();
  const {
    jumpInToRoom,
    createGuestSessionParams: { reset, loading },
  } = useEnterToChatRoom(chatRoomId || '');

  const logout = useCallback(() => {
    reset?.();
    clearChatContext({ status: ChatStatus.LoggedOut });
    history.push(CHAT_LOGOUT_URL_PATH + '/' + chatRoomId);
  }, [chatRoomId, clearChatContext, history, reset]);

  useEffect(() => {
    const timeoutId = setTimeout(() => logout(), 7200000); // 2 hours
    return () => clearTimeout(timeoutId); // Cleanup the timeout on unmount
  }, [logout]);

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        isOpen={true}
        leastDestructiveRef={cancelRef}
        onClose={() => {}}
      >
        <AlertDialogOverlay backdropFilter="blur(10px)" />

        <AlertDialogContent
          borderRadius="2rem"
          marginX={4}
          padding={8}
          textAlign="center"
          blur={true}
          maxWidth={['calc(100% - 2rem)', '28rem']}
        >
          <AlertDialogHeader
            as={Heading}
            fontWeight="bold"
            padding={0}
            size="sm"
          >
            Chat-keskustelu on aikakatkaistu turvallisuussyistä.
          </AlertDialogHeader>

          <AlertDialogBody maxWidth="23rem" paddingBottom={8} paddingTop={4}>
            <Text>
              <br></br>
              Voit jatkaa keskustelua valitsemalla &quot;Jatka&quot; tai
              kirjautua ulos. Pääset aina takaisin keskusteluun sähköpostisi
              linkin kautta.
            </Text>
            <Flex justifyContent="center" gap={4} mt={4} direction="column">
              <Button
                onClick={() =>
                  void jumpInToRoom(
                    userSessionIdTemp || '',
                    chat.isDevelopment
                      ? { hetu, givenNames, lastName }
                      : undefined,
                  )
                }
                isLoading={loading}
                colorScheme="primary"
                variant="solid"
                width="100%"
              >
                Jatka
              </Button>
              <Button
                onClick={() => logout()}
                colorScheme="primary"
                variant="outline"
                width="100%"
                disabled={loading}
              >
                Kirjaudu ulos
              </Button>
            </Flex>
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export { SessionExpiredModal };
