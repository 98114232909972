import { useCallback, useState } from 'react';
import {
  ChatContextType,
  CHAT_LOGIN_URL_PATH,
  defaultChatSettings,
  defaultChatUser,
  PING_INTERVAL,
  PING_TIMEOUT,
} from './chat-context';
import { v4 as uuidv4 } from 'uuid';
import { ChatUser, ChatSettings, ChatStatus } from './chat-types';
import {
  ListRoomsFilter,
  WebsocketMessageType,
  EnterRoomRequestMessage,
  GetRoomMessagesRequest,
  ChatMessageDeliveryStatus,
  ChatMessageContentType,
  RoomInfo,
  ChatterStatus,
  ExitRoomRequestMessage,
  ListedMessage,
  ChatErrorCode,
  ClientStatusUpdateMessage,
  NewChatMessage,
} from './copied-schemas/backend-api-types';
import { useDuodecimWebsocket } from './useDuodecimWebsocket';
import {
  chatLogger,
  isClientStatusUpdateMessage,
  isEnterRoomRequestMessage,
  isErrorMessage,
  isExitRoomRequestMessage,
  isGetRoomMessagesRequest,
  isListRoomsRequestMessage,
  isNewChatMessage,
  isPingMessage,
  isRoomListMessage,
  isRoomMemberListChangeMessage,
  isRoomMessageListMessage,
  isUpdateMessageStatusMessage,
  isUpdateRoomStatusMessage,
  isValidChatMessage,
  sortChatRooms,
  updateByProperty,
  WebsocketMessage,
} from './chat-utils';
import { find, uniqBy } from 'lodash';
import { useTypingStatus } from './useTypingStatus';
import { useChatTokens } from './useChatTokens';
import { useHistory } from 'react-router-dom';
import { chat } from '~src/configurations';
import { useToast } from '@chakra-ui/react';

const defaultRoomsFilter: ListRoomsFilter = {
  activeWithinHours: 999999,
};

/**
 * Custom hook to handle chat messaging over websocket
 * - function names starting handle<something> handles incoming websocket messages
 * - function names starting send<something> sends messages over websocket
 *
 * @param duodecimApp
 * @returns {ChatContextType}
 */
const useChat = (): ChatContextType => {
  const history = useHistory();
  const toast = useToast();
  const [chatError, setChatError] = useState<string | null>(null);
  const [userInfo, setUserInfo] = useState<ChatUser>(defaultChatUser);
  const {
    givenNames: patientGivenNames,
    lastName: patientLastName,
    chatRoomId,
    userGuid,
    status: userChatStatus,
  } = userInfo;
  const { accessToken, destroyChatSessionStorage, ...restChatTokenParams } =
    useChatTokens(chatRoomId);
  const [chatSettings, setChatSettings] =
    useState<ChatSettings>(defaultChatSettings);
  const [activeRoomId, setActiveRoomId] = useState<string | null>(null);
  const [chatRooms, setChatRooms] = useState<
    ChatContextType['chatRooms'] | null
  >(null);
  const [roomsMessagesLoaded, setRoomsMessagesLoaded] = useState<string[]>([]);

  const [roomsMessages, setRoomsMessages] = useState<
    ChatContextType['roomsMessages']
  >({});

  const upsertChatSettings = (
    settings: Partial<ChatContextType['chatSettings']>,
  ) => setChatSettings((prev) => ({ ...prev, ...settings }));

  const getChatRoomById = ({
    roomId,
  }: {
    roomId: string | null | undefined;
  }): RoomInfo | null => (roomId ? find(chatRooms, { roomId }) || null : null);

  /**
   * udpate unread message count for room
   *  - increase count by amount of "increaseBy" -parameter
   *
   * @param roomId
   * @param increaseBy
   */
  const updateRoomUnreadMessageCount = (props: {
    roomId: string;
    increaseBy: number;
  }) => {
    const { roomId, increaseBy } = props;
    setChatRooms((prev) => {
      const room = find(prev, { roomId });
      if (!room) return prev;

      return sortChatRooms(
        updateByProperty(prev || [], 'roomId', roomId, {
          unreadMessageCount: Math.max(
            0, // Ensures the count is not smaller than 0
            room.unreadMessageCount + increaseBy,
          ),
        }),
      );
    });
  };

  // websocket message handlers
  const handleMessageFunctions: Partial<
    Record<WebsocketMessageType, (message: WebsocketMessage) => void>
  > = {
    [WebsocketMessageType.ClientStatusUpdateMessage]:
      handleClientStatusUpdateMessage,
    [WebsocketMessageType.RoomListMessage]: handleRoomListMessage,
    [WebsocketMessageType.NewChatMessage]: handleNewChatMessage,
    [WebsocketMessageType.UpdateRoomStatusMessage]:
      handleUpdateRoomStatusMessage,
    [WebsocketMessageType.RoomMessagesListMessage]:
      handleRoomMessagesListMessage,
    [WebsocketMessageType.UpdateMessageStatusMessage]:
      handleUpdateMessageStatusMessage,
    [WebsocketMessageType.PingMessage]: handlePingMessage,
    [WebsocketMessageType.ErrorMessage]: handleErrorMessage,
    [WebsocketMessageType.RoomMemberListChangeMessage]:
      handleRoomMemberListChangeMessage,
  };

  const validateAndParseMessage = (
    message: unknown,
  ): WebsocketMessage | null => {
    const parsedMessage: unknown = JSON.parse(message as string);
    if (!isValidChatMessage(parsedMessage)) {
      chatLogger('Not valid websocket message received', parsedMessage);
      return null;
    }
    return parsedMessage as WebsocketMessage;
  };

  const { sendJsonMessage } = useDuodecimWebsocket(accessToken, {
    onMessage: (event: MessageEvent<string>) => {
      if (!accessToken) return;
      try {
        const message = validateAndParseMessage(event.data);
        if (!message) return;

        const handlerFunction = handleMessageFunctions[message.messageType];
        handlerFunction
          ? handlerFunction(message)
          : chatLogger('No handler function for message type', message);
      } catch (error) {
        chatLogger('Error handling websocket chat message', error, event);
      }
    },
    heartbeat: {
      message: JSON.stringify({
        messageType: WebsocketMessageType.PingMessage,
        timeStamp: new Date().toISOString(),
      }),
      //returnMessage: (string) - we can't use cause we dont know ping message timeStamp server sent
      timeout: PING_TIMEOUT, // 30 s, if no response is received, the connection will be closed
      interval: PING_INTERVAL, // every 30 seconds, a ping message will be sent
    },
  });

  function handlePingMessage(message: WebsocketMessage) {
    if (!isPingMessage(message)) return;
    chatLogger('PingMessage received', message);
  }

  function handleClientStatusUpdateMessage(message: WebsocketMessage): void {
    if (!isClientStatusUpdateMessage(message)) return;
    chatLogger('ClientStatusUpdateMessage received', message);
    const { firstName, lastName, status, roomId, guid } = message;

    if (!userGuid && guid) {
      const isAfterEnteredRoom =
        roomId && userChatStatus === ChatStatus.LoggedIn;
      if (isAfterEnteredRoom) {
        chatLogger('ClientStatusUpdateMessage received after entered room');
        setUserInfo((prev) => ({
          ...prev,
          givenNames: firstName,
          lastName,
          userGuid: guid,
        }));
        setActiveRoom({ roomId });
      }

      const isAfterAuthMessage =
        !roomId && userChatStatus === ChatStatus.LoggedOut;
      if (isAfterAuthMessage) {
        chatLogger(
          'ClientStatusUpdateMessage received after AuthorizationMessage',
        );
        setUserInfo((prev) => ({
          ...prev,
          givenNames: firstName,
          lastName,
          status: ChatStatus.LoggedIn,
        }));
        sendListRoomsRequestMessage();
      }
    }

    const memberExists = getChatRoomById({ roomId })?.members.find(
      (member) => member.guid === guid,
    );

    // - skip if member already removed from room
    if (!roomId || !guid || !memberExists) return;
    // update patient status on specific room
    setChatRooms((prev: RoomInfo[] | null) =>
      updateByProperty(prev || [], 'roomId', roomId, {
        members: updateByProperty(
          find(prev, { roomId })?.members || [],
          'guid',
          guid,
          { status },
        ),
      }),
    );
  }

  function setActiveRoom({ roomId }: { roomId: string | null }): void {
    /* load room messages if not yet loaded */
    if (roomId && !roomsMessagesLoaded.includes(roomId)) {
      chatLogger(
        'Active room set, messages not loaded, GetRoomMessagesRequestMessage -> expecting RoomMessagesListMessage. roomId',
        roomId,
      );
      sendGetRoomMessagesRequestMessage({
        roomId,
        take: 10000, // TODO-v2: should we add infinite scroll for messages? atleast amount of chatRoom.unreadMessageCount ?
      });
    }

    setActiveRoomId(roomId);
    return;
  }

  /**
   * Update client status on specific room, only Online / Writing -allowed
   *
   * @param {status: ChatterStatus, roomId: string}
   * @returns {void}
   */
  const sendClientStatusUpdateMessage = useCallback(
    ({
      status = ChatterStatus.Online,
      roomId,
    }: {
      status: ChatterStatus;
      roomId: string;
    }): void => {
      const payload: ClientStatusUpdateMessage = {
        messageType: WebsocketMessageType.ClientStatusUpdateMessage,
        timeStamp: new Date().toISOString(),
        firstName: patientGivenNames,
        lastName: patientLastName,
        status,
        roomId,
      };
      if (!isClientStatusUpdateMessage(payload)) return;
      sendJsonMessage(payload);
    },
    [patientGivenNames, patientLastName, sendJsonMessage],
  );

  const { startTyping, stopTyping } = useTypingStatus(
    activeRoomId,
    sendClientStatusUpdateMessage,
  );

  function sendListRoomsRequestMessage(props?: {
    filter: ListRoomsFilter;
  }): void {
    const { filter } = props ?? {};
    const payload = {
      messageType: WebsocketMessageType.ListRoomsRequestMessage,
      timeStamp: new Date().toISOString(),
      filter: {
        ...defaultRoomsFilter,
        ...filter,
      },
    };
    if (!isListRoomsRequestMessage(payload)) return;
    chatLogger(
      'sent ListRoomsRequestMessage, expecting RoomListMessage',
      payload,
    );

    sendJsonMessage(payload);
    return;
  }

  /** Response message to ListRoomsRequestMessage */
  function handleRoomListMessage(message: WebsocketMessage): void {
    if (!isRoomListMessage(message)) return;
    chatLogger('RoomListMessage received', message);
    const newRooms = message.rooms ?? [];
    setChatRooms(sortChatRooms(newRooms));

    if (chatRoomId && newRooms?.find((room) => room.roomId === chatRoomId)) {
      sendEnterRoomRequestMessage({
        roomId: chatRoomId,
      });
    } else {
      setChatError('Chat -huonetta ei löytynyt tunnistautuneelle käyttäjälle');
      history.push(`${CHAT_LOGIN_URL_PATH}/${chatRoomId}`);
    }
  }

  const sendNewChatMessage = (roomId: string, message: string): void => {
    const newMessage: NewChatMessage = {
      messageType: WebsocketMessageType.NewChatMessage,
      timeStamp: new Date().toISOString(),
      messageId: uuidv4(),
      contentType: ChatMessageContentType.Text,
      content: message,
      roomId: roomId,
      senderFirstName: patientGivenNames,
      senderLastName: patientLastName,
    };
    if (!isNewChatMessage(newMessage)) return;
    sendJsonMessage(newMessage);
  };

  function handleNewChatMessage(message: WebsocketMessage): void {
    if (!isNewChatMessage(message)) return;
    chatLogger('NewChatMessage received', message);
    const { roomId, senderGuid } = message;
    if (!roomId) return;

    const isOwnMessage = senderGuid === userGuid;
    const newMessage = {
      ...message,
      status: ChatMessageDeliveryStatus.Delivered,
    };
    setRoomsMessages((prev) => ({
      ...prev,
      [roomId]: uniqBy([...(prev[roomId] ?? []), newMessage], 'messageId'),
    }));

    chatLogger(
      `${
        isOwnMessage ? 'Own' : senderGuid ? 'Doctor send' : 'System message'
      } NewChatMessage `,
      message,
    );

    //if not own message increase unread message count by 1
    !isOwnMessage && updateRoomUnreadMessageCount({ roomId, increaseBy: 1 });
  }

  function sendEnterRoomRequestMessage({ roomId }: { roomId: string }): void {
    const payload: EnterRoomRequestMessage = {
      messageType: WebsocketMessageType.EnterRoomRequestMessage,
      timeStamp: new Date().toISOString(),
      roomId,
    };
    if (!isEnterRoomRequestMessage(payload)) return;

    sendJsonMessage(payload);
    chatLogger(
      'sent EnterRoomRequestMessage, expecting ClientStatusUpdateMessage',
      payload,
    );
  }

  /**
   * @param {roomId: string}
   * @returns {void}
   */
  const sendExitRoomRequestMessage = useCallback(
    ({ roomId }: { roomId: string }) => {
      const payload: ExitRoomRequestMessage = {
        messageType: WebsocketMessageType.ExitRoomRequestMessage,
        timeStamp: new Date().toISOString(),
        roomId,
      };
      if (!isExitRoomRequestMessage(payload)) return;
      sendJsonMessage(payload);
      chatLogger(
        'sent ExitRoomRequestMessage, expecting ClientStatusUpdateMessage',
        payload,
      );
    },
    [sendJsonMessage],
  );

  function handleUpdateRoomStatusMessage(message: WebsocketMessage): void {
    if (!isUpdateRoomStatusMessage(message)) return;

    const { status, roomId } = message;
    if (!roomId || !status) return;
    setChatRooms((prev) =>
      updateByProperty(prev || [], 'roomId', roomId, { status }),
    );
  }

  function sendGetRoomMessagesRequestMessage(props: {
    roomId: string;
    since?: string;
    take?: number;
    skip?: number;
  }): void {
    const payload: GetRoomMessagesRequest = {
      messageType: WebsocketMessageType.GetRoomMessagesRequestMessage,
      timeStamp: new Date().toISOString(),
      ...props,
    };
    if (!isGetRoomMessagesRequest(payload)) return;

    chatLogger(
      'GetRoomMessagesRequestMessage sent, expexting RoomMessageListMessage -event',
      payload,
    );
    sendJsonMessage(payload);
  }

  function handleRoomMessagesListMessage(message: WebsocketMessage): void {
    if (!isRoomMessageListMessage(message)) return;
    chatLogger('RoomMessagesListMessage received', message);
    try {
      const { roomId, messages } = message;
      if (!messages) return;
      setRoomsMessages((prev) => ({
        ...prev,
        [roomId]: uniqBy(
          [...(roomsMessages[roomId] || []), ...messages],
          'messageId',
        ),
      }));
      // TODO-v2: add infinite scroll for messages?
      setRoomsMessagesLoaded((prev) => [...prev, roomId]);
    } catch (error) {
      chatLogger('Error handling RoomMessagesListMessage', error, message);
    }
  }

  function sendUpdateMessageStatusMessage({
    roomId,
    messageId,
    status,
  }: {
    roomId: string | null;
    messageId: string;
    status: ChatMessageDeliveryStatus;
  }): void {
    const payload = {
      messageType: WebsocketMessageType.UpdateMessageStatusMessage,
      timeStamp: new Date().toISOString(),
      messageId: messageId,
      status: status ?? ChatMessageDeliveryStatus.Read,
    };
    if (!isUpdateMessageStatusMessage(payload) || !roomId) return;
    chatLogger(
      'sent UpdateMessageStatusMessage, expecting UpdateMessageStatusMessage',
      payload,
    );
    sendJsonMessage(payload);
  }

  function handleUpdateMessageStatusMessage(message: WebsocketMessage): void {
    if (!isUpdateMessageStatusMessage(message) || !message?.status) return;
    const { roomId, status, messageId } = message;

    if (!roomId) return; // should not be possible

    // update chat room unread message count
    // HOX: when last message is read, all earlier messages are marked as read also.
    // - server send back UpdateMessageStatusMessage for every message marked as read
    updateRoomUnreadMessageCount({ roomId, increaseBy: -1 });

    // update message status
    setRoomsMessages((prev: Record<string, ListedMessage[]>) => ({
      ...prev,
      [roomId]: updateByProperty(prev[roomId], 'messageId', messageId, {
        status,
      }),
    }));
    chatLogger('UpdateMessageStatusMessage received', message);
  }

  /*
   * received when doctor sent patient back to queue in D-platform
   */
  function handleRoomMemberListChangeMessage(message: WebsocketMessage): void {
    if (!isRoomMemberListChangeMessage(message)) return;
    chatLogger('RoomMemberListChangeMessage received', message);
    const { roomId, members } = message;
    if (!roomId) return;
    const room = getChatRoomById({ roomId });
    if (!room) return;

    setChatRooms((prev) =>
      updateByProperty(prev || [], 'roomId', roomId, {
        members,
      }),
    );
  }

  function handleErrorMessage(message: WebsocketMessage): void {
    if (!isErrorMessage(message)) return;
    chatLogger('ErrorMessage received', message, chatRoomId);
    if (message.errorCode === ChatErrorCode.InvalidSession && chatRoomId) {
      // if userSessionId found in sessionStorage,
      // show session expired message modal (Discussion.tsx) and ask if user wants to continue
      if (restChatTokenParams.userSessionId) {
        const { hetu, givenNames, lastName } = userInfo;
        clearChatContext({
          status: ChatStatus.SessionExpired,
          userSessionIdTemp: restChatTokenParams.userSessionId,
          ...(chat.isDevelopment ? { hetu, givenNames, lastName } : {}),
        });
      } else if (userInfo.status !== ChatStatus.SessionExpired) {
        setChatError('Chat -istuntosi on vanhentunut, kirjaudu uudelleen');
        history.push(`${CHAT_LOGIN_URL_PATH}/${chatRoomId}`);
      }
    } else {
      toast({
        title: 'Virhe chat -keskustelussa, yritä uudestaan!',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  }

  /**
   *
   * @param userInfo user information to be kept in chat context
   *
   * FYI: wrapped in useCallback to prevent unnecessary re-renders and to prevent SessionExpiredModal
   * useEffect unmounting and mounting again repeatedly
   */
  const clearChatContext = useCallback(
    (userInfo: Partial<ChatUser> = {}) => {
      activeRoomId && sendExitRoomRequestMessage({ roomId: activeRoomId });
      setUserInfo((prev) => ({
        ...defaultChatUser,
        chatRoomId: prev.chatRoomId,
        ...userInfo,
      }));
      setActiveRoomId(null);
      setChatRooms(null);
      setRoomsMessages({});
      setRoomsMessagesLoaded([]);
      destroyChatSessionStorage();
    },
    [activeRoomId, destroyChatSessionStorage, sendExitRoomRequestMessage],
  );

  /*
   * Add system message to roomsMessages
   * - used for system messages that exists only in frontend like "Doctor is offline..."
   * - FYI: need to wrapped in useCallback to avoid unneeeded useEffect unmounts/mounts
   */
  const addSystemMessage = useCallback(
    ({ message, roomId }: { message: string; roomId: string }) => {
      const newMessage = {
        createdTimestamp: new Date().toISOString(),
        messageId: uuidv4(),
        contentType: ChatMessageContentType.Text,
        content: message,
        roomId: roomId,
        status: ChatMessageDeliveryStatus.Delivered,
      };

      setRoomsMessages((prev) => ({
        ...prev,
        [roomId]: uniqBy([...(prev[roomId] ?? []), newMessage], 'messageId'),
      }));
    },
    [],
  );

  return {
    chatError,
    setChatError,
    addSystemMessage,
    userInfo,
    setUserInfo,
    chatSettings,
    upsertChatSettings,
    activeRoomId,
    setActiveRoom,
    chatRooms,
    setChatRooms,
    sendNewChatMessage,
    sendUpdateMessageStatusMessage,
    sendClientStatusUpdateMessage,
    sendEnterRoomRequestMessage,
    sendExitRoomRequestMessage,
    sendListRoomsRequestMessage,
    getRoomMessages: ({ roomId }: { roomId: string }) =>
      roomsMessages[roomId] ?? [],
    roomsMessages,
    roomsMessagesLoaded,
    sendWsMessage: sendJsonMessage,
    getChatRoomById,
    startTyping,
    stopTyping,
    clearChatContext,
    chatTokens: {
      accessToken,
      destroyChatSessionStorage,
      ...restChatTokenParams,
    },
  };
};

export { useChat };
