export interface APIKey {
  /** @format uuid */
  id: string;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  apiKey: string;
  externalCompany: ExternalCompany;
  /** @format date-time */
  validUntil?: string | null;
  allowedIpAddresses: string[];
  /** @minItems 1 */
  allowedQueues: SimplePatientQueueInfo[];
  apiKeyType: ApiKeyType;
}

export interface AccountInfo {
  /** Registration status information, if true registration ok */
  registrationOk?: boolean | null;
  /** User account activity information, if true account active */
  isActive?: boolean | null;
  /** Valid x509 certificates currently registered for client */
  validCertificates: X509CertificateInfo[];
  additionalProperties?: Record<string, unknown>;
}

export interface ActivitySettings {
  mode: ActivitySettingsMode;
  /**
   * @format date
   * @example "2005-01-20"
   */
  startDate?: string | null;
  /**
   * @format date
   * @example "2005-01-20"
   */
  endDate?: string | null;
}

export interface AddApiKeyRequest {
  /** @format uuid */
  externalCompanyId: string;
  /** @format date-time */
  validUntil?: string | null;
  allowedIpAddresses?: string[] | null;
  /** Relevant only for requests of type ApiKeyType.AddQueuedPatients */
  allowedQueues?: string[] | null;
  apiKeyType: ApiKeyType;
}

export interface AddApiKeyResponse {
  item: APIKey;
}

export interface AddExternalCompanyRequest {
  /** @format uuid */
  id?: string | null;
  /**
   * @minLength 1
   * @maxLength 255
   */
  name: string;
  /**
   * Finnish business ID number 'Y-tunnus'.
   * @maxLength 255
   */
  companyId?: string | null;
  /** @maxLength 255 */
  oid?: string | null;
  /**
   * If not null, SMS messages sent on behalf of the company will have this number as sender Validates that the parameter follows international phone number format requirements or is an Alphanumeric Sender ID:
   * - For numbers starting with '+358': ^\+358\d{6,12}$
   * - For other numbers: ^\+\d{1,3}\d{5,15}$
   * - Alphanumeric Sender ID: See e.g. twilio.com/docs/glossary/what-alphanumeric-sender-id
   * @maxLength 19
   */
  sms?: string | null;
  /**
   * If not null, Email messages sent on behalf of the company will have this address as sender
   * If omitted, address configured at server level Mailgun options is used instead. Validates values in requests using the method System.ComponentModel.DataAnnotations.EmailAddressAttribute.IsValid.
   * - The validation code is available on GitHub (https://github.com/dotnet/runtime).
   * @maxLength 255
   */
  email?: string | null;
  /**
   * Required for external companies that use chat feature and want emails about chat to be sent to patients.
   * If the url string contains {0} it will be replaced by chat room id.
   * Missing value means no chat-related emails will be sent to patients for this external company. Validates values in requests using the method Uri.IsWellFormedUriString.
   * - For more information, see https://learn.microsoft.com/en-us/dotnet/api/system.uri.iswellformeduristring.
   * @maxLength 2048
   */
  chatUrl?: string | null;
  /**
   * Required for external companies that want to ask patients for feedback.
   * Contains the url for the feedback survey page, included in email sent to patients about the feedback survey.
   * Missing or empty value means emails with feedback survey link will not be sent to patients. Validates values in requests using the method Uri.IsWellFormedUriString.
   * - For more information, see https://learn.microsoft.com/en-us/dotnet/api/system.uri.iswellformeduristring.
   * @maxLength 2048
   */
  feedbackSurveyUrl?: string | null;
  /**
   * Amount of hours from when patient was last opened, after which feedback survey will be sent to patient.
   * Missing or negative value means these emails will not be sent.
   * @format int32
   * @min -1
   * @max 10000
   */
  feedbackSurveyDelayInHours?: number | null;
  /**
   * Mailgun url used for emails sent on behalf of the company. Something like https://api.mailgun.net/v3/mg.kuurahealth.com/
   * If null or undefined, url configured at server level Mailgun options is used instead. Validates values in requests using the method Uri.IsWellFormedUriString.
   * - For more information, see https://learn.microsoft.com/en-us/dotnet/api/system.uri.iswellformeduristring.
   * @maxLength 2048
   */
  mailgunBaseUrl?: string | null;
  /**
   * Mailgun API key used for emails sent on behalf of the company.
   * If null or undefined, API key configured at server level Mailgun options is used instead.
   * @maxLength 256
   */
  mailgunApiKey?: string | null;
}

export interface AddExternalCompanyResponse {
  item: ExternalCompany;
}

export interface AddPatientQueueRequest {
  /**
   * If the value is not globally unique, the error type 35 'InvalidName' is returned.
   * @minLength 1
   * @maxLength 100
   */
  name: string;
  /** @format int32 */
  responseTimeSeconds?: number | null;
  /** @maxLength 100 */
  connectionMethod?: string | null;
  isFifo: boolean;
  isPublic: boolean;
  /** @maxLength 1000 */
  description?: string | null;
  hasGenderRestriction: boolean;
  genderRestriction?: Gender | null;
  /** If true, then at least one of the fields 'minAge' and 'maxAge' must be defined. */
  hasAgeLimit: boolean;
  /**
   * If defined along with the 'maxAge' field, this value must be smaller.
   * @format int32
   * @min 0
   * @max 2147483647
   */
  minAge?: number | null;
  /**
   * If defined along with the 'minAge' field, this value must be higher.
   * @format int32
   * @min 0
   * @max 2147483647
   */
  maxAge?: number | null;
  costs?: PatientQueueCostsInfo | null;
  /**
   * If true, adding patient to queue sends an email to them with a chat link that they can use to chat with their doctor.
   * (Patient can join the chat after receiving the link, doctor joins the chat after taking the item from queue)
   * Missing value is interpreted as false.
   */
  chatEnabled?: boolean | null;
  /** Missing value is interpreted as true. */
  showPreliminaryInfo?: boolean | null;
  /** Missing value is interpreted as true. */
  showPreliminaryDescription?: boolean | null;
  /** Each object must have a unique registration number. */
  permittedUsers: PatientQueueInfoUser[];
  /** Each object must have a unique OID. */
  permittedOrganizations: PatientQueueInfoOrganization[];
  /** Each object must have a unique registration number. Only the owner of the queue can modify this information. */
  adminUsers: PatientQueueInfoUser[];
}

export interface AddPatientQueueResponse {
  /**
   * If the value is not globally unique, the error type 35 'InvalidName' is returned.
   * @minLength 1
   * @maxLength 100
   */
  name: string;
  /** @format int32 */
  responseTimeSeconds?: number | null;
  /** @maxLength 100 */
  connectionMethod?: string | null;
  isFifo: boolean;
  isPublic: boolean;
  /** @maxLength 1000 */
  description?: string | null;
  hasGenderRestriction: boolean;
  genderRestriction?: Gender | null;
  /** If true, then at least one of the fields 'minAge' and 'maxAge' must be defined. */
  hasAgeLimit: boolean;
  /**
   * If defined along with the 'maxAge' field, this value must be smaller.
   * @format int32
   * @min 0
   * @max 2147483647
   */
  minAge?: number | null;
  /**
   * If defined along with the 'minAge' field, this value must be higher.
   * @format int32
   * @min 0
   * @max 2147483647
   */
  maxAge?: number | null;
  costs?: PatientQueueCostsInfo | null;
  /**
   * If true, adding patient to queue sends an email to them with a chat link that they can use to chat with their doctor.
   * (Patient can join the chat after receiving the link, doctor joins the chat after taking the item from queue)
   * Missing value is interpreted as false.
   */
  chatEnabled?: boolean | null;
  /** Each object must have a unique registration number. */
  permittedUsers: PatientQueueInfoUser[];
  /** Each object must have a unique OID. */
  permittedOrganizations: PatientQueueInfoOrganization[];
  /** Each object must have a unique registration number. Only the owner of the queue can modify this information. */
  adminUsers: PatientQueueInfoUser[];
  /** @format uuid */
  guid: string;
  owner: PatientQueueInfoUser;
  showPreliminaryInfo: boolean;
  showPreliminaryDescription: boolean;
}

export interface AddPatientRequest {
  patient: Patient;
  /**
   * If true, enable chat for the created patient. Missing value is treated as false.
   * Only relevant if patient is created in 'open' state without queues - usually queue configuration determines chat presence.
   * Note that currently chat emails will not be sent to patients added through this interface, because link to chat interface is external company specific.
   */
  enableChat?: boolean | null;
}

export interface AddPatientResponse {
  /** @format uuid */
  patientGuid: string;
  patient: Patient;
}

export interface AddPatientTemplateRequest {
  template: SimplePatientTemplate;
}

export interface AddPatientTemplateResponse {
  template: PatientTemplate;
}

export interface AddPhrasesRequest {
  phrase: SimplePhrase;
}

export interface AddPhrasesResponse {
  phrase: Phrase;
}

export interface AddReferralRequest {
  formFields: ReferralFormFields;
}

export interface AddUserAgreementRequest {
  /**
   * @minLength 1
   * @maxLength 40000
   */
  html: string;
  isValid: boolean;
}

export interface AddUserAgreementResponse {
  agreement: UserAgreement;
}

export interface Address {
  /** @maxLength 255 */
  streetAddress?: string | null;
  /** @maxLength 255 */
  postalCode?: string | null;
  /** @maxLength 255 */
  city?: string | null;
  /** @maxLength 255 */
  country?: string | null;
}

export interface AdminGetPatientQueuesResponse {
  items: SimplePatientQueueInfo[];
}

export interface AdminLoginValidateAndConfirmRequest {
  /**
   * @minLength 1
   * @maxLength 40000
   */
  eventId: string;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  smartCardResponseData: string;
  isMobile: boolean;
}

export interface AppNotification {
  /** @format uuid */
  guid: string;
  /** @format date-time */
  createdTS: string;
  type: AppNotifictionType;
  /** @maxLength 40000 */
  patientFirstName?: string | null;
  /** @maxLength 40000 */
  patientLastName?: string | null;
  /** @maxLength 40000 */
  patientID?: string | null;
  isRead: boolean;
  patientQueues: PatientQueue[];
  /** @maxLength 40000 */
  content?: string | null;
}

export interface ArchiveEntryBrokenMessage {
  messageType: WebsocketMessageType;
  /**
   * Time when this websocket message was sent
   * @format date-time
   */
  timeStamp: string;
}

export interface ArchiveEntryMessage {
  messageType: WebsocketMessageType;
  /**
   * Time when this websocket message was sent
   * @format date-time
   */
  timeStamp: string;
  modificationType: ModificationType;
  entryType?: EntryType | null;
  status?: EntryStatus | null;
  primaryDataView?: DataView | null;
}

export interface AuthoringSettings {
  mode: ArchiveAuthoringMode;
  otherPerson?: ContentAuthor | null;
  supervisor?: ContentAuthor | null;
  /** @format date-time */
  dictationRecordingTime?: string | null;
}

export interface AuthorizationMessage {
  messageType: WebsocketMessageType;
  /**
   * Time when this websocket message was sent
   * @format date-time
   */
  timeStamp: string;
  /** @maxLength 40000 */
  authorizationToken?: string | null;
}

/**
 * Sent by client when changing their status. Sent by server when status of room participant changes.
 * MessageType: ClientStatusUpdateMessage
 */
export interface ClientStatusUpdateMessage {
  messageType: WebsocketMessageType;
  /**
   * Time when this websocket message was sent
   * @format date-time
   */
  timeStamp: string;
  /**
   * Irrelevant in messages sent by client.
   * @minLength 1
   * @maxLength 128
   */
  firstName: string;
  /**
   * Irrelevant in messages sent by client.
   * @minLength 1
   * @maxLength 128
   */
  lastName: string;
  /**
   * User identifier, either User id or Patient id. Irrelevant in messages sent by client.
   * @format uuid
   */
  guid?: string | null;
  /**
   * Id of room the change pertains to. Null if global change, such as in the initial update after logging in.
   * @format uuid
   */
  roomId?: string | null;
  /** Client can only change status between Online and Writing with this message. */
  status: ChatterStatus;
}

export interface ClosePatientRequest {
  prescriptionCheckMode?: PatientClosePrescriptionCheckMode | null;
}

export interface ClosePatientResponse {
  /** @format int32 */
  unsentPrescriptionCount: number;
  /** @format date-time */
  unsentPrescriptionExpirationTime?: string | null;
}

export interface CloseServiceEventRequest {
  prescriptionCheckMode?: PatientClosePrescriptionCheckMode | null;
}

export interface CloseServiceEventResponse {
  /** @format int32 */
  unsentPrescriptionCount: number;
  /** @format date-time */
  unsentPrescriptionExpirationTime?: string | null;
}

export interface CodedSystemID {
  type: CodedSystemType;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  versionID: string;
}

export interface CommitPrescriptionsRequest {
  printPatientInstructions: boolean;
}

export interface CommitPrescriptionsResponse {
  pdfDocuments?: string[] | null;
}

export interface ConfirmUserAgreementRequest {
  /**
   * @minLength 1
   * @maxLength 40000
   */
  eventId: string;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  smartCardResponseData: string;
  /**
   * Validates values in requests using the following patterns:
   * - For numbers starting with '+358': ^\+358\d{6,12}$
   * - For other numbers: ^\+\d{1,3}\d{5,15}$
   * @minLength 1
   * @maxLength 19
   */
  phoneNumber: string;
  /**
   * Validates values in requests using the method System.ComponentModel.DataAnnotations.EmailAddressAttribute.IsValid.
   * - The validation code is available on GitHub (https://github.com/dotnet/runtime).
   * @minLength 1
   * @maxLength 320
   */
  email: string;
}

export interface ConfirmUserAgreementResponse {
  organisationUnits: OrganizationUnit[];
  user: UserInfo;
  confirmation: UserAgreementConfirmation;
}

export interface ConsentSummary {
  checkSuccessful: boolean;
  kantaNotificationStatus: KantaNotificationStatus;
  /** @maxLength 40000 */
  kantaNotificationVersion: string;
  archiveConsentGiven: boolean;
}

export interface ContentAuthor {
  /**
   * @minLength 1
   * @maxLength 40000
   */
  firstName: string | null;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  lastName: string | null;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  regNumber: string | null;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  idNumber: string | null;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  id: string | null;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  oid: string | null;
}

export interface ContentEditor {
  /** @minLength 1 */
  firstName: string | null;
  /** @minLength 1 */
  lastName: string | null;
  /** @minLength 1 */
  regNumber: string | null;
  /** @minLength 1 */
  idNumber: string | null;
  /** @minLength 1 */
  id: string | null;
  /** @minLength 1 */
  oid: string | null;
  /** @format date-time */
  editTime: string | null;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  role: string;
}

export interface ContentProducerOrganization {
  /** @minLength 1 */
  oid: string | null;
  /** @minLength 0 */
  name: string | null;
}

export interface CreateEntryRequest {
  entry: NewEntry;
}

export interface CreateEntryResponse {
  entry: Entry;
}

export interface CreateExternalPatientsRequest {
  patient: PatientExternalReq;
}

export interface CreateExternalPatientsResponse {
  /** @format uuid */
  patientGuid: string;
  patient: Patient;
  /**
   * Non-null if patient was added to queue with chat enabled.
   * @format uuid
   */
  chatRoomGuid?: string | null;
}

export interface CreateFeedbackRequest {
  /**
   * @minLength 1
   * @maxLength 255
   */
  title: string;
  /**
   * @minLength 1
   * @maxLength 10000
   */
  feedback: string;
  /**
   * Validates values in requests using the method System.ComponentModel.DataAnnotations.EmailAddressAttribute.IsValid.
   * - The validation code is available on GitHub (https://github.com/dotnet/runtime).
   * @maxLength 255
   */
  replyTo?: string | null;
  htmlBody?: boolean | null;
}

export interface CreateGuestSessionRequest {
  /**
   * @minLength 1
   * @maxLength 40000
   */
  ssid: string;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  firstName: string;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  lastName: string;
  personIdentifierType: PersonIdentifierType;
}

export interface CreateGuestSessionResponse {
  /**
   * @minLength 1
   * @maxLength 40000
   */
  accessToken: string;
  /** @format int32 */
  expiresIn: number;
}

export interface CreateLabTestOrderRequest {
  /**
   * Value is sent only to ERA (not to Synlab).
   * @maxLength 1000
   */
  additionalInformation?: string | null;
  /**
   * Each object must have unique code.
   * @minItems 1
   */
  operations: LabTestOperation[];
}

export interface CreateLabTestOrderResponse {
  /**
   * @minLength 1
   * @maxLength 40000
   */
  placerOrderNumberId: string;
  entry: Entry;
}

export interface CreatePrescriptionRequest {
  mode: PrescriptionCreateMode;
  /** @maxLength 40000 */
  originalPrescriptionOID?: string | null;
  originalPrescriptionIsNarcotic?: boolean | null;
  prescription: PrescriptionData;
}

export interface CreatePrescriptionResponse {
  prescription?: PrescriptionData | null;
}

export interface CreateServiceEventRequest {
  /** @format date-time */
  startTime: string;
  /** @format date-time */
  endTime?: string | null;
  includesHospitalization?: boolean | null;
  /** @maxLength 40000 */
  responsibleOrganizationOID?: string | null;
  underageDataReleaseToGuardian?: HealthcareUnderageDataRelease | null;
}

export interface CreateServiceEventResponse {
  serviceEvent: ServiceEvent;
}

export interface DataView {
  /**
   * @minLength 1
   * @maxLength 40000
   */
  code: string | null;
  /** @maxLength 40000 */
  name?: string | null;
  /** @maxLength 40000 */
  description?: string | null;
}

export interface DatabaseFileMetadata {
  /** @format uuid */
  guid: string;
  type: DatabaseFileType;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  fileName: string;
  /** @format int64 */
  sizeBytes: number;
  /**
   * Defined only for images (image/*) that have been added after the API change.
   * @format int32
   */
  width?: number | null;
  /**
   * Defined only for images (image/*) that have been added after the API change.
   * @format int32
   */
  height?: number | null;
  /**
   * Indicates when the file was saved by the backend.
   * @format date-time
   */
  createdTS: string;
}

export interface DeleteEntryRequest {
  /**
   * @minLength 1
   * @maxLength 40000
   */
  oid: string;
  deletePermanently: boolean;
  /** @maxLength 40000 */
  reason?: string | null;
}

export interface DeploymentTestConfig {
  type: DeploymentTestConfigType;
  state: DeploymentTestState;
  /**
   * @format date
   * @example "2005-01-20"
   */
  date?: string | null;
  /** @maxLength 40000 */
  patientID?: string | null;
  /** @maxLength 40000 */
  patientFirstName?: string | null;
  /** @maxLength 40000 */
  patientLastName?: string | null;
  /** @maxLength 40000 */
  userRegNumber?: string | null;
  /** @maxLength 40000 */
  userFirstName?: string | null;
  /** @maxLength 40000 */
  userLastName?: string | null;
}

export interface DiagnosisStatisticsRequest {
  /**
   * Start date of the query (inclusive)
   * @format date
   * @example "2005-01-20"
   */
  startTime: string;
  /**
   * End date of the query (inclusive)
   * @format date
   * @example "2005-01-20"
   */
  endTime: string;
  /** Only results for users with these ids. If omitted, results from all users */
  includeUsers?: number[] | null;
  /** If defined, exclude users with these ids from results. */
  excludeUsers?: number[] | null;
  /** List of root organization OIDs to be included in the search results. If the value is undefined/null/empty, organizations are not restricted. */
  includeRootOrganizationOIDs?: string[] | null;
  groupByTime: StatisticsTimeGroupingType;
  separateBy?: DiagnosisStatisticsSeparator | null;
  includeDiagnosisCodes?: string[] | null;
  excludeDiagnosisCodes?: string[] | null;
  includeExternalReasonCodes?: string[] | null;
  excludeExternalReasonCodes?: string[] | null;
  includeCertainties?: Certainty[] | null;
  excludeCertainties?: Certainty[] | null;
  includePermanences?: Permanence[] | null;
  excludePermanences?: Permanence[] | null;
  includePrimarities?: DiagnosisPrimarity[] | null;
  excludePrimarities?: DiagnosisPrimarity[] | null;
  includeQueues?: string[] | null;
  excludeQueues?: string[] | null;
}

export interface DispensationDrugQuantityData {
  type?: DispensationQuantityValueType | null;
  value?: ValueUnit_Nullable | null;
  /** @maxLength 40000 */
  expression?: string | null;
  /** @maxLength 40000 */
  text?: string | null;
}

export interface DocumentIdentificationData {
  /** @maxLength 40000 */
  documentOID?: string | null;
  /** @maxLength 40000 */
  documentSetOID?: string | null;
  /** @format int32 */
  version?: number | null;
}

export interface DrugDoseData {
  dose?: ValueUnitRange | null;
  administrationUnit?: SCNamedCode | null;
  physicalDose?: ValueUnitRange | null;
  asNeeded: boolean;
  timeOfDay?: TimeOfDay | null;
  /** @maxLength 40000 */
  time?: string | null;
  dayOfWeek?: DayOfWeek | null;
}

/** Token we return to clients. */
export interface DuodecimAccessToken {
  /** @maxLength 40000 */
  access_token?: string | null;
  /** @maxLength 40000 */
  token_type?: string | null;
  /** @format int32 */
  expires_in?: number | null;
  /** @format int64 */
  duoidentifier?: number | null;
}

export interface EditEntryRequest {
  entry: Entry;
}

export interface EditEntryResponse {
  entry: Entry;
}

export interface EditServiceEventRequest {
  /** @format date-time */
  startTime: string;
  /** @format date-time */
  endTime?: string | null;
  includesHospitalization?: boolean | null;
  /** @maxLength 40000 */
  responsibleOrganizationOID?: string | null;
  underageDataReleaseToGuardian?: HealthcareUnderageDataRelease | null;
}

export interface EditServiceEventResponse {
  serviceEvent: ServiceEvent;
}

export interface EmailData {
  /**
   * @minLength 1
   * @maxLength 40000
   */
  content: string;
  /** Indicates whether 'content' contains HTML code. */
  htmlBody: boolean;
}

/**
 * Client sent request to enter a chat room.
 * Responded to by ClientStatusUpdateMessage if successful
 * MessageType: EnterRoomRequestMessage
 */
export interface EnterRoomRequestMessage {
  messageType: WebsocketMessageType;
  /**
   * Time when this websocket message was sent
   * @format date-time
   */
  timeStamp: string;
  /** @format uuid */
  roomId: string;
}

export interface Entry {
  status: EntryStatus;
  releaseDelayType: ReleaseDelayType;
  /**
   * @format date
   * @example "2005-01-20"
   */
  releaseDateToPatient?: string | null;
  otherPerson?: Patient | null;
  primaryDataView: DataView;
  secondaryDataViews?: DataView[] | null;
  /** @minItems 1 */
  sections: EntrySection[];
  /** @maxLength 40000 */
  oid?: string | null;
  entryType?: EntryType | null;
  /** @format date-time */
  time: string | null;
  registry?: RegistrySettings | null;
  serviceProducer?: ContentProducerOrganization | null;
  author?: ContentAuthor | null;
  organization?: ContentProducerOrganization | null;
  editors?: ContentEditor[] | null;
  patient?: Patient | null;
  /** @maxLength 40000 */
  serviceEventOID?: string | null;
  partialForm?: PartialHealthcareFormContainer | null;
  maintainedDocument?: MaintainedDocumentContainer | null;
  htmlData?: string | null;
  underageDataReleaseToGuardian?: HealthcareUnderageDataRelease | null;
  documentID?: DocumentIdentificationData | null;
}

export interface EntryBatchQuery {
  type: EntryQueryType;
  serviceEventOIDs?: string[] | null;
  dataViews?: DataView[] | null;
  /** @format date-time */
  startDate?: string | null;
  /** @format date-time */
  endDate?: string | null;
}

export interface EntryQuery {
  type: EntryQueryType;
  purpose: EntryQueryPurpose;
  /** @maxLength 40000 */
  serviceEventOID?: string | null;
  dataView?: DataView | null;
  /** @format date-time */
  startDate?: string | null;
  /** @format date-time */
  endDate?: string | null;
  includeProtectedContent?: boolean | null;
  getStructuredContents?: boolean | null;
  getHTML?: EntryQueryHTMLMode | null;
  getPDF?: boolean | null;
}

export interface EntrySection {
  phase: HealthcareProcessPhase;
  title: EntrySectionTitle;
  secondaryTitle?: EntrySectionSecondaryTitle | null;
  /** @minItems 1 */
  contents: StructuredContentContainer[];
}

export interface EntrySectionSecondaryTitle {
  class: SecondaryTitleClass;
  specialType?: SpecialSecondaryTitleType | null;
  /** @maxLength 40000 */
  code?: string | null;
  /** @maxLength 40000 */
  text?: string | null;
  /** @maxLength 40000 */
  codeSystem?: string | null;
  /** @maxLength 40000 */
  codeSystemName?: string | null;
}

export interface EntrySectionTitle {
  /**
   * @minLength 1
   * @maxLength 40000
   */
  code: string;
  /** @maxLength 40000 */
  text?: string | null;
}

export interface EntryStatisticsRequest {
  /**
   * Start date of the query (inclusive)
   * @format date
   * @example "2005-01-20"
   */
  startTime: string;
  /**
   * End date of the query (inclusive)
   * @format date
   * @example "2005-01-20"
   */
  endTime: string;
  /** Only results for users with these ids. If omitted, results from all users */
  includeUsers?: number[] | null;
  /** If defined, exclude users with these ids from results. */
  excludeUsers?: number[] | null;
  /** List of root organization OIDs to be included in the search results. If the value is undefined/null/empty, organizations are not restricted. */
  includeRootOrganizationOIDs?: string[] | null;
  groupByTime: StatisticsTimeGroupingType;
  separateBy?: EntrySectionStatisticsSeparator | null;
  includeSectionCodes?: string[] | null;
  excludeSectionCodes?: string[] | null;
  includeQueues?: string[] | null;
  excludeQueues?: string[] | null;
}

/**
 * Sent by server when an error occurs.
 * MessageType: ErrorMessage
 */
export interface ErrorMessage {
  messageType: WebsocketMessageType;
  /**
   * Time when this websocket message was sent
   * @format date-time
   */
  timeStamp: string;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  error: string;
  errorCode: ChatErrorCode;
}

export interface ErrorResponse {
  type: ErrorType;
  /** @maxLength 40000 */
  title?: string | null;
  /** @maxLength 40000 */
  detail?: string | null;
  /** @maxLength 40000 */
  technicalDetail?: string | null;
  /** @format int32 */
  externalErrorCode?: number | null;
  /** @maxLength 40000 */
  traceId?: string | null;
}

export interface ErrorStatisticsRequest {
  /**
   * Start date of the query (inclusive)
   * @format date
   * @example "2005-01-20"
   */
  startTime: string;
  /**
   * End date of the query (inclusive)
   * @format date
   * @example "2005-01-20"
   */
  endTime: string;
  /** Only results for users with these ids. If omitted, results from all users */
  includeUsers?: number[] | null;
  /** If defined, exclude users with these ids from results. */
  excludeUsers?: number[] | null;
  /** List of root organization OIDs to be included in the search results. If the value is undefined/null/empty, organizations are not restricted. */
  includeRootOrganizationOIDs?: string[] | null;
  groupByTime: StatisticsTimeGroupingType;
  separateBy?: ErrorStatisticsSeparator | null;
  includeErrorTypes?: ErrorType[] | null;
  excludeErrorTypes?: ErrorType[] | null;
  includeErrorSources?: ErrorSource[] | null;
  excludeErrorSources?: ErrorSource[] | null;
  includeSeverities?: ErrorSeverity[] | null;
  excludeSeverities?: ErrorSeverity[] | null;
  includeExternalErrorCodes?: number[] | null;
  excludeExternalErrorCodes?: number[] | null;
}

/**
 * Client sent request to exit a chat room.
 * Responded to by ClientStatusUpdateMessage if successful
 * MessageType: ExitRoomRequestMessage
 */
export interface ExitRoomRequestMessage {
  messageType: WebsocketMessageType;
  /**
   * Time when this websocket message was sent
   * @format date-time
   */
  timeStamp: string;
  /** @format uuid */
  roomId: string;
}

export interface ExtendedPatientQueueInfo {
  /**
   * If the value is not globally unique, the error type 35 'InvalidName' is returned.
   * @minLength 1
   * @maxLength 100
   */
  name: string;
  /** @format int32 */
  responseTimeSeconds?: number | null;
  /** @maxLength 100 */
  connectionMethod?: string | null;
  isFifo: boolean;
  isPublic: boolean;
  /** @maxLength 1000 */
  description?: string | null;
  hasGenderRestriction: boolean;
  genderRestriction?: Gender | null;
  /** If true, then at least one of the fields 'minAge' and 'maxAge' must be defined. */
  hasAgeLimit: boolean;
  /**
   * If defined along with the 'maxAge' field, this value must be smaller.
   * @format int32
   * @min 0
   * @max 2147483647
   */
  minAge?: number | null;
  /**
   * If defined along with the 'minAge' field, this value must be higher.
   * @format int32
   * @min 0
   * @max 2147483647
   */
  maxAge?: number | null;
  costs?: PatientQueueCostsInfo | null;
  /**
   * If true, adding patient to queue sends an email to them with a chat link that they can use to chat with their doctor.
   * (Patient can join the chat after receiving the link, doctor joins the chat after taking the item from queue)
   * Missing value is interpreted as false.
   */
  chatEnabled?: boolean | null;
  /** Each object must have a unique registration number. */
  permittedUsers: PatientQueueInfoUser[];
  /** Each object must have a unique OID. */
  permittedOrganizations: PatientQueueInfoOrganization[];
  /** Each object must have a unique registration number. Only the owner of the queue can modify this information. */
  adminUsers: PatientQueueInfoUser[];
  /** @format uuid */
  guid: string;
  owner: PatientQueueInfoUser;
  showPreliminaryInfo: boolean;
  showPreliminaryDescription: boolean;
}

export interface ExternalCompany {
  /** @format uuid */
  id: string;
  /**
   * @minLength 1
   * @maxLength 255
   */
  name: string;
  /**
   * Finnish business ID number 'Y-tunnus'.
   * @maxLength 255
   */
  companyId?: string | null;
  /** @maxLength 255 */
  oid?: string | null;
  /**
   * Contains phone number or Alphanumeric Sender ID displayed as the sender in SMS messages.
   * If not null, SMS messages sent on behalf of the company will have this number as sender
   * @maxLength 40000
   */
  sms?: string | null;
  /**
   * If not null, Email messages sent on behalf of the company will have this address as sender
   * If omitted, address configured at server level Mailgun options is used instead.
   * @maxLength 40000
   */
  email?: string | null;
  /**
   * Required for external companies that use chat feature and want emails about chat to be sent to patients.
   * If the url string contains {0} it will be replaced by chat room id.
   * Missing value means no chat-related emails will be sent to patients for this external company.
   * @maxLength 40000
   */
  chatUrl?: string | null;
  /**
   * Required for external companies that want to ask patients for feedback.
   * Contains the url for the feedback survey page, included in email sent to patients about the feedback survey.
   * Missing or empty value means emails with feedback survey link will not be sent to patients.
   * @maxLength 40000
   */
  feedbackSurveyUrl?: string | null;
  /**
   * Amount of hours from when patient was last opened, after which feedback survey will be sent to patient.
   * Missing or negative value means these emails will not be sent.
   * @format int32
   */
  feedbackSurveyDelayInHours?: number | null;
  /**
   * Mailgun url used for emails sent on behalf of the company. Something like https://api.mailgun.net/v3/mg.kuurahealth.com/
   * If null or undefined, url configured at server level Mailgun options is used instead.
   * @maxLength 40000
   */
  mailgunBaseUrl?: string | null;
  /**
   * Mailgun API key used for emails sent on behalf of the company.
   * If null or undefined, API key configured at server level Mailgun options is used instead.
   * @maxLength 40000
   */
  mailgunApiKey?: string | null;
}

export interface ExternalCompanyDefaultValues {
  /**
   * @minLength 1
   * @maxLength 40000
   */
  sms: string;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  email: string;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  mailgunBaseUrl: string;
  /**
   * This property is always null in production. Contains a value used for D-Prescription simulation in non-production environments.
   * At the time of writing, the usage is limited to endpoint POST /Patients/{id}/Emails.
   * The value does not affect features exclusive to the D-Platform or background tasks for which the need for simulation is difficult to define.
   * @maxLength 40000
   */
  noProductionSimulateDPrescriptionEmail?: string | null;
  /**
   * This property is always null in production. Contains a value used for D-Prescription simulation in non-production environments.
   * At the time of writing, the usage is limited to endpoint POST /Patients/{id}/Emails.
   * The value does not affect features exclusive to the D-Platform or background tasks for which the need for simulation is difficult to define.
   * @maxLength 40000
   */
  noProductionSimulateDPrescriptionMailgunBaseUrl?: string | null;
}

export interface FeedbackItem {
  /** @format date-time */
  createdTS: string;
  /** @format uuid */
  guid: string;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  title: string;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  content: string;
  /** @maxLength 40000 */
  replyTo?: string | null;
  htmlBody?: boolean | null;
}

export interface GetAddressRequest {
  webPageType?: WebPageType | null;
  specialViewType?: SpecialViewType | null;
  /** @maxLength 40000 */
  returnAddress?: string | null;
}

/** Return individual information of all prescriptions created during the given time window. */
export interface GetAllPrescriptionsRequest {
  /**
   * If omitted, no start time limit is used.
   * @format date-time
   */
  startTime?: string | null;
  /**
   * If omitted, no end time limit is used.
   * @format date-time
   */
  endTime?: string | null;
  /** List of root organization OIDs to be included in the search results. If the value is undefined/null/empty, organizations are not restricted. */
  includeRootOrganizationOIDs?: string[] | null;
}

export interface GetAllPrescriptionsResponse {
  prescriptions: PrescriptionInfo[];
}

export interface GetAllSessionsRequest {
  /**
   * Maximum number of items to return.
   * @format int32
   * @min 1
   * @max 2147483647
   */
  takeCount?: number | null;
  /**
   * Number of items to skip.
   * @format int32
   * @min 0
   * @max 2147483647
   */
  skipCount?: number | null;
  /**
   * Only return items that are older than this. If omitted, the newest items are returned.
   * Provides a slightly more efficient and robust way of achieving paging, especially when there is no need to skip pages.
   * @format date-time
   */
  olderThan?: string | null;
  filterParameters?: StatisticsSessionFilterParameters | null;
}

export interface GetAllSessionsResponse {
  sessions: StatisticsSessionInfo[];
}

export interface GetAllowedPrescriptionOperationsRequest {
  patientPermission?: PatientPermission | null;
  /** @minItems 1 */
  prescriptionDocumentSetOids: string[];
}

export interface GetAllowedPrescriptionOperationsResponse {
  results: PrescriptionOperationResult[];
}

export interface GetApiKeysResponse {
  items: APIKey[];
}

export interface GetCodesRequest {
  type: CodedSystemType;
  /** @minItems 1 */
  flags: CodedItemFlag[];
  options?: OffsetLimit | null;
}

export interface GetCodesResponse {
  codedSystemID: CodedSystemID;
  result: SimpleCodedItemsResult;
}

export interface GetConfigurationUsersResponse {
  type: UserConfigType;
  organizationAccessMode: UserOrganizationAccessMode;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  regNumber: string;
  /** @maxLength 40000 */
  idNumber?: string | null;
  /** @maxLength 40000 */
  id?: string | null;
  /** @maxLength 40000 */
  oid?: string | null;
  /** @maxLength 40000 */
  businessID?: string | null;
  /** @maxLength 40000 */
  firstName?: string | null;
  /** @maxLength 40000 */
  lastName?: string | null;
  /** @maxLength 40000 */
  occupationCode?: string | null;
  /** @maxLength 40000 */
  occupationName?: string | null;
  /** @maxLength 40000 */
  degreeCode?: string | null;
  /** @maxLength 40000 */
  degreeName?: string | null;
  /** @maxLength 40000 */
  jobDescription?: string | null;
  roles: UserConfigRole[];
  /**
   * @minLength 1
   * @maxLength 40000
   */
  preferredLanguage: string;
  address?: Address | null;
  isIndependent: boolean;
  prescriptionFee?: ValueUnit | null;
  worksUnderSupervision: boolean;
  supervisorIDs?: string[] | null;
  activity: ActivitySettings;
  ipValidation: IPValidationSettings;
  nonIntegratedAccess: NonIntegratedAccessSettings;
  acceptsRenewalRequests: boolean;
  deploymentTests?: DeploymentTestConfig[] | null;
  /** @maxLength 40000 */
  phoneNumber?: string | null;
  /** @maxLength 40000 */
  email?: string | null;
  /** @maxLength 40000 */
  webhookUrl?: string | null;
}

export interface GetEmailsResponse {
  emails: PatientCommunicationEmail[];
}

export interface GetEntriesRequest {
  query: EntryQuery;
}

export interface GetEntriesResponse {
  protectedContentRemoved: boolean | null;
  entries?: Entry[] | null;
  /** @maxLength 40000 */
  htmlData?: string | null;
  /** @maxLength 40000 */
  pdfData?: string | null;
  livingWill?: LivingWill | null;
  organDonationWill?: OrganDonationWill | null;
  otherRegistryContentExcluded?: QueryResponseSpecifier | null;
  /**
   * Contains value 'true' if parsing of entries returned by ERA succeeded only partially.
   * In such cases, the 'entries' collection may lack results or they may be incomplete.
   */
  partialSuccess: boolean;
}

export interface GetEntryBatchSearchResultsRequest {
  /**
   * @minLength 1
   * @maxLength 40000
   */
  batchID: string;
  /** @format int32 */
  batchNumber: number;
}

export interface GetEntryBatchSearchResultsResponse {
  entries: Entry[];
  /**
   * Contains value 'true' if parsing of entries returned by ERA succeeded only partially.
   * In such cases, the 'entries' collection may lack results or they may be incomplete.
   */
  partialSuccess: boolean;
}

export interface GetExternalCompaniesResponse {
  items: ExternalCompany[];
  /** Contains default values for fields used when corresponding fields are not explicitly defined for an external company. In other words, if a field value is explicitly set, the corresponding field in this object should be disregarded. */
  defaultValues: ExternalCompanyDefaultValues;
}

export interface GetFeedbackResponse {
  /** Requested feedback items */
  feedbacks: FeedbackItem[];
  /**
   * Total amount of Feedback items in database
   * @format int32
   */
  total: number;
}

export interface GetFlagsRequest {
  type: CodedSystemType;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  code: string;
  includeExtraCodes: boolean;
  extraCodeOptions?: OffsetLimit | null;
}

export interface GetFlagsResponse {
  codedSystemID: CodedSystemID;
  item: SimpleCodedItem;
  extraCodes: SimpleCodedItemsResult;
}

export interface GetHealthResponse {
  /** @maxLength 40000 */
  version?: string | null;
  /** @maxLength 40000 */
  commit?: string | null;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  url: string;
  status: HealthStatus;
  /** @format int64 */
  elapsedMilliseconds: number;
  entries: HealthEntry[];
}

export interface GetHealthcareFormResponse {
  item: HealthcareForm;
}

export interface GetHealthcareFormsRequest {
  /**
   * The official personal identification number of the patient.
   * This property will be ignored if it is empty.
   * @maxLength 40000
   */
  patientIdentifier?: string | null;
  /** This property will be ignored if it is empty. */
  types?: DPlatformHealthcareFormType[] | null;
  /**
   * This property will be ignored if it is empty.
   * @format date
   * @example "2005-01-20"
   */
  createdTimeFrom?: string | null;
  /**
   * This property will be ignored if it is empty.
   * @format date
   * @example "2005-01-20"
   */
  createdTimeTo?: string | null;
}

export interface GetHealthcareFormsResponse {
  items: HealthcareFormSimple[];
}

export interface GetLaboratoryOperationCodesRequest {
  /**
   * Search for results that contain this string. Null or an empty value means search for all items.
   * @maxLength 40000
   */
  searchString?: string | null;
  /**
   * Maximum number of items to search. The default value if omitted is 10.
   * @format int32
   * @min 1
   * @max 2147483647
   */
  limit?: number | null;
  /**
   * Number of items to skip. The default value if omitted is 0.
   * @format int32
   */
  skip?: number | null;
}

export interface GetLaboratoryOperationCodesResponse {
  items: LaboratoryOperationCode[];
}

export interface GetLatestFeedbackRequest {
  /**
   * Maximum number of items to return.
   * @format int32
   * @min 1
   * @max 2147483647
   */
  takeCount?: number | null;
  /**
   * Number of items to skip.
   * @format int32
   * @min 0
   * @max 2147483647
   */
  skipCount?: number | null;
  /**
   * Only return items that are older than this. If omitted, the newest items are returned.
   * Provides a slightly more efficient and robust way of achieving paging, especially when there is no need to skip pages.
   * @format date-time
   */
  olderThan?: string | null;
}

export interface GetLivingWillResponse {
  /** The field is null if no living will was found for the patient (ERA error 101). */
  livingWill?: LivingWill | null;
}

export interface GetNotificationsResponse {
  appNotifications: AppNotification[];
}

export interface GetOrganizationNamesRequest {
  /**
   * Search for results that contain this string. Null or an empty value means search for all items.
   * @maxLength 40000
   */
  searchString?: string | null;
  /**
   * Maximum number of items to search. The default value if omitted is 10.
   * @format int32
   * @min 1
   * @max 2147483647
   */
  limit?: number | null;
  /**
   * Number of items to skip. The default value if omitted is 0.
   * @format int32
   */
  skip?: number | null;
}

export interface GetOrganizationNamesResponse {
  namesToOids: Record<string, string>;
}

export interface GetOrganizationsResponse {
  /**
   * @minLength 1
   * @maxLength 40000
   */
  oid: string;
  /** @maxLength 40000 */
  name?: string | null;
  /** @maxLength 40000 */
  businessID?: string | null;
  areaOfOperation: OrganizationAreaOfOperation;
  sector?: OrganizationSectorType | null;
  /** @maxLength 40000 */
  phoneNumber?: string | null;
  phoneNumberType?: PhoneNumberType | null;
  address?: Address | null;
  publicServiceJoinModel: PublicServiceJoinModel;
  /** @maxLength 40000 */
  hostOID?: string | null;
  /** @maxLength 40000 */
  hostName?: string | null;
  activity: ActivitySettings;
  ipValidation: IPValidationSettings;
  nonIntegratedAccess: NonIntegratedAccessSettings;
  acceptsRenewalRequests: boolean;
  deploymentTests?: DeploymentTestConfig[] | null;
}

export interface GetPatientQueuesResponse {
  items: PatientQueueSettings[];
}

export interface GetPatientResponse {
  /** Data is based on information stored in the database, not retrieved from ERA. */
  patient: Patient;
}

export interface GetPatientTemplatesResponse {
  templates: PatientTemplate[];
}

export interface GetPatientsResponse {
  queues: PatientQueue[];
  /** Data is based on information stored in the database, not retrieved from ERA. */
  patients: Patient[];
}

export interface GetPhrasesResponse {
  items: Phrase[];
}

export interface GetPrescriptionHeadersRequest {
  queryType: PrescriptionDataQueryType;
  reasonForQuery: ReasonForPrescriptionDataQuery;
  patientPermission?: PatientPermission | null;
  onlyPrescribedByUser?: boolean | null;
  dispensationStatus?: PrescriptionDispensationStatusSearchMode | null;
  /**
   * @format date
   * @example "2005-01-20"
   */
  beginDate?: string | null;
  /**
   * @format date
   * @example "2005-01-20"
   */
  endDate?: string | null;
  /** @maxLength 40000 */
  prescriptionOid?: string | null;
}

export interface GetPrescriptionHeadersResponse {
  prescriptions: PrescriptionHeaderData[];
  /**
   * Contains value 'true' if parsing of prescriptions returned by ERA succeeded only partially.
   * In such cases, the 'prescriptions' collection lacks results.
   */
  partialSuccess: boolean;
}

/**
 * Sent by client to request messages of room the client has joined
 * Responded to by RoomMessageListMessage if successful
 * MessageType: GetRoomMessagesRequestMessage
 */
export interface GetRoomMessagesRequest {
  messageType: WebsocketMessageType;
  /**
   * Time when this websocket message was sent
   * @format date-time
   */
  timeStamp: string;
  /** @format uuid */
  roomId: string;
  /**
   * If defined, only get messages written after this time
   * @format date-time
   */
  since?: string | null;
  /**
   * If defined, get this many messages starting from most recent. Otherwise get all matching messges
   * @format int32
   * @min 0
   * @max 2147483647
   */
  take?: number | null;
  /**
   * If defined, skip this many otherwise matching messages starting from most recent, and get the rest
   * @format int32
   * @min 0
   * @max 2147483647
   */
  skip?: number | null;
}

export interface GetSettingsPatientQueuesResponse {
  items: ExtendedPatientQueueInfo[];
}

export interface GetSettingsResponse {
  /** @maxLength 19 */
  phoneNumber?: string | null;
  /** @maxLength 320 */
  email?: string | null;
  /** @maxLength 2048 */
  webhookUrl?: string | null;
}

export interface GetSmsMessagesResponse {
  /** @format int32 */
  count: number;
  /**
   * Messages associated with the currently opened patient queue item.
   * NOTE: May also include messages sent by other users (and their responses) if the user linked to the patient queue item has changed during its lifecycle.
   */
  messages: SmsMessage[];
  /**
   * Messages associated with the patient's (identified by the personal ID) other patient queue items, where the patient queue item is linked to the requesting user.
   * NOTE: May also include messages sent by other users (and their responses) if the user linked to the patient queue item has changed during its lifecycle.
   */
  otherMessages: SmsMessage[];
}

export interface GetUserAgreementConfirmationsRequest {
  /**
   * Maximum number of items to return.
   * @format int32
   * @min 1
   * @max 2147483647
   */
  takeCount?: number | null;
  /**
   * Number of items to skip.
   * @format int32
   * @min 0
   * @max 2147483647
   */
  skipCount?: number | null;
  /**
   * Only return items that are older than this. If omitted, the newest items are returned.
   * Provides a slightly more efficient and robust way of achieving paging, especially when there is no need to skip pages.
   * @format date-time
   */
  olderThan?: string | null;
}

export interface GetUserAgreementConfirmationsResponse {
  /**
   * Total amount of confirmations for the user agreement.
   * @format int32
   */
  total: number;
  confirmations: UserAgreementConfirmationExtended[];
}

export interface GetUserAgreementsResponse {
  agreements: UserAgreementExtended[];
}

export interface GetUserProfileResponse {
  profile: UserProfile;
}

export interface GetUserPublicInfoRequest {
  /**
   * @minLength 1
   * @maxLength 40000
   */
  regNumber: string;
}

export interface GetUserPublicInfoResponse {
  /**
   * @minLength 1
   * @maxLength 40000
   */
  regNumber: string;
  independentPractitionerInfo?: IndependentPractitionerInfo | null;
}

export interface GetUsersFilterParameters {
  /** @minItems 1 */
  registrationStatuses?: RegistrationStatus[] | null;
}

export interface GetUsersRequest {
  /**
   * Maximum number of items to return.
   * @format int32
   * @min 1
   * @max 2147483647
   */
  takeCount?: number | null;
  /**
   * Number of items to skip.
   * @format int32
   * @min 0
   * @max 2147483647
   */
  skipCount?: number | null;
  /**
   * Only return items that are older than this. If omitted, the newest items are returned.
   * Provides a slightly more efficient and robust way of achieving paging, especially when there is no need to skip pages.
   * @format date-time
   */
  olderThan?: string | null;
  filterParameters?: GetUsersFilterParameters | null;
}

export interface GetUsersResponse {
  users: UserInfoAdmin[];
}

export interface GroupedStatisticsResponse {
  /**
   * @format date
   * @example "2005-01-20"
   */
  startDate: string;
  /**
   * @format date
   * @example "2005-01-20"
   */
  endDate: string;
  /**
   * The result data for the query. The counts are divided according to request SeparateBy. A null key is represented as "null"
   * If SeparateBy is not used, there will be only one item with key: "*"
   */
  counts: Record<string, number>;
}

export interface GroupedTimedStatisticsData {
  /** @format int32 */
  count: number;
  /** @example "00:00:00" */
  min: string;
  /** @example "00:00:00" */
  max: string;
  /** @example "00:00:00" */
  total: string;
  /** @example "00:00:00" */
  median: string;
}

export interface GroupedTimedStatisticsResponse {
  /**
   * @format date
   * @example "2005-01-20"
   */
  startDate: string;
  /**
   * @format date
   * @example "2005-01-20"
   */
  endDate: string;
  /**
   * The result data for the query. The Data are divided according to request SeparateBy. A null key is represented as "null"
   * If SeparateBy is not used, there will be only one item with key: "*"
   */
  data: Record<string, GroupedTimedStatisticsData>;
}

export interface HealthEntry {
  name: HealthEntryName;
  status: HealthStatus;
  /** @format int64 */
  elapsedMilliseconds: number;
}

export interface HealthcareDocumentInfo {
  id?: DocumentIdentificationData | null;
  /** @format date-time */
  creationTime?: string | null;
  dataViews?: DataView[] | null;
  underageDataReleaseToGuardian?: HealthcareUnderageDataRelease | null;
  patient?: Patient | null;
  otherPersons?: Patient[] | null;
  /** @maxLength 40000 */
  serviceEventOID?: string | null;
  registry?: RegistrySettings | null;
  serviceProducer?: ContentProducerOrganization | null;
  organizations?: ContentProducerOrganization[] | null;
  authors?: ContentAuthor[] | null;
}

export interface HealthcareForm {
  /** @format uuid */
  id: string;
  type: DPlatformHealthcareFormType;
  /** Required if 'type' is 'SV120'. */
  sV120DocumentData?: SV120DocumentData | null;
  /** @format date-time */
  createdTS: string;
  /** @format date-time */
  updatedTS?: string | null;
  /**
   * PDF data encoded in Base64 format.
   * @maxLength 40000
   */
  data?: string | null;
}

export interface HealthcareFormForwardedMessage {
  messageType: WebsocketMessageType;
  /**
   * Time when this websocket message was sent
   * @format date-time
   */
  timeStamp: string;
  entryType?: EntryType | null;
  formType?: HealthcareFormType | null;
}

export interface HealthcareFormSimple {
  /** @format uuid */
  id: string;
  type: DPlatformHealthcareFormType;
  /** Required if 'type' is 'SV120'. */
  sV120DocumentData?: SV120DocumentData | null;
  /** @format date-time */
  createdTS: string;
  /** @format date-time */
  updatedTS?: string | null;
}

/** Palsokyselyn palauttamat henkilötiedot */
export interface HenkilonTiedot {
  /**
   * Henkilön etunimet
   * @minLength 1
   * @maxLength 40000
   */
  etunimet?: string | null;
  /**
   * Henkilötunnus 11 merkkiä
   * @minLength 11
   * @maxLength 40000
   */
  henkilotunnus: string;
  /**
   * Henkilön sukunimi
   * @minLength 1
   * @maxLength 40000
   */
  sukunimi?: string | null;
}

export interface IPValidationSettings {
  allowEdit?: boolean | null;
  mode: AccessIPValidationMode;
  allowedIPs?: string[] | null;
}

export interface IndependentPractitionerInfo {
  /** @maxLength 40000 */
  longName?: string | null;
}

export interface InitializeEntryBatchSearchRequest {
  query: EntryBatchQuery;
  excludedDocuments?: string[] | null;
  getDocumentList?: boolean | null;
}

export interface InitializeEntryBatchSearchResponse {
  /**
   * @minLength 1
   * @maxLength 40000
   */
  batchID: string | null;
  /** @format int32 */
  batchCount: number;
  protectedContentDataViews?: DataView[] | null;
  excludedDocuments?: string[] | null;
  nonExistingDocuments?: string[] | null;
  serviceEvents?: ServiceEvent[] | null;
  otherRegistryContentExcluded?: QueryResponseSpecifier | null;
}

export interface JoinUserWaitingListRequest {
  /**
   * @minLength 1
   * @maxLength 40000
   */
  regNumber: string;
  /**
   * Validates values in requests using the following patterns:
   * - For numbers starting with '+358': ^\+358\d{6,12}$
   * - For other numbers: ^\+\d{1,3}\d{5,15}$
   * @minLength 1
   * @maxLength 19
   */
  phoneNumber: string;
  /**
   * Validates values in requests using the method System.ComponentModel.DataAnnotations.EmailAddressAttribute.IsValid.
   * - The validation code is available on GitHub (https://github.com/dotnet/runtime).
   * @minLength 1
   * @maxLength 320
   */
  email: string;
}

export interface JoinUserWaitingListResponse {
  /**
   * @minLength 1
   * @maxLength 40000
   */
  regNumber: string;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  phoneNumber: string;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  email: string;
}

/** Kyselyn tekijän tiedot */
export interface Kyselija {
  /**
   * Kyselyn suorittaneen kyselijän yksilöivä tunnus. (Organisaation antama henkilön yksilöivä tunnus)
   * @minLength 1
   * @maxLength 40000
   */
  kyselijanYksiloivaTunnus: string;
  /**
   * Kyselyn suorittaneen organisaation nimi. (Toimipiste/ itsenäinen ammatinharjoittaja)
   * @minLength 1
   * @maxLength 40000
   */
  organisaatioNimi: string;
  /**
   * Kyselyn suorittaneen organisaation suorakorvaussopimuksen tunnus
   * @minLength 1
   * @maxLength 40000
   * @pattern [0-9]{5,11}
   */
  suorakorvausSopimuksenTunnus: string;
}

export interface LabTestInformation {
  /**
   * @minLength 1
   * @maxLength 40000
   */
  hl7Identifier: string;
  /**
   * @minLength 1
   * @maxLength 65536
   */
  value: string;
}

export interface LabTestOperation {
  code: SCNamedCode;
  /**
   * Value is sent only to ERA (not to Synlab).
   * @maxLength 1000
   */
  additionalInformation?: string | null;
  /**
   * Mandatory and optional additional information related to the operation intended to be prompted from the user. \
   * Each object must have unique identifier. \
   * Value is validated with the following rules:
   * - If the 'JsonFieldType' is 'Number', the value should be a maximum of 16 characters long number.
   * - If the 'JsonFieldType' is 'Options', the value should be selected from the allowed options.
   * - No additional restrictions apply otherwise.
   */
  information?: LabTestInformation[] | null;
}

export interface LaboratoryOperationCode {
  /**
   * Code of the operation.
   * @minLength 1
   * @maxLength 40000
   */
  code: string;
  /**
   * Name abbreviation of the operation.
   * @maxLength 40000
   */
  name?: string | null;
  /**
   * Additional description related to the operation.
   * @maxLength 40000
   */
  description?: string | null;
  /** Mandatory and optional additional information related to the operation intended to be prompted from the user. */
  information?: LaboratoryOperationInfo[] | null;
}

export interface LaboratoryOperationInfo {
  /**
   * @minLength 1
   * @maxLength 40000
   */
  description: string;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  hl7Identifier: string;
  type: JsonFieldType;
  isRequired: boolean;
  /** @maxLength 40000 */
  measurementUnit?: string | null;
  /** Allowed value options if field 'type' has value 'Options'. */
  options?: string[] | null;
}

export interface ListReferralsResponse {
  referrals: ReferralSummary[];
}

export interface ListRoomsFilter {
  /**
   * Two things count as activity: Room creation and new non-system messages.
   * @format int32
   */
  activeWithinHours: number;
  /** If omitted, return rooms of any status */
  status?: ChatRoomStatus | null;
  /** Room ids specified here will be included in the response even if they would be excluded otherwise */
  include?: string[] | null;
}

/**
 * Request sent by client to request information of rooms that they are allowed to join
 * Responded to by RoomListMessage
 * MessageType: ListRoomsRequestMessage
 */
export interface ListRoomsRequestMessage {
  messageType: WebsocketMessageType;
  /**
   * Time when this websocket message was sent
   * @format date-time
   */
  timeStamp: string;
  filter: ListRoomsFilter;
}

export interface ListedMessage {
  /** @format uuid */
  messageId: string;
  contentType: ChatMessageContentType;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  content: string;
  /**
   * Time when message was saved to database.
   * @format date-time
   */
  createdTimestamp?: string | null;
  /**
   * Null if message is system message.
   * @maxLength 128
   */
  senderFirstName?: string | null;
  /**
   * Null if message is system message.
   * @maxLength 128
   */
  senderLastName?: string | null;
  /**
   * Null if message is system message.
   * @format uuid
   */
  senderGuid?: string | null;
  status: ChatMessageDeliveryStatus;
}

export interface LivingWill {
  /** @format date-time */
  time: string;
  patient: Patient;
  author: ContentAuthor;
  serviceProducer: ContentProducerOrganization;
  given: boolean;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  text: string;
}

export interface LoginConfirmOrganisationUnit {
  /**
   * @minLength 1
   * @maxLength 40000
   */
  oid: string;
  type: OrganizationType;
}

export interface LoginConfirmUserRight {
  object: UserRightSystemAccessObject;
  action: UserRightSystemAccessAction;
  scope: UserRightSystemAccessScope;
}

export interface LogoutRequest {
  /** @maxLength 40000 */
  accessToken?: string | null;
  rememberOrganization?: boolean | null;
}

export interface MDHealthAndTreatmentPlan {
  /** @maxLength 40000 */
  treatmentNeed?: string | null;
  /** @maxLength 40000 */
  workCapacityNeeds?: string | null;
  treatmentReasons?: MDHealthAndTreatmentPlanTreatmentReason[] | null;
  /** @maxLength 40000 */
  treatmentObjectives?: string | null;
  /** @maxLength 40000 */
  workCapacityObjectives?: string | null;
  /** @maxLength 40000 */
  objectivesSetter?: string | null;
  objectiveIdentifiers?: MDHealthAndTreatmentPlanObjectiveCondition[] | null;
  /** @maxLength 40000 */
  treatmentExecution?: string | null;
  healthcareOrganizations?:
    | MDHealthAndTreatmentPlanHealthcareOrganization[]
    | null;
  services?: MDHealthAndTreatmentPlanService[] | null;
  /** @maxLength 40000 */
  supportMonitoringAndEvaluation?: string | null;
  healthcareProfessionals: MDHealthAndTreatmentPlanHealthcareProfessional[];
  /** @maxLength 40000 */
  additionalInformation?: string | null;
  /** @maxLength 40000 */
  diagnosisList?: string | null;
  /** @maxLength 40000 */
  medicationList?: string | null;
}

export interface MDHealthAndTreatmentPlanHealthcareOrganization {
  /** @maxLength 40000 */
  organizationOID?: string | null;
  /** @maxLength 40000 */
  independentPractitionerOID?: string | null;
  /** @maxLength 40000 */
  treatmentUnitOrganizationOID?: string | null;
  /** @maxLength 40000 */
  treatmentUnitIndependentPractitionerOID?: string | null;
  occupation?: SCNamedCode | null;
  /** @maxLength 40000 */
  professionalFirstName?: string | null;
  /** @maxLength 40000 */
  professionalLastName?: string | null;
  /** @maxLength 40000 */
  additionalInformation?: string | null;
}

export interface MDHealthAndTreatmentPlanHealthcareProfessional {
  /**
   * @minLength 1
   * @maxLength 40000
   */
  firstName: string;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  lastName: string;
  /** @maxLength 40000 */
  personIdentifier?: string | null;
  /** @maxLength 40000 */
  registrationNumber?: string | null;
  occupation?: SCNamedCode | null;
  /** @maxLength 40000 */
  additionalInformation?: string | null;
}

export interface MDHealthAndTreatmentPlanObjectiveCondition {
  identifier: SCNamedCode;
  conditionLow?: ValueUnit | null;
  conditionHigh?: ValueUnit | null;
  /** @maxLength 40000 */
  conditionAdditionalInformation?: string | null;
}

export interface MDHealthAndTreatmentPlanService {
  serviceType: SCNamedCode;
  serviceContents?: MDHealthAndTreatmentPlanServiceContent[] | null;
}

export interface MDHealthAndTreatmentPlanServiceCondition {
  /**
   * @minLength 1
   * @maxLength 40000
   */
  conditionDescription: string;
  alternativeConditions?:
    | MDHealthAndTreatmentPlanServiceConditionCollection[]
    | null;
}

export interface MDHealthAndTreatmentPlanServiceConditionCollection {
  conditions: MDHealthAndTreatmentPlanServiceConditionIdentifier[];
}

export interface MDHealthAndTreatmentPlanServiceConditionIdentifier {
  identifier: SCNamedCode;
  conditionLow?: ValueUnit | null;
  conditionHigh?: ValueUnit | null;
}

export interface MDHealthAndTreatmentPlanServiceContent {
  /** @maxLength 40000 */
  content?: string | null;
  /** @maxLength 40000 */
  serviceAdditionalInformation?: string | null;
  serviceStatus?: HealthAndTreatmentPlanServiceStatus | null;
  serviceStatusChanges?: MDHealthAndTreatmentPlanServiceStateChange[] | null;
  /** @format date-time */
  servicePlannedExecutionTime: string;
  serviceConditions?: MDHealthAndTreatmentPlanServiceCondition[] | null;
}

export interface MDHealthAndTreatmentPlanServiceStateChange {
  /** @format date-time */
  changeTime: string;
}

export interface MDHealthAndTreatmentPlanTreatmentReason {
  treatmentReason: SCNamedCode;
  /** @maxLength 40000 */
  treatmentReasonExtension?: string | null;
  /** @maxLength 40000 */
  coordinator?: string | null;
  /** @maxLength 40000 */
  coordinatorProfessionalFirstName?: string | null;
  /** @maxLength 40000 */
  coordinatorProfessionalLastName?: string | null;
  /** @maxLength 40000 */
  coordinatorOrganizationOID?: string | null;
  /** @maxLength 40000 */
  coordinatorIndependentPractitionerOID?: string | null;
  /** @maxLength 40000 */
  coordinatorAdditionalInformation?: string | null;
}

export interface MaintainedDocumentContainer {
  type: MaintainedDocumentType;
  healthAndTreatmentPlan?: MDHealthAndTreatmentPlan | null;
}

export interface ModifyEntryBatchSearchRequest {
  /**
   * @minLength 1
   * @maxLength 40000
   */
  batchID: string;
  includedProtectedContentDataViews?: DataView[] | null;
}

export interface ModifyEntryBatchSearchResponse {
  /**
   * @minLength 1
   * @maxLength 40000
   */
  batchID: string;
  /** @format int32 */
  batchCount: number;
  includedProtectedContentDataViews?: DataView[] | null;
  excludedProtectedContentDataViews?: DataView[] | null;
}

export interface NewAppNotificationMessage {
  messageType: WebsocketMessageType;
  /**
   * Time when this websocket message was sent
   * @format date-time
   */
  timeStamp: string;
  notification: AppNotification;
}

/**
 * Sent by client when sending a message, and by server when informing participants of a new message.
 * When sent by client, responded to by another NewChatMessage if successful
 * MessageType: NewChatMessage
 */
export interface NewChatMessage {
  messageType: WebsocketMessageType;
  /**
   * Time when this websocket message was sent
   * @format date-time
   */
  timeStamp: string;
  /** @format uuid */
  messageId: string;
  contentType: ChatMessageContentType;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  content: string;
  /** @format uuid */
  roomId?: string | null;
  /**
   * Time when message was saved to database. Irrelevant in messages sent by client.
   * @format date-time
   */
  createdTimestamp?: string | null;
  /**
   * Irrelevant in messages sent by client.
   * Contains first name of sender, or null if message is system notification
   * @maxLength 128
   */
  senderFirstName?: string | null;
  /**
   * Irrelevant in messages sent by client.
   * Contains last name of sender, or null if message is system notification
   * @maxLength 128
   */
  senderLastName?: string | null;
  /**
   * Irrelevant in messages sent by client.
   * Contains guid of sender, or null if message is system notification
   * @format uuid
   */
  senderGuid?: string | null;
}

export interface NewEntry {
  status: EntryStatus;
  releaseDelayType: ReleaseDelayType;
  /**
   * @format date
   * @example "2005-01-20"
   */
  releaseDateToPatient?: string | null;
  otherPerson?: Patient | null;
  primaryDataView: DataView;
  secondaryDataViews?: DataView[] | null;
  /** @minItems 1 */
  sections: EntrySection[];
  /**
   * Current time is used as default value if not defined.
   * @format date-time
   */
  time?: string | null;
}

export interface NewPatientInQueueMessage {
  messageType: WebsocketMessageType;
  /**
   * Time when this websocket message was sent
   * @format date-time
   */
  timeStamp: string;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  queueName: string;
  /** @format uuid */
  queueId: string;
  /** @format int32 */
  waitingCount: number;
  /** @format date-time */
  longestWaitingTime: string;
}

export interface NonIntegratedAccessSettings {
  allowEdit?: boolean | null;
  mode: NonIntegratedAccessMode;
}

export interface NotificationScheduleDays {
  mon: boolean;
  tue: boolean;
  wed: boolean;
  thu: boolean;
  fri: boolean;
  sat: boolean;
  sun: boolean;
}

export interface NotificationScheduleHours {
  /** @example "00:00:00" */
  startTime: string;
  /** @example "00:00:00" */
  endTime: string;
}

export interface OffsetLimit {
  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  offset: number;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  limit: number;
}

export interface OpenPatientRequest {
  usePopulationRegistry?: boolean | null;
  /** @format uuid */
  patientGuid: string;
  outsourcing?: OpenPatientRequestOutsourcingSettings | null;
  authoring: OpenPatientRequestAuthoringSettings;
  registry: OpenPatientRequestRegistrySettings;
  accessReason: PatientInfoAccessReason;
  /** @maxLength 40000 */
  accessReasonDescription?: string | null;
}

export interface OpenPatientRequestAuthoringSettings {
  mode: ArchiveAuthoringMode;
  /** @maxLength 40000 */
  otherPersonID?: string | null;
  /** @maxLength 40000 */
  supervisorID?: string | null;
  /** @format date-time */
  dictationRecordingTime?: string | null;
}

export interface OpenPatientRequestOutsourcingSettings {
  type: OutsourcingType;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  authorizationOID: string;
}

export interface OpenPatientRequestRegistrySettings {
  accessModel: RegistryAccessModel;
  /** @maxLength 40000 */
  occupationalHealthcareClientID?: string | null;
}

export interface OpenPatientResponse {
  consentSummary?: ConsentSummary | null;
  authoringSettings: AuthoringSettings;
  registrySettings?: RegistrySettings | null;
  outsourcingAuthorization?: OutsourcingAuthorization | null;
  serviceEvent?: ServiceEvent | null;
}

export interface OpenServiceEventRequest {
  /**
   * @minLength 1
   * @maxLength 40000
   */
  oid: string;
  natureOfVisit?: HealthcareNatureOfVisit | null;
  serviceType?: SCNamedCode | null;
  contactType?: HealthcareContactType | null;
}

export interface OpenServiceEventResponse {
  serviceEvent: ServiceEvent;
}

export interface OpenedServiceEventStatisticsRequest {
  /**
   * Start date of the query (inclusive)
   * @format date
   * @example "2005-01-20"
   */
  startTime: string;
  /**
   * End date of the query (inclusive)
   * @format date
   * @example "2005-01-20"
   */
  endTime: string;
  /** Only results for users with these ids. If omitted, results from all users */
  includeUsers?: number[] | null;
  /** If defined, exclude users with these ids from results. */
  excludeUsers?: number[] | null;
  /** List of root organization OIDs to be included in the search results. If the value is undefined/null/empty, organizations are not restricted. */
  includeRootOrganizationOIDs?: string[] | null;
  groupByTime: StatisticsTimeGroupingType;
  separateBy: OpenedServiceEventStatisticsSeparator;
}

export interface OrganDonationWill {
  /** @format date-time */
  time: string;
  patient: Patient;
  author: ContentAuthor;
  serviceProducer: ContentProducerOrganization;
  donationAllowed: boolean;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  comments: string;
}

export interface OrganizationUnit {
  /**
   * @minLength 1
   * @maxLength 40000
   */
  oid: string;
  type?: OrganizationType | null;
  /** @maxLength 40000 */
  name?: string | null;
  /** @maxLength 40000 */
  phoneNumber?: string | null;
  phoneNumberType?: PhoneNumberType | null;
  address?: Address | null;
  /** @maxLength 40000 */
  rootOrganizationOID?: string | null;
  /** @maxLength 40000 */
  rootOrganizationName?: string | null;
}

export interface OutsourcingAuthorization {
  /**
   * @minLength 1
   * @maxLength 40000
   */
  oid: string;
  /** @format date-time */
  creationTime: string;
  type: OutsourcingType;
  /**
   * @format date
   * @example "2005-01-20"
   */
  startTime: string;
  /**
   * @format date
   * @example "2005-01-20"
   */
  endTime: string;
  patient?: Patient | null;
  author: ContentAuthor;
  organization: ContentProducerOrganization;
  serviceOrganizer: ContentProducerOrganization;
  serviceOrganizerResponsibleUnit?: ContentProducerOrganization | null;
  serviceProducer: ContentProducerOrganization;
  registry: RegistrySettings;
  producerWriteAccess: OutsourcingAuthorizationProducerWriteAccess;
  /** @maxLength 40000 */
  serviceEventOID?: string | null;
  producerReadAccess: OutsourcingAuthorizationProducerReadAccess;
  releasedServiceEvents?: string[] | null;
  /** @format date-time */
  releasedDocumentsStartTime?: string | null;
  /** @format date-time */
  releasedDocumentsEndTime?: string | null;
}

export interface PHFHiddenForm {
  hiddenType?: HealthcareFormType | null;
}

export interface PHFMedicalCertificateA {
  primaryDiagnosis?: SCNamedCode | null;
  otherDiagnoses?: SCNamedCode[] | null;
  workAccident?: boolean | null;
  trafficAccident?: boolean | null;
  leisureTimeAccident?: boolean | null;
  otherAccident?: boolean | null;
  /** @maxLength 40000 */
  otherAccidentDescription?: string | null;
  occupationalDisease?: boolean | null;
  organDonation?: boolean | null;
  /** @maxLength 40000 */
  organDonationDescription?: string | null;
  /**
   * @format date
   * @example "2005-01-20"
   */
  unableToWorkStartDate?: string | null;
  /**
   * @format date
   * @example "2005-01-20"
   */
  unableToWorkEndDate?: string | null;
  /** @maxLength 40000 */
  additionalInformation?: string | null;
  certificateReasonSicknessAllowance?: boolean | null;
  certificateReasonOther?: boolean | null;
  /** @maxLength 40000 */
  certificateReasonOtherExplanation?: string | null;
}

export interface PHFMedicalCertificateB {
  /** @maxLength 40000 */
  patientHeight?: string | null;
  /** @maxLength 40000 */
  patientWeight?: string | null;
  patientNativeLanguage?: SCNamedCode | null;
  languageUsedDuringConsultation?: SCNamedCode | null;
  diagnosesForMedicineReimbursement?: PHFMedicalCertificateBDiagnosis[] | null;
  statementRelatedDiagnoses?: PHFMedicalCertificateBDiagnosis[] | null;
  /**
   * @format date
   * @example "2005-01-20"
   */
  partiallyUnfitForWorkStartDate?: string | null;
  /**
   * @format date
   * @example "2005-01-20"
   */
  partiallyUnfitForWorkEndDate?: string | null;
  /**
   * @format date
   * @example "2005-01-20"
   */
  unfitForWorkStartDate?: string | null;
  /**
   * @format date
   * @example "2005-01-20"
   */
  unfitForWorkEndDate?: string | null;
  /**
   * @format date
   * @example "2005-01-20"
   */
  recommendedPartialSicknessAllowanceStartDate?: string | null;
  /**
   * @format date
   * @example "2005-01-20"
   */
  recommendedPartialSicknessAllowanceEndDate?: string | null;
  /**
   * @format date
   * @example "2005-01-20"
   */
  recommendedYearsOfServicePensionStartDate?: string | null;
}

export interface PHFMedicalCertificateBDiagnosis {
  code: SCNamedCode;
  /** @maxLength 40000 */
  name?: string | null;
}

export interface PHFMedicalCertificateD {
  diagnoses?: PHFMedicalCertificateDDiagnosis[] | null;
}

export interface PHFMedicalCertificateDDiagnosis {
  code: SCNamedCode;
  /** @maxLength 40000 */
  name?: string | null;
  /**
   * @format date
   * @example "2005-01-20"
   */
  date: string;
}

export interface PHFMileageCertificate {
  visitDates?: string[] | null;
}

export interface PalsoKysely {
  /**
   * Aikaleima ISO8601 Date and time in UTC -muodossa
   * @format date-time
   */
  aikaleima: string;
  /**
   * Henkilötunnus 11 merkkiä
   * @minLength 11
   * @maxLength 40000
   */
  henkilotunnus: string;
  kyselija: Kyselija;
}

export interface PalsoVastaus {
  henkilonTiedot: HenkilonTiedot;
  /** Palsokyselyn palauttama tieto suorakorvausoikeudesta */
  suorakorvausOikeus?: boolean | null;
  /**
   * Työpaikkakassan nimi, jos henkilö kuuluu työpaikkakassaaan
   * @minLength 1
   * @maxLength 40000
   */
  tyopaikkakassaNimi?: string | null;
  /**
   * Työpaikkakassan numero, jos henkilö kuuluu työpaikkakassaaan
   * @minLength 1
   * @maxLength 40000
   */
  tyopaikkakassaNro?: string | null;
}

export interface PartialHealthcareFormContainer {
  type: HealthcareFormType;
  unknownForm?: PHFUnknownForm | null;
  medicalCertificateA?: PHFMedicalCertificateA | null;
  medicalCertificateB?: PHFMedicalCertificateB | null;
  medicalCertificateD?: PHFMedicalCertificateD | null;
  mileageCertificate?: PHFMileageCertificate | null;
  hiddenForm?: PHFHiddenForm | null;
}

export interface Patient {
  /** Only value 'Permanent' is allowed. */
  idType: PersonIdentifierType;
  /** @maxLength 63 */
  id?: string | null;
  /**
   * Generated patient visit identifier
   * @format uuid
   */
  guid?: string | null;
  /**
   * The field is only considered if DPlatform.Model.Shared.Patient.IDType is DPlatform.Model.PersonIdentifierType.Temporary.
   * @format int32
   * @min 1800
   * @max 2999
   */
  idCreationYear?: number | null;
  /**
   * The field is only considered if DPlatform.Model.Shared.Patient.IDType is DPlatform.Model.PersonIdentifierType.ExternalTemporary.
   * @maxLength 64
   */
  temporaryIDRoot?: string | null;
  /**
   * The field is ignored when adding a new patient with idType 'Permanent'.
   * @format date
   * @example "2005-01-20"
   */
  dateOfBirth?: string | null;
  gender: Gender;
  /** @format uuid */
  pmsid?: string | null;
  /**
   * @minLength 1
   * @maxLength 128
   */
  firstName: string;
  /** @maxLength 128 */
  friendlyName?: string | null;
  /**
   * @minLength 1
   * @maxLength 128
   */
  lastName: string;
  /** @maxLength 2 */
  preferredLanguage?: string | null;
  /**
   * Validates values in requests using the following patterns:
   * - For numbers starting with '+358': ^\+358\d{6,12}$
   * - For other numbers: ^\+\d{1,3}\d{5,15}$
   * @maxLength 19
   */
  mobilePhoneNumber?: string | null;
  address?: Address | null;
  municipalityOfResidence?: SCNamedCode | null;
  /** @maxLength 255 */
  profession?: string | null;
  /** @maxLength 255 */
  jobDescription?: string | null;
  /** @maxLength 255 */
  employerName?: string | null;
  /** @maxLength 255 */
  insuranceCompanyName?: string | null;
  /**
   * Important for patients with chat feature enabled - chat instruction emails will use this address. Validates values in requests using the method System.ComponentModel.DataAnnotations.EmailAddressAttribute.IsValid.
   * - The validation code is available on GitHub (https://github.com/dotnet/runtime).
   * @maxLength 320
   */
  email?: string | null;
  /** @maxLength 255 */
  preliminaryInfo?: string | null;
  /** @maxLength 4000 */
  preliminaryDescription?: string | null;
  /**
   * Requests: The field is only relevant in patient addition requests (POST /Patients and POST /External/Patients).
   * Responses: The field is only relevant in the response message of POST /Era/Patient/GetInfo.
   * @maxLength 4000
   */
  preliminaryDescriptionNoQueue?: string | null;
  status?: PatientStatus | null;
  primaryGuardian?: PatientRelatedPerson | null;
  secondaryGuardian?: PatientRelatedPerson | null;
  /** The object is used exclusively in response messages and disregarded in requests. The fields are only updated during state changes. */
  timestamps?: StateChangeTimestamps | null;
  /**
   * The field is used exclusively in response messages and disregarded in requests.
   * @format uuid
   */
  sourceQueueGuid?: string | null;
  /**
   * The field is used exclusively in response messages and disregarded in requests.
   * @maxLength 40000
   */
  sourceQueueName?: string | null;
  queueGuids?: string[] | null;
  /**
   * The field is used exclusively in response messages and disregarded in requests.
   * True if an archive entry with status Complete or Archived has been created for the patient.
   */
  archiveEntryCreated?: boolean | null;
  /**
   * The field is used exclusively in response messages and disregarded in requests.
   * True if a prescription has been signed and sent.
   */
  prescriptionSent?: boolean;
  /**
   * The field is used exclusively in response messages and disregarded in requests.
   * True if a healthcare form has been signed and sent.
   */
  formForwarded?: boolean;
  /**
   * The field is used exclusively in response messages and disregarded in requests.
   * True if a message has been sent to the patient.
   */
  messageSentToPatient?: boolean;
  /**
   * The field is used exclusively in response messages and disregarded in requests.
   * True if a message has been sent to the patient.
   */
  messageReceivedFromPatient?: boolean;
  /**
   * The field is used exclusively in response messages and disregarded in requests.
   * True if underage data release to guardian has been decided. False if it has not been decided. Null if it has not been set.
   */
  underageDataReleaseToGuardianSet?: boolean | null;
  laboratoryReferralStatus?: LabTestRequestStatus;
  /**
   * NOT IN USE YET!
   * The field is used exclusively in response messages and disregarded in requests.
   * True if non-laboratory referral has been sent for patient.
   */
  otherReferralSent?: boolean;
  registryInfo?: PatientRegistryInfo | null;
  /** True if chat is enabled for this patient. Ignored in messages sent by client. */
  chatEnabled?: boolean;
  /**
   * Non-null if ChatEnabled is true. Ignored in messages sent by client.
   * @format uuid
   */
  chatRoomId?: string | null;
  /** @format date-time */
  lastOpenedAt?: string | null;
  /**
   * The field is used exclusively in response messages and disregarded in requests. Contains the data provided in the POST /External/Patients endpoint.
   * The value of the field must be compatible with JSON.stringify/JSON.parse, but no validations have been applied to it by the backend.
   * @maxLength 40000
   */
  externalData?: string | null;
  /**
   * The field is used exclusively in response messages and disregarded in requests. Present only if the patient was added via POST /External/Patients.
   * @format uuid
   */
  sendingCompanyId?: string | null;
  /** The field is used exclusively in response messages and disregarded in requests. Contains information for images added via the POST /External/Files/Upload endpoint. */
  preliminaryImages?: PatientPreliminaryImage[] | null;
}

export interface PatientCommunicationEmail {
  /** @format uuid */
  guid: string;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  title: string;
  status: EmailStatus;
  /** @format date-time */
  statusTS: string;
  /** @format date-time */
  createdTS: string;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  fromEmailAddress: string;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  toEmailAddress: string;
  /** Either this or 'templateEmailData' is defined. */
  emailData?: EmailData | null;
  /** Either this or 'emailData' is defined. */
  templateEmailData?: TemplateEmailData | null;
}

export interface PatientExternalReq {
  /** Only value 'Permanent' is allowed. */
  idType: PersonIdentifierType;
  gender: Gender;
  /** @format uuid */
  pmsid?: string | null;
  /**
   * @minLength 1
   * @maxLength 128
   */
  firstName: string;
  /** @maxLength 128 */
  friendlyName?: string | null;
  /**
   * @minLength 1
   * @maxLength 128
   */
  lastName: string;
  /** @maxLength 2 */
  preferredLanguage?: string | null;
  /**
   * Validates values in requests using the following patterns:
   * - For numbers starting with '+358': ^\+358\d{6,12}$
   * - For other numbers: ^\+\d{1,3}\d{5,15}$
   * @maxLength 19
   */
  mobilePhoneNumber?: string | null;
  address?: Address | null;
  municipalityOfResidence?: SCNamedCode | null;
  /** @maxLength 255 */
  profession?: string | null;
  /** @maxLength 255 */
  jobDescription?: string | null;
  /** @maxLength 255 */
  employerName?: string | null;
  /** @maxLength 255 */
  insuranceCompanyName?: string | null;
  /**
   * Important for patients with chat feature enabled - chat instruction emails will use this address. Validates values in requests using the method System.ComponentModel.DataAnnotations.EmailAddressAttribute.IsValid.
   * - The validation code is available on GitHub (https://github.com/dotnet/runtime).
   * @maxLength 320
   */
  email?: string | null;
  /** @maxLength 255 */
  preliminaryInfo?: string | null;
  /** @maxLength 4000 */
  preliminaryDescription?: string | null;
  primaryGuardian?: PatientRelatedPerson | null;
  secondaryGuardian?: PatientRelatedPerson | null;
  /**
   * @minLength 11
   * @maxLength 11
   */
  id: string;
  /** @maxLength 4000 */
  preliminaryDescriptionNoQueue?: string | null;
  /**
   * Identifiers of the queues to which the patient will be added.
   * @maxItems 255
   * @minItems 1
   */
  queueGuids: string[];
  /**
   * The value of the field must be compatible with JSON.stringify/JSON.parse, but no validations are applied to it by the backend.
   * Sensitive patient information must not be stored in this field.
   * It is the caller's responsibility to ensure that the data can be read later, which can be achieved, for example, by including versioning in the data.
   * @minLength 1
   * @maxLength 40000
   */
  externalData: string;
  /** @format uuid */
  sendingCompanyId: string;
}

export interface PatientInfoResponse {
  patient?: Patient | null;
  serviceEvent?: ServiceEvent | null;
}

export interface PatientPreliminaryImage {
  /** @format uuid */
  id: string;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  fileName: string;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  mimeType: string;
  /**
   * Size of the original file in bytes.
   * @format int64
   */
  filesize: number;
  /**
   * Defined only for images (image/*) that have been added after the API change.
   * @format int32
   */
  width?: number | null;
  /**
   * Defined only for images (image/*) that have been added after the API change.
   * @format int32
   */
  height?: number | null;
  /** If null, no additional sizes are available for the image. */
  sizes?: PatientPreliminaryImageSizes | null;
}

export interface PatientPreliminaryImageFullHd {
  /**
   * @minLength 1
   * @maxLength 40000
   */
  mimeType: string;
  /**
   * Size of the full-hd file in bytes.
   * @format int64
   */
  filesize: number;
  /** @format int32 */
  width: number;
  /** @format int32 */
  height: number;
}

export interface PatientPreliminaryImageSizes {
  fullHd: PatientPreliminaryImageFullHd;
  thumbnail: PatientPreliminaryImageThumbnail;
}

export interface PatientPreliminaryImageThumbnail {
  /**
   * @minLength 1
   * @maxLength 40000
   */
  mimeType: string;
  /**
   * @minLength 1
   * @maxLength 2147483647
   */
  imageBase64: string;
  /**
   * Size of the thumbnail file in bytes.
   * @format int64
   */
  filesize: number;
  /** @format int32 */
  width: number;
  /** @format int32 */
  height: number;
}

export interface PatientQueue {
  /** @format uuid */
  guid: string;
  /**
   * @minLength 1
   * @maxLength 100
   */
  name: string;
  isFifo: boolean;
  showPreliminaryInfo: boolean;
  showPreliminaryDescription: boolean;
}

export interface PatientQueueCostsInfo {
  priceType: PriceType;
  /** @format double */
  price?: number | null;
  /** @format double */
  minPrice?: number | null;
  /** @format double */
  maxPrice?: number | null;
  /** @maxLength 500 */
  description?: string | null;
}

export interface PatientQueueInfoOrganization {
  /**
   * @minLength 1
   * @maxLength 255
   */
  oid: string;
  /**
   * The field is used exclusively in response messages and disregarded in requests.
   * @maxLength 40000
   */
  name?: string | null;
}

export interface PatientQueueInfoUser {
  /**
   * @minLength 1
   * @maxLength 11
   */
  regNumber: string;
  /**
   * @minLength 1
   * @maxLength 128
   */
  firstName: string;
  /**
   * @minLength 1
   * @maxLength 128
   */
  lastName: string;
}

export interface PatientQueueSettings {
  queueInfo: SimplePatientQueueInfo;
  userSettings?: PatientQueueUserSettings | null;
}

export interface PatientQueueUserSettings {
  isVisible: boolean;
  getNotifications: boolean;
  notificationScheduleDays: NotificationScheduleDays;
  notificationScheduleHours?: NotificationScheduleHours | null;
  smsNotification: boolean;
  /**
   * Validates values in requests using the following patterns:
   * - For numbers starting with '+358': ^\+358\d{6,12}$
   * - For other numbers: ^\+\d{1,3}\d{5,15}$
   * @maxLength 19
   */
  phoneNumber?: string | null;
  emailNotification: boolean;
  /**
   * Validates values in requests using the method System.ComponentModel.DataAnnotations.EmailAddressAttribute.IsValid.
   * - The validation code is available on GitHub (https://github.com/dotnet/runtime).
   * @maxLength 320
   */
  emailAddress?: string | null;
  webhookNotification: boolean;
  /**
   * Validates values in requests using the method Uri.IsWellFormedUriString.
   * - For more information, see https://learn.microsoft.com/en-us/dotnet/api/system.uri.iswellformeduristring.
   * @maxLength 2048
   */
  webhookUrl?: string | null;
  uiNotification: boolean;
}

export interface PatientRegistryInfo {
  /** Determines whether the patient's registry is associated with the user's active registry by considering the root organizations. */
  isLinkedToUserActiveRegistry: boolean;
  /** @maxLength 40000 */
  rootOrganizationOID?: string | null;
  /** @maxLength 40000 */
  rootOrganizationName?: string | null;
}

export interface PatientRelatedPerson {
  /** @maxLength 128 */
  firstName?: string | null;
  /** @maxLength 128 */
  lastName?: string | null;
  /** @maxLength 11 */
  id?: string | null;
}

export interface PatientStateStatisticsRequest {
  /**
   * Start date of the query (inclusive)
   * @format date
   * @example "2005-01-20"
   */
  startTime: string;
  /**
   * End date of the query (inclusive)
   * @format date
   * @example "2005-01-20"
   */
  endTime: string;
  /** Only results for users with these ids. If omitted, results from all users */
  includeUsers?: number[] | null;
  /** If defined, exclude users with these ids from results. */
  excludeUsers?: number[] | null;
  /** List of root organization OIDs to be included in the search results. If the value is undefined/null/empty, organizations are not restricted. */
  includeRootOrganizationOIDs?: string[] | null;
  groupByTime: StatisticsTimeGroupingType;
  includeStates?: PatientStatus[] | null;
  excludeStates?: PatientStatus[] | null;
  separateBy?: PatientStateStatisticsSeparator | null;
  includeQueues?: string[] | null;
  excludeQueues?: string[] | null;
}

export interface PatientStateTimedStatisticsRequest {
  /**
   * Start date of the query (inclusive)
   * @format date
   * @example "2005-01-20"
   */
  startTime: string;
  /**
   * End date of the query (inclusive)
   * @format date
   * @example "2005-01-20"
   */
  endTime: string;
  /** Only results for users with these ids. If omitted, results from all users */
  includeUsers?: number[] | null;
  /** If defined, exclude users with these ids from results. */
  excludeUsers?: number[] | null;
  /** List of root organization OIDs to be included in the search results. If the value is undefined/null/empty, organizations are not restricted. */
  includeRootOrganizationOIDs?: string[] | null;
  groupByTime: StatisticsTimeGroupingType;
  /** @minItems 1 */
  startStates: PatientStatus[];
  /** @minItems 1 */
  endStates: PatientStatus[];
  separateBy?: PatientStateTimedStatisticsSeparator | null;
  includeQueues?: string[] | null;
  excludeQueues?: string[] | null;
}

export interface PatientTemplate {
  /**
   * @minLength 1
   * @maxLength 128
   */
  firstName: string;
  /** @maxLength 128 */
  friendlyName?: string | null;
  /**
   * @minLength 1
   * @maxLength 128
   */
  lastName: string;
  idType: PersonIdentifierType;
  /** @maxLength 63 */
  id?: string | null;
  /** @format int32 */
  idCreationYear?: number | null;
  /** @maxLength 64 */
  temporaryIDRoot?: string | null;
  /**
   * @format date
   * @example "2005-01-20"
   */
  dateOfBirth?: string | null;
  /** @format uuid */
  guid: string;
  /** @maxLength 19 */
  mobilePhoneNumber?: string | null;
  /** @maxLength 320 */
  email?: string | null;
  address?: Address | null;
  /**
   * Indicates when the user last opened the patient (based on the personal ID) through POST Era/Patient/Open within the same root organization.
   * @format date-time
   */
  lastOpenedAt?: string | null;
  /** @format date-time */
  createdTS: string;
}

export interface PatientTimeFromEventToStateStatisticsRequest {
  /**
   * Start date of the query (inclusive)
   * @format date
   * @example "2005-01-20"
   */
  startTime: string;
  /**
   * End date of the query (inclusive)
   * @format date
   * @example "2005-01-20"
   */
  endTime: string;
  /** Only results for users with these ids. If omitted, results from all users */
  includeUsers?: number[] | null;
  /** If defined, exclude users with these ids from results. */
  excludeUsers?: number[] | null;
  /** List of root organization OIDs to be included in the search results. If the value is undefined/null/empty, organizations are not restricted. */
  includeRootOrganizationOIDs?: string[] | null;
  groupByTime: StatisticsTimeGroupingType;
  /** @minItems 1 */
  startEvents: PatientStatisticsEventType[];
  /** @minItems 1 */
  endStates: PatientStatus[];
  separateBy?: PatientTimeToTreatedStatisticsSeparator | null;
  includeQueues?: string[] | null;
  excludeQueues?: string[] | null;
}

export interface Phrase {
  /** @format uuid */
  guid: string;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  name: string;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  value: string;
  type: PhraseType;
  /** If true, phrase is shared between users of the same root organization. Must not be true for IAH users. */
  isShared: boolean;
  category: PhraseCategory;
}

/**
 * Message sent by client to ensure the connection remains open.
 * Server responds by sending a PingMessage back.
 * MessageType: PingMessage
 */
export interface PingMessage {
  messageType: WebsocketMessageType;
  /**
   * Time when this websocket message was sent
   * @format date-time
   */
  timeStamp: string;
}

export interface PortInformationRequest {
  /**
   * @minLength 1
   * @maxLength 100
   */
  scSessionID: string;
  /**
   * @minLength 1
   * @maxLength 10
   */
  scPort: string;
}

export interface PrefillFormRequest {
  mode: HealthcareFormPrefillMode;
  emptyFormType?: HealthcareFormType | null;
  template?: PartialHealthcareFormContainer | null;
  /** @maxLength 40000 */
  entryOID?: string | null;
  /** @maxLength 40000 */
  redirectOnSave?: string | null;
  /** @maxLength 40000 */
  redirectOnCancel?: string | null;
}

export interface PrefillPrescriptionRequest {
  mode: PrescriptionPrefillMode;
  /** @maxLength 40000 */
  originalPrescriptionOID?: string | null;
  originalPrescriptionIsNarcotic?: boolean | null;
  emptyPrescriptionType?: DrugProductType | null;
  template?: PrescriptionTemplateData | null;
  medicationChangeReason?: MedicalTreatmentChangeReason | null;
  /** @maxLength 40000 */
  medicationChangeExplanation?: string | null;
  medicationChangePermission?: PatientPermission | null;
  /** @maxLength 40000 */
  redirectOnSave?: string | null;
  /** @maxLength 40000 */
  redirectOnCancel?: string | null;
}

/**
 * While there are no strict technical length limits for string fields, it's important to consider their lengths for printing.
 * Visible characters in PDF fields depend on the reader and characters used.
 * Suggested indicative max length limits for continuous text in Chrome are noted in brackets in the field descriptions.
 */
export interface PrefillSV120HealthcareFormRequest {
  /** Each item must contain unique 'tariffCode' value. */
  services?: SV120Service[] | null;
  /**
   * Corresponds to the SV120 'Allekirjoituksen paikka ja aika' [approx. max 70 characters].
   * @maxLength 40000
   */
  placeAndTime?: string | null;
}

export interface PrefillSV120HealthcareFormResponse {
  /**
   * PDF data encoded in Base64 format.
   * @minLength 1
   * @maxLength 2147483647
   */
  data: string;
}

export interface PrefillSV132HealthcareFormRequest {
  /**
   * Specifies from which date (vastaanottopäivä) the SV120 document information is included to the result (inclusive).
   * @format date
   * @example "2005-01-20"
   */
  dateFrom: string;
  /**
   * Specifies up to which date (vastaanottopäivä) the SV120 document information is included to the result (inclusive).
   * @format date
   * @example "2005-01-20"
   */
  dateTo: string;
}

export interface PrescriptionData {
  state?: PrescriptionState | null;
  /** @maxLength 40000 */
  documentOID?: string | null;
  /** @format int32 */
  version?: number | null;
  /** @maxLength 40000 */
  documentSetOID?: string | null;
  /** @maxLength 40000 */
  renewalFromPrescription?: string | null;
  reservationStatus?: PrescriptionReservationStatus | null;
  dispensationStatus?: PrescriptionDispensationStatus | null;
  lockStatus?: PrescriptionLockStatus | null;
  renewalStatus?: PrescriptionRenewalStatus | null;
  /** @maxLength 40000 */
  serviceEventOID?: string | null;
  /** @format date-time */
  documentCreationTime?: string | null;
  /** @format date-time */
  prescriptionCreationTime?: string | null;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  languageCode: string;
  author?: ContentAuthor | null;
  organization?: ContentProducerOrganization | null;
  originalAuthor?: ContentAuthor | null;
  originalOrganization?: ContentProducerOrganization | null;
  reservingOrganization?: ContentProducerOrganization | null;
  patient?: Patient | null;
  drug: PrescriptionDrugData;
  drugIngredients?: PrescriptionDrugIngredientData[] | null;
  drugQuantity: PrescriptionDrugQuantityData;
  changeForbidden?: boolean | null;
  dosageInstructionsOnlyAsText: boolean;
  /** @maxLength 40000 */
  dosageInstructions?: string | null;
  /** @maxLength 40000 */
  intendedUseDescription?: string | null;
  patientWeight?: ValueUnit | null;
  /** @maxLength 40000 */
  iterationCount?: string | null;
  /** @maxLength 40000 */
  iterationInterval?: string | null;
  /** @maxLength 40000 */
  increasedCompensationReasonCode?: string | null;
  /** @maxLength 40000 */
  increasedCompensationReasonText?: string | null;
  /** @maxLength 40000 */
  messageToPharmacy?: string | null;
  /**
   * @format date
   * @example "2005-01-20"
   */
  expirationDate?: string | null;
  treatmentTypeIllness?: boolean | null;
  treatmentTypeAccident?: boolean | null;
  treatmentTypeOther?: boolean | null;
  /** @maxLength 40000 */
  employerName?: string | null;
  /** @maxLength 40000 */
  insuranceCompanyName?: string | null;
  portionedFulfillment?: boolean | null;
  /** @maxLength 40000 */
  portionedFulfillmentExplanation?: string | null;
  renewalForbiddanceReason?: MedicalTreatmentChangeReason | null;
  /** @maxLength 40000 */
  renewalForbiddanceExplanation?: string | null;
  sic?: boolean | null;
  narcoticType?: NarcoticType | null;
  isNarcotic?: boolean | null;
  patientIdentificationType?: PatientIdentificationType | null;
  /** @maxLength 40000 */
  patientIdentificationExplanation?: string | null;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  drugDatabaseVersion: string;
  permanentMedication?: boolean | null;
  isNewMedication?: boolean | null;
  productType: PrescriptionProductType;
  prescriptionType: PrescriptionType;
  underageDataReleaseToGuardian?: HealthcareUnderageDataRelease | null;
  invalidationReason?: MedicalTreatmentChangeReason | null;
  invalidationPermission?: PatientPermission | null;
  /** @maxLength 40000 */
  invalidationExplanation?: string | null;
  correctionReason?: MedicalTreatmentChangeReason | null;
  /** @maxLength 40000 */
  correctionExplanation?: string | null;
  /** @maxLength 40000 */
  remainingQuantityDescription?: string | null;
  prescribersFee?: ValueUnit | null;
  isPrescribedAsSpecialist?: boolean | null;
  isDrugForTreatmentOfDangerousDisease?: boolean | null;
  pharmacyCreatedPrescriptionType?: PharmacyCreatedPrescriptionType | null;
  pharmacyCreatedPrescriptionReason?: PharmacyCreatedPrescriptionReason | null;
  /** @maxLength 40000 */
  pharmacyCreatedPrescriptionExplanation?: string | null;
  hospitalPharmacy?: ContentProducerOrganization | null;
  /** @maxLength 40000 */
  prescribedDrugIdentifier?: string | null;
  /** @format int32 */
  prescribedDrugPartIdentifier?: number | null;
  specialExplanationCodeItem?: SCNamedCode | null;
  /**
   * @format date
   * @example "2005-01-20"
   */
  specialExplanationDate?: string | null;
  structuredDosageInstruction?: StructuredDosageInstruction | null;
  requiresSpecialObservation?: YesNoNotKnownValue | null;
  biologicalDrug?: YesNoNotKnownValue | null;
  biosimilar?: YesNoNotKnownValue | null;
  /** @maxLength 40000 */
  reasoningForMoreExpensiveBiological?: string | null;
  /** @maxLength 40000 */
  drugGroup?: string | null;
  bloodOrPlasmaProduct?: YesNoNotKnownValue | null;
}

export interface PrescriptionDrugData {
  type: DrugProductType;
  atcClass?: SCNamedCode | null;
  /** @maxLength 40000 */
  productNumber?: string | null;
  /** @maxLength 40000 */
  name?: string | null;
  /** @maxLength 40000 */
  strength?: string | null;
  /** @maxLength 40000 */
  dosageForm?: string | null;
  /** @maxLength 40000 */
  container?: string | null;
  /** @maxLength 40000 */
  device?: string | null;
  /** @maxLength 40000 */
  company?: string | null;
  /** @maxLength 40000 */
  drugTypeCode?: string | null;
  /** @maxLength 40000 */
  drugTypeName?: string | null;
  /** @maxLength 40000 */
  manufacturingInstructions?: string | null;
  /** @maxLength 40000 */
  description?: string | null;
}

export interface PrescriptionDrugIngredientData {
  isActiveIngredient: boolean;
  atcClass?: SCNamedCode | null;
  /** @maxLength 40000 */
  name?: string | null;
  strength?: ValueUnit | null;
  /** @maxLength 40000 */
  strengthAsText?: string | null;
}

export interface PrescriptionDrugQuantityData {
  quantityType: DrugQuantityType;
  /** @maxLength 40000 */
  packageText?: string | null;
  /** @maxLength 40000 */
  packageSizeMultiplier?: string | null;
  packageSize?: ValueUnit | null;
  /** @maxLength 40000 */
  packageCount?: string | null;
  totalQuantity?: ValueUnit | null;
  treatmentDuration?: ValueUnit | null;
}

export interface PrescriptionHeaderData {
  state?: PrescriptionState | null;
  /** @maxLength 40000 */
  documentOid?: string | null;
  /** @format int32 */
  version?: number | null;
  /** @maxLength 40000 */
  documentSetOid?: string | null;
  reservationStatus?: PrescriptionReservationStatus | null;
  /** @maxLength 40000 */
  reservingOrganization?: string | null;
  dispensationStatus?: PrescriptionDispensationStatus | null;
  lockStatus?: PrescriptionLockStatus | null;
  /** @maxLength 40000 */
  lockOwner?: string | null;
  renewalStatus?: PrescriptionRenewalStatus | null;
  /** @format date-time */
  documentCreationTime?: string | null;
  /** @maxLength 40000 */
  languageCode?: string | null;
  contentAuthor?: ContentAuthor | null;
  organization?: ContentProducerOrganization | null;
  patient?: Patient | null;
  drug?: PrescriptionDrugData | null;
  drugQuantity?: PrescriptionDrugQuantityData | null;
  /** @maxLength 40000 */
  drugQuantityText?: string | null;
  /** @maxLength 40000 */
  dosageInstructions?: string | null;
  dosageInstructionsOnlyAsText?: boolean | null;
  /** @maxLength 40000 */
  intendedUseDescription?: string | null;
  /** @maxLength 40000 */
  iterationCount?: string | null;
  /** @maxLength 40000 */
  iterationInterval?: string | null;
  /** @format date-time */
  prescriptionCreationTime?: string | null;
  /**
   * @format date
   * @example "2005-01-20"
   */
  expirationDate?: string | null;
  portionedFulfillment?: boolean | null;
  renewalForbidden?: boolean | null;
  sic?: boolean | null;
  narcoticType?: NarcoticType | null;
  isNarcotic?: boolean | null;
  permanentMedication?: boolean | null;
  prescriptionType?: PrescriptionType | null;
  underageDataReleaseToGuardian?: HealthcareUnderageDataRelease | null;
  validityStatus?: PrescriptionValidityStatus | null;
  invalidityReason?: PrescriptionInvalidityReason | null;
  remainingQuantity?: DispensationDrugQuantityData | null;
  /** @format date-time */
  lastDispensationTime?: string | null;
  /** @maxLength 40000 */
  lastDispensationDescription?: string | null;
  /** @maxLength 40000 */
  prescribedDrugIdentifier?: string | null;
  /** @format int32 */
  prescribedDrugPartIdentifier?: number | null;
  pharmacyCreatedPrescriptionType?: PharmacyCreatedPrescriptionType | null;
  structuredDosageInstruction?: StructuredDosageInstruction | null;
  changeForbidden?: boolean | null;
  patientWeight?: ValueUnit | null;
}

export interface PrescriptionInfo {
  /** @format date-time */
  prescriptionCreationTime?: string | null;
  /** @maxLength 40000 */
  prescriptionName?: string | null;
  /**
   * Consists of Name, Strength and Dosage form
   * @maxLength 40000
   */
  prescriptionLongName?: string | null;
  /**
   * Aka: VNR code
   * @maxLength 40000
   */
  prescriptionProductNumber?: string | null;
  prescriptionState?: PrescriptionState | null;
  /**
   * True, if service event where prescription was created contains an archive entry whose status is completed or archived.
   * Null, if prescription was created before entry status saving was implemented.
   */
  archiveEntrySaved?: boolean | null;
  /** @maxLength 40000 */
  userFirstName?: string | null;
  /** @maxLength 40000 */
  userLastName?: string | null;
  /** @maxLength 40000 */
  userRegNumber?: string | null;
  /** @format int32 */
  userId?: number | null;
}

export interface PrescriptionMessage {
  messageType: WebsocketMessageType;
  /**
   * Time when this websocket message was sent
   * @format date-time
   */
  timeStamp: string;
  modificationType: ModificationType;
  /** @maxLength 40000 */
  prescriptionName?: string | null;
  /** @maxLength 40000 */
  prescriptionStrength?: string | null;
  prescriptionQuantityData?: PrescriptionDrugQuantityData | null;
}

export interface PrescriptionOperationResult {
  /**
   * @minLength 1
   * @maxLength 40000
   */
  prescriptionOid: string;
  result: AllowedPrescriptionOperationResult;
  allowedPrefillModes: PrescriptionPrefillMode[];
}

export interface PrescriptionStatisticsRequest {
  /**
   * Start date of the query (inclusive)
   * @format date
   * @example "2005-01-20"
   */
  startTime: string;
  /**
   * End date of the query (inclusive)
   * @format date
   * @example "2005-01-20"
   */
  endTime: string;
  /** Only results for users with these ids. If omitted, results from all users */
  includeUsers?: number[] | null;
  /** If defined, exclude users with these ids from results. */
  excludeUsers?: number[] | null;
  /** List of root organization OIDs to be included in the search results. If the value is undefined/null/empty, organizations are not restricted. */
  includeRootOrganizationOIDs?: string[] | null;
  groupByTime: StatisticsTimeGroupingType;
  separateBy?: PrescriptionStatisticsSeparator | null;
  includeProducts?: string[] | null;
  excludeProducts?: string[] | null;
  includeQueues?: string[] | null;
  excludeQueues?: string[] | null;
}

export interface PrescriptionTemplate {
  /**
   * Note: Value is ignored in POST and PUT requests even if provided by client
   * (The value is generated by backend in POST requests and value from URL is used in PUT requests)
   * @format uuid
   */
  id?: string | null;
  /** @maxLength 100 */
  name?: string | null;
  /** @maxLength 100 */
  vnr?: string | null;
  /** @maxLength 100 */
  duodecimId?: string | null;
  quantityType?: DrugQuantityType | null;
  /** @maxLength 100 */
  packageSize?: string | null;
  /** @maxLength 100 */
  packageCount?: string | null;
  /** @maxLength 100 */
  amount?: string | null;
  /** @maxLength 100 */
  unit?: string | null;
  /** @maxLength 1000 */
  dosageInstructions?: string | null;
  /** @maxLength 1000 */
  intendedUseDescription?: string | null;
  type?: DrugProductType | null;
  /** @maxLength 200 */
  longName?: string | null;
  /** @maxLength 100 */
  templateName?: string | null;
}

export interface PrescriptionTemplateData {
  drug: PrescriptionDrugData;
  drugIngredients?: PrescriptionDrugIngredientData[] | null;
  drugQuantity?: PrescriptionDrugQuantityData | null;
  /** @maxLength 40000 */
  dosageInstructions?: string | null;
  /** @maxLength 40000 */
  intendedUseDescription?: string | null;
  narcoticType?: NarcoticType | null;
  isNarcotic?: boolean | null;
  productType?: PrescriptionProductType | null;
  dosageReportingType: PrescriptionDosageReportingType;
  structuredDosageInstruction?: StructuredDosageInstruction | null;
}

export interface PrescriptionTemplateRequest {
  template: PrescriptionTemplate;
}

export interface PrescriptionTemplateResponse {
  template: PrescriptionTemplate;
}

export interface PrescriptionTemplatesResponse {
  templates: PrescriptionTemplate[];
}

export interface QueueWaitTimeResponse {
  /** @format uuid */
  queueId: string;
  /**
   * Amount of patients currently waiting in queue
   * @format int32
   */
  waitingPatientCount: number;
  /**
   * Maximum wait time among currently waiting patients as milliseconds.
   * Zero if there are no waiting patients.
   * Note that for patients who have been taken from the queue and afterwards returned to it, all of that time will be considered wait time.
   * @format int64
   */
  maximumWaitTime: number;
}

export interface RedirectResponse {
  /**
   * @minLength 1
   * @maxLength 40000
   */
  url: string;
}

export interface ReferralFormFields {
  /** @maxLength 40000 */
  sendingFacilityName?: string | null;
  /** @maxLength 40000 */
  sendingFacilityAddress?: string | null;
  /** @format date-time */
  date?: string | null;
  /** @maxLength 40000 */
  receivingFacilityName?: string | null;
  /** @maxLength 40000 */
  receivingFacilityAddress?: string | null;
  /** @maxLength 40000 */
  speciality?: string | null;
  urgency?: ReferralUrgency | null;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  patientSsid: string;
  /** @maxLength 40000 */
  patientLastAndFirstNames?: string | null;
  /** @maxLength 40000 */
  profession?: string | null;
  /** @maxLength 40000 */
  patientAddress?: string | null;
  /** @maxLength 40000 */
  patientPostNumber?: string | null;
  /** @maxLength 40000 */
  patientPostalDistrict?: string | null;
  /** @maxLength 40000 */
  patientMunicipality?: string | null;
  /** @maxLength 40000 */
  patientHomePhoneNumber?: string | null;
  /** @maxLength 40000 */
  patientWorkPhoneNumber?: string | null;
  employment?: ReferralEmployment | null;
  /** @maxLength 40000 */
  diagnosis?: string | null;
  /** @maxLength 40000 */
  information?: string | null;
  senderCannotContinueCare?: boolean | null;
  senderRequiresFinalStatement?: boolean | null;
  patientBringsRtg?: boolean | null;
  patientBringsLab?: boolean | null;
  patientBringsOther?: boolean | null;
  /** @maxLength 40000 */
  patientBringsOtherDescription?: string | null;
  /** @maxLength 40000 */
  sendingDoctorNameAndSVNumber?: string | null;
  /** @maxLength 40000 */
  sendingDoctorAddressAndPhone?: string | null;
  specialCare?: boolean | null;
  /** @maxLength 40000 */
  careLevel?: string | null;
  /** @maxLength 40000 */
  executiveMedicalDirectorApproval?: string | null;
}

export interface ReferralSummary {
  /** @format uuid */
  referralId: string;
  /** @format date-time */
  createdTS: string;
  /** @maxLength 40000 */
  diagnosis?: string | null;
  urgency?: ReferralUrgency | null;
  /** @maxLength 40000 */
  receivingFacilityName?: string | null;
}

export interface RefreshTokenRequest {
  /**
   * @minLength 1
   * @maxLength 40000
   */
  accessToken: string;
  tokenType: AccessTokenType;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  refreshToken: string;
}

export interface RefreshTokenResponse {
  /**
   * @minLength 1
   * @maxLength 40000
   */
  accessToken: string;
  /** @format int32 */
  expiresIn: number;
  tokenType: AccessTokenType;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  refreshToken: string;
  sessionStatus: SessionStatus;
}

export interface RegistrySettings {
  /**
   * @minLength 1
   * @maxLength 40000
   */
  ownerOID: string;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  ownerName: string;
  type: ArchiveRegistryType;
  /** @maxLength 40000 */
  extensionID?: string | null;
  /** @maxLength 40000 */
  extensionName?: string | null;
}

export interface RoomInfo {
  /** @format uuid */
  roomId: string;
  /** @format int32 */
  unreadMessageCount: number;
  /** @format date-time */
  created: string;
  /**
   * Defined as creation time of last non-system message in room. Null for rooms with no messages yet.
   * @format date-time
   */
  lastActive?: string | null;
  /**
   * Id of queue where patient of room was taken from. Null if patient was not from a queue.
   * @format uuid
   */
  queueId?: string | null;
  /**
   * Identifier that is unique to the patient queue item this room is connected to, same as 'Guid' in GET /Patients end point results.
   * @format uuid
   */
  patientQueueItemGuid: string;
  status: ChatRoomStatus;
  members: RoomMember[];
}

/**
 * Message sent by server as response to ListRoomsRequestMessage
 * MessageType: RoomListMessage
 */
export interface RoomListMessage {
  messageType: WebsocketMessageType;
  /**
   * Time when this websocket message was sent
   * @format date-time
   */
  timeStamp: string;
  rooms?: RoomInfo[] | null;
}

export interface RoomMember {
  /**
   * @minLength 1
   * @maxLength 128
   */
  firstName: string;
  /**
   * @minLength 1
   * @maxLength 128
   */
  lastName: string;
  role: ChatterRole;
  status: ChatterStatus;
  /**
   * Identifier that is unique to the chatter's person.
   * @format uuid
   */
  guid: string;
  /**
   * This is only non-null in messages sent to doctors, and even then only non-null for RoomMembers where Role is Patient
   * @maxLength 40000
   */
  ssid?: string | null;
}

/** Message sent by server to joined members of room when allowed member list of room changes */
export interface RoomMemberListChangeMessage {
  messageType: WebsocketMessageType;
  /**
   * Time when this websocket message was sent
   * @format date-time
   */
  timeStamp: string;
  /** @format uuid */
  roomId: string;
  members: RoomMember[];
}

/**
 * Message sent by server as response to GetRoomMessagesRequest
 * MessageType: RoomMessagesListMessage
 */
export interface RoomMessageListMessage {
  messageType: WebsocketMessageType;
  /**
   * Time when this websocket message was sent
   * @format date-time
   */
  timeStamp: string;
  /** @format uuid */
  roomId: string;
  messages?: ListedMessage[] | null;
}

export interface SCCodeSystemCode {
  /**
   * @minLength 1
   * @maxLength 40000
   */
  codeSystemOID: string;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  codeSystemName: string;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  code: string;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  name: string;
}

export interface SCDiagnosis {
  code: SCNamedCode;
  /** @maxLength 40000 */
  name?: string | null;
  primarity: DiagnosisPrimarity;
  externalReasonCode?: SCNamedCode | null;
  /** @maxLength 40000 */
  externalReasonDescription?: string | null;
  injuryTypeCode?: SCNamedCode | null;
  /** @maxLength 40000 */
  injuryTypeDescription?: string | null;
  adverseEffectReasonCode?: SCNamedCode | null;
  /** @maxLength 40000 */
  adverseEffectReasonDescription?: string | null;
  icD10RelationCode?: SCNamedCode | null;
  certainty?: Certainty | null;
  permanence: Permanence | null;
  episodes?: SCDiagnosisEpisode[] | null;
  source?: InformationSource | null;
  /**
   * @format date
   * @example "2005-01-20"
   */
  startTime: string;
  /**
   * @format date
   * @example "2005-01-20"
   */
  endTime?: string | null;
  author?: ContentAuthor | null;
  authorOrganization?: ContentProducerOrganization | null;
  conclusionAuthor?: ContentAuthor | null;
  conclusionAuthorOrganization?: ContentProducerOrganization | null;
  conclusionReason?: HealthInformationChangeReason | null;
  /** @maxLength 40000 */
  conclusionDescription?: string | null;
}

export interface SCDiagnosis2 {
  code: SCNamedCode;
  /** @maxLength 40000 */
  name?: string | null;
  primarity: DiagnosisPrimarity | null;
  externalReasonCode?: SCNamedCode | null;
  injuryTypeCode?: SCNamedCode | null;
  injuryExcerciseType?: SCNamedCode | null;
  adverseEffectReasonCode?: SCNamedCode | null;
  icD10RelationCode?: SCNamedCode | null;
  certainty?: Certainty | null;
  permanence: Permanence | null;
  episodes?: SCDiagnosisEpisode[] | null;
  source?: InformationSource | null;
  /**
   * @format date
   * @example "2005-01-20"
   */
  startTime: string | null;
  /**
   * @format date
   * @example "2005-01-20"
   */
  endTime?: string | null;
  isAuthorNotDoctor?: boolean | null;
  author?: ContentAuthor | null;
  authorOrganization?: ContentProducerOrganization | null;
  conclusionAuthor?: ContentAuthor | null;
  conclusionAuthorOrganization?: ContentProducerOrganization | null;
  conclusionReason?: HealthInformationChangeReason | null;
  /** @maxLength 40000 */
  conclusionDescription?: string | null;
}

export interface SCDiagnosisEpisode {
  /**
   * @minLength 1
   * @maxLength 40000
   */
  oid: string;
  /** @maxLength 40000 */
  name: string | null;
}

export interface SCHiddenContent {
  hiddenType: StructuredContentType;
}

export interface SCLabTestOperation {
  code: SCNamedCode;
  /**
   * @minLength 0
   * @maxLength 40000
   */
  abbreviation: string;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  description: string | null;
}

export interface SCLabTestOperation2 {
  code?: SCNamedCode | null;
  codeSystemOperation?: SCCodeSystemCode | null;
  /** @maxLength 40000 */
  additionalInformation?: string | null;
}

export interface SCLabTestReferenceValue {
  low?: ValueUnit | null;
  high?: ValueUnit | null;
  /** @maxLength 40000 */
  referenceAsText?: string | null;
  /** @maxLength 40000 */
  target?: string | null;
}

export interface SCLabTestReport {
  /**
   * @minLength 1
   * @maxLength 40000
   */
  report: string;
}

export interface SCLabTestReport2 {
  status: ReportStatus;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  resultIdentifier: string;
  /** @format date-time */
  time: string;
  author?: ContentAuthor | null;
  organization?: ContentProducerOrganization | null;
  operation?: SCNamedCode | null;
  codeSystemOperation?: SCCodeSystemCode | null;
  specimenType?: SCNamedCode | null;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  report: string;
}

export interface SCLabTestRequest {
  state: ArchiveLabTestOperationState | null;
  /** @format date-time */
  time: string | null;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  description: string | null;
  isMainLabTest: boolean;
  operations: SCLabTestOperation[];
}

export interface SCLabTestRequest2 {
  /** @maxLength 40000 */
  requestIdentifier?: string | null;
  /** @format date-time */
  time: string;
  requester?: ContentAuthor | null;
  requesterOrganization?: ContentProducerOrganization | null;
  /** @maxLength 40000 */
  additionalInformation?: string | null;
  operations: SCLabTestOperation2[];
  specimenType?: SCNamedCode | null;
  /** @format date-time */
  repetitionStartDate?: string | null;
  /** @format date-time */
  repetitionEndDate?: string | null;
  /** @format int32 */
  repetitionMaxCount?: number | null;
}

export interface SCLabTestResult {
  state: LabTestResultState;
  /** @format date-time */
  time: string;
  performer?: ContentAuthor | null;
  organization?: ContentProducerOrganization | null;
  operation: SCLabTestOperation;
  type: ArchiveLabResultType;
  /** @maxLength 40000 */
  result?: string | null;
  /** @maxLength 40000 */
  resultUnit?: string | null;
  /** @maxLength 40000 */
  radiation?: string | null;
  /** @maxLength 40000 */
  radiationUnit?: string | null;
  referenceValueDeviation: ArchiveLabResultInterpretationCode | null;
  referenceValues?: SCLabTestReferenceValue[] | null;
  /** @format date-time */
  resultFinishedTime: string | null;
  /** @maxLength 40000 */
  additionalInformation?: string | null;
  microbeCultivationResults?: SCMicrobeCultivationResult[] | null;
  /** @maxLength 40000 */
  reportText?: string | null;
}

export interface SCLabTestResult2 {
  resultStatus: LabTestResultState;
  /** @maxLength 40000 */
  resultIdentifier?: string | null;
  /** @format date-time */
  samplingTime: string | null;
  /** @maxLength 40000 */
  performerName?: string | null;
  operation?: SCNamedCode | null;
  codeSystemOperation?: SCCodeSystemCode | null;
  type: ArchiveLabResultType | null;
  resultValue?: ValueUnit | null;
  /** @maxLength 40000 */
  resultText?: string | null;
  referenceValueDeviation: ArchiveLabResultInterpretationCode | null;
  referenceValues?: SCLabTestReferenceValue[] | null;
  specimenType?: SCNamedCode | null;
  /** @format date-time */
  resultFinishedTime: string | null;
  method: MeasurementMethod | null;
  /** @maxLength 40000 */
  additionalInformation?: string | null;
  informationSource?: InformationSource | null;
  microbeCultivationResults?: SCMicrobeCultivationResult2[] | null;
  /** @maxLength 40000 */
  requestIdentitier?: string | null;
  requestDocumentData?: DocumentIdentificationData | null;
  reportStatus?: ReportStatus | null;
  /** @maxLength 40000 */
  reportText?: string | null;
  /** @format date-time */
  reportTime?: string | null;
  reportAuthor?: ContentAuthor | null;
  reportOrganization?: ContentProducerOrganization | null;
}

export interface SCMicrobeCultivationResult {
  /** @maxLength 40000 */
  oid?: string | null;
  code: SCNamedCode;
  operationCode?: SCNamedCode | null;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  value: string;
  /** @maxLength 40000 */
  valueUnit?: string | null;
  type: MicrobeStudyType;
  /** @maxLength 40000 */
  typeExplanation?: string | null;
  /** @maxLength 40000 */
  description?: string | null;
  sensitivityStudies?: SCSensitivityStudy[] | null;
}

export interface SCMicrobeCultivationResult2 {
  findingCode: SCNamedCode;
  value?: ValueUnit_Nullable | null;
  valueAssessment?: MicrobeQuantity | null;
  /** @maxLength 40000 */
  additionalInformation?: string | null;
  sensitivityStudies?: SCSensitivityStudy2[] | null;
}

export interface SCNamedCode {
  type: CodedSystemType;
  /**
   * @minLength 1
   * @maxLength 255
   */
  code: string;
  /**
   * The field is used exclusively in response messages and disregarded in requests.
   * @maxLength 40000
   */
  name?: string | null;
}

export interface SCRiskInfo {
  /** @maxLength 40000 */
  description: string;
  code?: SCNamedCode | null;
  level: RiskLevel;
  typeCode: SCNamedCode;
  certainty?: Certainty | null;
  permanence?: Permanence | null;
  informationSource?: InformationSource | null;
  /** @maxLength 40000 */
  additionalInfoDescription?: string | null;
  additionalInfoCode?: SCNamedCode | null;
  aboBloodGroup?: ABOBloodGroup | null;
  rhDBloodGroup?: RhDBloodGroup | null;
  /** @maxLength 40000 */
  instructionsForTreatment?: string | null;
  /** @format date-time */
  startTime: string;
  /** @format date-time */
  endTime?: string | null;
  author?: ContentAuthor | null;
  organization?: ContentProducerOrganization | null;
  conclusionReason?: HealthInformationChangeReason | null;
  /** @maxLength 40000 */
  conclusionExplanation?: string | null;
  conclusionAuthor?: ContentAuthor | null;
  conclusionAuthorOrganization?: ContentProducerOrganization | null;
}

export interface SCRiskInfo2 {
  /** @maxLength 40000 */
  oid?: string | null;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  description: string;
  code: SCNamedCode_SCRiskInfo2;
  level: RiskLevel;
  typeCode: SCNamedCode;
  certainty?: Certainty | null;
  permanence?: Permanence | null;
  informationSource?: InformationSource | null;
  /** @maxLength 40000 */
  additionalInfoDescription?: string | null;
  additionalInfoCode?: SCNamedCode | null;
  /** @maxLength 40000 */
  instructionsForTreatment?: string | null;
  /** @format date-time */
  startTime?: string | null;
  /** @format date-time */
  endTime?: string | null;
  isEstimatedEndTime?: boolean | null;
  author?: ContentAuthor | null;
  organization?: ContentProducerOrganization | null;
  conclusionReason?: HealthInformationChangeReason | null;
  /** @maxLength 40000 */
  conclusionExplanation?: string | null;
  conclusionAuthor?: ContentAuthor | null;
  conclusionAuthorOrganization?: ContentProducerOrganization | null;
}

export interface SCSensitivityStudy {
  /** @maxLength 40000 */
  oid?: string | null;
  operationCode?: SCNamedCode | null;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  name: string;
  /** @maxLength 40000 */
  value?: string | null;
  /** @maxLength 40000 */
  valueUnit?: string | null;
  interpretationCode?: ArchiveLabResultInterpretationCode | null;
  method: MicrobeMethod;
  /** @maxLength 40000 */
  methodExplanation?: string | null;
}

export interface SCSensitivityStudy2 {
  microbeDrugCode: SCNamedCode;
  value?: ValueUnit | null;
  valueAssessment?: MicrobeSensitivity | null;
  method: MicrobialDrugSensitivityTestMethod;
  hygienicallySignificantResult?: boolean | null;
  /** @maxLength 40000 */
  additionalInformation?: string | null;
}

export interface SCTextContent {
  /** @maxLength 40000 */
  simpleText: string;
}

export interface SCToothFace {
  surface: ToothSurface;
  gingivalInterface: boolean;
}

export interface SCTreatment {
  /** @maxLength 40000 */
  operationName?: string | null;
  code: SCNamedCode;
  primarity: DiagnosisPrimarity;
  additionalCodes?: SCNamedCode[] | null;
  operatingPersons?: SCTreatmentOperatingPerson[] | null;
  /** @format date-time */
  time: string | null;
  state: ArchiveTreatmentState;
  /** @maxLength 40000 */
  complicationDescription?: string | null;
  complicationCode?: SCNamedCode | null;
  /** @maxLength 40000 */
  actionReport?: string | null;
}

export interface SCTreatment2 {
  /** @maxLength 40000 */
  oid?: string | null;
  /** @maxLength 40000 */
  operationName?: string | null;
  code: SCNamedCode;
  primarity: DiagnosisPrimarity | null;
  additionalCodes?: SCNamedCode[] | null;
  operatingPersons?: SCTreatmentOperatingPerson[] | null;
  /** @format date-time */
  time: string;
  state: ArchiveTreatmentState;
  type: ArchiveTreatmentType | null;
  /** @maxLength 40000 */
  complicationDescription?: string | null;
  complicationCode?: SCNamedCode | null;
  sideEffectExternalReason?: SCNamedCode | null;
  mainOperationDiagnosis?: SCNamedCode | null;
  teeth?: SCTreatmentTooth[] | null;
}

export interface SCTreatmentOperatingPerson {
  author: ContentAuthor;
  organization: ContentProducerOrganization;
  role: ArchiveTreatmentOperatingPersonRole;
}

export interface SCTreatmentTooth {
  /**
   * @minLength 1
   * @maxLength 40000
   */
  number: string;
  supernumerary: boolean;
  surfaces: SCToothFace[];
  prostheticType: ProstheticToothType | null;
}

export interface SCVaccination {
  /**
   * @minLength 1
   * @maxLength 40000
   */
  vnrNumber: string | null;
  /** @maxLength 40000 */
  vaccineName?: string | null;
  atcCode?: SCNamedCode | null;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  packageBatchNumber: string;
  /** @maxLength 40000 */
  dosageNumber?: string | null;
  /** @maxLength 40000 */
  dosageValue: string;
  /** @maxLength 40000 */
  dosageUnit: string;
  administrationMethod: VaccinationAdministrationMethod;
  injectionSite?: VaccinationInjectionSite | null;
  performer?: ContentAuthor | null;
  performerOrganization?: ContentProducerOrganization | null;
  /** @format date-time */
  vaccinationTime: string;
  sideEffects?: SCVaccineReactionDiagnosis[] | null;
  vaccineNotGiven: boolean;
}

export interface SCVaccination2 {
  /** @maxLength 40000 */
  oid?: string | null;
  atcCode?: SCNamedCode | null;
  /** @maxLength 40000 */
  vaccineName?: string | null;
  /** @maxLength 40000 */
  vnrNumber?: string | null;
  /** @maxLength 40000 */
  packageBatchNumber?: string | null;
  immunizations?: SCNamedCode[] | null;
  /** @maxLength 40000 */
  immunizationSpecifier?: string | null;
  /** @format int32 */
  dosageNumber?: number | null;
  dosage?: ValueUnit | null;
  administrationMethod?: VaccinationAdministrationMethod | null;
  injectionSite?: VaccinationInjectionSite | null;
  performer?: ContentAuthor | null;
  performerOrganization?: ContentProducerOrganization | null;
  /** @format date-time */
  vaccinationTime: string;
  sideEffects?: SCVaccineReactionDiagnosis2[] | null;
  sideEffectExternalCause?: SCNamedCode | null;
  /** @maxLength 40000 */
  additionalInformation?: string | null;
  source?: InformationSource | null;
  isResearchVaccine: boolean;
  researchVaccineIDs?: string[] | null;
}

export interface SCVaccineReactionDiagnosis {
  primarity: DiagnosisPrimarity;
  certainty?: Certainty | null;
  permanence: Permanence;
  code: SCNamedCode;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  description: string;
  /** @format date-time */
  time: string;
}

export interface SCVaccineReactionDiagnosis2 {
  code: SCNamedCode;
  /** @format date-time */
  time: string;
}

export interface SV120DocumentData {
  /**
   * @minLength 1
   * @maxLength 40000
   */
  patientIdentifier: string;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  patientName: string;
}

export interface SV120Service {
  tariffCode: TariffCode;
  /**
   * Corresponds to the SV120 'Vastaanottopäivä'.
   * @format date
   * @example "2005-01-20"
   */
  receptionDate?: string | null;
  /**
   * Corresponds to the SV120 'Vastaanoton pääasiallinen aihe, ICD-koodi' [approx. max 35 characters].
   * The field must be empty, null, or undefined if 'tariffCode' is 'Code0107L'.
   * @maxLength 40000
   */
  icdCode?: string | null;
  /**
   * Corresponds to the SV120 'Kustannus' in cents.
   * @format int32
   */
  costCents?: number | null;
  /**
   * Corresponds to the SV120 'Korvaus' in cents.
   * @format int32
   */
  compensationCents?: number | null;
}

export interface SaveHealthcareFormRequest {
  /** Only value 'SV120' is allowed. */
  type: DPlatformHealthcareFormType;
  /**
   * PDF data encoded in Base64 format.
   * SV120: The personal identification number must correspond to the open patient.
   * @minLength 1
   * @maxLength 40000
   */
  data: string;
}

export interface SaveHealthcareFormResponse {
  /** @format uuid */
  id: string;
  type: DPlatformHealthcareFormType;
  /** Required if 'type' is 'SV120'. */
  sV120DocumentData?: SV120DocumentData | null;
  /** @format date-time */
  createdTS: string;
  /** @format date-time */
  updatedTS?: string | null;
  /**
   * PDF data encoded in Base64 format.
   * @maxLength 40000
   */
  data?: string | null;
}

export interface SearchPatientsRequest {
  /**
   * By default, items created or opened from the past 7 days are returned.
   * @format date-time
   */
  since?: string | null;
  /**
   * List of statuses for which the 'since' parameter will be ignored. By default, the 'Waiting' status is ignored.
   * Exceptionally, the value 'Unknown' is also allowed, in which case no status will be ignored.
   */
  getAllForStatuses?: PatientStatus[] | null;
  /**
   * List of social security identifiers for the patients to be searched. Results are not limited if the list is undefined or null.
   * @minItems 1
   */
  socialSecurityIds?: string[] | null;
}

export interface SearchPatientsResponse {
  queues: PatientQueue[];
  /** Data is based on information stored in the database, not retrieved from ERA. */
  patients: Patient[];
}

export interface SendEmailRequest {
  /**
   * @minLength 1
   * @maxLength 40000
   */
  title: string;
  /**
   * Recipient's email address. If not specified, the message will be sent to the email address saved in the patient's information. Validates values in requests using the method System.ComponentModel.DataAnnotations.EmailAddressAttribute.IsValid.
   * - The validation code is available on GitHub (https://github.com/dotnet/runtime).
   * @maxLength 40000
   */
  toEmailAddress?: string | null;
  /** Either this or 'templateEmailData' must be specified. */
  emailData?: EmailData | null;
  /** Either this or 'emailData' must be specified. */
  templateEmailData?: TemplateEmailData | null;
}

export interface SendEmailResponse {
  email: PatientCommunicationEmail;
}

export interface SendSmsMessageRequest {
  /**
   * @minLength 1
   * @maxLength 1600
   */
  body: string;
  /**
   * Validates values in requests using the following patterns:
   * - For numbers starting with '+358': ^\+358\d{6,12}$
   * - For other numbers: ^\+\d{1,3}\d{5,15}$
   * @maxItems 10
   * @minItems 1
   */
  receivers: string[];
  dryRun?: boolean | null;
}

export interface SendSmsMessageResponse {
  message: SmsMessageSimple;
  receivers: SmsMessageReceiver[];
  errors: SmsMessageError[];
}

export interface ServiceEvent {
  /**
   * @minLength 1
   * @maxLength 40000
   */
  oid: string;
  /** @format date-time */
  startTime: string;
  /** @format date-time */
  endTime: string | null;
  includesHospitalization: boolean;
  patient: Patient;
  author: ContentAuthor;
  registry: RegistrySettings;
  serviceProducer: ContentProducerOrganization;
  responsibleOrganization: ContentProducerOrganization;
  status: ServiceEventStatus;
  patientRelationshipValidated: boolean;
  underageDataReleaseToGuardian?: HealthcareUnderageDataRelease | null;
  documents?: HealthcareDocumentInfo[] | null;
}

export interface ServiceEventMessage {
  messageType: WebsocketMessageType;
  /**
   * Time when this websocket message was sent
   * @format date-time
   */
  timeStamp: string;
  modificationType: ModificationType;
}

export interface SessionConfirmUnlockRequest {
  /**
   * @minLength 1
   * @maxLength 40000
   */
  smartCardResponseData: string;
}

export interface SessionInitializeUnlockRequest {
  /** @maxLength 40000 */
  smartCardDriverPort?: string | null;
}

export interface SessionInitializeUnlockResponse {
  /**
   * @minLength 1
   * @maxLength 40000
   */
  unlockUrl: string;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  unlockCommandData: string;
}

export interface SessionStatisticsRequest {
  /**
   * Start date of the query (inclusive)
   * @format date
   * @example "2005-01-20"
   */
  startTime: string;
  /**
   * End date of the query (inclusive)
   * @format date
   * @example "2005-01-20"
   */
  endTime: string;
  /** Only results for users with these ids. If omitted, results from all users */
  includeUsers?: number[] | null;
  /** If defined, exclude users with these ids from results. */
  excludeUsers?: number[] | null;
  /** List of root organization OIDs to be included in the search results. If the value is undefined/null/empty, organizations are not restricted. */
  includeRootOrganizationOIDs?: string[] | null;
  groupByTime: StatisticsTimeGroupingType;
  separateBy?: SessionStatisticsSeparator | null;
  includeBrowsers?: string[] | null;
  excludeBrowsers?: string[] | null;
  includePlatforms?: string[] | null;
  excludePlatforms?: string[] | null;
  /** If set, only include mobile/non-mobile sessions */
  isMobile?: boolean | null;
}

export interface SessionStatusMessage {
  messageType: WebsocketMessageType;
  /**
   * Time when this websocket message was sent
   * @format date-time
   */
  timeStamp: string;
  newStatus: SessionStatus;
}

export interface SignPrescriptionsResponse {
  /**
   * @minLength 1
   * @maxLength 40000
   */
  signEventID: string;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  signatureCommandData: string;
}

export interface SimpleCodedItem {
  /**
   * @minLength 1
   * @maxLength 40000
   */
  code: string;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  longName: string;
  flags: CodedItemFlag[];
}

export interface SimpleCodedItemsResult {
  /** @format int32 */
  count: number;
  options?: OffsetLimit | null;
  items: SimpleCodedItem[];
}

export interface SimplePatientQueueInfo {
  /** @format uuid */
  guid: string;
  /**
   * @minLength 1
   * @maxLength 100
   */
  name: string;
  /** @format int32 */
  responseTimeSeconds?: number | null;
  /** @maxLength 100 */
  connectionMethod?: string | null;
  isFifo: boolean;
  /** @maxLength 1000 */
  description?: string | null;
  hasGenderRestriction: boolean;
  genderRestriction?: Gender | null;
  hasAgeLimit: boolean;
  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  minAge?: number | null;
  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  maxAge?: number | null;
  chatEnabled?: boolean | null;
  showPreliminaryInfo: boolean;
  showPreliminaryDescription: boolean;
  owner: PatientQueueInfoUser;
}

export interface SimplePatientTemplate {
  /**
   * @minLength 1
   * @maxLength 128
   */
  firstName: string;
  /** @maxLength 128 */
  friendlyName?: string | null;
  /**
   * @minLength 1
   * @maxLength 128
   */
  lastName: string;
  idType: PersonIdentifierType;
  /** @maxLength 63 */
  id?: string | null;
  /** @format int32 */
  idCreationYear?: number | null;
  /** @maxLength 64 */
  temporaryIDRoot?: string | null;
  /**
   * @format date
   * @example "2005-01-20"
   */
  dateOfBirth?: string | null;
}

export interface SimplePhrase {
  /**
   * @minLength 1
   * @maxLength 100
   */
  name: string;
  /**
   * Note: For Type 'Sms', the maximum length is 1600 characters.
   * @minLength 1
   * @maxLength 65000
   */
  value: string;
  type: PhraseType;
  /** If true, phrase is shared between users of the same root organization. Must not be true for IAH users. */
  isShared: boolean;
}

export interface SmSMessageStatisticsRequest {
  /**
   * Start date of the query (inclusive)
   * @format date
   * @example "2005-01-20"
   */
  startTime: string;
  /**
   * End date of the query (inclusive)
   * @format date
   * @example "2005-01-20"
   */
  endTime: string;
  /** Only results for users with these ids. If omitted, results from all users */
  includeUsers?: number[] | null;
  /** If defined, exclude users with these ids from results. */
  excludeUsers?: number[] | null;
  /** List of root organization OIDs to be included in the search results. If the value is undefined/null/empty, organizations are not restricted. */
  includeRootOrganizationOIDs?: string[] | null;
  groupByTime: StatisticsTimeGroupingType;
  separateBy?: SmSMessageStatisticsSeparator | null;
  includeStatuses?: SmsMessageStatus[] | null;
  excludeStatuses?: SmsMessageStatus[] | null;
  includeQueues?: string[] | null;
  excludeQueues?: string[] | null;
}

export interface SmartCardLoginConfirmRequest {
  /**
   * @minLength 1
   * @maxLength 40000
   */
  eventId: string;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  userRegNumber: string;
  organisationUnit?: LoginConfirmOrganisationUnit | null;
  isMobile: boolean;
  rememberOrganization?: boolean | null;
}

export interface SmartCardLoginConfirmResponse {
  /**
   * @minLength 1
   * @maxLength 40000
   */
  accessToken: string;
  /** @format int32 */
  expiresIn: number;
  tokenType: AccessTokenType;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  refreshToken: string;
  userRestrictions: UserRestriction[];
  /** @format int32 */
  undeliveredPrescriptionsCount: number;
  userRights: LoginConfirmUserRight[];
  systemRights: UserRightExternalSystem[];
}

export interface SmartCardLoginRequest {
  /** The field is required if the server is D-prescription or the 'regNumber' field is empty. */
  loginType?: LoginType | null;
  /**
   * The field is required if the 'loginType' field is empty.
   * @maxLength 40000
   */
  regNumber?: string | null;
  /** @maxLength 40000 */
  smartCardDriverPort?: string | null;
  /**
   * If not null, backend will wait to receive a port number notification message from ERASC
   * and use the port number in it to initialize the connection, and also provide the port number in the response LoginUrl.
   * @maxLength 40000
   */
  scSessionID?: string | null;
}

export interface SmartCardLoginResponse {
  /**
   * @minLength 1
   * @maxLength 40000
   */
  loginUrl: string;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  eventId: string;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  loginCommandData: string;
  /** @maxLength 40000 */
  eraScPort?: string | null;
}

export interface SmartCardLoginValidateRequest {
  /**
   * @minLength 1
   * @maxLength 40000
   */
  eventId: string;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  smartCardResponseData: string;
  /**
   * Used only in D-Prescription. The field is ignored in D-Platform.
   * If true, the response will contain user's encrypted registration number. Defaults to false if not specified.
   */
  getEncryptedRegNumber?: boolean | null;
}

export interface SmartCardLoginValidateResponse {
  organisationUnits: OrganizationUnit[];
  user: UserInfo;
  userAgreementConfirmation?: UserAgreementConfirmation | null;
  agreement?: UserAgreement | null;
}

export interface SmsMessage {
  /**
   * Contains the unique identifier for the patient queue item.
   * @format uuid
   */
  patientGuid: string;
  /** @format date-time */
  sentTS?: string | null;
  /** @format date-time */
  updatedTS?: string | null;
  direction: SmsMessageDirection;
  /** @maxLength 40000 */
  from?: string | null;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  to: string;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  body: string;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  sid: string;
  status: SmsMessageStatus;
  /**
   * Represents the identifier for a specific batch to which the message belongs.
   * The identifier remains the same for all messages sent within the same batch (individual API call or background service iteration).
   * @format uuid
   */
  batchID: string;
  sender: SmsMessageSender;
}

export interface SmsMessageError {
  /**
   * @minLength 1
   * @maxLength 40000
   */
  to: string;
  details: ErrorResponse;
}

export interface SmsMessageReceiver {
  /** @format date-time */
  sentTS?: string | null;
  /** @format date-time */
  updatedTS?: string | null;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  to: string;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  sid: string;
  status: SmsMessageStatus;
}

export interface SmsMessageSender {
  /**
   * @minLength 1
   * @maxLength 40000
   */
  firstName: string;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  lastName: string;
}

export interface SmsMessageSimple {
  /** @format uuid */
  patientGuid: string;
  direction: SmsMessageDirection;
  /** @maxLength 40000 */
  from?: string | null;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  body: string;
  /**
   * Represents the identifier for a specific batch to which the message belongs.
   * The identifier remains the same for all messages sent within the same batch (individual API call or background service iteration).
   * @format uuid
   */
  batchID: string;
  sender: SmsMessageSender;
}

export interface SsoRedirectRequest {
  /**
   * @minLength 1
   * @maxLength 40000
   */
  target_url: string;
}

export interface StateChangeTimestamps {
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  waiting?: string | null;
  /** @format date-time */
  open?: string | null;
  /** @format date-time */
  treated?: string | null;
  /** @format date-time */
  closed?: string | null;
}

export interface StatisticsResponse {
  /** Details of Queues present in Results. Null unless request specified SeparateBy:QueueGuid */
  queues?: PatientQueue[] | null;
  /** Details of Users present in Results. Null unless request specified SeparateBy:UserId */
  users?: StatisticsUserInfo[] | null;
  /**
   * The results divided to objects according to GroupByTime selection.
   * Groups are only returned for time periods that have data.
   */
  results: GroupedStatisticsResponse[];
}

export interface StatisticsServiceUnitInfo {
  /**
   * @minLength 1
   * @maxLength 40000
   */
  oid: string;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  name: string;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  rootOrganizationOID: string;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  rootOrganizationName: string;
}

export interface StatisticsSessionFilterParameters {
  /** @format date-time */
  newerThan?: string | null;
  includeUsers?: number[] | null;
  includeRootOrganizationOIDs?: string[] | null;
}

export interface StatisticsSessionInfo {
  isMobile: boolean;
  /** @maxLength 40000 */
  platform?: string | null;
  /** @maxLength 40000 */
  browser?: string | null;
  /** @maxLength 40000 */
  mobileDeviceType?: string | null;
  /** @maxLength 40000 */
  browserVersion?: string | null;
  /** @maxLength 40000 */
  eraSessionId?: string | null;
  loginType?: LoginType | null;
  /** @format date-time */
  terminationTS?: string | null;
  terminationReason?: SessionTerminationReason | null;
  user: StatisticsUserInfo;
  serviceUnit?: StatisticsServiceUnitInfo | null;
  /** @format date-time */
  createdTS: string;
}

export interface StatisticsUserInfo {
  /** @format int32 */
  userId: number;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  firstName: string;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  lastName: string;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  regNumber: string;
}

export interface StructuredContentContainer {
  /** @maxLength 40000 */
  oid?: string | null;
  /** @maxLength 40000 */
  pmsid?: string | null;
  editMode?: ContentEditMode | null;
  type: StructuredContentType;
  text?: SCTextContent | null;
  labTestRequest?: SCLabTestRequest | null;
  labTestRequest2?: SCLabTestRequest2 | null;
  labTestResult?: SCLabTestResult | null;
  labTestResult2?: SCLabTestResult2 | null;
  labTestReport?: SCLabTestReport | null;
  labTestReport2?: SCLabTestReport2 | null;
  diagnosis?: SCDiagnosis | null;
  diagnosis2?: SCDiagnosis2 | null;
  treatment?: SCTreatment | null;
  treatment2?: SCTreatment2 | null;
  riskInfo?: SCRiskInfo | null;
  riskInfo2?: SCRiskInfo2 | null;
  vaccination?: SCVaccination | null;
  vaccination2?: SCVaccination2 | null;
  hiddenContent?: SCHiddenContent | null;
}

export interface StructuredDosageInstruction {
  administrationRouteAndMethod?: SCNamedCode | null;
  /** @maxLength 40000 */
  dosageInstructionAdditionalInfo?: string | null;
  /**
   * @format date
   * @example "2005-01-20"
   */
  dosingPeriodStartTime?: string | null;
  /**
   * @format date
   * @example "2005-01-20"
   */
  dosingPeriodEndTime?: string | null;
  dosingPeriodLength?: ValueUnitRange | null;
  /**
   * @format date
   * @example "2005-01-20"
   */
  dosingOnPauseStartTime?: string | null;
  /**
   * @format date
   * @example "2005-01-20"
   */
  dosingOnPauseEndTime?: string | null;
  dosingAsNeeded?: boolean | null;
  doseCycleLength: ValueUnitRange;
  drugDoses: DrugDoseData[];
}

export interface TemplateEmailData {
  /**
   * @minLength 1
   * @maxLength 40000
   */
  templateId: string;
  variables?: Record<string, string>;
}

export interface TimedStatisticsResponse {
  /** Details of Queues present in Results. Null unless request specified SeparateBy:QueueGuid */
  queues?: PatientQueue[] | null;
  /** Details of Users present in Results. Null unless request specified SeparateBy:UserId */
  users?: StatisticsUserInfo[] | null;
  /**
   * The results divided to objects according to GroupByTime selection.
   * Groups are only returned for time periods that have data.
   */
  results: GroupedTimedStatisticsResponse[];
}

export interface UnsentPrescriptionCountResponse {
  /** @format int32 */
  prescriptionCount: number;
}

export interface UpdateApiKeyRequest {
  /** @format date-time */
  validUntil?: string | null;
  allowedIpAddresses?: string[];
  /** @minItems 1 */
  allowedQueues?: string[];
}

export interface UpdateApiKeyResponse {
  item: APIKey;
}

export interface UpdateEntryStatusRequest {
  /**
   * @minLength 1
   * @maxLength 40000
   */
  oid: string;
  status: EntryStatus;
}

export interface UpdateEntryStatusResponse {
  entry: Entry;
}

export interface UpdateExternalCompanyRequest {
  /** @maxLength 255 */
  name?: string;
  /**
   * Finnish business ID number 'Y-tunnus'.
   * @maxLength 255
   */
  companyId?: string | null;
  /** @maxLength 255 */
  oid?: string | null;
  /**
   * If not null, SMS messages sent on behalf of the company will have this number as sender Validates that the parameter follows international phone number format requirements or is an Alphanumeric Sender ID:
   * - For numbers starting with '+358': ^\+358\d{6,12}$
   * - For other numbers: ^\+\d{1,3}\d{5,15}$
   * - Alphanumeric Sender ID: See e.g. twilio.com/docs/glossary/what-alphanumeric-sender-id
   * @maxLength 19
   */
  sms?: string | null;
  /**
   * If not null, Email messages sent on behalf of the company will have this address as sender
   * If omitted, address configured at server level Mailgun options is used instead. Validates values in requests using the method System.ComponentModel.DataAnnotations.EmailAddressAttribute.IsValid.
   * - The validation code is available on GitHub (https://github.com/dotnet/runtime).
   * @maxLength 255
   */
  email?: string | null;
  /**
   * Required for external companies that use chat feature and want emails about chat to be sent to patients.
   * If the url string contains {0} it will be replaced by chat room id.
   * Missing value means no chat-related emails will be sent to patients for this external company. Validates values in requests using the method Uri.IsWellFormedUriString.
   * - For more information, see https://learn.microsoft.com/en-us/dotnet/api/system.uri.iswellformeduristring.
   * @maxLength 2048
   */
  chatUrl?: string | null;
  /**
   * Required for external companies that want to ask patients for feedback.
   * Contains the url for the feedback survey page, included in email sent to patients about the feedback survey.
   * Missing or empty value means emails with feedback survey link will not be sent to patients. Validates values in requests using the method Uri.IsWellFormedUriString.
   * - For more information, see https://learn.microsoft.com/en-us/dotnet/api/system.uri.iswellformeduristring.
   * @maxLength 2048
   */
  feedbackSurveyUrl?: string | null;
  /**
   * Amount of hours from when patient was last opened, after which feedback survey will be sent to patient.
   * Missing or negative value means these emails will not be sent.
   * @format int32
   * @min -1
   * @max 10000
   */
  feedbackSurveyDelayInHours?: number | null;
  /**
   * Mailgun url used for emails sent on behalf of the company. Something like https://api.mailgun.net/v3/mg.kuurahealth.com/
   * If null or undefined, url configured at server level Mailgun options is used instead. Validates values in requests using the method Uri.IsWellFormedUriString.
   * - For more information, see https://learn.microsoft.com/en-us/dotnet/api/system.uri.iswellformeduristring.
   * @maxLength 2048
   */
  mailgunBaseUrl?: string | null;
  /**
   * Mailgun API key used for emails sent on behalf of the company.
   * If null or undefined, API key configured at server level Mailgun options is used instead.
   * @maxLength 256
   */
  mailgunApiKey?: string | null;
}

export interface UpdateExternalCompanyResponse {
  item: ExternalCompany;
}

export interface UpdateHealthcareFormRequest {
  /**
   * PDF data encoded in Base64 format.
   * SV120: The personal identification number must not have changed.
   * @minLength 1
   * @maxLength 40000
   */
  data: string;
}

export interface UpdateHealthcareFormResponse {
  item: HealthcareForm;
}

/**
 * Sent by client when updating message status, and by server when informing participants of message status update.
 * If sent by client, also updates all previous messages of same sender to the same status (unless status was same or later already).
 * When sent by client, responded to by another UpdateMessageStatusMessage (or multiple - one for each message that was affected) if successful.
 * MessageType: UpdateMessageStatusMessage
 */
export interface UpdateMessageStatusMessage {
  messageType: WebsocketMessageType;
  /**
   * Time when this websocket message was sent
   * @format date-time
   */
  timeStamp: string;
  /** @format uuid */
  messageId: string;
  /**
   * Id of room where message was sent. Ignored in messages sent by client.
   * @format uuid
   */
  roomId?: string | null;
  /** Initial message status is Delivering. Message status can only be changed by recipient, and only to Delivered or Read. Once status is Read it cannot be changed back. */
  status?: ChatMessageDeliveryStatus | null;
}

export interface UpdateNotificationIsReadRequest {
  isRead: boolean;
  /** @minItems 1 */
  notificationIds: string[];
}

export interface UpdatePatientNamesPatientOpenParams {
  usePopulationRegistry?: boolean | null;
  outsourcing?: OpenPatientRequestOutsourcingSettings | null;
  authoring: OpenPatientRequestAuthoringSettings;
  registry: OpenPatientRequestRegistrySettings;
  accessReason: PatientInfoAccessReason;
  /** @maxLength 40000 */
  accessReasonDescription?: string | null;
}

export interface UpdatePatientNamesRequest {
  /**
   * @minLength 1
   * @maxLength 128
   */
  firstName?: string;
  /** @maxLength 128 */
  friendlyName?: string | null;
  /**
   * @minLength 1
   * @maxLength 128
   */
  lastName?: string;
  /** Updating the names for the service event requires opening the patient in ERA, for which these parameters are used. */
  patientOpenParams: UpdatePatientNamesPatientOpenParams;
}

export interface UpdatePatientNamesResponse {
  /** Data is based on information stored in the database, not retrieved from ERA. */
  patient: Patient;
}

export interface UpdatePatientQueueRequest {
  /**
   * If the value is not globally unique, the error type 35 'InvalidName' is returned.
   * @minLength 1
   * @maxLength 100
   */
  name?: string;
  /** @format int32 */
  responseTimeSeconds?: number | null;
  /** @maxLength 100 */
  connectionMethod?: string | null;
  isFifo?: boolean;
  isPublic?: boolean;
  /** @maxLength 1000 */
  description?: string | null;
  hasGenderRestriction?: boolean;
  genderRestriction?: Gender | null;
  /** If true, then at least one of the fields 'minAge' and 'maxAge' must be defined. */
  hasAgeLimit?: boolean;
  /**
   * If defined along with the 'maxAge' field, this value must be smaller.
   * @format int32
   * @min 0
   * @max 2147483647
   */
  minAge?: number | null;
  /**
   * If defined along with the 'minAge' field, this value must be higher.
   * @format int32
   * @min 0
   * @max 2147483647
   */
  maxAge?: number | null;
  costs?: PatientQueueCostsInfo | null;
  /**
   * If true, adding patient to queue sends an email to them with a chat link that they can use to chat with their doctor.
   * Note that a chat room is created only if EVERY queue the patient is added to has ChatEnabled = true.
   * (Patient can join the chat after receiving the link, doctor joins the chat after taking the item from queue)
   */
  chatEnabled?: boolean | null;
  showPreliminaryInfo?: boolean;
  showPreliminaryDescription?: boolean;
  /** Each object must have a unique registration number. */
  permittedUsers?: PatientQueueInfoUser[] | null;
  /** Each object must have a unique OID. */
  permittedOrganizations?: PatientQueueInfoOrganization[] | null;
  /** Each object must have a unique registration number. Only the owner of the queue can modify this information. */
  adminUsers?: PatientQueueInfoUser[] | null;
}

export interface UpdatePatientQueueResponse {
  /**
   * If the value is not globally unique, the error type 35 'InvalidName' is returned.
   * @minLength 1
   * @maxLength 100
   */
  name: string;
  /** @format int32 */
  responseTimeSeconds?: number | null;
  /** @maxLength 100 */
  connectionMethod?: string | null;
  isFifo: boolean;
  isPublic: boolean;
  /** @maxLength 1000 */
  description?: string | null;
  hasGenderRestriction: boolean;
  genderRestriction?: Gender | null;
  /** If true, then at least one of the fields 'minAge' and 'maxAge' must be defined. */
  hasAgeLimit: boolean;
  /**
   * If defined along with the 'maxAge' field, this value must be smaller.
   * @format int32
   * @min 0
   * @max 2147483647
   */
  minAge?: number | null;
  /**
   * If defined along with the 'minAge' field, this value must be higher.
   * @format int32
   * @min 0
   * @max 2147483647
   */
  maxAge?: number | null;
  costs?: PatientQueueCostsInfo | null;
  /**
   * If true, adding patient to queue sends an email to them with a chat link that they can use to chat with their doctor.
   * (Patient can join the chat after receiving the link, doctor joins the chat after taking the item from queue)
   * Missing value is interpreted as false.
   */
  chatEnabled?: boolean | null;
  /** Each object must have a unique registration number. */
  permittedUsers: PatientQueueInfoUser[];
  /** Each object must have a unique OID. */
  permittedOrganizations: PatientQueueInfoOrganization[];
  /** Each object must have a unique registration number. Only the owner of the queue can modify this information. */
  adminUsers: PatientQueueInfoUser[];
  /** @format uuid */
  guid: string;
  owner: PatientQueueInfoUser;
  showPreliminaryInfo: boolean;
  showPreliminaryDescription: boolean;
}

export interface UpdatePatientQueueUserSettingsRequest {
  isVisible?: boolean;
  getNotifications?: boolean;
  notificationScheduleDays?: NotificationScheduleDays | null;
  notificationScheduleHours?: NotificationScheduleHours | null;
  smsNotification?: boolean;
  /**
   * Validates values in requests using the following patterns:
   * - For numbers starting with '+358': ^\+358\d{6,12}$
   * - For other numbers: ^\+\d{1,3}\d{5,15}$
   * @maxLength 19
   */
  phoneNumber?: string | null;
  emailNotification?: boolean;
  /**
   * Validates values in requests using the method System.ComponentModel.DataAnnotations.EmailAddressAttribute.IsValid.
   * - The validation code is available on GitHub (https://github.com/dotnet/runtime).
   * @maxLength 320
   */
  emailAddress?: string | null;
  webhookNotification?: boolean;
  /**
   * Validates values in requests using the method Uri.IsWellFormedUriString.
   * - For more information, see https://learn.microsoft.com/en-us/dotnet/api/system.uri.iswellformeduristring.
   * @maxLength 2048
   */
  webhookUrl?: string | null;
  uiNotification?: boolean;
}

export interface UpdatePatientQueueUserSettingsResponse {
  queueInfo: SimplePatientQueueInfo;
  userSettings?: PatientQueueUserSettings | null;
}

export interface UpdatePatientRequest {
  /**
   * Validates values in requests using the following patterns:
   * - For numbers starting with '+358': ^\+358\d{6,12}$
   * - For other numbers: ^\+\d{1,3}\d{5,15}$
   * @maxLength 19
   */
  mobilePhoneNumber?: string | null;
  /**
   * Validates values in requests using the method System.ComponentModel.DataAnnotations.EmailAddressAttribute.IsValid.
   * - The validation code is available on GitHub (https://github.com/dotnet/runtime).
   * @maxLength 320
   */
  email?: string | null;
  address?: Address | null;
}

export interface UpdatePatientResponse {
  /** Data is based on information stored in the database, not retrieved from ERA. */
  patient: Patient;
}

export interface UpdatePatientStatusRequest {
  status: PatientStatus;
  /**
   * Required when prior status is Waiting.
   * @format uuid
   */
  queueGuid?: string | null;
}

export interface UpdatePatientStatusResponse {
  /** @format uuid */
  id: string;
  status: PatientStatus;
}

export interface UpdatePhrasesRequest {
  phrase: SimplePhrase;
}

export interface UpdatePhrasesResponse {
  phrase: Phrase;
}

/**
 * Sent by client when updating room status, and by server when informing participants of room status update.
 * Only Doctor participant can update room status.
 * When sent by client, responded to by another UpdateRoomStatusMessage if successful
 * MessageType: UpdateRoomStatusMessage
 */
export interface UpdateRoomStatusMessage {
  messageType: WebsocketMessageType;
  /**
   * Time when this websocket message was sent
   * @format date-time
   */
  timeStamp: string;
  /** @format uuid */
  roomId: string;
  status: ChatRoomStatus;
}

export interface UpdateSettingsRequest {
  /**
   * Validates values in requests using the following patterns:
   * - For numbers starting with '+358': ^\+358\d{6,12}$
   * - For other numbers: ^\+\d{1,3}\d{5,15}$
   * @maxLength 19
   */
  phoneNumber?: string | null;
  /**
   * Validates values in requests using the method System.ComponentModel.DataAnnotations.EmailAddressAttribute.IsValid.
   * - The validation code is available on GitHub (https://github.com/dotnet/runtime).
   * @maxLength 320
   */
  email?: string | null;
  /**
   * Validates values in requests using the method Uri.IsWellFormedUriString.
   * - For more information, see https://learn.microsoft.com/en-us/dotnet/api/system.uri.iswellformeduristring.
   * @maxLength 2048
   */
  webhookUrl?: string | null;
}

export interface UpdateSettingsResponse {
  /** @maxLength 19 */
  phoneNumber?: string | null;
  /** @maxLength 320 */
  email?: string | null;
  /** @maxLength 2048 */
  webhookUrl?: string | null;
}

export interface UpdateUserAgreementRequest {
  isValid: boolean;
}

export interface UpdateUserAgreementResponse {
  agreement: UserAgreement;
}

export interface UpdateUserProfileRequest {
  profile: UserProfile;
}

export interface UpdateUserProfileResponse {
  profile: UserProfile;
}

export interface UpdateUserRegistrationStatusRequest {
  /** @format int32 */
  userId: number;
  /** Value 'Unknown' is not allowed. */
  registrationStatus: RegistrationStatus;
}

export interface UpdateUserRegistrationStatusResponse {
  user: UserInfoAdmin;
}

export interface UploadExternalFilesResponse {
  /** Contains all files added for the patient, including previously added ones. */
  files: DatabaseFileMetadata[];
}

export interface UserAgreement {
  /** @format int32 */
  id: number;
  /** @format int32 */
  version: number;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  html: string;
  isValid: boolean;
  /** @format date-time */
  createdTS: string;
  /** @format date-time */
  updatedTS?: string | null;
}

export interface UserAgreementConfirmation {
  /**
   * @minLength 1
   * @maxLength 40000
   */
  phoneNumber: string;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  email: string;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  firstName: string;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  lastName: string;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  regNumber: string;
  /** @format date-time */
  createdTS: string;
  agreement: UserAgreement;
}

export interface UserAgreementConfirmationExtended {
  /**
   * @minLength 1
   * @maxLength 40000
   */
  phoneNumber: string;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  email: string;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  firstName: string;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  lastName: string;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  regNumber: string;
  /** @format date-time */
  createdTS: string;
  /** @format int32 */
  userAgreementId: number;
}

export interface UserAgreementExtended {
  /** @format int32 */
  id: number;
  /** @format int32 */
  version: number;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  html: string;
  isValid: boolean;
  /** @format date-time */
  createdTS: string;
  /** @format date-time */
  updatedTS?: string | null;
  /** @format int32 */
  confirmationCount: number;
}

export interface UserConfigRole {
  /**
   * @minLength 1
   * @maxLength 40000
   */
  id: string;
  /** @maxLength 40000 */
  name?: string | null;
}

export interface UserInfo {
  /**
   * @minLength 1
   * @maxLength 40000
   */
  firstName: string;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  lastName: string;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  regNumber: string;
  /**
   * @format date
   * @example "2005-01-20"
   */
  cardBeginDate: string;
  /**
   * @format date
   * @example "2005-01-20"
   */
  cardEndDate: string;
  /**
   * Contains service unit identifier.
   * @maxLength 40000
   */
  latestOrganizationOID?: string | null;
  /** Specifies whether the service unit should be remembered for the next login. */
  rememberOrganization: boolean;
  registrationStatus: RegistrationStatus;
  /** @maxLength 40000 */
  profilePhoneNumber?: string | null;
  /** @maxLength 40000 */
  profileEmail?: string | null;
  /**
   * Used only in D-Prescription. The field is always null in D-Platform.
   * Relevant only for the POST /Era/User/SmartCardLoginValidate endpoint.
   * @maxLength 40000
   */
  encryptedRegNumber?: string | null;
}

/** Represents user information for admin endpoints. */
export interface UserInfoAdmin {
  /** @format int32 */
  userId: number;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  firstName: string;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  lastName: string;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  regNumber: string;
  /** @maxLength 40000 */
  profilePhoneNumber?: string | null;
  /** @maxLength 40000 */
  profileEmail?: string | null;
  registrationStatus: RegistrationStatus;
  /** @format date-time */
  createdTS: string;
}

export interface UserInfoResponse {
  user: UserLoginInfo;
}

export interface UserLoginInfo {
  /**
   * @minLength 1
   * @maxLength 40000
   */
  regNumber: string;
  /** @maxLength 40000 */
  idNumber?: string | null;
  /** @maxLength 40000 */
  id?: string | null;
  /** @maxLength 40000 */
  pmsid?: string | null;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  firstName: string;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  lastName: string;
  healthProfessionalGroup?: HealthProfessionalGroup | null;
  /** @maxLength 40000 */
  occupationCode?: string | null;
  /** @maxLength 40000 */
  occupationName?: string | null;
  /** @maxLength 40000 */
  degreeCode?: string | null;
  /** @maxLength 40000 */
  degreeName?: string | null;
  /** @maxLength 40000 */
  jobDescription?: string | null;
  address?: Address | null;
  organizationUnit?: OrganizationUnit | null;
  /** @maxLength 40000 */
  preferredLanguage?: string | null;
  userRestrictions: UserRestriction[];
  /** @maxLength 40000 */
  phoneNumber?: string | null;
  /** @maxLength 40000 */
  email?: string | null;
  /** @maxLength 40000 */
  webhookUrl?: string | null;
  /**
   * User's identifier in D-Platform. Not used by ERA.
   * @format uuid
   */
  userGuid: string;
  systemRights: UserRightExternalSystem[];
}

export interface UserProfile {
  /**
   * User's identifier. Not editable by client.
   * @format uuid
   */
  userGuid?: string | null;
  /**
   * Validates values in requests using the following patterns:
   * - For numbers starting with '+358': ^\+358\d{6,12}$
   * - For other numbers: ^\+\d{1,3}\d{5,15}$
   * @maxLength 19
   */
  phoneNumber?: string | null;
  /**
   * Validates values in requests using the method System.ComponentModel.DataAnnotations.EmailAddressAttribute.IsValid.
   * - The validation code is available on GitHub (https://github.com/dotnet/runtime).
   * @maxLength 320
   */
  email?: string | null;
  languages?: string[] | null;
  /** @maxLength 255 */
  competences?: string | null;
  allowThirdPartyAccess?: boolean;
  /** @maxLength 255 */
  place?: string | null;
}

export interface UserRestriction {
  category?: UserRestrictionCategory | null;
  /** @maxLength 40000 */
  description?: string | null;
  /** @format date-time */
  startDate?: string | null;
  /** @format date-time */
  endDate?: string | null;
  drugGroup?: NarcoticType | null;
  productGroup?: DrugOrProductType | null;
  atcCode?: SCNamedCode | null;
  workplace?: WorkplaceRestrictionType | null;
  drugPrescribing?: DrugPrescribingRestrictionType | null;
  treatmentCode?: SCNamedCode | null;
  treatmentMethodOrSubject?: TreatmentMethodOrSubjectRestrictionType | null;
  positionOrSupervision?: SCNamedCode | null;
  otherRestriction?: boolean | null;
}

export interface UserRightExternalSystem {
  /** @maxLength 40000 */
  object?: string | null;
  /** @maxLength 40000 */
  action?: string | null;
  /** @maxLength 40000 */
  displayText?: string | null;
}

export interface ValueUnit {
  /**
   * @minLength 1
   * @maxLength 40000
   */
  value: string;
  /** @maxLength 40000 */
  unit: string;
}

export interface ValueUnitRange {
  valueUnitMin?: ValueUnit | null;
  valueUnitMax?: ValueUnit | null;
  valueUnit?: ValueUnit | null;
}

export interface X509CertificateInfo {
  /**
   * Certificates common name
   * @maxLength 40000
   */
  cn: string;
  /**
   * Certificates serial number
   * @maxLength 40000
   */
  serialNumber: string;
  /**
   * Certificates issuer information
   * @maxLength 40000
   */
  issuer: string;
  /**
   * Certificate information as x509 string
   * @maxLength 40000
   */
  x509: string;
  /**
   * Certificates validity NOT BEFORE in ISO 6801
   * @maxLength 40000
   */
  notBefore: string;
  /**
   * Certificates validity NOT AFTER in ISO 6801
   * @maxLength 40000
   */
  notAfter: string;
  /**
   * Timestamp for registration in ISO 6801
   * @maxLength 40000
   */
  registered: string;
  additionalProperties?: Record<string, unknown>;
}

export interface SCNamedCode_SCRiskInfo2 {
  type: CodedSystemType;
  /**
   * @minLength 1
   * @maxLength 255
   */
  code: string | null;
  /**
   * The field is used exclusively in response messages and disregarded in requests.
   * @maxLength 40000
   */
  name?: string | null;
}

export interface ValueUnit_Nullable {
  /**
   * @minLength 1
   * @maxLength 40000
   */
  value: string | null;
  /**
   * @minLength 1
   * @maxLength 40000
   */
  unit: string | null;
}

export enum ABOBloodGroup {
  Unknown = 'Unknown',
  A = 'A',
  B = 'B',
  AB = 'AB',
  O = 'O',
}

export enum AccessIPValidationMode {
  Unknown = 'Unknown',
  NoValidation = 'NoValidation',
  Validate = 'Validate',
  ChildSpecific = 'ChildSpecific',
}

export enum AccessTokenType {
  Unknown = 'Unknown',
  Bearer = 'Bearer',
  GuestBearer = 'GuestBearer',
}

export enum ActivitySettingsMode {
  Unknown = 'Unknown',
  Inactive = 'Inactive',
  Active = 'Active',
  SpecifiedTime = 'SpecifiedTime',
}

export enum AllowedPrescriptionOperationResult {
  Unknown = 'Unknown',
  Ok = 'Ok',
  NotFound = 'NotFound',
  UnsentVersionExists = 'UnsentVersionExists',
}

export enum ApiKeyType {
  Unknown = 'Unknown',
  AddQueuedPatients = 'AddQueuedPatients',
  CreateGuestSessions = 'CreateGuestSessions',
}

export enum AppNotifictionType {
  Unknown = 'Unknown',
  NewPatientInQueue = 'NewPatientInQueue',
  TextMessageFromPatient = 'TextMessageFromPatient',
  ReceivedLabResult = 'ReceivedLabResult',
  CalendarReminder = 'CalendarReminder',
  ChatMessageFromPatient = 'ChatMessageFromPatient',
}

export enum ArchiveAuthoringMode {
  Unknown = 'Unknown',
  None = 'None',
  Default = 'Default',
  User = 'User',
  UserUnderSupervision = 'UserUnderSupervision',
  ForOtherUser = 'ForOtherUser',
  FromDictation = 'FromDictation',
}

export enum ArchiveLabResultInterpretationCode {
  Unknown = 'Unknown',
  LimitsNotDefined = 'LimitsNotDefined',
  LesserThanScale = 'LesserThanScale',
  LesserThanCriticalLimit = 'LesserThanCriticalLimit',
  LesserThanLimit = 'LesserThanLimit',
  GreaterThanLimit = 'GreaterThanLimit',
  GreaterThanCriticalLimit = 'GreaterThanCriticalLimit',
  GreaterThanScale = 'GreaterThanScale',
  Normal = 'Normal',
  Abnormal = 'Abnormal',
  HighlyAbnormal = 'HighlyAbnormal',
  VeryLow = 'VeryLow',
  VeryHigh = 'VeryHigh',
  SignificantChangeLow = 'SignificantChangeLow',
  SignificantChangeHigh = 'SignificantChangeHigh',
  Worse = 'Worse',
  Average = 'Average',
  Better = 'Better',
  MicrobeCultivation = 'MicrobeCultivation',
  Resistant = 'Resistant',
  ModeratelySensitive = 'ModeratelySensitive',
  Sensitive = 'Sensitive',
  VerySensitive = 'VerySensitive',
  NonSensitive = 'NonSensitive',
  DoseDependentSensitivity = 'DoseDependentSensitivity',
  SynergyResistant = 'SynergyResistant',
  SynergySensitive = 'SynergySensitive',
  Detected = 'Detected',
  NotDetected = 'NotDetected',
  Indeterminate = 'Indeterminate',
  InsufficientEvidence = 'InsufficientEvidence',
  Negative = 'Negative',
  Positive = 'Positive',
  Reactive = 'Reactive',
  NonReactive = 'NonReactive',
  WeaklyReactive = 'WeaklyReactive',
}

export enum ArchiveLabResultType {
  Unknown = 'Unknown',
  Normal = 'Normal',
  MicrobeCultivation = 'MicrobeCultivation',
}

export enum ArchiveLabTestOperationState {
  Unknown = 'Unknown',
  Requested = 'Requested',
  Taken = 'Taken',
}

export enum ArchiveRegistryType {
  Unknown = 'Unknown',
  PublicHealthcare = 'PublicHealthcare',
  PrivateHealthcare = 'PrivateHealthcare',
  OccupationalHealthcare = 'OccupationalHealthcare',
  OccupationalDocuments = 'OccupationalDocuments',
}

export enum ArchiveTreatmentOperatingPersonRole {
  Unknown = 'Unknown',
  Primary = 'Primary',
  Assistant = 'Assistant',
}

export enum ArchiveTreatmentState {
  Unknown = 'Unknown',
  Proposed = 'Proposed',
  Planned = 'Planned',
  Scheduled = 'Scheduled',
  Performed = 'Performed',
}

export enum ArchiveTreatmentType {
  Unknown = 'Unknown',
  Other = 'Other',
  SmallOperation = 'SmallOperation',
  MouthOperation = 'MouthOperation',
  AdvancedOperation = 'AdvancedOperation',
  ImagingStudy = 'ImagingStudy',
}

export enum Certainty {
  Unknown = 'Unknown',
  Suspect = 'Suspect',
  ProbableOrDefinite = 'ProbableOrDefinite',
  Definite = 'Definite',
  Corrected = 'Corrected',
}

export enum ChatErrorCode {
  Unkown = 'Unkown',
  UnknownUser = 'UnknownUser',
  InvalidSession = 'InvalidSession',
  ActionForbidden = 'ActionForbidden',
  RoomNotFound = 'RoomNotFound',
  InvalidMessage = 'InvalidMessage',
  InvalidRoom = 'InvalidRoom',
  MessageNotFound = 'MessageNotFound',
  InvalidRequest = 'InvalidRequest',
}

export enum ChatMessageContentType {
  Unknown = 'Unknown',
  Text = 'Text',
}

export enum ChatMessageDeliveryStatus {
  Unknown = 'Unknown',
  Delivering = 'Delivering',
  Delivered = 'Delivered',
  Read = 'Read',
}

export enum ChatRoomStatus {
  Unknown = 'Unknown',
  Open = 'Open',
  Closed = 'Closed',
}

export enum ChatterRole {
  Unknown = 'Unknown',
  Doctor = 'Doctor',
  Patient = 'Patient',
}

export enum ChatterStatus {
  Unknown = 'Unknown',
  Offline = 'Offline',
  Online = 'Online',
  Writing = 'Writing',
}

export enum CodedItemFlag {
  Unknown = 'Unknown',
  Primary = 'Primary',
  ExternalReason = 'ExternalReason',
  InjuryType = 'InjuryType',
  AlcoholRelatedInfo = 'AlcoholRelatedInfo',
  OtherEnvironmentalFactor = 'OtherEnvironmentalFactor',
  Symptom = 'Symptom',
  Reason = 'Reason',
  Tumor = 'Tumor',
  LongTermIllness = 'LongTermIllness',
  EndocrinologicalDisorder = 'EndocrinologicalDisorder',
  ExternalReasonOfDrugPoisoning = 'ExternalReasonOfDrugPoisoning',
  TypicalForMale = 'TypicalForMale',
  TypicalForFemale = 'TypicalForFemale',
  SportsAccident = 'SportsAccident',
  OtherAccident = 'OtherAccident',
  ConsequenceOfInjuryOrPoisoning = 'ConsequenceOfInjuryOrPoisoning',
  RequiresReason = 'RequiresReason',
  RequiresTumor = 'RequiresTumor',
  RequiresLongTermIllness = 'RequiresLongTermIllness',
  SymptomRecommended = 'SymptomRecommended',
  RequiresEndocrinologicalDisorder = 'RequiresEndocrinologicalDisorder',
  ATCCodeRecommended = 'ATCCodeRecommended',
  RequiresATCCode = 'RequiresATCCode',
  RequiresExternalReasonOfDrugPoisoning = 'RequiresExternalReasonOfDrugPoisoning',
  Private = 'Private',
  Public = 'Public',
  Healthcare = 'Healthcare',
  SocialServices = 'SocialServices',
  HealthcareRegistryRoot = 'HealthcareRegistryRoot',
  SocialServicesRoot = 'SocialServicesRoot',
}

export enum CodedSystemType {
  Unknown = 'Unknown',
  ICD10 = 'ICD10',
  ICPC2 = 'ICPC2',
  Treatment = 'Treatment',
  ATC = 'ATC',
  RiskType = 'RiskType',
  TreatmentAction30 = 'TreatmentAction30',
  TreatmentNeed30 = 'TreatmentNeed30',
  DataView = 'DataView',
  SectionTitle = 'SectionTitle',
  RadiologyOperation = 'RadiologyOperation',
  MicrobeNomenclature = 'MicrobeNomenclature',
  PhysiologicalMeasurements = 'PhysiologicalMeasurements',
  DataViewingReason = 'DataViewingReason',
  Organization = 'Organization',
  IndependentPractitioner = 'IndependentPractitioner',
  Municipality = 'Municipality',
  LaboratoryOperation = 'LaboratoryOperation',
  OralTreatment = 'OralTreatment',
  DentalProstheticType = 'DentalProstheticType',
  ToothProstheticStructureType = 'ToothProstheticStructureType',
  ToothObservationAndFinding = 'ToothObservationAndFinding',
  PrimaryHealthcareOutPatientTreatment = 'PrimaryHealthcareOutPatientTreatment',
  ExcerciseType = 'ExcerciseType',
  SpecimenType = 'SpecimenType',
  MicrobialDrug = 'MicrobialDrug',
  Immunization = 'Immunization',
  PositionOrSupervision = 'PositionOrSupervision',
  TreatmentRestriction = 'TreatmentRestriction',
  AnatomicalClassification = 'AnatomicalClassification',
  SoSeServiceType = 'SoSeServiceType',
  SoSeGeneralDocumentType = 'SoSeGeneralDocumentType',
  SoSeSpecificDocumentType = 'SoSeSpecificDocumentType',
  SoSeServiceCategory1989 = 'SoSeServiceCategory1989',
  SoSeServiceCategory2011 = 'SoSeServiceCategory2011',
  DosageSite = 'DosageSite',
  ReasonNotTaken = 'ReasonNotTaken',
  MouthAreaObservationsAndFindings = 'MouthAreaObservationsAndFindings',
  TeethObservationsAndFindings = 'TeethObservationsAndFindings',
  Languages = 'Languages',
  Countries = 'Countries',
  ChildWelfareCaseReason = 'ChildWelfareCaseReason',
  EMSAssignmentType = 'EMSAssignmentType',
  EMSWrittenTreatmentInstructions = 'EMSWrittenTreatmentInstructions',
  EMSSkinFinding = 'EMSSkinFinding',
  EMSHeadFinding = 'EMSHeadFinding',
  EMSFaceFinding = 'EMSFaceFinding',
  EMSNeckFinding = 'EMSNeckFinding',
  EMSChestOrLungFinding = 'EMSChestOrLungFinding',
  EMSHeartAuscultationFinding = 'EMSHeartAuscultationFinding',
  EMSAbdomenFinding = 'EMSAbdomenFinding',
  EMSPelvisOrGenitalFinding = 'EMSPelvisOrGenitalFinding',
  EMSBackOrSpineFinding = 'EMSBackOrSpineFinding',
  EMSLimbFinding = 'EMSLimbFinding',
  EMSEyeFinding = 'EMSEyeFinding',
  EMSMentalState = 'EMSMentalState',
  EMSNeurologicalState = 'EMSNeurologicalState',
  EMSAlcoholAndDrugUse = 'EMSAlcoholAndDrugUse',
  EMSTreatmentFacility = 'EMSTreatmentFacility',
  EMSHospitalUnit = 'EMSHospitalUnit',
  EMSECGFinding = 'EMSECGFinding',
  EMSFluidTransferMethod = 'EMSFluidTransferMethod',
  EMSTreatmentType = 'EMSTreatmentType',
  EMSTreatmentComplicationCode = 'EMSTreatmentComplicationCode',
  DrugRoute = 'DrugRoute',
  HealthcareServiceType = 'HealthcareServiceType',
  ICPC2EMS = 'ICPC2EMS',
  DrugRouteAndMethod = 'DrugRouteAndMethod',
  DrugAdministrationUnit = 'DrugAdministrationUnit',
  SpecialExplanation = 'SpecialExplanation',
  KantaNotificationTextVersion = 'KantaNotificationTextVersion',
  ICFWorkCapacityClassification = 'ICFWorkCapacityClassification',
  OccupationClassification = 'OccupationClassification',
  ServiceNomenclature = 'ServiceNomenclature',
  ABOBloodGroup = 'ABOBloodGroup',
  RhDBloodGroup = 'RhDBloodGroup',
  AssessmentOfGoalAchievement = 'AssessmentOfGoalAchievement',
  None = 'None',
}

export enum ContentEditMode {
  Unknown = 'Unknown',
  NoChange = 'NoChange',
  New = 'New',
  Modified = 'Modified',
  Deleted = 'Deleted',
}

export enum DPlatformHealthcareFormType {
  Unknown = 'Unknown',
  SV120 = 'SV120',
  SV132 = 'SV132',
}

export enum DatabaseFileType {
  Unknown = 'Unknown',
  PatientPreliminaryMedia = 'PatientPreliminaryMedia',
}

export enum DayOfWeek {
  Unknown = 'Unknown',
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
}

export enum DeploymentTestConfigType {
  Unknown = 'Unknown',
  Prescription = 'Prescription',
  Archive = 'Archive',
  SoseArchive = 'SoseArchive',
}

export enum DeploymentTestState {
  Unknown = 'Unknown',
  NotTested = 'NotTested',
  TestPlanned = 'TestPlanned',
  Accepted = 'Accepted',
}

export enum DiagnosisPrimarity {
  Unknown = 'Unknown',
  Primary = 'Primary',
  Secondary = 'Secondary',
}

export enum DiagnosisStatisticsSeparator {
  UserId = 'UserId',
  QueueGuid = 'QueueGuid',
  DiagnosisCode = 'DiagnosisCode',
  ExternalReasonCode = 'ExternalReasonCode',
  Certainty = 'Certainty',
  Permanence = 'Permanence',
  Primarity = 'Primarity',
  RootOrganizationOID = 'RootOrganizationOID',
}

export enum DispensationQuantityValueType {
  Unknown = 'Unknown',
  Value = 'Value',
  Expression = 'Expression',
  Text = 'Text',
}

export enum DrugOrProductType {
  Unknown = 'Unknown',
  Alcohol = 'Alcohol',
  AllDrugs = 'AllDrugs',
  TreatmentProduct = 'TreatmentProduct',
  DevicesAndSupplies = 'DevicesAndSupplies',
  OtherProduct = 'OtherProduct',
}

export enum DrugPrescribingRestrictionType {
  Unknown = 'Unknown',
  ProAuctore = 'ProAuctore',
  SelfPrescribing = 'SelfPrescribing',
  DrugHandling = 'DrugHandling',
  Other = 'Other',
}

export enum DrugProductType {
  Unknown = 'Unknown',
  Normal = 'Normal',
  SelectByActiveIngredient = 'SelectByActiveIngredient',
  ManufacturedByPharmacy = 'ManufacturedByPharmacy',
  NotInDatabase = 'NotInDatabase',
}

export enum DrugQuantityType {
  Unknown = 'Unknown',
  Package = 'Package',
  TotalQuantity = 'TotalQuantity',
  Duration = 'Duration',
}

export enum EmailStatus {
  Unknown = 'Unknown',
  Accepted = 'Accepted',
  Rejected = 'Rejected',
  Delivered = 'Delivered',
  Failed = 'Failed',
}

export enum EntryQueryHTMLMode {
  Unknown = 'Unknown',
  None = 'None',
  Partial = 'Partial',
  Document = 'Document',
  EntrySpecificPartial = 'EntrySpecificPartial',
  EntrySpecificDocument = 'EntrySpecificDocument',
}

export enum EntryQueryPurpose {
  Unknown = 'Unknown',
  PMSInternalUse = 'PMSInternalUse',
  DisplayToUser = 'DisplayToUser',
  Release = 'Release',
}

export enum EntryQueryType {
  Unknown = 'Unknown',
  OnlyOwnRegistry = 'OnlyOwnRegistry',
  OnlyOtherRegistries = 'OnlyOtherRegistries',
  OnlySharedRegistry = 'OnlySharedRegistry',
  OnlyOtherSharedRegistries = 'OnlyOtherSharedRegistries',
  AllRegistries = 'AllRegistries',
  EmergencyOnlyOtherRegistries = 'EmergencyOnlyOtherRegistries',
  EmergencyAllRegistries = 'EmergencyAllRegistries',
  OnlyOutsourcingProviderRegistry = 'OnlyOutsourcingProviderRegistry',
  OnlyOutsourcingOtherRegistries = 'OnlyOutsourcingOtherRegistries',
  OutsourcingAllRegistries = 'OutsourcingAllRegistries',
  EmergencyOnlyOutsourcingOtherRegistries = 'EmergencyOnlyOutsourcingOtherRegistries',
  EmergencyOutsourcingAllRegistries = 'EmergencyOutsourcingAllRegistries',
}

export enum EntrySectionStatisticsSeparator {
  UserId = 'UserId',
  QueueGuid = 'QueueGuid',
  Sections = 'Sections',
  RootOrganizationOID = 'RootOrganizationOID',
}

export enum EntryStatus {
  Unknown = 'Unknown',
  PrivateNote = 'PrivateNote',
  Draft = 'Draft',
  Complete = 'Complete',
  Archived = 'Archived',
  Invalidated = 'Invalidated',
  Automatic = 'Automatic',
}

export enum EntryType {
  Unknown = 'Unknown',
  Normal = 'Normal',
  Form = 'Form',
  HealthAndTreatmentPlan = 'HealthAndTreatmentPlan',
  Referral = 'Referral',
}

export enum ErrorSeverity {
  Minor = 'Minor',
  Normal = 'Normal',
  Critical = 'Critical',
  Fatal = 'Fatal',
}

export enum ErrorSource {
  Unknown = 'Unknown',
  System = 'System',
  IntegratingApplication = 'IntegratingApplication',
  ExternalService = 'ExternalService',
  User = 'User',
}

export enum ErrorStatisticsSeparator {
  UserId = 'UserId',
  ErrorType = 'ErrorType',
  ErrorSource = 'ErrorSource',
  Severity = 'Severity',
  ExternalErrorCode = 'ExternalErrorCode',
  RootOrganizationOID = 'RootOrganizationOID',
}

export enum ErrorType {
  Unknown = 'Unknown',
  InvalidRequest = 'InvalidRequest',
  ConnectionError = 'ConnectionError',
  ERAError = 'ERAError',
  SessionNotFound = 'SessionNotFound',
  InvalidRefreshToken = 'InvalidRefreshToken',
  ExpiredRefreshToken = 'ExpiredRefreshToken',
  NeverShouldHaveHappenedError = 'NeverShouldHaveHappenedError',
  Unauthorized = 'Unauthorized',
  InvalidAccessToken = 'InvalidAccessToken',
  PatientNotFound = 'PatientNotFound',
  InvalidID = 'InvalidID',
  ServiceEventNotFound = 'ServiceEventNotFound',
  InvalidConfiguration = 'InvalidConfiguration',
  SessionLocked = 'SessionLocked',
  ERAPatientOpen = 'ERAPatientOpen',
  DatabaseConflict = 'DatabaseConflict',
  InvalidResponse = 'InvalidResponse',
  CodeSystemRefreshFailed = 'CodeSystemRefreshFailed',
  NoSearchResults = 'NoSearchResults',
  BackgroundTaskFailed = 'BackgroundTaskFailed',
  LoggingOut = 'LoggingOut',
  Forbidden = 'Forbidden',
  TwilioError = 'TwilioError',
  TimerError = 'TimerError',
  MissingPhoneNumber = 'MissingPhoneNumber',
  TwilioUnexpectedError = 'TwilioUnexpectedError',
  InvalidPhoneNumber = 'InvalidPhoneNumber',
  InvalidEmail = 'InvalidEmail',
  UnlicensedERAUser = 'UnlicensedERAUser',
  InvalidUrl = 'InvalidUrl',
  ReferralNotFound = 'ReferralNotFound',
  InvalidLanguageCode = 'InvalidLanguageCode',
  SynlabError = 'SynlabError',
  PortWaitTimeout = 'PortWaitTimeout',
  InvalidName = 'InvalidName',
  TemplateNotFound = 'TemplateNotFound',
  InvalidFile = 'InvalidFile',
  AzureBlobStorageError = 'AzureBlobStorageError',
  PartialSuccess = 'PartialSuccess',
  InvalidEnumValue = 'InvalidEnumValue',
  MailGunError = 'MailGunError',
  FailedToSelectLoginType = 'FailedToSelectLoginType',
  MissingEmailAddress = 'MissingEmailAddress',
  ChatMessagingError = 'ChatMessagingError',
  ConcurrencyError = 'ConcurrencyError',
  GenericDatabaseError = 'GenericDatabaseError',
  DatabaseTimeout = 'DatabaseTimeout',
}

export enum FileType {
  Unknown = 'Unknown',
  LaboratoryOperationCodes = 'LaboratoryOperationCodes',
  LaboratoryOperationInfo = 'LaboratoryOperationInfo',
}

export enum Gender {
  Unknown = 'Unknown',
  Male = 'Male',
  Female = 'Female',
  Other = 'Other',
  Undefined = 'Undefined',
  NotKnown = 'NotKnown',
}

export enum HealthAndTreatmentPlanServiceStatus {
  Planned = 'Planned',
  Required = 'Required',
  Promised = 'Promised',
  Suggested = 'Suggested',
  Ordered = 'Ordered',
  Reserved = 'Reserved',
  Ongoing = 'Ongoing',
  Executed = 'Executed',
}

export enum HealthEntryName {
  Unknown = 'Unknown',
  Database = 'Database',
  ERA = 'ERA',
}

export enum HealthInformationChangeReason {
  Unknown = 'Unknown',
  Other = 'Other',
  Temporary = 'Temporary',
  Healed = 'Healed',
  Precised = 'Precised',
  Error = 'Error',
}

export enum HealthProfessionalGroup {
  Unknown = 'Unknown',
  None = 'None',
  Nurse = 'Nurse',
  NurseWithPrescriptionRights = 'NurseWithPrescriptionRights',
  MedicalStudent = 'MedicalStudent',
  Doctor = 'Doctor',
  Pharmacist = 'Pharmacist',
  OtherRegisteredProfessional = 'OtherRegisteredProfessional',
}

export enum HealthStatus {
  Unknown = 'Unknown',
  Failed = 'Failed',
  Passed = 'Passed',
}

export enum HealthcareContactType {
  Unknown = 'Unknown',
  ReceptionVisit = 'ReceptionVisit',
  HomeVisit = 'HomeVisit',
  WorkplaceVisit = 'WorkplaceVisit',
  ProfessionalsOtherVisit = 'ProfessionalsOtherVisit',
  RealtimeRemoteContact = 'RealtimeRemoteContact',
  NonRealtimeRemoteContact = 'NonRealtimeRemoteContact',
  ProfessionalConsultation = 'ProfessionalConsultation',
  ProfessionalNegotiation = 'ProfessionalNegotiation',
  AttendingClientsAffair = 'AttendingClientsAffair',
  InpatientCare = 'InpatientCare',
  Other = 'Other',
}

export enum HealthcareFormPrefillMode {
  Unknown = 'Unknown',
  NewEmpty = 'NewEmpty',
  NewFromTemplate = 'NewFromTemplate',
  ContinueEdit = 'ContinueEdit',
  Correct = 'Correct',
}

export enum HealthcareFormType {
  Unknown = 'Unknown',
  UnknownForm = 'UnknownForm',
  MedicalCertificateA = 'MedicalCertificateA',
  MedicalCertificateB = 'MedicalCertificateB',
  MedicalCertificateD = 'MedicalCertificateD',
  PregnancyCertificate = 'PregnancyCertificate',
  ExtendedMaternityLeaveCertificate = 'ExtendedMaternityLeaveCertificate',
  MileageCertificate = 'MileageCertificate',
  ObservationReferral = 'ObservationReferral',
  ObservationStatement = 'ObservationStatement',
  DrivingCapabilityAssessment = 'DrivingCapabilityAssessment',
  HiddenForm = 'HiddenForm',
}

export enum HealthcareNatureOfVisit {
  Unknown = 'Unknown',
  Healthcare = 'Healthcare',
  SpecializedHealthcare = 'SpecializedHealthcare',
}

export enum HealthcareProcessPhase {
  Unknown = 'Unknown',
  None = 'None',
  Preparation = 'Preparation',
  Planning = 'Planning',
  Execution = 'Execution',
  Evaluation = 'Evaluation',
}

export enum HealthcareUnderageDataRelease {
  Unknown = 'Unknown',
  IncapableToDecide = 'IncapableToDecide',
  ReleaseAllowed = 'ReleaseAllowed',
  ReleaseDenied = 'ReleaseDenied',
  WillUnresolved = 'WillUnresolved',
  ReferralChainHasProhibition = 'ReferralChainHasProhibition',
}

export enum InformationSource {
  Unknown = 'Unknown',
  Mother = 'Mother',
  Advocate = 'Advocate',
  Organization = 'Organization',
  Father = 'Father',
  Other = 'Other',
  Patient = 'Patient',
  OtherOrganization = 'OtherOrganization',
  Official = 'Official',
  Device = 'Device',
  Employer = 'Employer',
}

export enum JsonFieldType {
  Unknown = 'Unknown',
  Text = 'Text',
  Number = 'Number',
  Date = 'Date',
  DateTime = 'DateTime',
  Options = 'Options',
}

export enum KantaNotificationStatus {
  Unknown = 'Unknown',
  KantaNotificationNotGiven = 'KantaNotificationNotGiven',
  KantaNotificationGiven = 'KantaNotificationGiven',
  KantaNotificationObsolete = 'KantaNotificationObsolete',
  KantaNotificationWhenUnderaged = 'KantaNotificationWhenUnderaged',
}

export enum LabTestRequestStatus {
  Unknown = 'Unknown',
  None = 'None',
  RequestSent = 'RequestSent',
  ResultsReceived = 'ResultsReceived',
}

export enum LabTestResultState {
  Unknown = 'Unknown',
  Initial = 'Initial',
  Partial = 'Partial',
  Preliminary = 'Preliminary',
  Unverified = 'Unverified',
  Final = 'Final',
  Untestable = 'Untestable',
  Corrective = 'Corrective',
  Delete = 'Delete',
  Error = 'Error',
  ChangedToFinal = 'ChangedToFinal',
  TestNotDone = 'TestNotDone',
}

export enum LoginType {
  Unknown = 'Unknown',
  Organization = 'Organization',
  IndependentPractitioner = 'IndependentPractitioner',
  Admin = 'Admin',
}

export enum MaintainedDocumentType {
  Unknown = 'Unknown',
  HealthAndTreatmentPlan = 'HealthAndTreatmentPlan',
}

export enum MeasurementMethod {
  Unknown = 'Unknown',
  Laboratory = 'Laboratory',
  Professional = 'Professional',
  Patient = 'Patient',
}

export enum MedicalTreatmentChangeReason {
  Unknown = 'Unknown',
  TemporaryMedication = 'TemporaryMedication',
  MedicationNeedEnded = 'MedicationNeedEnded',
  MedicationNegativeEffect = 'MedicationNegativeEffect',
  OtherMedicationChange = 'OtherMedicationChange',
  Other = 'Other',
  ChangeInHealthCondition = 'ChangeInHealthCondition',
  Technical = 'Technical',
  MisinformationFromPatient = 'MisinformationFromPatient',
}

export enum MicrobeMethod {
  Unknown = 'Unknown',
  MIC = 'MIC',
  Disc = 'Disc',
  BreakPoint = 'BreakPoint',
  Other = 'Other',
}

export enum MicrobeQuantity {
  Unknown = 'Unknown',
  None = 'None',
  Small = 'Small',
  Moderate = 'Moderate',
  Significant = 'Significant',
}

export enum MicrobeSensitivity {
  Unknown = 'Unknown',
  Uncertain = 'Uncertain',
  Immune = 'Immune',
  Sensitive = 'Sensitive',
}

export enum MicrobeStudyType {
  Unknown = 'Unknown',
  Cultivation = 'Cultivation',
  Dye = 'Dye',
  NativeFungi = 'NativeFungi',
  Other = 'Other',
  DiskSusceptibility = 'DiskSusceptibility',
}

export enum MicrobialDrugSensitivityTestMethod {
  Unknown = 'Unknown',
  Disc = 'Disc',
  MinimumInhibitoryConcentration = 'MinimumInhibitoryConcentration',
  BreakPoint = 'BreakPoint',
  Other = 'Other',
}

export enum ModificationType {
  Unknown = 'Unknown',
  Created = 'Created',
  Modified = 'Modified',
  Deleted = 'Deleted',
}

export enum NarcoticType {
  Unknown = 'Unknown',
  No = 'No',
  Yes = 'Yes',
  CNSAffecting = 'CNSAffecting',
  Psychotropic = 'Psychotropic',
  CNSAffectingRequiresOriginalPrescription = 'CNSAffectingRequiresOriginalPrescription',
  PsychotropicRequiresOriginalPrescription = 'PsychotropicRequiresOriginalPrescription',
}

export enum NonIntegratedAccessMode {
  Unknown = 'Unknown',
  None = 'None',
  DoctorsOnly = 'DoctorsOnly',
  AllUsers = 'AllUsers',
  ChildSpecific = 'ChildSpecific',
}

export enum OpenedServiceEventStatisticsSeparator {
  UserId = 'UserId',
  RootOrganizationOID = 'RootOrganizationOID',
}

export enum OrganizationAreaOfOperation {
  Unknown = 'Unknown',
  Healthcare = 'Healthcare',
  SocialServices = 'SocialServices',
}

export enum OrganizationSectorType {
  Unknown = 'Unknown',
  Private = 'Private',
  Public = 'Public',
}

export enum OrganizationType {
  Unknown = 'Unknown',
  PublicHealthcare = 'PublicHealthcare',
  PrivateHealthcare = 'PrivateHealthcare',
  PublicSocialServices = 'PublicSocialServices',
  PrivateSocialServices = 'PrivateSocialServices',
  IndependentPractitioner = 'IndependentPractitioner',
}

export enum OutsourcingAuthorizationProducerReadAccess {
  Unknown = 'Unknown',
  None = 'None',
  AllDocuments = 'AllDocuments',
  SpecifiedServiceEvents = 'SpecifiedServiceEvents',
  SpecifiedTime = 'SpecifiedTime',
}

export enum OutsourcingAuthorizationProducerWriteAccess {
  Unknown = 'Unknown',
  SpecifiedServiceEvent = 'SpecifiedServiceEvent',
  AnyServiceEvent = 'AnyServiceEvent',
}

export enum OutsourcingType {
  Unknown = 'Unknown',
  Patient = 'Patient',
  Population = 'Population',
}

export enum PatientClosePrescriptionCheckMode {
  Unknown = 'Unknown',
  BlockCloseIfExists = 'BlockCloseIfExists',
  SavePrescriptions = 'SavePrescriptions',
  DeletePrescriptions = 'DeletePrescriptions',
}

export enum PatientIdentificationType {
  Unknown = 'Unknown',
  Passport = 'Passport',
  DriversLicense = 'DriversLicense',
  IdentificationCard = 'IdentificationCard',
  Other = 'Other',
}

export enum PatientInfoAccessReason {
  Unknown = 'Unknown',
  NeedOfCareEvaluation = 'NeedOfCareEvaluation',
  PatientCare = 'PatientCare',
  CareRelatedPhoneCall = 'CareRelatedPhoneCall',
  Consultation = 'Consultation',
  DocumentCorrection = 'DocumentCorrection',
  Technical = 'Technical',
  Emergency = 'Emergency',
}

export enum PatientPermission {
  Unknown = 'Unknown',
  NoPermission = 'NoPermission',
  NotNeeded = 'NotNeeded',
  OralRequest = 'OralRequest',
  OralPermission = 'OralPermission',
  WrittenPermission = 'WrittenPermission',
  Agreement = 'Agreement',
  PermissionFromInformationManagementService = 'PermissionFromInformationManagementService',
  ActingOnBehalfOfAnotherPersonUsingSuomiFiAuthorizations = 'ActingOnBehalfOfAnotherPersonUsingSuomiFiAuthorizations',
}

export enum PatientPreliminaryImageSize {
  Unknown = 'Unknown',
  Original = 'Original',
  FullHd = 'FullHd',
  Thumbnail = 'Thumbnail',
}

export enum PatientStateStatisticsSeparator {
  UserId = 'UserId',
  QueueGuid = 'QueueGuid',
  PatientStatus = 'PatientStatus',
  RootOrganizationOID = 'RootOrganizationOID',
}

export enum PatientStateTimedStatisticsSeparator {
  UserId = 'UserId',
  QueueGuid = 'QueueGuid',
  RootOrganizationOID = 'RootOrganizationOID',
}

export enum PatientStatisticsEventType {
  Unknown = 'Unknown',
  ArchiveEntryCreate = 'ArchiveEntryCreate',
  ArchiveEntryComplete = 'ArchiveEntryComplete',
  PrefillPrescription = 'PrefillPrescription',
  PrefillForm = 'PrefillForm',
  ArchiveEntryEdited = 'ArchiveEntryEdited',
}

export enum PatientStatus {
  Unknown = 'Unknown',
  Waiting = 'Waiting',
  Open = 'Open',
  Treated = 'Treated',
  Closed = 'Closed',
}

export enum PatientTimeToTreatedStatisticsSeparator {
  UserId = 'UserId',
  QueueGuid = 'QueueGuid',
  RootOrganizationOID = 'RootOrganizationOID',
}

export enum Permanence {
  Unknown = 'Unknown',
  Temporary = 'Temporary',
  Permanent = 'Permanent',
  Initial = 'Initial',
}

export enum PersonIdentifierType {
  Unknown = 'Unknown',
  None = 'None',
  Permanent = 'Permanent',
  Temporary = 'Temporary',
  ExternalTemporary = 'ExternalTemporary',
}

export enum PharmacyCreatedPrescriptionReason {
  Unknown = 'Unknown',
  TechnicalError = 'TechnicalError',
  UrgentTreatment = 'UrgentTreatment',
  Other = 'Other',
  SpecialPermit = 'SpecialPermit',
}

export enum PharmacyCreatedPrescriptionType {
  Unknown = 'Unknown',
  Paper = 'Paper',
  Phone = 'Phone',
  SpecialPermit = 'SpecialPermit',
}

export enum PhoneNumberType {
  Unknown = 'Unknown',
  Direct = 'Direct',
  Exchange = 'Exchange',
  Mobile = 'Mobile',
  Work = 'Work',
}

export enum PhraseCategory {
  Unknown = 'Unknown',
  User = 'User',
  Organization = 'Organization',
  General = 'General',
}

export enum PhraseType {
  Unknown = 'Unknown',
  Sms = 'Sms',
  Hospital = 'Hospital',
  Prescription = 'Prescription',
  Chat = 'Chat',
}

export enum PrescriptionCreateMode {
  Unknown = 'Unknown',
  New = 'New',
  Renew = 'Renew',
  Correct = 'Correct',
  Replace = 'Replace',
}

export enum PrescriptionDataQueryType {
  Unknown = 'Unknown',
  All = 'All',
  Single = 'Single',
  CurrentMedication = 'CurrentMedication',
}

export enum PrescriptionDispensationStatus {
  Unknown = 'Unknown',
  Undispensed = 'Undispensed',
  PartlyDispensed = 'PartlyDispensed',
  FullyDispensed = 'FullyDispensed',
  Invalidated = 'Invalidated',
  Archived = 'Archived',
}

export enum PrescriptionDispensationStatusSearchMode {
  Unknown = 'Unknown',
  UndispensedOrPartlyDispensed = 'UndispensedOrPartlyDispensed',
  FullyDispensed = 'FullyDispensed',
}

export enum PrescriptionDosageReportingType {
  Unknown = 'Unknown',
  Structured = 'Structured',
  OnlyText = 'OnlyText',
}

export enum PrescriptionInvalidityReason {
  Unknown = 'Unknown',
  Treatment = 'Treatment',
  Technical = 'Technical',
  MisinformationFromPatient = 'MisinformationFromPatient',
  Expiration = 'Expiration',
  PatientIsDead = 'PatientIsDead',
}

export enum PrescriptionLockStatus {
  Unknown = 'Unknown',
  Unlocked = 'Unlocked',
  Locked = 'Locked',
}

export enum PrescriptionPrefillMode {
  Unknown = 'Unknown',
  NewEmpty = 'NewEmpty',
  NewFromTemplate = 'NewFromTemplate',
  Renew = 'Renew',
  Correct = 'Correct',
  Replace = 'Replace',
}

export enum PrescriptionProductType {
  Unknown = 'Unknown',
  LabeledDrug = 'LabeledDrug',
  DatabaseIncludedBasicLotion = 'DatabaseIncludedBasicLotion',
  DatabaseIncludedNutritionProduct = 'DatabaseIncludedNutritionProduct',
  TemporarySpecialPermissionProduct = 'TemporarySpecialPermissionProduct',
  DrugDatabaseRegisteredDrugProduct = 'DrugDatabaseRegisteredDrugProduct',
  NotInDatabase = 'NotInDatabase',
  ManufacturedByPharmacy = 'ManufacturedByPharmacy',
  ResearchDrugProduct = 'ResearchDrugProduct',
  SelectByActiveIngredient = 'SelectByActiveIngredient',
  MedicalAccessory = 'MedicalAccessory',
  PersonalSpecialPermissionProduct = 'PersonalSpecialPermissionProduct',
  ProductPrescribedAbroad = 'ProductPrescribedAbroad',
}

export enum PrescriptionRenewalStatus {
  Unknown = 'Unknown',
  NoRequest = 'NoRequest',
  Accepted = 'Accepted',
  Rejected = 'Rejected',
  Unprocessed = 'Unprocessed',
  Expired = 'Expired',
  Failed = 'Failed',
  Returned = 'Returned',
  Saved = 'Saved',
}

export enum PrescriptionReservationStatus {
  Unknown = 'Unknown',
  NoReservations = 'NoReservations',
  DispensationHold = 'DispensationHold',
  Hold = 'Hold',
  PortionedFulfillmentHold = 'PortionedFulfillmentHold',
  SpecialPermissionHold = 'SpecialPermissionHold',
}

export enum PrescriptionState {
  Unknown = 'Unknown',
  Normal = 'Normal',
  Invalidated = 'Invalidated',
  Corrected = 'Corrected',
}

export enum PrescriptionStatisticsSeparator {
  UserId = 'UserId',
  QueueGuid = 'QueueGuid',
  Product = 'Product',
  RootOrganizationOID = 'RootOrganizationOID',
}

export enum PrescriptionType {
  Unknown = 'Unknown',
  Normal = 'Normal',
  HospitalPharmacy = 'HospitalPharmacy',
  ForPurchaseAbroad = 'ForPurchaseAbroad',
}

export enum PrescriptionValidityStatus {
  Unknown = 'Unknown',
  Valid = 'Valid',
  Invalidated = 'Invalidated',
  Expired = 'Expired',
}

export enum PriceType {
  Unknown = 'Unknown',
  Fixed = 'Fixed',
  Range = 'Range',
}

export enum ProstheticToothType {
  Unknown = 'Unknown',
  ProstheticTooth = 'ProstheticTooth',
  DentalAbutment = 'DentalAbutment',
  PremolarTooth = 'PremolarTooth',
  BridgeOuterTooth = 'BridgeOuterTooth',
}

export enum PublicServiceJoinModel {
  Unknown = 'Unknown',
  Direct = 'Direct',
  Tenant = 'Tenant',
  Franchisee = 'Franchisee',
}

export enum QueryResponseSpecifier {
  Unknown = 'Unknown',
  NoEffectiveConsent = 'NoEffectiveConsent',
  NoArchiveInforming = 'NoArchiveInforming',
}

export enum ReasonForPrescriptionDataQuery {
  Unknown = 'Unknown',
  Treatment = 'Treatment',
  UrgentTreatment = 'UrgentTreatment',
  Invalidation = 'Invalidation',
  Correction = 'Correction',
  RetrieveNarcotics = 'RetrieveNarcotics',
  Other = 'Other',
}

export enum ReferralEmployment {
  Working = 'Working',
  Unemployed = 'Unemployed',
  UnableToWork = 'UnableToWork',
  Retired = 'Retired',
}

export enum ReferralUrgency {
  OnCall = 'OnCall',
  OneToSevenDays = 'OneToSevenDays',
  EightToThirtyDays = 'EightToThirtyDays',
  OverThirtyDays = 'OverThirtyDays',
}

export enum RegistrationStatus {
  Unknown = 'Unknown',
  None = 'None',
  Pending = 'Pending',
  Completed = 'Completed',
  Rejected = 'Rejected',
}

export enum RegistryAccessModel {
  Unknown = 'Unknown',
  None = 'None',
  Default = 'Default',
  User = 'User',
  Organization = 'Organization',
  HostOrganization = 'HostOrganization',
}

export enum ReleaseDelayType {
  Unknown = 'Unknown',
  None = 'None',
  Custom = 'Custom',
  Permanent = 'Permanent',
}

export enum ReportStatus {
  Unknown = 'Unknown',
  Preliminary = 'Preliminary',
  Final = 'Final',
  Supplementary = 'Supplementary',
}

export enum RhDBloodGroup {
  Unknown = 'Unknown',
  RhDPositive = 'RhDPositive',
  RhDNegative = 'RhDNegative',
}

export enum RiskLevel {
  Unknown = 'Unknown',
  Normal = 'Normal',
  Critical = 'Critical',
}

export enum SecondaryTitleClass {
  Unknown = 'Unknown',
  None = 'None',
  Standard = 'Standard',
  Special = 'Special',
  Custom = 'Custom',
}

export enum ServiceEventStatus {
  Unknown = 'Unknown',
  Pending = 'Pending',
  Active = 'Active',
  Closed = 'Closed',
  Old = 'Old',
  Cancelled = 'Cancelled',
  Invalidated = 'Invalidated',
}

export enum SessionStatisticsSeparator {
  UserId = 'UserId',
  IsMobile = 'IsMobile',
  Platform = 'Platform',
  Browser = 'Browser',
  RootOrganizationOID = 'RootOrganizationOID',
}

export enum SessionStatus {
  Unknown = 'Unknown',
  Active = 'Active',
  Locked = 'Locked',
  Expired = 'Expired',
  LoggingOut = 'LoggingOut',
}

export enum SessionTerminationReason {
  Unknown = 'Unknown',
  Logout = 'Logout',
  ClosedOnNewLogin = 'ClosedOnNewLogin',
  ERAUnauthorizedRequest = 'ERAUnauthorizedRequest',
  ERAExpirationPushNotification = 'ERAExpirationPushNotification',
  Expired = 'Expired',
}

export enum SmSMessageStatisticsSeparator {
  UserId = 'UserId',
  QueueGuid = 'QueueGuid',
  MessageStatus = 'MessageStatus',
  RootOrganizationOID = 'RootOrganizationOID',
}

export enum SmsMessageDirection {
  Unknown = 'Unknown',
  Inbound = 'Inbound',
  OutboundApi = 'OutboundApi',
  OutboundCall = 'OutboundCall',
  OutboundReply = 'OutboundReply',
}

export enum SmsMessageStatus {
  Unknown = 'Unknown',
  Queued = 'Queued',
  Sending = 'Sending',
  Sent = 'Sent',
  Failed = 'Failed',
  Delivered = 'Delivered',
  Undelivered = 'Undelivered',
  Receiving = 'Receiving',
  Received = 'Received',
  Accepted = 'Accepted',
  Scheduled = 'Scheduled',
  Read = 'Read',
  PartiallyDelivered = 'PartiallyDelivered',
  DryRun = 'DryRun',
}

export enum SpecialSecondaryTitleType {
  Unknown = 'Unknown',
  OptometryPreExaminations = 'OptometryPreExaminations',
  OptometryRefractiveErrorEvaluation = 'OptometryRefractiveErrorEvaluation',
  OptometryEyeHealthCheck = 'OptometryEyeHealthCheck',
  OptometryContactLensControl = 'OptometryContactLensControl',
  OptometryVisionAndAccomodation = 'OptometryVisionAndAccomodation',
}

export enum SpecialViewType {
  Unknown = 'Unknown',
  PatientViewHealthAndTreatmentPlan = 'PatientViewHealthAndTreatmentPlan',
}

export enum StatisticsTimeGroupingType {
  None = 'None',
  Day = 'Day',
  Week = 'Week',
  Month = 'Month',
  Year = 'Year',
}

export enum StructuredContentType {
  Unknown = 'Unknown',
  UnknownContent = 'UnknownContent',
  Text = 'Text',
  ImagingRequest = 'ImagingRequest',
  ImagingResult = 'ImagingResult',
  ImagingReport = 'ImagingReport',
  LabTestRequest = 'LabTestRequest',
  LabTestResult = 'LabTestResult',
  LabTestReport = 'LabTestReport',
  Diagnosis = 'Diagnosis',
  Treatment = 'Treatment',
  RiskInfo = 'RiskInfo',
  TreatmentNeed = 'TreatmentNeed',
  Medication = 'Medication',
  Vaccination = 'Vaccination',
  PhysiologicalMeasurement = 'PhysiologicalMeasurement',
  TreatmentAction = 'TreatmentAction',
  TreatmentResult = 'TreatmentResult',
  PhysiologicalMeasurementReport = 'PhysiologicalMeasurementReport',
  DentalProsthetic = 'DentalProsthetic',
  OralAnamnesis = 'OralAnamnesis',
  DentalStatus = 'DentalStatus',
  OcclusionStatus = 'OcclusionStatus',
  FrontalTeethObservation = 'FrontalTeethObservation',
  UpperTeethObservation = 'UpperTeethObservation',
  SpeeCurveObservation = 'SpeeCurveObservation',
  TemporomandibularJointObservation = 'TemporomandibularJointObservation',
  TemporomandibularMuscleObservation = 'TemporomandibularMuscleObservation',
  FaceObservation = 'FaceObservation',
  OcclusionTextInfo = 'OcclusionTextInfo',
  MouthAreaObservation = 'MouthAreaObservation',
  LipAreaObservation = 'LipAreaObservation',
  LymphNodeObservation = 'LymphNodeObservation',
  AdenoidAndTonsilObservation = 'AdenoidAndTonsilObservation',
  SalivaryGlandObservation = 'SalivaryGlandObservation',
  MouthAreaOtherTissueObservation = 'MouthAreaOtherTissueObservation',
  OralTissueText = 'OralTissueText',
  TeethPeriodontiumObservation = 'TeethPeriodontiumObservation',
  OralMeasurement = 'OralMeasurement',
  CommunityPeriodontalIndex = 'CommunityPeriodontalIndex',
  SalivaMeasurement = 'SalivaMeasurement',
  LowerTeethObservation = 'LowerTeethObservation',
  CariesDamageIndex = 'CariesDamageIndex',
  OptometryTreatmentReason = 'OptometryTreatmentReason',
  OptometryAppointmentReason = 'OptometryAppointmentReason',
  OptometryAnamnesis = 'OptometryAnamnesis',
  Convergence = 'Convergence',
  VisionWithoutGlasses = 'VisionWithoutGlasses',
  OptometryObservation = 'OptometryObservation',
  RefractiveErrorEvaluation = 'RefractiveErrorEvaluation',
  OptometryTreatment = 'OptometryTreatment',
  OptometryOtherTreatment = 'OptometryOtherTreatment',
  OptometryStatement = 'OptometryStatement',
  OptometryPlan = 'OptometryPlan',
  EyeExaminationObservation = 'EyeExaminationObservation',
  EyeExaminationMeasurement = 'EyeExaminationMeasurement',
  FundusExamination = 'FundusExamination',
  IntraocularPressureMeasurement = 'IntraocularPressureMeasurement',
  CorneaThicknessMeasurement = 'CorneaThicknessMeasurement',
  KeratometryValues = 'KeratometryValues',
  ContactLensFitting = 'ContactLensFitting',
  ContactLensUsageInstructions = 'ContactLensUsageInstructions',
  VisionWithBothEyes = 'VisionWithBothEyes',
  EyeAdaptability = 'EyeAdaptability',
  EMSBasicInfo = 'EMSBasicInfo',
  EMSOrganization = 'EMSOrganization',
  EMSPatientInfo = 'EMSPatientInfo',
  EMSTreatmentReasonAndUrgency = 'EMSTreatmentReasonAndUrgency',
  EMSTreatmentInstructions = 'EMSTreatmentInstructions',
  EMSInjuryDetails = 'EMSInjuryDetails',
  EMSPatientStatus = 'EMSPatientStatus',
  EMSPhysiologicalMeasurement = 'EMSPhysiologicalMeasurement',
  EMSLabTest = 'EMSLabTest',
  EMSImagingStudy = 'EMSImagingStudy',
  EMSResuscitation = 'EMSResuscitation',
  EMSTreatment = 'EMSTreatment',
  EMSMedicalTreatment = 'EMSMedicalTreatment',
  EMSFollowUpActions = 'EMSFollowUpActions',
  EMSDeath = 'EMSDeath',
  EMSDraftVersion = 'EMSDraftVersion',
  MedicationCheckStatus = 'MedicationCheckStatus',
  Treatment2 = 'Treatment2',
  PhysiologicalMeasurement2 = 'PhysiologicalMeasurement2',
  Diagnosis2 = 'Diagnosis2',
  RiskInfo2 = 'RiskInfo2',
  Vaccination2 = 'Vaccination2',
  LabTestRequest2 = 'LabTestRequest2',
  LabTestResult2 = 'LabTestResult2',
  LabTestReport2 = 'LabTestReport2',
  ImagingRequest2 = 'ImagingRequest2',
  ImagingResult2 = 'ImagingResult2',
  ImagingReport2 = 'ImagingReport2',
  DentalStatus2 = 'DentalStatus2',
  OralMeasurement2 = 'OralMeasurement2',
  DentalProsthetic2 = 'DentalProsthetic2',
  ExcludedDiagnosis = 'ExcludedDiagnosis',
  HiddenContent = 'HiddenContent',
}

export enum TariffCode {
  Unknown = 'Unknown',
  Code0101L = 'Code0101L',
  CodeE101L = 'CodeE101L',
  CodeE101V = 'CodeE101V',
  Code0107L = 'Code0107L',
}

export enum TimeOfDay {
  Unknown = 'Unknown',
  EarlyMorning = 'EarlyMorning',
  Morning = 'Morning',
  Forenoon = 'Forenoon',
  Day = 'Day',
  Afternoon = 'Afternoon',
  Evening = 'Evening',
  LateEvening = 'LateEvening',
  Night = 'Night',
}

export enum ToothSurface {
  Unknown = 'Unknown',
  Occlusal = 'Occlusal',
  Mesial = 'Mesial',
  BuccalOrLabial = 'BuccalOrLabial',
  Distal = 'Distal',
  LingualOrPalatal = 'LingualOrPalatal',
  Retrograde = 'Retrograde',
}

export enum TreatmentMethodOrSubjectRestrictionType {
  Unknown = 'Unknown',
  NoUnderagedPatients = 'NoUnderagedPatients',
  OtherSubject = 'OtherSubject',
  OtherMethod = 'OtherMethod',
}

export enum UserConfigType {
  Unknown = 'Unknown',
  Normal = 'Normal',
  Admin = 'Admin',
}

export enum UserOrganizationAccessMode {
  Unknown = 'Unknown',
  Organization = 'Organization',
  OrganizationGroup = 'OrganizationGroup',
}

export enum UserRestrictionCategory {
  Unknown = 'Unknown',
  DrugPrescription = 'DrugPrescription',
  DrugHandling = 'DrugHandling',
  Supervision = 'Supervision',
  WorkplaceOrMethod = 'WorkplaceOrMethod',
  Operation = 'Operation',
  SubjectOrMethod = 'SubjectOrMethod',
  MedicalStudent = 'MedicalStudent',
  LicenseToPractise = 'LicenseToPractise',
  Other = 'Other',
}

export enum UserRightSystemAccessAction {
  Unknown = 'Unknown',
  Read = 'Read',
  Create = 'Create',
  Edit = 'Edit',
  Delete = 'Delete',
  Cancel = 'Cancel',
  Unlock = 'Unlock',
  Return = 'Return',
  Refuse = 'Refuse',
  Assign = 'Assign',
  Move = 'Move',
  Forward = 'Forward',
  Execute = 'Execute',
}

export enum UserRightSystemAccessObject {
  Unknown = 'Unknown',
  SystemClient = 'SystemClient',
  OrganizationGroup = 'OrganizationGroup',
  Organization = 'Organization',
  User = 'User',
  SystemConfig = 'SystemConfig',
  DownloadableMaterial = 'DownloadableMaterial',
  DocumentRepairTasks = 'DocumentRepairTasks',
  SystemSubClient = 'SystemSubClient',
  MailListSubscription = 'MailListSubscription',
  SignatureCertificate = 'SignatureCertificate',
  ActionLog = 'ActionLog',
  ErrorLog = 'ErrorLog',
  Statistics = 'Statistics',
  Session = 'Session',
  Profiler = 'Profiler',
  MessageLog = 'MessageLog',
  ErrorNotificationDocument = 'ErrorNotificationDocument',
  PrescriptionTemplateUser = 'PrescriptionTemplateUser',
  PrescriptionTemplateOrganization = 'PrescriptionTemplateOrganization',
  OccupationalHealthcareClientUser = 'OccupationalHealthcareClientUser',
  OccupationalHealthcareClientOrganization = 'OccupationalHealthcareClientOrganization',
  RoleOrganizationGroup = 'RoleOrganizationGroup',
  RoleSystemClient = 'RoleSystemClient',
  ArchiveTemplateUser = 'ArchiveTemplateUser',
  ArchiveTemplateOrganization = 'ArchiveTemplateOrganization',
  LabTestTemplateUser = 'LabTestTemplateUser',
  LabTestTemplateOrganization = 'LabTestTemplateOrganization',
  SupportContactInfo = 'SupportContactInfo',
  StyleSettingGroup = 'StyleSettingGroup',
  ReferralDeliveryPointOrganization = 'ReferralDeliveryPointOrganization',
  PhraseUser = 'PhraseUser',
  PhraseOrganization = 'PhraseOrganization',
  Prescription = 'Prescription',
  RenewalRequest = 'RenewalRequest',
  ArchiveNotification = 'ArchiveNotification',
  ArchiveConsent = 'ArchiveConsent',
  ArchiveProhibition = 'ArchiveProhibition',
  ServiceEvent = 'ServiceEvent',
  Appointment = 'Appointment',
  PatientID = 'PatientID',
  ArchiveContent = 'ArchiveContent',
  ArchiveContentForOther = 'ArchiveContentForOther',
  ArchiveContentOld = 'ArchiveContentOld',
  PrescriptionProhibition = 'PrescriptionProhibition',
  ArchiveWill = 'ArchiveWill',
  AdminPrescription = 'AdminPrescription',
  PrescriptionDispensation = 'PrescriptionDispensation',
  ArchiveForm = 'ArchiveForm',
  OutsourcingAuthorizationAsProvider = 'OutsourcingAuthorizationAsProvider',
  OutsourcingAuthorizationAsProducer = 'OutsourcingAuthorizationAsProducer',
  ServiceEventOld = 'ServiceEventOld',
  HealthAndTreatmentPlan = 'HealthAndTreatmentPlan',
  ArchiveImage = 'ArchiveImage',
  SoSeCustomerRelationship = 'SoSeCustomerRelationship',
  SoSeCase = 'SoSeCase',
  SoSeDocument = 'SoSeDocument',
  SoSeCustomerRelationshipOld = 'SoSeCustomerRelationshipOld',
  SoSeCaseOld = 'SoSeCaseOld',
  SoSeDocumentOld = 'SoSeDocumentOld',
  ArchiveEntryPrefetchRequest = 'ArchiveEntryPrefetchRequest',
  ArchiveReferral = 'ArchiveReferral',
  ArchiveTreatmentResponse = 'ArchiveTreatmentResponse',
  SecureContactInfo = 'SecureContactInfo',
  ArchiveCertificate = 'ArchiveCertificate',
  ArchiveSummary = 'ArchiveSummary',
  ExternalAccessSystemClient = 'ExternalAccessSystemClient',
  ArchiveOnlyOwnRegistry = 'ArchiveOnlyOwnRegistry',
  ArchiveContactProhibition = 'ArchiveContactProhibition',
  SoseArchiveConsent = 'SoseArchiveConsent',
  SoseArchiveProhibition = 'SoseArchiveProhibition',
}

export enum UserRightSystemAccessScope {
  Unknown = 'Unknown',
  None = 'None',
  User = 'User',
  Organization = 'Organization',
  OrganizationGroup = 'OrganizationGroup',
  SystemClient = 'SystemClient',
  All = 'All',
  Global = 'Global',
}

export enum VaccinationAdministrationMethod {
  Unknown = 'Unknown',
  Oral = 'Oral',
  Transdermal = 'Transdermal',
  Subcutaneous = 'Subcutaneous',
  Intramuscular = 'Intramuscular',
  Intranasal = 'Intranasal',
  Other = 'Other',
}

export enum VaccinationInjectionSite {
  Unknown = 'Unknown',
  LeftThigh = 'LeftThigh',
  RightThigh = 'RightThigh',
  LeftUpperArm = 'LeftUpperArm',
  RightUpperArm = 'RightUpperArm',
  LeftButtock = 'LeftButtock',
  RightButtock = 'RightButtock',
  Other = 'Other',
}

export enum WebPageType {
  Unknown = 'Unknown',
  User = 'User',
  UserInfo = 'UserInfo',
  UserRenewalRequests = 'UserRenewalRequests',
  UserOutsourcingAuthorizations = 'UserOutsourcingAuthorizations',
  UserPendingPrescriptions = 'UserPendingPrescriptions',
  Organization = 'Organization',
  OrganizationInfo = 'OrganizationInfo',
  OrganizationRenewalRequests = 'OrganizationRenewalRequests',
  OrganizationOutsourcingAuthorizations = 'OrganizationOutsourcingAuthorizations',
  Patient = 'Patient',
  PatientInfo = 'PatientInfo',
  PatientRenewalRequests = 'PatientRenewalRequests',
  PatientNewPrescriptions = 'PatientNewPrescriptions',
  PatientOldPrescriptions = 'PatientOldPrescriptions',
  PatientServiceEvent = 'PatientServiceEvent',
  PatientArchive = 'PatientArchive',
  PatientRisks = 'PatientRisks',
  PatientMedication = 'PatientMedication',
  PatientImagingStudies = 'PatientImagingStudies',
  PatientLabTests = 'PatientLabTests',
  PatientForms = 'PatientForms',
  PatientDentalStatus = 'PatientDentalStatus',
  PatientOutsourcingAuthorizations = 'PatientOutsourcingAuthorizations',
  PatientSoSeCustomerRelationship = 'PatientSoSeCustomerRelationship',
  PatientSoSeCases = 'PatientSoSeCases',
  PatientSoSeDocuments = 'PatientSoSeDocuments',
}

export enum WebsocketMessageType {
  Unknown = 'Unknown',
  SessionStatusMessage = 'SessionStatusMessage',
  AuthorizationMessage = 'AuthorizationMessage',
  PrescriptionMessage = 'PrescriptionMessage',
  ServiceEventMessage = 'ServiceEventMessage',
  ArchiveEntryMessage = 'ArchiveEntryMessage',
  ArchiveEntryBrokenMessage = 'ArchiveEntryBrokenMessage',
  HealthCareFormForwardedMessage = 'HealthCareFormForwardedMessage',
  NewPatientInQueueMessage = 'NewPatientInQueueMessage',
  NewAppNotificationMessage = 'NewAppNotificationMessage',
  ListRoomsRequestMessage = 'ListRoomsRequestMessage',
  RoomListMessage = 'RoomListMessage',
  NewChatMessage = 'NewChatMessage',
  EnterRoomRequestMessage = 'EnterRoomRequestMessage',
  ExitRoomRequestMessage = 'ExitRoomRequestMessage',
  UpdateRoomStatusMessage = 'UpdateRoomStatusMessage',
  GetRoomMessagesRequestMessage = 'GetRoomMessagesRequestMessage',
  RoomMessagesListMessage = 'RoomMessagesListMessage',
  UpdateMessageStatusMessage = 'UpdateMessageStatusMessage',
  ClientStatusUpdateMessage = 'ClientStatusUpdateMessage',
  RoomMemberListChangeMessage = 'RoomMemberListChangeMessage',
  PingMessage = 'PingMessage',
  ErrorMessage = 'ErrorMessage',
}

export enum WorkplaceRestrictionType {
  Unknown = 'Unknown',
  NotInPublicHealthcare = 'NotInPublicHealthcare',
  NotInPrivateHealthcare = 'NotInPrivateHealthcare',
  NotInPublicSocialServices = 'NotInPublicSocialServices',
  NotInPrivateSocialServices = 'NotInPrivateSocialServices',
  NotInPharmacy = 'NotInPharmacy',
  OnlyInSpecifiedOrganization = 'OnlyInSpecifiedOrganization',
  OtherProhibitedWorkplace = 'OtherProhibitedWorkplace',
  NotAsIndependentPractitioner = 'NotAsIndependentPractitioner',
  NotWithoutEmploymentRelationship = 'NotWithoutEmploymentRelationship',
  OtherProhibitedWorkMethod = 'OtherProhibitedWorkMethod',
}

export enum YesNoNotKnownValue {
  Unknown = 'Unknown',
  Yes = 'Yes',
  No = 'No',
  NotKnown = 'NotKnown',
}

export type PHFUnknownForm = object;
export type TwiMLResult = object;
