// Generated by ts-to-zod
import { z } from 'zod';
import {
  ApiKeyType,
  ActivitySettingsMode,
  Gender,
  AppNotifictionType,
  WebsocketMessageType,
  ModificationType,
  EntryType,
  EntryStatus,
  ArchiveAuthoringMode,
  ChatterStatus,
  PatientClosePrescriptionCheckMode,
  CodedSystemType,
  KantaNotificationStatus,
  PersonIdentifierType,
  PrescriptionCreateMode,
  HealthcareUnderageDataRelease,
  DatabaseFileType,
  DeploymentTestConfigType,
  DeploymentTestState,
  StatisticsTimeGroupingType,
  DiagnosisStatisticsSeparator,
  Certainty,
  Permanence,
  DiagnosisPrimarity,
  DispensationQuantityValueType,
  TimeOfDay,
  DayOfWeek,
  ReleaseDelayType,
  EntryQueryType,
  EntryQueryPurpose,
  EntryQueryHTMLMode,
  HealthcareProcessPhase,
  SecondaryTitleClass,
  SpecialSecondaryTitleType,
  EntrySectionStatisticsSeparator,
  ChatErrorCode,
  ErrorType,
  ErrorStatisticsSeparator,
  ErrorSource,
  ErrorSeverity,
  WebPageType,
  SpecialViewType,
  PatientPermission,
  CodedItemFlag,
  UserConfigType,
  UserOrganizationAccessMode,
  QueryResponseSpecifier,
  HealthStatus,
  DPlatformHealthcareFormType,
  OrganizationAreaOfOperation,
  OrganizationSectorType,
  PhoneNumberType,
  PublicServiceJoinModel,
  PrescriptionDataQueryType,
  ReasonForPrescriptionDataQuery,
  PrescriptionDispensationStatusSearchMode,
  RegistrationStatus,
  HealthEntryName,
  HealthcareFormType,
  AccessIPValidationMode,
  JsonFieldType,
  ChatRoomStatus,
  ChatMessageContentType,
  ChatMessageDeliveryStatus,
  OrganizationType,
  UserRightSystemAccessObject,
  UserRightSystemAccessAction,
  UserRightSystemAccessScope,
  HealthAndTreatmentPlanServiceStatus,
  MaintainedDocumentType,
  NonIntegratedAccessMode,
  PatientInfoAccessReason,
  OutsourcingType,
  RegistryAccessModel,
  HealthcareNatureOfVisit,
  HealthcareContactType,
  OpenedServiceEventStatisticsSeparator,
  OutsourcingAuthorizationProducerWriteAccess,
  OutsourcingAuthorizationProducerReadAccess,
  PatientStatus,
  LabTestRequestStatus,
  EmailStatus,
  PriceType,
  PatientStateStatisticsSeparator,
  PatientStateTimedStatisticsSeparator,
  PatientStatisticsEventType,
  PatientTimeToTreatedStatisticsSeparator,
  PhraseType,
  PhraseCategory,
  HealthcareFormPrefillMode,
  PrescriptionPrefillMode,
  DrugProductType,
  MedicalTreatmentChangeReason,
  PrescriptionState,
  PrescriptionReservationStatus,
  PrescriptionDispensationStatus,
  PrescriptionLockStatus,
  PrescriptionRenewalStatus,
  NarcoticType,
  PatientIdentificationType,
  PrescriptionProductType,
  PrescriptionType,
  PharmacyCreatedPrescriptionType,
  PharmacyCreatedPrescriptionReason,
  YesNoNotKnownValue,
  DrugQuantityType,
  PrescriptionValidityStatus,
  PrescriptionInvalidityReason,
  AllowedPrescriptionOperationResult,
  PrescriptionStatisticsSeparator,
  PrescriptionDosageReportingType,
  ReferralUrgency,
  ReferralEmployment,
  AccessTokenType,
  SessionStatus,
  ArchiveRegistryType,
  ChatterRole,
  InformationSource,
  HealthInformationChangeReason,
  StructuredContentType,
  ReportStatus,
  ArchiveLabTestOperationState,
  LabTestResultState,
  ArchiveLabResultType,
  ArchiveLabResultInterpretationCode,
  MeasurementMethod,
  MicrobeStudyType,
  MicrobeQuantity,
  RiskLevel,
  ABOBloodGroup,
  RhDBloodGroup,
  MicrobeMethod,
  MicrobeSensitivity,
  MicrobialDrugSensitivityTestMethod,
  ToothSurface,
  ArchiveTreatmentState,
  ArchiveTreatmentType,
  ArchiveTreatmentOperatingPersonRole,
  ProstheticToothType,
  VaccinationAdministrationMethod,
  VaccinationInjectionSite,
  TariffCode,
  ServiceEventStatus,
  SessionStatisticsSeparator,
  SmSMessageStatisticsSeparator,
  SmsMessageStatus,
  LoginType,
  SmsMessageDirection,
  SessionTerminationReason,
  ContentEditMode,
  HealthProfessionalGroup,
  UserRestrictionCategory,
  DrugOrProductType,
  WorkplaceRestrictionType,
  DrugPrescribingRestrictionType,
  TreatmentMethodOrSubjectRestrictionType,
  FileType,
  PatientPreliminaryImageSize,
} from './backend-api-types';

export const externalCompanySchema = z.object({
  id: z.string().uuid(),
  name: z.string().min(1).max(255),
  companyId: z.string().max(255).optional().nullable(),
  oid: z.string().max(255).optional().nullable(),
  sms: z.string().max(40000).optional().nullable(),
  email: z.string().max(40000).optional().nullable(),
  chatUrl: z.string().max(40000).optional().nullable(),
  feedbackSurveyUrl: z.string().max(40000).optional().nullable(),
  feedbackSurveyDelayInHours: z.number().optional().nullable(),
  mailgunBaseUrl: z.string().max(40000).optional().nullable(),
  mailgunApiKey: z.string().max(40000).optional().nullable(),
});

export const apiKeyTypeSchema = z.nativeEnum(ApiKeyType);

export const x509CertificateInfoSchema = z.object({
  cn: z.string().max(40000),
  serialNumber: z.string().max(40000),
  issuer: z.string().max(40000),
  x509: z.string().max(40000),
  notBefore: z.string().max(40000),
  notAfter: z.string().max(40000),
  registered: z.string().max(40000),
  additionalProperties: z.record(z.unknown()).optional(),
});

export const activitySettingsModeSchema = z.nativeEnum(ActivitySettingsMode);

export const addApiKeyRequestSchema = z.object({
  externalCompanyId: z.string().uuid(),
  validUntil: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .optional()
    .nullable(),
  allowedIpAddresses: z.array(z.string()).optional().nullable(),
  allowedQueues: z.array(z.string()).optional().nullable(),
  apiKeyType: apiKeyTypeSchema,
});

export const addExternalCompanyRequestSchema = z.object({
  id: z.string().uuid().optional().nullable(),
  name: z.string().min(1).max(255),
  companyId: z.string().max(255).optional().nullable(),
  oid: z.string().max(255).optional().nullable(),
  sms: z.string().max(19).optional().nullable(),
  email: z.string().max(255).optional().nullable(),
  chatUrl: z.string().max(2048).optional().nullable(),
  feedbackSurveyUrl: z.string().max(2048).optional().nullable(),
  feedbackSurveyDelayInHours: z.number().optional().nullable(),
  mailgunBaseUrl: z.string().max(2048).optional().nullable(),
  mailgunApiKey: z.string().max(256).optional().nullable(),
});

export const addExternalCompanyResponseSchema = z.object({
  item: externalCompanySchema,
});

export const genderSchema = z.nativeEnum(Gender);

export const patientQueueInfoUserSchema = z.object({
  regNumber: z.string().min(1).max(11),
  firstName: z.string().min(1).max(128),
  lastName: z.string().min(1).max(128),
});

export const patientQueueInfoOrganizationSchema = z.object({
  oid: z.string().min(1).max(255),
  name: z.string().max(40000).optional().nullable(),
});

export const addUserAgreementRequestSchema = z.object({
  html: z.string().min(1).max(40000),
  isValid: z.boolean(),
});

export const userAgreementSchema = z.object({
  id: z.number(),
  version: z.number(),
  html: z.string().min(1).max(40000),
  isValid: z.boolean(),
  createdTS: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
  updatedTS: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .optional()
    .nullable(),
});

export const addressSchema = z.object({
  streetAddress: z.string().max(255).optional().nullable(),
  postalCode: z.string().max(255).optional().nullable(),
  city: z.string().max(255).optional().nullable(),
  country: z.string().max(255).optional().nullable(),
});

export const simplePatientQueueInfoSchema = z.object({
  guid: z.string().uuid(),
  name: z.string().min(1).max(100),
  responseTimeSeconds: z.number().optional().nullable(),
  connectionMethod: z.string().max(100).optional().nullable(),
  isFifo: z.boolean(),
  description: z.string().max(1000).optional().nullable(),
  hasGenderRestriction: z.boolean(),
  genderRestriction: genderSchema.optional().nullable(),
  hasAgeLimit: z.boolean(),
  minAge: z.number().optional().nullable(),
  maxAge: z.number().optional().nullable(),
  chatEnabled: z.boolean().optional().nullable(),
  showPreliminaryInfo: z.boolean(),
  showPreliminaryDescription: z.boolean(),
  owner: patientQueueInfoUserSchema,
});

export const adminLoginValidateAndConfirmRequestSchema = z.object({
  eventId: z.string().min(1).max(40000),
  smartCardResponseData: z.string().min(1).max(40000),
  isMobile: z.boolean(),
});

export const appNotifictionTypeSchema = z.nativeEnum(AppNotifictionType);

export const patientQueueSchema = z.object({
  guid: z.string().uuid(),
  name: z.string().min(1).max(100),
  isFifo: z.boolean(),
  showPreliminaryInfo: z.boolean(),
  showPreliminaryDescription: z.boolean(),
});

export const websocketMessageTypeSchema = z.nativeEnum(WebsocketMessageType);

export const modificationTypeSchema = z.nativeEnum(ModificationType);

export const entryTypeSchema = z.nativeEnum(EntryType);

export const entryStatusSchema = z.nativeEnum(EntryStatus);

export const dataViewSchema = z.object({
  code: z.string().min(1).max(40000).nullable(),
  name: z.string().max(40000).optional().nullable(),
  description: z.string().max(40000).optional().nullable(),
});

export const archiveAuthoringModeSchema = z.nativeEnum(ArchiveAuthoringMode);

export const contentAuthorSchema = z.object({
  firstName: z.string().min(1).max(40000).nullable(),
  lastName: z.string().min(1).max(40000).nullable(),
  regNumber: z.string().min(1).max(40000).nullable(),
  idNumber: z.string().min(1).max(40000).nullable(),
  id: z.string().min(1).max(40000).nullable(),
  oid: z.string().min(1).max(40000).nullable(),
});

export const authorizationMessageSchema = z.object({
  messageType: websocketMessageTypeSchema,
  timeStamp: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
  authorizationToken: z.string().max(40000).optional().nullable(),
});

export const chatterStatusSchema = z.nativeEnum(ChatterStatus);

export const patientClosePrescriptionCheckModeSchema = z.nativeEnum(
  PatientClosePrescriptionCheckMode,
);

export const closePatientResponseSchema = z.object({
  unsentPrescriptionCount: z.number(),
  unsentPrescriptionExpirationTime: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .optional()
    .nullable(),
});

export const closeServiceEventRequestSchema = z.object({
  prescriptionCheckMode: patientClosePrescriptionCheckModeSchema
    .optional()
    .nullable(),
});

export const closeServiceEventResponseSchema = z.object({
  unsentPrescriptionCount: z.number(),
  unsentPrescriptionExpirationTime: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .optional()
    .nullable(),
});

export const codedSystemTypeSchema = z.nativeEnum(CodedSystemType);

export const commitPrescriptionsRequestSchema = z.object({
  printPatientInstructions: z.boolean(),
});

export const commitPrescriptionsResponseSchema = z.object({
  pdfDocuments: z.array(z.string()).optional().nullable(),
});

export const confirmUserAgreementRequestSchema = z.object({
  eventId: z.string().min(1).max(40000),
  smartCardResponseData: z.string().min(1).max(40000),
  phoneNumber: z.string().min(1).max(19),
  email: z.string().min(1).max(320),
});

export const userAgreementConfirmationSchema = z.object({
  phoneNumber: z.string().min(1).max(40000),
  email: z.string().min(1).max(40000),
  firstName: z.string().min(1).max(40000),
  lastName: z.string().min(1).max(40000),
  regNumber: z.string().min(1).max(40000),
  createdTS: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
  agreement: userAgreementSchema,
});

export const kantaNotificationStatusSchema = z.nativeEnum(
  KantaNotificationStatus,
);

export const contentEditorSchema = z.object({
  firstName: z.string().min(1).nullable(),
  lastName: z.string().min(1).nullable(),
  regNumber: z.string().min(1).nullable(),
  idNumber: z.string().min(1).nullable(),
  id: z.string().min(1).nullable(),
  oid: z.string().min(1).nullable(),
  editTime: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .nullable(),
  role: z.string().min(1).max(40000),
});

export const contentProducerOrganizationSchema = z.object({
  oid: z.string().min(1).nullable(),
  name: z.string().min(0).nullable(),
});

export const createFeedbackRequestSchema = z.object({
  title: z.string().min(1).max(255),
  feedback: z.string().min(1).max(10000),
  replyTo: z.string().max(255).optional().nullable(),
  htmlBody: z.boolean().optional().nullable(),
});

export const personIdentifierTypeSchema = z.nativeEnum(PersonIdentifierType);

export const createGuestSessionResponseSchema = z.object({
  accessToken: z.string().min(1).max(40000),
  expiresIn: z.number(),
});

export const prescriptionCreateModeSchema = z.nativeEnum(
  PrescriptionCreateMode,
);

export const healthcareUnderageDataReleaseSchema = z.nativeEnum(
  HealthcareUnderageDataRelease,
);

export const databaseFileTypeSchema = z.nativeEnum(DatabaseFileType);

export const deleteEntryRequestSchema = z.object({
  oid: z.string().min(1).max(40000),
  deletePermanently: z.boolean(),
  reason: z.string().max(40000).optional().nullable(),
});

export const deploymentTestConfigTypeSchema = z.nativeEnum(
  DeploymentTestConfigType,
);

export const deploymentTestStateSchema = z.nativeEnum(DeploymentTestState);

export const statisticsTimeGroupingTypeSchema = z.nativeEnum(
  StatisticsTimeGroupingType,
);

export const diagnosisStatisticsSeparatorSchema = z.nativeEnum(
  DiagnosisStatisticsSeparator,
);

export const certaintySchema = z.nativeEnum(Certainty);

export const permanenceSchema = z.nativeEnum(Permanence);

export const diagnosisPrimaritySchema = z.nativeEnum(DiagnosisPrimarity);

export const dispensationQuantityValueTypeSchema = z.nativeEnum(
  DispensationQuantityValueType,
);

export const valueUnitNullableSchema = z.object({
  value: z.string().min(1).max(40000).nullable(),
  unit: z.string().min(1).max(40000).nullable(),
});

export const documentIdentificationDataSchema = z.object({
  documentOID: z.string().max(40000).optional().nullable(),
  documentSetOID: z.string().max(40000).optional().nullable(),
  version: z.number().optional().nullable(),
});

export const sCNamedCodeSchema = z.object({
  type: codedSystemTypeSchema,
  code: z.string().min(1).max(255),
  name: z.string().max(40000).optional().nullable(),
});

export const timeOfDaySchema = z.nativeEnum(TimeOfDay);

export const dayOfWeekSchema = z.nativeEnum(DayOfWeek);

export const duodecimAccessTokenSchema = z.object({
  access_token: z.string().max(40000).optional().nullable(),
  token_type: z.string().max(40000).optional().nullable(),
  expires_in: z.number().optional().nullable(),
  duoidentifier: z.number().optional().nullable(),
});

export const editServiceEventRequestSchema = z.object({
  startTime: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
  endTime: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .optional()
    .nullable(),
  includesHospitalization: z.boolean().optional().nullable(),
  responsibleOrganizationOID: z.string().max(40000).optional().nullable(),
  underageDataReleaseToGuardian: healthcareUnderageDataReleaseSchema
    .optional()
    .nullable(),
});

export const emailDataSchema = z.object({
  content: z.string().min(1).max(40000),
  htmlBody: z.boolean(),
});

export const enterRoomRequestMessageSchema = z.object({
  messageType: websocketMessageTypeSchema,
  timeStamp: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
  roomId: z.string().uuid(),
});

export const releaseDelayTypeSchema = z.nativeEnum(ReleaseDelayType);

export const entryQueryTypeSchema = z.nativeEnum(EntryQueryType);

export const entryQueryPurposeSchema = z.nativeEnum(EntryQueryPurpose);

export const entryQueryHTMLModeSchema = z.nativeEnum(EntryQueryHTMLMode);

export const healthcareProcessPhaseSchema = z.nativeEnum(
  HealthcareProcessPhase,
);

export const entrySectionTitleSchema = z.object({
  code: z.string().min(1).max(40000),
  text: z.string().max(40000).optional().nullable(),
});

export const secondaryTitleClassSchema = z.nativeEnum(SecondaryTitleClass);

export const specialSecondaryTitleTypeSchema = z.nativeEnum(
  SpecialSecondaryTitleType,
);

export const entrySectionStatisticsSeparatorSchema = z.nativeEnum(
  EntrySectionStatisticsSeparator,
);

export const chatErrorCodeSchema = z.nativeEnum(ChatErrorCode);

export const errorTypeSchema = z.nativeEnum(ErrorType);

export const errorStatisticsSeparatorSchema = z.nativeEnum(
  ErrorStatisticsSeparator,
);

export const errorSourceSchema = z.nativeEnum(ErrorSource);

export const errorSeveritySchema = z.nativeEnum(ErrorSeverity);

export const exitRoomRequestMessageSchema = z.object({
  messageType: websocketMessageTypeSchema,
  timeStamp: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
  roomId: z.string().uuid(),
});

export const externalCompanyDefaultValuesSchema = z.object({
  sms: z.string().min(1).max(40000),
  email: z.string().min(1).max(40000),
  mailgunBaseUrl: z.string().min(1).max(40000),
  noProductionSimulateDPrescriptionEmail: z
    .string()
    .max(40000)
    .optional()
    .nullable(),
  noProductionSimulateDPrescriptionMailgunBaseUrl: z
    .string()
    .max(40000)
    .optional()
    .nullable(),
});

export const feedbackItemSchema = z.object({
  createdTS: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
  guid: z.string().uuid(),
  title: z.string().min(1).max(40000),
  content: z.string().min(1).max(40000),
  replyTo: z.string().max(40000).optional().nullable(),
  htmlBody: z.boolean().optional().nullable(),
});

export const webPageTypeSchema = z.nativeEnum(WebPageType);

export const specialViewTypeSchema = z.nativeEnum(SpecialViewType);

export const getAllPrescriptionsRequestSchema = z.object({
  startTime: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .optional()
    .nullable(),
  endTime: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .optional()
    .nullable(),
  includeRootOrganizationOIDs: z.array(z.string()).optional().nullable(),
});

export const statisticsSessionFilterParametersSchema = z.object({
  newerThan: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .optional()
    .nullable(),
  includeUsers: z.array(z.number()).optional().nullable(),
  includeRootOrganizationOIDs: z.array(z.string()).optional().nullable(),
});

export const patientPermissionSchema = z.nativeEnum(PatientPermission);

export const aPIKeySchema = z.object({
  id: z.string().uuid(),
  apiKey: z.string().min(1).max(40000),
  externalCompany: externalCompanySchema,
  validUntil: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .optional()
    .nullable(),
  allowedIpAddresses: z.array(z.string()),
  allowedQueues: z.array(simplePatientQueueInfoSchema),
  apiKeyType: apiKeyTypeSchema,
});

export const codedItemFlagSchema = z.nativeEnum(CodedItemFlag);

export const offsetLimitSchema = z.object({
  offset: z.number(),
  limit: z.number(),
});

export const codedSystemIDSchema = z.object({
  type: codedSystemTypeSchema,
  versionID: z.string().min(1).max(40000),
});

export const userConfigTypeSchema = z.nativeEnum(UserConfigType);

export const userOrganizationAccessModeSchema = z.nativeEnum(
  UserOrganizationAccessMode,
);

export const userConfigRoleSchema = z.object({
  id: z.string().min(1).max(40000),
  name: z.string().max(40000).optional().nullable(),
});

export const valueUnitSchema = z.object({
  value: z.string().min(1).max(40000),
  unit: z.string().max(40000),
});

export const activitySettingsSchema = z.object({
  mode: activitySettingsModeSchema,
  startDate: z.string().date().optional().nullable(),
  endDate: z.string().date().optional().nullable(),
});

export const deploymentTestConfigSchema = z.object({
  type: deploymentTestConfigTypeSchema,
  state: deploymentTestStateSchema,
  date: z.string().date().optional().nullable(),
  patientID: z.string().max(40000).optional().nullable(),
  patientFirstName: z.string().max(40000).optional().nullable(),
  patientLastName: z.string().max(40000).optional().nullable(),
  userRegNumber: z.string().max(40000).optional().nullable(),
  userFirstName: z.string().max(40000).optional().nullable(),
  userLastName: z.string().max(40000).optional().nullable(),
});

export const entryQuerySchema = z.object({
  type: entryQueryTypeSchema,
  purpose: entryQueryPurposeSchema,
  serviceEventOID: z.string().max(40000).optional().nullable(),
  dataView: dataViewSchema.optional().nullable(),
  startDate: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .optional()
    .nullable(),
  endDate: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .optional()
    .nullable(),
  includeProtectedContent: z.boolean().optional().nullable(),
  getStructuredContents: z.boolean().optional().nullable(),
  getHTML: entryQueryHTMLModeSchema.optional().nullable(),
  getPDF: z.boolean().optional().nullable(),
});

export const queryResponseSpecifierSchema = z.nativeEnum(
  QueryResponseSpecifier,
);

export const getEntryBatchSearchResultsRequestSchema = z.object({
  batchID: z.string().min(1).max(40000),
  batchNumber: z.number(),
});

export const getExternalCompaniesResponseSchema = z.object({
  items: z.array(externalCompanySchema),
  defaultValues: externalCompanyDefaultValuesSchema,
});

export const getFeedbackResponseSchema = z.object({
  feedbacks: z.array(feedbackItemSchema),
  total: z.number(),
});

export const getFlagsRequestSchema = z.object({
  type: codedSystemTypeSchema,
  code: z.string().min(1).max(40000),
  includeExtraCodes: z.boolean(),
  extraCodeOptions: offsetLimitSchema.optional().nullable(),
});

export const simpleCodedItemSchema = z.object({
  code: z.string().min(1).max(40000),
  longName: z.string().min(1).max(40000),
  flags: z.array(codedItemFlagSchema),
});

export const simpleCodedItemsResultSchema = z.object({
  count: z.number(),
  options: offsetLimitSchema.optional().nullable(),
  items: z.array(simpleCodedItemSchema),
});

export const healthStatusSchema = z.nativeEnum(HealthStatus);

export const dPlatformHealthcareFormTypeSchema = z.nativeEnum(
  DPlatformHealthcareFormType,
);

export const getLaboratoryOperationCodesRequestSchema = z.object({
  searchString: z.string().max(40000).optional().nullable(),
  limit: z.number().optional().nullable(),
  skip: z.number().optional().nullable(),
});

export const getLatestFeedbackRequestSchema = z.object({
  takeCount: z.number().optional().nullable(),
  skipCount: z.number().optional().nullable(),
  olderThan: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .optional()
    .nullable(),
});

export const appNotificationSchema = z.object({
  guid: z.string().uuid(),
  createdTS: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
  type: appNotifictionTypeSchema,
  patientFirstName: z.string().max(40000).optional().nullable(),
  patientLastName: z.string().max(40000).optional().nullable(),
  patientID: z.string().max(40000).optional().nullable(),
  isRead: z.boolean(),
  patientQueues: z.array(patientQueueSchema),
  content: z.string().max(40000).optional().nullable(),
});

export const getOrganizationNamesRequestSchema = z.object({
  searchString: z.string().max(40000).optional().nullable(),
  limit: z.number().optional().nullable(),
  skip: z.number().optional().nullable(),
});

export const getOrganizationNamesResponseSchema = z.object({
  namesToOids: z.record(z.string()),
});

export const organizationAreaOfOperationSchema = z.nativeEnum(
  OrganizationAreaOfOperation,
);

export const organizationSectorTypeSchema = z.nativeEnum(
  OrganizationSectorType,
);

export const phoneNumberTypeSchema = z.nativeEnum(PhoneNumberType);

export const publicServiceJoinModelSchema = z.nativeEnum(
  PublicServiceJoinModel,
);

export const patientTemplateSchema = z.object({
  firstName: z.string().min(1).max(128),
  friendlyName: z.string().max(128).optional().nullable(),
  lastName: z.string().min(1).max(128),
  idType: personIdentifierTypeSchema,
  id: z.string().max(63).optional().nullable(),
  idCreationYear: z.number().optional().nullable(),
  temporaryIDRoot: z.string().max(64).optional().nullable(),
  dateOfBirth: z.string().date().optional().nullable(),
  guid: z.string().uuid(),
  mobilePhoneNumber: z.string().max(19).optional().nullable(),
  email: z.string().max(320).optional().nullable(),
  address: addressSchema.optional().nullable(),
  lastOpenedAt: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .optional()
    .nullable(),
  createdTS: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
});

export const prescriptionDataQueryTypeSchema = z.nativeEnum(
  PrescriptionDataQueryType,
);

export const reasonForPrescriptionDataQuerySchema = z.nativeEnum(
  ReasonForPrescriptionDataQuery,
);

export const prescriptionDispensationStatusSearchModeSchema = z.nativeEnum(
  PrescriptionDispensationStatusSearchMode,
);

export const getRoomMessagesRequestSchema = z.object({
  messageType: websocketMessageTypeSchema,
  timeStamp: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
  roomId: z.string().uuid(),
  since: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .optional()
    .nullable(),
  take: z.number().optional().nullable(),
  skip: z.number().optional().nullable(),
});

export const getSettingsResponseSchema = z.object({
  phoneNumber: z.string().max(19).optional().nullable(),
  email: z.string().max(320).optional().nullable(),
  webhookUrl: z.string().max(2048).optional().nullable(),
});

export const getUserAgreementConfirmationsRequestSchema = z.object({
  takeCount: z.number().optional().nullable(),
  skipCount: z.number().optional().nullable(),
  olderThan: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .optional()
    .nullable(),
});

export const userAgreementConfirmationExtendedSchema = z.object({
  phoneNumber: z.string().min(1).max(40000),
  email: z.string().min(1).max(40000),
  firstName: z.string().min(1).max(40000),
  lastName: z.string().min(1).max(40000),
  regNumber: z.string().min(1).max(40000),
  createdTS: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
  userAgreementId: z.number(),
});

export const userAgreementExtendedSchema = z.object({
  id: z.number(),
  version: z.number(),
  html: z.string().min(1).max(40000),
  isValid: z.boolean(),
  createdTS: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
  updatedTS: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .optional()
    .nullable(),
  confirmationCount: z.number(),
});

export const userProfileSchema = z.object({
  userGuid: z.string().uuid().optional().nullable(),
  phoneNumber: z.string().max(19).optional().nullable(),
  email: z.string().max(320).optional().nullable(),
  languages: z.array(z.string()).optional().nullable(),
  competences: z.string().max(255).optional().nullable(),
  allowThirdPartyAccess: z.boolean().optional(),
  place: z.string().max(255).optional().nullable(),
});

export const getUserPublicInfoRequestSchema = z.object({
  regNumber: z.string().min(1).max(40000),
});

export const independentPractitionerInfoSchema = z.object({
  longName: z.string().max(40000).optional().nullable(),
});

export const registrationStatusSchema = z.nativeEnum(RegistrationStatus);

export const getUsersFilterParametersSchema = z.object({
  registrationStatuses: z.array(registrationStatusSchema).optional().nullable(),
});

export const userInfoAdminSchema = z.object({
  userId: z.number(),
  firstName: z.string().min(1).max(40000),
  lastName: z.string().min(1).max(40000),
  regNumber: z.string().min(1).max(40000),
  profilePhoneNumber: z.string().max(40000).optional().nullable(),
  profileEmail: z.string().max(40000).optional().nullable(),
  registrationStatus: registrationStatusSchema,
  createdTS: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
});

export const groupedStatisticsResponseSchema = z.object({
  startDate: z.string().date(),
  endDate: z.string().date(),
  counts: z.record(z.number()),
});

export const groupedTimedStatisticsDataSchema = z.object({
  count: z.number(),
  min: z.string(),
  max: z.string(),
  total: z.string(),
  median: z.string(),
});

export const groupedTimedStatisticsResponseSchema = z.object({
  startDate: z.string().date(),
  endDate: z.string().date(),
  data: z.record(groupedTimedStatisticsDataSchema),
});

export const healthEntryNameSchema = z.nativeEnum(HealthEntryName);

export const sV120DocumentDataSchema = z.object({
  patientIdentifier: z.string().min(1).max(40000),
  patientName: z.string().min(1).max(40000),
});

export const healthcareFormTypeSchema = z.nativeEnum(HealthcareFormType);

export const healthcareFormSimpleSchema = z.object({
  id: z.string().uuid(),
  type: dPlatformHealthcareFormTypeSchema,
  sV120DocumentData: sV120DocumentDataSchema.optional().nullable(),
  createdTS: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
  updatedTS: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .optional()
    .nullable(),
});

export const henkilonTiedotSchema = z.object({
  etunimet: z.string().min(1).max(40000).optional().nullable(),
  henkilotunnus: z.string().min(11).max(40000),
  sukunimi: z.string().min(1).max(40000).optional().nullable(),
});

export const accessIPValidationModeSchema = z.nativeEnum(
  AccessIPValidationMode,
);

export const entryBatchQuerySchema = z.object({
  type: entryQueryTypeSchema,
  serviceEventOIDs: z.array(z.string()).optional().nullable(),
  dataViews: z.array(dataViewSchema).optional().nullable(),
  startDate: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .optional()
    .nullable(),
  endDate: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .optional()
    .nullable(),
});

export const joinUserWaitingListRequestSchema = z.object({
  regNumber: z.string().min(1).max(40000),
  phoneNumber: z.string().min(1).max(19),
  email: z.string().min(1).max(320),
});

export const joinUserWaitingListResponseSchema = z.object({
  regNumber: z.string().min(1).max(40000),
  phoneNumber: z.string().min(1).max(40000),
  email: z.string().min(1).max(40000),
});

export const kyselijaSchema = z.object({
  kyselijanYksiloivaTunnus: z.string().min(1).max(40000),
  organisaatioNimi: z.string().min(1).max(40000),
  suorakorvausSopimuksenTunnus: z
    .string()
    .min(1)
    .max(40000)
    .regex(/[0-9]{5,11}/),
});

export const labTestInformationSchema = z.object({
  hl7Identifier: z.string().min(1).max(40000),
  value: z.string().min(1).max(65536),
});

export const labTestOperationSchema = z.object({
  code: sCNamedCodeSchema,
  additionalInformation: z.string().max(1000).optional().nullable(),
  information: z.array(labTestInformationSchema).optional().nullable(),
});

export const jsonFieldTypeSchema = z.nativeEnum(JsonFieldType);

export const chatRoomStatusSchema = z.nativeEnum(ChatRoomStatus);

export const listRoomsFilterSchema = z.object({
  activeWithinHours: z.number(),
  status: chatRoomStatusSchema.optional().nullable(),
  include: z.array(z.string()).optional().nullable(),
});

export const chatMessageContentTypeSchema = z.nativeEnum(
  ChatMessageContentType,
);

export const chatMessageDeliveryStatusSchema = z.nativeEnum(
  ChatMessageDeliveryStatus,
);

export const organizationTypeSchema = z.nativeEnum(OrganizationType);

export const userRightSystemAccessObjectSchema = z.nativeEnum(
  UserRightSystemAccessObject,
);

export const userRightSystemAccessActionSchema = z.nativeEnum(
  UserRightSystemAccessAction,
);

export const userRightSystemAccessScopeSchema = z.nativeEnum(
  UserRightSystemAccessScope,
);

export const logoutRequestSchema = z.object({
  accessToken: z.string().max(40000).optional().nullable(),
  rememberOrganization: z.boolean().optional().nullable(),
});

export const mDHealthAndTreatmentPlanTreatmentReasonSchema = z.object({
  treatmentReason: sCNamedCodeSchema,
  treatmentReasonExtension: z.string().max(40000).optional().nullable(),
  coordinator: z.string().max(40000).optional().nullable(),
  coordinatorProfessionalFirstName: z.string().max(40000).optional().nullable(),
  coordinatorProfessionalLastName: z.string().max(40000).optional().nullable(),
  coordinatorOrganizationOID: z.string().max(40000).optional().nullable(),
  coordinatorIndependentPractitionerOID: z
    .string()
    .max(40000)
    .optional()
    .nullable(),
  coordinatorAdditionalInformation: z.string().max(40000).optional().nullable(),
});

export const mDHealthAndTreatmentPlanObjectiveConditionSchema = z.object({
  identifier: sCNamedCodeSchema,
  conditionLow: valueUnitSchema.optional().nullable(),
  conditionHigh: valueUnitSchema.optional().nullable(),
  conditionAdditionalInformation: z.string().max(40000).optional().nullable(),
});

export const mDHealthAndTreatmentPlanHealthcareOrganizationSchema = z.object({
  organizationOID: z.string().max(40000).optional().nullable(),
  independentPractitionerOID: z.string().max(40000).optional().nullable(),
  treatmentUnitOrganizationOID: z.string().max(40000).optional().nullable(),
  treatmentUnitIndependentPractitionerOID: z
    .string()
    .max(40000)
    .optional()
    .nullable(),
  occupation: sCNamedCodeSchema.optional().nullable(),
  professionalFirstName: z.string().max(40000).optional().nullable(),
  professionalLastName: z.string().max(40000).optional().nullable(),
  additionalInformation: z.string().max(40000).optional().nullable(),
});

export const mDHealthAndTreatmentPlanHealthcareProfessionalSchema = z.object({
  firstName: z.string().min(1).max(40000),
  lastName: z.string().min(1).max(40000),
  personIdentifier: z.string().max(40000).optional().nullable(),
  registrationNumber: z.string().max(40000).optional().nullable(),
  occupation: sCNamedCodeSchema.optional().nullable(),
  additionalInformation: z.string().max(40000).optional().nullable(),
});

export const mDHealthAndTreatmentPlanServiceConditionIdentifierSchema =
  z.object({
    identifier: sCNamedCodeSchema,
    conditionLow: valueUnitSchema.optional().nullable(),
    conditionHigh: valueUnitSchema.optional().nullable(),
  });

export const healthAndTreatmentPlanServiceStatusSchema = z.nativeEnum(
  HealthAndTreatmentPlanServiceStatus,
);

export const mDHealthAndTreatmentPlanServiceStateChangeSchema = z.object({
  changeTime: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
});

export const maintainedDocumentTypeSchema = z.nativeEnum(
  MaintainedDocumentType,
);

export const modifyEntryBatchSearchRequestSchema = z.object({
  batchID: z.string().min(1).max(40000),
  includedProtectedContentDataViews: z
    .array(dataViewSchema)
    .optional()
    .nullable(),
});

export const modifyEntryBatchSearchResponseSchema = z.object({
  batchID: z.string().min(1).max(40000),
  batchCount: z.number(),
  includedProtectedContentDataViews: z
    .array(dataViewSchema)
    .optional()
    .nullable(),
  excludedProtectedContentDataViews: z
    .array(dataViewSchema)
    .optional()
    .nullable(),
});

export const newAppNotificationMessageSchema = z.object({
  messageType: websocketMessageTypeSchema,
  timeStamp: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
  notification: appNotificationSchema,
});

export const newChatMessageSchema = z.object({
  messageType: websocketMessageTypeSchema,
  timeStamp: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
  messageId: z.string().uuid(),
  contentType: chatMessageContentTypeSchema,
  content: z.string().min(1).max(40000),
  roomId: z.string().uuid().optional().nullable(),
  createdTimestamp: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .optional()
    .nullable(),
  senderFirstName: z.string().max(128).optional().nullable(),
  senderLastName: z.string().max(128).optional().nullable(),
  senderGuid: z.string().uuid().optional().nullable(),
});

export const newPatientInQueueMessageSchema = z.object({
  messageType: websocketMessageTypeSchema,
  timeStamp: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
  queueName: z.string().min(1).max(40000),
  queueId: z.string().uuid(),
  waitingCount: z.number(),
  longestWaitingTime: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
});

export const nonIntegratedAccessModeSchema = z.nativeEnum(
  NonIntegratedAccessMode,
);

export const notificationScheduleDaysSchema = z.object({
  mon: z.boolean(),
  tue: z.boolean(),
  wed: z.boolean(),
  thu: z.boolean(),
  fri: z.boolean(),
  sat: z.boolean(),
  sun: z.boolean(),
});

export const notificationScheduleHoursSchema = z.object({
  startTime: z.string(),
  endTime: z.string(),
});

export const openPatientRequestAuthoringSettingsSchema = z.object({
  mode: archiveAuthoringModeSchema,
  otherPersonID: z.string().max(40000).optional().nullable(),
  supervisorID: z.string().max(40000).optional().nullable(),
  dictationRecordingTime: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .optional()
    .nullable(),
});

export const patientInfoAccessReasonSchema = z.nativeEnum(
  PatientInfoAccessReason,
);

export const outsourcingTypeSchema = z.nativeEnum(OutsourcingType);

export const registryAccessModelSchema = z.nativeEnum(RegistryAccessModel);

export const consentSummarySchema = z.object({
  checkSuccessful: z.boolean(),
  kantaNotificationStatus: kantaNotificationStatusSchema,
  kantaNotificationVersion: z.string().max(40000),
  archiveConsentGiven: z.boolean(),
});

export const authoringSettingsSchema = z.object({
  mode: archiveAuthoringModeSchema,
  otherPerson: contentAuthorSchema.optional().nullable(),
  supervisor: contentAuthorSchema.optional().nullable(),
  dictationRecordingTime: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .optional()
    .nullable(),
});

export const healthcareNatureOfVisitSchema = z.nativeEnum(
  HealthcareNatureOfVisit,
);

export const healthcareContactTypeSchema = z.nativeEnum(HealthcareContactType);

export const openedServiceEventStatisticsSeparatorSchema = z.nativeEnum(
  OpenedServiceEventStatisticsSeparator,
);

export const organizationUnitSchema = z.object({
  oid: z.string().min(1).max(40000),
  type: organizationTypeSchema.optional().nullable(),
  name: z.string().max(40000).optional().nullable(),
  phoneNumber: z.string().max(40000).optional().nullable(),
  phoneNumberType: phoneNumberTypeSchema.optional().nullable(),
  address: addressSchema.optional().nullable(),
  rootOrganizationOID: z.string().max(40000).optional().nullable(),
  rootOrganizationName: z.string().max(40000).optional().nullable(),
});

export const outsourcingAuthorizationProducerWriteAccessSchema = z.nativeEnum(
  OutsourcingAuthorizationProducerWriteAccess,
);

export const outsourcingAuthorizationProducerReadAccessSchema = z.nativeEnum(
  OutsourcingAuthorizationProducerReadAccess,
);

export const pHFHiddenFormSchema = z.object({
  hiddenType: healthcareFormTypeSchema.optional().nullable(),
});

export const pHFMedicalCertificateASchema = z.object({
  primaryDiagnosis: sCNamedCodeSchema.optional().nullable(),
  otherDiagnoses: z.array(sCNamedCodeSchema).optional().nullable(),
  workAccident: z.boolean().optional().nullable(),
  trafficAccident: z.boolean().optional().nullable(),
  leisureTimeAccident: z.boolean().optional().nullable(),
  otherAccident: z.boolean().optional().nullable(),
  otherAccidentDescription: z.string().max(40000).optional().nullable(),
  occupationalDisease: z.boolean().optional().nullable(),
  organDonation: z.boolean().optional().nullable(),
  organDonationDescription: z.string().max(40000).optional().nullable(),
  unableToWorkStartDate: z.string().date().optional().nullable(),
  unableToWorkEndDate: z.string().date().optional().nullable(),
  additionalInformation: z.string().max(40000).optional().nullable(),
  certificateReasonSicknessAllowance: z.boolean().optional().nullable(),
  certificateReasonOther: z.boolean().optional().nullable(),
  certificateReasonOtherExplanation: z
    .string()
    .max(40000)
    .optional()
    .nullable(),
});

export const pHFMedicalCertificateBDiagnosisSchema = z.object({
  code: sCNamedCodeSchema,
  name: z.string().max(40000).optional().nullable(),
});

export const pHFMedicalCertificateDDiagnosisSchema = z.object({
  code: sCNamedCodeSchema,
  name: z.string().max(40000).optional().nullable(),
  date: z.string().date(),
});

export const pHFMileageCertificateSchema = z.object({
  visitDates: z.array(z.string()).optional().nullable(),
});

export const palsoKyselySchema = z.object({
  aikaleima: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
  henkilotunnus: z.string().min(11).max(40000),
  kyselija: kyselijaSchema,
});

export const palsoVastausSchema = z.object({
  henkilonTiedot: henkilonTiedotSchema,
  suorakorvausOikeus: z.boolean().optional().nullable(),
  tyopaikkakassaNimi: z.string().min(1).max(40000).optional().nullable(),
  tyopaikkakassaNro: z.string().min(1).max(40000).optional().nullable(),
});

export const pHFUnknownFormSchema = z.any();

export const pHFMedicalCertificateBSchema = z.object({
  patientHeight: z.string().max(40000).optional().nullable(),
  patientWeight: z.string().max(40000).optional().nullable(),
  patientNativeLanguage: sCNamedCodeSchema.optional().nullable(),
  languageUsedDuringConsultation: sCNamedCodeSchema.optional().nullable(),
  diagnosesForMedicineReimbursement: z
    .array(pHFMedicalCertificateBDiagnosisSchema)
    .optional()
    .nullable(),
  statementRelatedDiagnoses: z
    .array(pHFMedicalCertificateBDiagnosisSchema)
    .optional()
    .nullable(),
  partiallyUnfitForWorkStartDate: z.string().date().optional().nullable(),
  partiallyUnfitForWorkEndDate: z.string().date().optional().nullable(),
  unfitForWorkStartDate: z.string().date().optional().nullable(),
  unfitForWorkEndDate: z.string().date().optional().nullable(),
  recommendedPartialSicknessAllowanceStartDate: z
    .string()
    .date()
    .optional()
    .nullable(),
  recommendedPartialSicknessAllowanceEndDate: z
    .string()
    .date()
    .optional()
    .nullable(),
  recommendedYearsOfServicePensionStartDate: z
    .string()
    .date()
    .optional()
    .nullable(),
});

export const pHFMedicalCertificateDSchema = z.object({
  diagnoses: z
    .array(pHFMedicalCertificateDDiagnosisSchema)
    .optional()
    .nullable(),
});

export const patientStatusSchema = z.nativeEnum(PatientStatus);

export const patientRelatedPersonSchema = z.object({
  firstName: z.string().max(128).optional().nullable(),
  lastName: z.string().max(128).optional().nullable(),
  id: z.string().max(11).optional().nullable(),
});

export const stateChangeTimestampsSchema = z.object({
  createdAt: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
  waiting: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .optional()
    .nullable(),
  open: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .optional()
    .nullable(),
  treated: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .optional()
    .nullable(),
  closed: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .optional()
    .nullable(),
});

export const labTestRequestStatusSchema = z.nativeEnum(LabTestRequestStatus);

export const patientRegistryInfoSchema = z.object({
  isLinkedToUserActiveRegistry: z.boolean(),
  rootOrganizationOID: z.string().max(40000).optional().nullable(),
  rootOrganizationName: z.string().max(40000).optional().nullable(),
});

export const emailStatusSchema = z.nativeEnum(EmailStatus);

export const templateEmailDataSchema = z.object({
  templateId: z.string().min(1).max(40000),
  variables: z.record(z.string()).optional(),
});

export const patientExternalReqSchema = z.object({
  idType: personIdentifierTypeSchema,
  gender: genderSchema,
  pmsid: z.string().uuid().optional().nullable(),
  firstName: z.string().min(1).max(128),
  friendlyName: z.string().max(128).optional().nullable(),
  lastName: z.string().min(1).max(128),
  preferredLanguage: z.string().max(2).optional().nullable(),
  mobilePhoneNumber: z.string().max(19).optional().nullable(),
  address: addressSchema.optional().nullable(),
  municipalityOfResidence: sCNamedCodeSchema.optional().nullable(),
  profession: z.string().max(255).optional().nullable(),
  jobDescription: z.string().max(255).optional().nullable(),
  employerName: z.string().max(255).optional().nullable(),
  insuranceCompanyName: z.string().max(255).optional().nullable(),
  email: z.string().max(320).optional().nullable(),
  preliminaryInfo: z.string().max(255).optional().nullable(),
  preliminaryDescription: z.string().max(4000).optional().nullable(),
  primaryGuardian: patientRelatedPersonSchema.optional().nullable(),
  secondaryGuardian: patientRelatedPersonSchema.optional().nullable(),
  id: z.string().min(11).max(11),
  preliminaryDescriptionNoQueue: z.string().max(4000).optional().nullable(),
  queueGuids: z.array(z.string()),
  externalData: z.string().min(1).max(40000),
  sendingCompanyId: z.string().uuid(),
});

export const patientPreliminaryImageFullHdSchema = z.object({
  mimeType: z.string().min(1).max(40000),
  filesize: z.number(),
  width: z.number(),
  height: z.number(),
});

export const patientPreliminaryImageThumbnailSchema = z.object({
  mimeType: z.string().min(1).max(40000),
  imageBase64: z.string().min(1).max(2147483647),
  filesize: z.number(),
  width: z.number(),
  height: z.number(),
});

export const priceTypeSchema = z.nativeEnum(PriceType);

export const patientQueueUserSettingsSchema = z.object({
  isVisible: z.boolean(),
  getNotifications: z.boolean(),
  notificationScheduleDays: notificationScheduleDaysSchema,
  notificationScheduleHours: notificationScheduleHoursSchema
    .optional()
    .nullable(),
  smsNotification: z.boolean(),
  phoneNumber: z.string().max(19).optional().nullable(),
  emailNotification: z.boolean(),
  emailAddress: z.string().max(320).optional().nullable(),
  webhookNotification: z.boolean(),
  webhookUrl: z.string().max(2048).optional().nullable(),
  uiNotification: z.boolean(),
});

export const patientStateStatisticsSeparatorSchema = z.nativeEnum(
  PatientStateStatisticsSeparator,
);

export const patientStateTimedStatisticsSeparatorSchema = z.nativeEnum(
  PatientStateTimedStatisticsSeparator,
);

export const patientStatisticsEventTypeSchema = z.nativeEnum(
  PatientStatisticsEventType,
);

export const patientTimeToTreatedStatisticsSeparatorSchema = z.nativeEnum(
  PatientTimeToTreatedStatisticsSeparator,
);

export const phraseTypeSchema = z.nativeEnum(PhraseType);

export const phraseCategorySchema = z.nativeEnum(PhraseCategory);

export const pingMessageSchema = z.object({
  messageType: websocketMessageTypeSchema,
  timeStamp: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
});

export const portInformationRequestSchema = z.object({
  scSessionID: z.string().min(1).max(100),
  scPort: z.string().min(1).max(10),
});

export const healthcareFormPrefillModeSchema = z.nativeEnum(
  HealthcareFormPrefillMode,
);

export const partialHealthcareFormContainerSchema = z.object({
  type: healthcareFormTypeSchema,
  unknownForm: pHFUnknownFormSchema.optional().nullable(),
  medicalCertificateA: pHFMedicalCertificateASchema.optional().nullable(),
  medicalCertificateB: pHFMedicalCertificateBSchema.optional().nullable(),
  medicalCertificateD: pHFMedicalCertificateDSchema.optional().nullable(),
  mileageCertificate: pHFMileageCertificateSchema.optional().nullable(),
  hiddenForm: pHFHiddenFormSchema.optional().nullable(),
});

export const prescriptionPrefillModeSchema = z.nativeEnum(
  PrescriptionPrefillMode,
);

export const drugProductTypeSchema = z.nativeEnum(DrugProductType);

export const medicalTreatmentChangeReasonSchema = z.nativeEnum(
  MedicalTreatmentChangeReason,
);

export const prefillSV120HealthcareFormResponseSchema = z.object({
  data: z.string().min(1).max(2147483647),
});

export const prefillSV132HealthcareFormRequestSchema = z.object({
  dateFrom: z.string().date(),
  dateTo: z.string().date(),
});

export const prescriptionStateSchema = z.nativeEnum(PrescriptionState);

export const prescriptionReservationStatusSchema = z.nativeEnum(
  PrescriptionReservationStatus,
);

export const prescriptionDispensationStatusSchema = z.nativeEnum(
  PrescriptionDispensationStatus,
);

export const prescriptionLockStatusSchema = z.nativeEnum(
  PrescriptionLockStatus,
);

export const prescriptionRenewalStatusSchema = z.nativeEnum(
  PrescriptionRenewalStatus,
);

export const prescriptionDrugDataSchema = z.object({
  type: drugProductTypeSchema,
  atcClass: sCNamedCodeSchema.optional().nullable(),
  productNumber: z.string().max(40000).optional().nullable(),
  name: z.string().max(40000).optional().nullable(),
  strength: z.string().max(40000).optional().nullable(),
  dosageForm: z.string().max(40000).optional().nullable(),
  container: z.string().max(40000).optional().nullable(),
  device: z.string().max(40000).optional().nullable(),
  company: z.string().max(40000).optional().nullable(),
  drugTypeCode: z.string().max(40000).optional().nullable(),
  drugTypeName: z.string().max(40000).optional().nullable(),
  manufacturingInstructions: z.string().max(40000).optional().nullable(),
  description: z.string().max(40000).optional().nullable(),
});

export const prescriptionDrugIngredientDataSchema = z.object({
  isActiveIngredient: z.boolean(),
  atcClass: sCNamedCodeSchema.optional().nullable(),
  name: z.string().max(40000).optional().nullable(),
  strength: valueUnitSchema.optional().nullable(),
  strengthAsText: z.string().max(40000).optional().nullable(),
});

export const narcoticTypeSchema = z.nativeEnum(NarcoticType);

export const patientIdentificationTypeSchema = z.nativeEnum(
  PatientIdentificationType,
);

export const prescriptionProductTypeSchema = z.nativeEnum(
  PrescriptionProductType,
);

export const prescriptionTypeSchema = z.nativeEnum(PrescriptionType);

export const pharmacyCreatedPrescriptionTypeSchema = z.nativeEnum(
  PharmacyCreatedPrescriptionType,
);

export const pharmacyCreatedPrescriptionReasonSchema = z.nativeEnum(
  PharmacyCreatedPrescriptionReason,
);

export const yesNoNotKnownValueSchema = z.nativeEnum(YesNoNotKnownValue);

export const drugQuantityTypeSchema = z.nativeEnum(DrugQuantityType);

export const prescriptionDrugQuantityDataSchema = z.object({
  quantityType: drugQuantityTypeSchema,
  packageText: z.string().max(40000).optional().nullable(),
  packageSizeMultiplier: z.string().max(40000).optional().nullable(),
  packageSize: valueUnitSchema.optional().nullable(),
  packageCount: z.string().max(40000).optional().nullable(),
  totalQuantity: valueUnitSchema.optional().nullable(),
  treatmentDuration: valueUnitSchema.optional().nullable(),
});

export const prescriptionValidityStatusSchema = z.nativeEnum(
  PrescriptionValidityStatus,
);

export const prescriptionInvalidityReasonSchema = z.nativeEnum(
  PrescriptionInvalidityReason,
);

export const dispensationDrugQuantityDataSchema = z.object({
  type: dispensationQuantityValueTypeSchema.optional().nullable(),
  value: valueUnitNullableSchema.optional().nullable(),
  expression: z.string().max(40000).optional().nullable(),
  text: z.string().max(40000).optional().nullable(),
});

export const prescriptionInfoSchema = z.object({
  prescriptionCreationTime: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .optional()
    .nullable(),
  prescriptionName: z.string().max(40000).optional().nullable(),
  prescriptionLongName: z.string().max(40000).optional().nullable(),
  prescriptionProductNumber: z.string().max(40000).optional().nullable(),
  prescriptionState: prescriptionStateSchema.optional().nullable(),
  archiveEntrySaved: z.boolean().optional().nullable(),
  userFirstName: z.string().max(40000).optional().nullable(),
  userLastName: z.string().max(40000).optional().nullable(),
  userRegNumber: z.string().max(40000).optional().nullable(),
  userId: z.number().optional().nullable(),
});

export const prescriptionMessageSchema = z.object({
  messageType: websocketMessageTypeSchema,
  timeStamp: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
  modificationType: modificationTypeSchema,
  prescriptionName: z.string().max(40000).optional().nullable(),
  prescriptionStrength: z.string().max(40000).optional().nullable(),
  prescriptionQuantityData: prescriptionDrugQuantityDataSchema
    .optional()
    .nullable(),
});

export const allowedPrescriptionOperationResultSchema = z.nativeEnum(
  AllowedPrescriptionOperationResult,
);

export const prescriptionStatisticsSeparatorSchema = z.nativeEnum(
  PrescriptionStatisticsSeparator,
);

export const prescriptionTemplateSchema = z.object({
  id: z.string().uuid().optional().nullable(),
  name: z.string().max(100).optional().nullable(),
  vnr: z.string().max(100).optional().nullable(),
  duodecimId: z.string().max(100).optional().nullable(),
  quantityType: drugQuantityTypeSchema.optional().nullable(),
  packageSize: z.string().max(100).optional().nullable(),
  packageCount: z.string().max(100).optional().nullable(),
  amount: z.string().max(100).optional().nullable(),
  unit: z.string().max(100).optional().nullable(),
  dosageInstructions: z.string().max(1000).optional().nullable(),
  intendedUseDescription: z.string().max(1000).optional().nullable(),
  type: drugProductTypeSchema.optional().nullable(),
  longName: z.string().max(200).optional().nullable(),
  templateName: z.string().max(100).optional().nullable(),
});

export const prescriptionDosageReportingTypeSchema = z.nativeEnum(
  PrescriptionDosageReportingType,
);

export const prescriptionTemplateRequestSchema = z.object({
  template: prescriptionTemplateSchema,
});

export const prescriptionTemplateResponseSchema = z.object({
  template: prescriptionTemplateSchema,
});

export const prescriptionTemplatesResponseSchema = z.object({
  templates: z.array(prescriptionTemplateSchema),
});

export const queueWaitTimeResponseSchema = z.object({
  queueId: z.string().uuid(),
  waitingPatientCount: z.number(),
  maximumWaitTime: z.number(),
});

export const redirectResponseSchema = z.object({
  url: z.string().min(1).max(40000),
});

export const referralUrgencySchema = z.nativeEnum(ReferralUrgency);

export const referralEmploymentSchema = z.nativeEnum(ReferralEmployment);

export const referralSummarySchema = z.object({
  referralId: z.string().uuid(),
  createdTS: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
  diagnosis: z.string().max(40000).optional().nullable(),
  urgency: referralUrgencySchema.optional().nullable(),
  receivingFacilityName: z.string().max(40000).optional().nullable(),
});

export const accessTokenTypeSchema = z.nativeEnum(AccessTokenType);

export const sessionStatusSchema = z.nativeEnum(SessionStatus);

export const archiveRegistryTypeSchema = z.nativeEnum(ArchiveRegistryType);

export const chatterRoleSchema = z.nativeEnum(ChatterRole);

export const roomMemberSchema = z.object({
  firstName: z.string().min(1).max(128),
  lastName: z.string().min(1).max(128),
  role: chatterRoleSchema,
  status: chatterStatusSchema,
  guid: z.string().uuid(),
  ssid: z.string().max(40000).optional().nullable(),
});

export const listedMessageSchema = z.object({
  messageId: z.string().uuid(),
  contentType: chatMessageContentTypeSchema,
  content: z.string().min(1).max(40000),
  createdTimestamp: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .optional()
    .nullable(),
  senderFirstName: z.string().max(128).optional().nullable(),
  senderLastName: z.string().max(128).optional().nullable(),
  senderGuid: z.string().uuid().optional().nullable(),
  status: chatMessageDeliveryStatusSchema,
});

export const sCCodeSystemCodeSchema = z.object({
  codeSystemOID: z.string().min(1).max(40000),
  codeSystemName: z.string().min(1).max(40000),
  code: z.string().min(1).max(40000),
  name: z.string().min(1).max(40000),
});

export const sCDiagnosisEpisodeSchema = z.object({
  oid: z.string().min(1).max(40000),
  name: z.string().max(40000).nullable(),
});

export const informationSourceSchema = z.nativeEnum(InformationSource);

export const healthInformationChangeReasonSchema = z.nativeEnum(
  HealthInformationChangeReason,
);

export const sCDiagnosis2Schema = z.object({
  code: sCNamedCodeSchema,
  name: z.string().max(40000).optional().nullable(),
  primarity: diagnosisPrimaritySchema.nullable(),
  externalReasonCode: sCNamedCodeSchema.optional().nullable(),
  injuryTypeCode: sCNamedCodeSchema.optional().nullable(),
  injuryExcerciseType: sCNamedCodeSchema.optional().nullable(),
  adverseEffectReasonCode: sCNamedCodeSchema.optional().nullable(),
  icD10RelationCode: sCNamedCodeSchema.optional().nullable(),
  certainty: certaintySchema.optional().nullable(),
  permanence: permanenceSchema.nullable(),
  episodes: z.array(sCDiagnosisEpisodeSchema).optional().nullable(),
  source: informationSourceSchema.optional().nullable(),
  startTime: z.string().date().nullable(),
  endTime: z.string().date().optional().nullable(),
  isAuthorNotDoctor: z.boolean().optional().nullable(),
  author: contentAuthorSchema.optional().nullable(),
  authorOrganization: contentProducerOrganizationSchema.optional().nullable(),
  conclusionAuthor: contentAuthorSchema.optional().nullable(),
  conclusionAuthorOrganization: contentProducerOrganizationSchema
    .optional()
    .nullable(),
  conclusionReason: healthInformationChangeReasonSchema.optional().nullable(),
  conclusionDescription: z.string().max(40000).optional().nullable(),
});

export const structuredContentTypeSchema = z.nativeEnum(StructuredContentType);

export const sCLabTestOperationSchema = z.object({
  code: sCNamedCodeSchema,
  abbreviation: z.string().min(0).max(40000),
  description: z.string().min(1).max(40000).nullable(),
});

export const sCLabTestOperation2Schema = z.object({
  code: sCNamedCodeSchema.optional().nullable(),
  codeSystemOperation: sCCodeSystemCodeSchema.optional().nullable(),
  additionalInformation: z.string().max(40000).optional().nullable(),
});

export const sCLabTestReferenceValueSchema = z.object({
  low: valueUnitSchema.optional().nullable(),
  high: valueUnitSchema.optional().nullable(),
  referenceAsText: z.string().max(40000).optional().nullable(),
  target: z.string().max(40000).optional().nullable(),
});

export const sCLabTestReportSchema = z.object({
  report: z.string().min(1).max(40000),
});

export const reportStatusSchema = z.nativeEnum(ReportStatus);

export const archiveLabTestOperationStateSchema = z.nativeEnum(
  ArchiveLabTestOperationState,
);

export const sCLabTestRequest2Schema = z.object({
  requestIdentifier: z.string().max(40000).optional().nullable(),
  time: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
  requester: contentAuthorSchema.optional().nullable(),
  requesterOrganization: contentProducerOrganizationSchema
    .optional()
    .nullable(),
  additionalInformation: z.string().max(40000).optional().nullable(),
  operations: z.array(sCLabTestOperation2Schema),
  specimenType: sCNamedCodeSchema.optional().nullable(),
  repetitionStartDate: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .optional()
    .nullable(),
  repetitionEndDate: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .optional()
    .nullable(),
  repetitionMaxCount: z.number().optional().nullable(),
});

export const labTestResultStateSchema = z.nativeEnum(LabTestResultState);

export const archiveLabResultTypeSchema = z.nativeEnum(ArchiveLabResultType);

export const archiveLabResultInterpretationCodeSchema = z.nativeEnum(
  ArchiveLabResultInterpretationCode,
);

export const measurementMethodSchema = z.nativeEnum(MeasurementMethod);

export const microbeStudyTypeSchema = z.nativeEnum(MicrobeStudyType);

export const microbeQuantitySchema = z.nativeEnum(MicrobeQuantity);

export const riskLevelSchema = z.nativeEnum(RiskLevel);

export const aBOBloodGroupSchema = z.nativeEnum(ABOBloodGroup);

export const rhDBloodGroupSchema = z.nativeEnum(RhDBloodGroup);

export const sCNamedCodeSCRiskInfo2Schema = z.object({
  type: codedSystemTypeSchema,
  code: z.string().min(1).max(255).nullable(),
  name: z.string().max(40000).optional().nullable(),
});

export const microbeMethodSchema = z.nativeEnum(MicrobeMethod);

export const microbeSensitivitySchema = z.nativeEnum(MicrobeSensitivity);

export const microbialDrugSensitivityTestMethodSchema = z.nativeEnum(
  MicrobialDrugSensitivityTestMethod,
);

export const sCTextContentSchema = z.object({
  simpleText: z.string().max(40000),
});

export const toothSurfaceSchema = z.nativeEnum(ToothSurface);

export const archiveTreatmentStateSchema = z.nativeEnum(ArchiveTreatmentState);

export const archiveTreatmentTypeSchema = z.nativeEnum(ArchiveTreatmentType);

export const archiveTreatmentOperatingPersonRoleSchema = z.nativeEnum(
  ArchiveTreatmentOperatingPersonRole,
);

export const sCToothFaceSchema = z.object({
  surface: toothSurfaceSchema,
  gingivalInterface: z.boolean(),
});

export const prostheticToothTypeSchema = z.nativeEnum(ProstheticToothType);

export const vaccinationAdministrationMethodSchema = z.nativeEnum(
  VaccinationAdministrationMethod,
);

export const vaccinationInjectionSiteSchema = z.nativeEnum(
  VaccinationInjectionSite,
);

export const sCVaccineReactionDiagnosisSchema = z.object({
  primarity: diagnosisPrimaritySchema,
  certainty: certaintySchema.optional().nullable(),
  permanence: permanenceSchema,
  code: sCNamedCodeSchema,
  description: z.string().min(1).max(40000),
  time: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
});

export const sCVaccineReactionDiagnosis2Schema = z.object({
  code: sCNamedCodeSchema,
  time: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
});

export const tariffCodeSchema = z.nativeEnum(TariffCode);

export const saveHealthcareFormRequestSchema = z.object({
  type: dPlatformHealthcareFormTypeSchema,
  data: z.string().min(1).max(40000),
});

export const saveHealthcareFormResponseSchema = z.object({
  id: z.string().uuid(),
  type: dPlatformHealthcareFormTypeSchema,
  sV120DocumentData: sV120DocumentDataSchema.optional().nullable(),
  createdTS: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
  updatedTS: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .optional()
    .nullable(),
  data: z.string().max(40000).optional().nullable(),
});

export const searchPatientsRequestSchema = z.object({
  since: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .optional()
    .nullable(),
  getAllForStatuses: z.array(patientStatusSchema).optional().nullable(),
  socialSecurityIds: z.array(z.string()).optional().nullable(),
});

export const sendEmailRequestSchema = z.object({
  title: z.string().min(1).max(40000),
  toEmailAddress: z.string().max(40000).optional().nullable(),
  emailData: emailDataSchema.optional().nullable(),
  templateEmailData: templateEmailDataSchema.optional().nullable(),
});

export const patientCommunicationEmailSchema = z.object({
  guid: z.string().uuid(),
  title: z.string().min(1).max(40000),
  status: emailStatusSchema,
  statusTS: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
  createdTS: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
  fromEmailAddress: z.string().min(1).max(40000),
  toEmailAddress: z.string().min(1).max(40000),
  emailData: emailDataSchema.optional().nullable(),
  templateEmailData: templateEmailDataSchema.optional().nullable(),
});

export const sendSmsMessageRequestSchema = z.object({
  body: z.string().min(1).max(1600),
  receivers: z.array(z.string()),
  dryRun: z.boolean().optional().nullable(),
});

export const registrySettingsSchema = z.object({
  ownerOID: z.string().min(1).max(40000),
  ownerName: z.string().min(1).max(40000),
  type: archiveRegistryTypeSchema,
  extensionID: z.string().max(40000).optional().nullable(),
  extensionName: z.string().max(40000).optional().nullable(),
});

export const serviceEventStatusSchema = z.nativeEnum(ServiceEventStatus);

export const serviceEventMessageSchema = z.object({
  messageType: websocketMessageTypeSchema,
  timeStamp: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
  modificationType: modificationTypeSchema,
});

export const sessionConfirmUnlockRequestSchema = z.object({
  smartCardResponseData: z.string().min(1).max(40000),
});

export const sessionInitializeUnlockRequestSchema = z.object({
  smartCardDriverPort: z.string().max(40000).optional().nullable(),
});

export const sessionInitializeUnlockResponseSchema = z.object({
  unlockUrl: z.string().min(1).max(40000),
  unlockCommandData: z.string().min(1).max(40000),
});

export const sessionStatisticsSeparatorSchema = z.nativeEnum(
  SessionStatisticsSeparator,
);

export const sessionStatusMessageSchema = z.object({
  messageType: websocketMessageTypeSchema,
  timeStamp: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
  newStatus: sessionStatusSchema,
});

export const signPrescriptionsResponseSchema = z.object({
  signEventID: z.string().min(1).max(40000),
  signatureCommandData: z.string().min(1).max(40000),
});

export const simplePatientTemplateSchema = z.object({
  firstName: z.string().min(1).max(128),
  friendlyName: z.string().max(128).optional().nullable(),
  lastName: z.string().min(1).max(128),
  idType: personIdentifierTypeSchema,
  id: z.string().max(63).optional().nullable(),
  idCreationYear: z.number().optional().nullable(),
  temporaryIDRoot: z.string().max(64).optional().nullable(),
  dateOfBirth: z.string().date().optional().nullable(),
});

export const simplePhraseSchema = z.object({
  name: z.string().min(1).max(100),
  value: z.string().min(1).max(65000),
  type: phraseTypeSchema,
  isShared: z.boolean(),
});

export const smSMessageStatisticsSeparatorSchema = z.nativeEnum(
  SmSMessageStatisticsSeparator,
);

export const smsMessageStatusSchema = z.nativeEnum(SmsMessageStatus);

export const loginConfirmOrganisationUnitSchema = z.object({
  oid: z.string().min(1).max(40000),
  type: organizationTypeSchema,
});

export const loginConfirmUserRightSchema = z.object({
  object: userRightSystemAccessObjectSchema,
  action: userRightSystemAccessActionSchema,
  scope: userRightSystemAccessScopeSchema,
});

export const userRightExternalSystemSchema = z.object({
  object: z.string().max(40000).optional().nullable(),
  action: z.string().max(40000).optional().nullable(),
  displayText: z.string().max(40000).optional().nullable(),
});

export const loginTypeSchema = z.nativeEnum(LoginType);

export const smartCardLoginResponseSchema = z.object({
  loginUrl: z.string().min(1).max(40000),
  eventId: z.string().min(1).max(40000),
  loginCommandData: z.string().min(1).max(40000),
  eraScPort: z.string().max(40000).optional().nullable(),
});

export const smartCardLoginValidateRequestSchema = z.object({
  eventId: z.string().min(1).max(40000),
  smartCardResponseData: z.string().min(1).max(40000),
  getEncryptedRegNumber: z.boolean().optional().nullable(),
});

export const userInfoSchema = z.object({
  firstName: z.string().min(1).max(40000),
  lastName: z.string().min(1).max(40000),
  regNumber: z.string().min(1).max(40000),
  cardBeginDate: z.string().date(),
  cardEndDate: z.string().date(),
  latestOrganizationOID: z.string().max(40000).optional().nullable(),
  rememberOrganization: z.boolean(),
  registrationStatus: registrationStatusSchema,
  profilePhoneNumber: z.string().max(40000).optional().nullable(),
  profileEmail: z.string().max(40000).optional().nullable(),
  encryptedRegNumber: z.string().max(40000).optional().nullable(),
});

export const smsMessageDirectionSchema = z.nativeEnum(SmsMessageDirection);

export const smsMessageSenderSchema = z.object({
  firstName: z.string().min(1).max(40000),
  lastName: z.string().min(1).max(40000),
});

export const errorResponseSchema = z.object({
  type: errorTypeSchema,
  title: z.string().max(40000).optional().nullable(),
  detail: z.string().max(40000).optional().nullable(),
  technicalDetail: z.string().max(40000).optional().nullable(),
  externalErrorCode: z.number().optional().nullable(),
  traceId: z.string().max(40000).optional().nullable(),
});

export const smsMessageReceiverSchema = z.object({
  sentTS: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .optional()
    .nullable(),
  updatedTS: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .optional()
    .nullable(),
  to: z.string().min(1).max(40000),
  sid: z.string().min(1).max(40000),
  status: smsMessageStatusSchema,
});

export const smsMessageSimpleSchema = z.object({
  patientGuid: z.string().uuid(),
  direction: smsMessageDirectionSchema,
  from: z.string().max(40000).optional().nullable(),
  body: z.string().min(1).max(40000),
  batchID: z.string().uuid(),
  sender: smsMessageSenderSchema,
});

export const ssoRedirectRequestSchema = z.object({
  target_url: z.string().min(1).max(40000),
});

export const statisticsUserInfoSchema = z.object({
  userId: z.number(),
  firstName: z.string().min(1).max(40000),
  lastName: z.string().min(1).max(40000),
  regNumber: z.string().min(1).max(40000),
});

export const statisticsServiceUnitInfoSchema = z.object({
  oid: z.string().min(1).max(40000),
  name: z.string().min(1).max(40000),
  rootOrganizationOID: z.string().min(1).max(40000),
  rootOrganizationName: z.string().min(1).max(40000),
});

export const sessionTerminationReasonSchema = z.nativeEnum(
  SessionTerminationReason,
);

export const contentEditModeSchema = z.nativeEnum(ContentEditMode);

export const sCLabTestRequestSchema = z.object({
  state: archiveLabTestOperationStateSchema.nullable(),
  time: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .nullable(),
  description: z.string().min(1).max(40000).nullable(),
  isMainLabTest: z.boolean(),
  operations: z.array(sCLabTestOperationSchema),
});

export const sCLabTestReport2Schema = z.object({
  status: reportStatusSchema,
  resultIdentifier: z.string().min(1).max(40000),
  time: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
  author: contentAuthorSchema.optional().nullable(),
  organization: contentProducerOrganizationSchema.optional().nullable(),
  operation: sCNamedCodeSchema.optional().nullable(),
  codeSystemOperation: sCCodeSystemCodeSchema.optional().nullable(),
  specimenType: sCNamedCodeSchema.optional().nullable(),
  report: z.string().min(1).max(40000),
});

export const sCDiagnosisSchema = z.object({
  code: sCNamedCodeSchema,
  name: z.string().max(40000).optional().nullable(),
  primarity: diagnosisPrimaritySchema,
  externalReasonCode: sCNamedCodeSchema.optional().nullable(),
  externalReasonDescription: z.string().max(40000).optional().nullable(),
  injuryTypeCode: sCNamedCodeSchema.optional().nullable(),
  injuryTypeDescription: z.string().max(40000).optional().nullable(),
  adverseEffectReasonCode: sCNamedCodeSchema.optional().nullable(),
  adverseEffectReasonDescription: z.string().max(40000).optional().nullable(),
  icD10RelationCode: sCNamedCodeSchema.optional().nullable(),
  certainty: certaintySchema.optional().nullable(),
  permanence: permanenceSchema.nullable(),
  episodes: z.array(sCDiagnosisEpisodeSchema).optional().nullable(),
  source: informationSourceSchema.optional().nullable(),
  startTime: z.string().date(),
  endTime: z.string().date().optional().nullable(),
  author: contentAuthorSchema.optional().nullable(),
  authorOrganization: contentProducerOrganizationSchema.optional().nullable(),
  conclusionAuthor: contentAuthorSchema.optional().nullable(),
  conclusionAuthorOrganization: contentProducerOrganizationSchema
    .optional()
    .nullable(),
  conclusionReason: healthInformationChangeReasonSchema.optional().nullable(),
  conclusionDescription: z.string().max(40000).optional().nullable(),
});

export const sCRiskInfoSchema = z.object({
  description: z.string().max(40000),
  code: sCNamedCodeSchema.optional().nullable(),
  level: riskLevelSchema,
  typeCode: sCNamedCodeSchema,
  certainty: certaintySchema.optional().nullable(),
  permanence: permanenceSchema.optional().nullable(),
  informationSource: informationSourceSchema.optional().nullable(),
  additionalInfoDescription: z.string().max(40000).optional().nullable(),
  additionalInfoCode: sCNamedCodeSchema.optional().nullable(),
  aboBloodGroup: aBOBloodGroupSchema.optional().nullable(),
  rhDBloodGroup: rhDBloodGroupSchema.optional().nullable(),
  instructionsForTreatment: z.string().max(40000).optional().nullable(),
  startTime: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
  endTime: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .optional()
    .nullable(),
  author: contentAuthorSchema.optional().nullable(),
  organization: contentProducerOrganizationSchema.optional().nullable(),
  conclusionReason: healthInformationChangeReasonSchema.optional().nullable(),
  conclusionExplanation: z.string().max(40000).optional().nullable(),
  conclusionAuthor: contentAuthorSchema.optional().nullable(),
  conclusionAuthorOrganization: contentProducerOrganizationSchema
    .optional()
    .nullable(),
});

export const sCRiskInfo2Schema = z.object({
  oid: z.string().max(40000).optional().nullable(),
  description: z.string().min(1).max(40000),
  code: sCNamedCodeSCRiskInfo2Schema,
  level: riskLevelSchema,
  typeCode: sCNamedCodeSchema,
  certainty: certaintySchema.optional().nullable(),
  permanence: permanenceSchema.optional().nullable(),
  informationSource: informationSourceSchema.optional().nullable(),
  additionalInfoDescription: z.string().max(40000).optional().nullable(),
  additionalInfoCode: sCNamedCodeSchema.optional().nullable(),
  instructionsForTreatment: z.string().max(40000).optional().nullable(),
  startTime: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .optional()
    .nullable(),
  endTime: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .optional()
    .nullable(),
  isEstimatedEndTime: z.boolean().optional().nullable(),
  author: contentAuthorSchema.optional().nullable(),
  organization: contentProducerOrganizationSchema.optional().nullable(),
  conclusionReason: healthInformationChangeReasonSchema.optional().nullable(),
  conclusionExplanation: z.string().max(40000).optional().nullable(),
  conclusionAuthor: contentAuthorSchema.optional().nullable(),
  conclusionAuthorOrganization: contentProducerOrganizationSchema
    .optional()
    .nullable(),
});

export const sCVaccinationSchema = z.object({
  vnrNumber: z.string().min(1).max(40000).nullable(),
  vaccineName: z.string().max(40000).optional().nullable(),
  atcCode: sCNamedCodeSchema.optional().nullable(),
  packageBatchNumber: z.string().min(1).max(40000),
  dosageNumber: z.string().max(40000).optional().nullable(),
  dosageValue: z.string().max(40000),
  dosageUnit: z.string().max(40000),
  administrationMethod: vaccinationAdministrationMethodSchema,
  injectionSite: vaccinationInjectionSiteSchema.optional().nullable(),
  performer: contentAuthorSchema.optional().nullable(),
  performerOrganization: contentProducerOrganizationSchema
    .optional()
    .nullable(),
  vaccinationTime: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
  sideEffects: z.array(sCVaccineReactionDiagnosisSchema).optional().nullable(),
  vaccineNotGiven: z.boolean(),
});

export const sCVaccination2Schema = z.object({
  oid: z.string().max(40000).optional().nullable(),
  atcCode: sCNamedCodeSchema.optional().nullable(),
  vaccineName: z.string().max(40000).optional().nullable(),
  vnrNumber: z.string().max(40000).optional().nullable(),
  packageBatchNumber: z.string().max(40000).optional().nullable(),
  immunizations: z.array(sCNamedCodeSchema).optional().nullable(),
  immunizationSpecifier: z.string().max(40000).optional().nullable(),
  dosageNumber: z.number().optional().nullable(),
  dosage: valueUnitSchema.optional().nullable(),
  administrationMethod: vaccinationAdministrationMethodSchema
    .optional()
    .nullable(),
  injectionSite: vaccinationInjectionSiteSchema.optional().nullable(),
  performer: contentAuthorSchema.optional().nullable(),
  performerOrganization: contentProducerOrganizationSchema
    .optional()
    .nullable(),
  vaccinationTime: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
  sideEffects: z.array(sCVaccineReactionDiagnosis2Schema).optional().nullable(),
  sideEffectExternalCause: sCNamedCodeSchema.optional().nullable(),
  additionalInformation: z.string().max(40000).optional().nullable(),
  source: informationSourceSchema.optional().nullable(),
  isResearchVaccine: z.boolean(),
  researchVaccineIDs: z.array(z.string()).optional().nullable(),
});

export const sCHiddenContentSchema = z.object({
  hiddenType: structuredContentTypeSchema,
});

export const valueUnitRangeSchema = z.object({
  valueUnitMin: valueUnitSchema.optional().nullable(),
  valueUnitMax: valueUnitSchema.optional().nullable(),
  valueUnit: valueUnitSchema.optional().nullable(),
});

export const drugDoseDataSchema = z.object({
  dose: valueUnitRangeSchema.optional().nullable(),
  administrationUnit: sCNamedCodeSchema.optional().nullable(),
  physicalDose: valueUnitRangeSchema.optional().nullable(),
  asNeeded: z.boolean(),
  timeOfDay: timeOfDaySchema.optional().nullable(),
  time: z.string().max(40000).optional().nullable(),
  dayOfWeek: dayOfWeekSchema.optional().nullable(),
});

export const timedStatisticsResponseSchema = z.object({
  queues: z.array(patientQueueSchema).optional().nullable(),
  users: z.array(statisticsUserInfoSchema).optional().nullable(),
  results: z.array(groupedTimedStatisticsResponseSchema),
});

export const unsentPrescriptionCountResponseSchema = z.object({
  prescriptionCount: z.number(),
});

export const updateApiKeyRequestSchema = z.object({
  validUntil: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .optional()
    .nullable(),
  allowedIpAddresses: z.array(z.string()).optional(),
  allowedQueues: z.array(z.string()).optional(),
});

export const updateApiKeyResponseSchema = z.object({
  item: aPIKeySchema,
});

export const updateEntryStatusRequestSchema = z.object({
  oid: z.string().min(1).max(40000),
  status: entryStatusSchema,
});

export const updateExternalCompanyRequestSchema = z.object({
  name: z.string().max(255).optional(),
  companyId: z.string().max(255).optional().nullable(),
  oid: z.string().max(255).optional().nullable(),
  sms: z.string().max(19).optional().nullable(),
  email: z.string().max(255).optional().nullable(),
  chatUrl: z.string().max(2048).optional().nullable(),
  feedbackSurveyUrl: z.string().max(2048).optional().nullable(),
  feedbackSurveyDelayInHours: z.number().optional().nullable(),
  mailgunBaseUrl: z.string().max(2048).optional().nullable(),
  mailgunApiKey: z.string().max(256).optional().nullable(),
});

export const updateExternalCompanyResponseSchema = z.object({
  item: externalCompanySchema,
});

export const updateHealthcareFormRequestSchema = z.object({
  data: z.string().min(1).max(40000),
});

export const healthcareFormSchema = z.object({
  id: z.string().uuid(),
  type: dPlatformHealthcareFormTypeSchema,
  sV120DocumentData: sV120DocumentDataSchema.optional().nullable(),
  createdTS: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
  updatedTS: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .optional()
    .nullable(),
  data: z.string().max(40000).optional().nullable(),
});

export const updateMessageStatusMessageSchema = z.object({
  messageType: websocketMessageTypeSchema,
  timeStamp: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
  messageId: z.string().uuid(),
  roomId: z.string().uuid().optional().nullable(),
  status: chatMessageDeliveryStatusSchema.optional().nullable(),
});

export const updateNotificationIsReadRequestSchema = z.object({
  isRead: z.boolean(),
  notificationIds: z.array(z.string()),
});

export const openPatientRequestOutsourcingSettingsSchema = z.object({
  type: outsourcingTypeSchema,
  authorizationOID: z.string().min(1).max(40000),
});

export const openPatientRequestRegistrySettingsSchema = z.object({
  accessModel: registryAccessModelSchema,
  occupationalHealthcareClientID: z.string().max(40000).optional().nullable(),
});

export const updatePatientNamesPatientOpenParamsSchema = z.object({
  usePopulationRegistry: z.boolean().optional().nullable(),
  outsourcing: openPatientRequestOutsourcingSettingsSchema
    .optional()
    .nullable(),
  authoring: openPatientRequestAuthoringSettingsSchema,
  registry: openPatientRequestRegistrySettingsSchema,
  accessReason: patientInfoAccessReasonSchema,
  accessReasonDescription: z.string().max(40000).optional().nullable(),
});

export const patientQueueCostsInfoSchema = z.object({
  priceType: priceTypeSchema,
  price: z.number().optional().nullable(),
  minPrice: z.number().optional().nullable(),
  maxPrice: z.number().optional().nullable(),
  description: z.string().max(500).optional().nullable(),
});

export const updatePatientQueueResponseSchema = z.object({
  name: z.string().min(1).max(100),
  responseTimeSeconds: z.number().optional().nullable(),
  connectionMethod: z.string().max(100).optional().nullable(),
  isFifo: z.boolean(),
  isPublic: z.boolean(),
  description: z.string().max(1000).optional().nullable(),
  hasGenderRestriction: z.boolean(),
  genderRestriction: genderSchema.optional().nullable(),
  hasAgeLimit: z.boolean(),
  minAge: z.number().optional().nullable(),
  maxAge: z.number().optional().nullable(),
  costs: patientQueueCostsInfoSchema.optional().nullable(),
  chatEnabled: z.boolean().optional().nullable(),
  permittedUsers: z.array(patientQueueInfoUserSchema),
  permittedOrganizations: z.array(patientQueueInfoOrganizationSchema),
  adminUsers: z.array(patientQueueInfoUserSchema),
  guid: z.string().uuid(),
  owner: patientQueueInfoUserSchema,
  showPreliminaryInfo: z.boolean(),
  showPreliminaryDescription: z.boolean(),
});

export const updatePatientQueueUserSettingsRequestSchema = z.object({
  isVisible: z.boolean().optional(),
  getNotifications: z.boolean().optional(),
  notificationScheduleDays: notificationScheduleDaysSchema
    .optional()
    .nullable(),
  notificationScheduleHours: notificationScheduleHoursSchema
    .optional()
    .nullable(),
  smsNotification: z.boolean().optional(),
  phoneNumber: z.string().max(19).optional().nullable(),
  emailNotification: z.boolean().optional(),
  emailAddress: z.string().max(320).optional().nullable(),
  webhookNotification: z.boolean().optional(),
  webhookUrl: z.string().max(2048).optional().nullable(),
  uiNotification: z.boolean().optional(),
});

export const updatePatientQueueUserSettingsResponseSchema = z.object({
  queueInfo: simplePatientQueueInfoSchema,
  userSettings: patientQueueUserSettingsSchema.optional().nullable(),
});

export const updatePatientRequestSchema = z.object({
  mobilePhoneNumber: z.string().max(19).optional().nullable(),
  email: z.string().max(320).optional().nullable(),
  address: addressSchema.optional().nullable(),
});

export const updatePatientStatusRequestSchema = z.object({
  status: patientStatusSchema,
  queueGuid: z.string().uuid().optional().nullable(),
});

export const updatePatientStatusResponseSchema = z.object({
  id: z.string().uuid(),
  status: patientStatusSchema,
});

export const updatePhrasesRequestSchema = z.object({
  phrase: simplePhraseSchema,
});

export const phraseSchema = z.object({
  guid: z.string().uuid(),
  name: z.string().min(1).max(40000),
  value: z.string().min(1).max(40000),
  type: phraseTypeSchema,
  isShared: z.boolean(),
  category: phraseCategorySchema,
});

export const updateRoomStatusMessageSchema = z.object({
  messageType: websocketMessageTypeSchema,
  timeStamp: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
  roomId: z.string().uuid(),
  status: chatRoomStatusSchema,
});

export const updateSettingsRequestSchema = z.object({
  phoneNumber: z.string().max(19).optional().nullable(),
  email: z.string().max(320).optional().nullable(),
  webhookUrl: z.string().max(2048).optional().nullable(),
});

export const updateSettingsResponseSchema = z.object({
  phoneNumber: z.string().max(19).optional().nullable(),
  email: z.string().max(320).optional().nullable(),
  webhookUrl: z.string().max(2048).optional().nullable(),
});

export const updateUserAgreementRequestSchema = z.object({
  isValid: z.boolean(),
});

export const updateUserAgreementResponseSchema = z.object({
  agreement: userAgreementSchema,
});

export const updateUserProfileRequestSchema = z.object({
  profile: userProfileSchema,
});

export const updateUserProfileResponseSchema = z.object({
  profile: userProfileSchema,
});

export const updateUserRegistrationStatusRequestSchema = z.object({
  userId: z.number(),
  registrationStatus: registrationStatusSchema,
});

export const updateUserRegistrationStatusResponseSchema = z.object({
  user: userInfoAdminSchema,
});

export const databaseFileMetadataSchema = z.object({
  guid: z.string().uuid(),
  type: databaseFileTypeSchema,
  fileName: z.string().min(1).max(40000),
  sizeBytes: z.number(),
  width: z.number().optional().nullable(),
  height: z.number().optional().nullable(),
  createdTS: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
});

export const healthProfessionalGroupSchema = z.nativeEnum(
  HealthProfessionalGroup,
);

export const userRestrictionCategorySchema = z.nativeEnum(
  UserRestrictionCategory,
);

export const drugOrProductTypeSchema = z.nativeEnum(DrugOrProductType);

export const workplaceRestrictionTypeSchema = z.nativeEnum(
  WorkplaceRestrictionType,
);

export const drugPrescribingRestrictionTypeSchema = z.nativeEnum(
  DrugPrescribingRestrictionType,
);

export const treatmentMethodOrSubjectRestrictionTypeSchema = z.nativeEnum(
  TreatmentMethodOrSubjectRestrictionType,
);

export const fileTypeSchema = z.nativeEnum(FileType);

export const patientPreliminaryImageSizeSchema = z.nativeEnum(
  PatientPreliminaryImageSize,
);

export const twiMLResultSchema = z.any();

export const accountInfoSchema = z.object({
  registrationOk: z.boolean().optional().nullable(),
  isActive: z.boolean().optional().nullable(),
  validCertificates: z.array(x509CertificateInfoSchema),
  additionalProperties: z.record(z.unknown()).optional(),
});

export const addApiKeyResponseSchema = z.object({
  item: aPIKeySchema,
});

export const addPatientQueueRequestSchema = z.object({
  name: z.string().min(1).max(100),
  responseTimeSeconds: z.number().optional().nullable(),
  connectionMethod: z.string().max(100).optional().nullable(),
  isFifo: z.boolean(),
  isPublic: z.boolean(),
  description: z.string().max(1000).optional().nullable(),
  hasGenderRestriction: z.boolean(),
  genderRestriction: genderSchema.optional().nullable(),
  hasAgeLimit: z.boolean(),
  minAge: z.number().optional().nullable(),
  maxAge: z.number().optional().nullable(),
  costs: patientQueueCostsInfoSchema.optional().nullable(),
  chatEnabled: z.boolean().optional().nullable(),
  showPreliminaryInfo: z.boolean().optional().nullable(),
  showPreliminaryDescription: z.boolean().optional().nullable(),
  permittedUsers: z.array(patientQueueInfoUserSchema),
  permittedOrganizations: z.array(patientQueueInfoOrganizationSchema),
  adminUsers: z.array(patientQueueInfoUserSchema),
});

export const addPatientQueueResponseSchema = z.object({
  name: z.string().min(1).max(100),
  responseTimeSeconds: z.number().optional().nullable(),
  connectionMethod: z.string().max(100).optional().nullable(),
  isFifo: z.boolean(),
  isPublic: z.boolean(),
  description: z.string().max(1000).optional().nullable(),
  hasGenderRestriction: z.boolean(),
  genderRestriction: genderSchema.optional().nullable(),
  hasAgeLimit: z.boolean(),
  minAge: z.number().optional().nullable(),
  maxAge: z.number().optional().nullable(),
  costs: patientQueueCostsInfoSchema.optional().nullable(),
  chatEnabled: z.boolean().optional().nullable(),
  permittedUsers: z.array(patientQueueInfoUserSchema),
  permittedOrganizations: z.array(patientQueueInfoOrganizationSchema),
  adminUsers: z.array(patientQueueInfoUserSchema),
  guid: z.string().uuid(),
  owner: patientQueueInfoUserSchema,
  showPreliminaryInfo: z.boolean(),
  showPreliminaryDescription: z.boolean(),
});

export const addPatientTemplateRequestSchema = z.object({
  template: simplePatientTemplateSchema,
});

export const addPatientTemplateResponseSchema = z.object({
  template: patientTemplateSchema,
});

export const addPhrasesRequestSchema = z.object({
  phrase: simplePhraseSchema,
});

export const addPhrasesResponseSchema = z.object({
  phrase: phraseSchema,
});

export const referralFormFieldsSchema = z.object({
  sendingFacilityName: z.string().max(40000).optional().nullable(),
  sendingFacilityAddress: z.string().max(40000).optional().nullable(),
  date: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .optional()
    .nullable(),
  receivingFacilityName: z.string().max(40000).optional().nullable(),
  receivingFacilityAddress: z.string().max(40000).optional().nullable(),
  speciality: z.string().max(40000).optional().nullable(),
  urgency: referralUrgencySchema.optional().nullable(),
  patientSsid: z.string().min(1).max(40000),
  patientLastAndFirstNames: z.string().max(40000).optional().nullable(),
  profession: z.string().max(40000).optional().nullable(),
  patientAddress: z.string().max(40000).optional().nullable(),
  patientPostNumber: z.string().max(40000).optional().nullable(),
  patientPostalDistrict: z.string().max(40000).optional().nullable(),
  patientMunicipality: z.string().max(40000).optional().nullable(),
  patientHomePhoneNumber: z.string().max(40000).optional().nullable(),
  patientWorkPhoneNumber: z.string().max(40000).optional().nullable(),
  employment: referralEmploymentSchema.optional().nullable(),
  diagnosis: z.string().max(40000).optional().nullable(),
  information: z.string().max(40000).optional().nullable(),
  senderCannotContinueCare: z.boolean().optional().nullable(),
  senderRequiresFinalStatement: z.boolean().optional().nullable(),
  patientBringsRtg: z.boolean().optional().nullable(),
  patientBringsLab: z.boolean().optional().nullable(),
  patientBringsOther: z.boolean().optional().nullable(),
  patientBringsOtherDescription: z.string().max(40000).optional().nullable(),
  sendingDoctorNameAndSVNumber: z.string().max(40000).optional().nullable(),
  sendingDoctorAddressAndPhone: z.string().max(40000).optional().nullable(),
  specialCare: z.boolean().optional().nullable(),
  careLevel: z.string().max(40000).optional().nullable(),
  executiveMedicalDirectorApproval: z.string().max(40000).optional().nullable(),
});

export const addUserAgreementResponseSchema = z.object({
  agreement: userAgreementSchema,
});

export const adminGetPatientQueuesResponseSchema = z.object({
  items: z.array(simplePatientQueueInfoSchema),
});

export const archiveEntryBrokenMessageSchema = z.object({
  messageType: websocketMessageTypeSchema,
  timeStamp: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
});

export const archiveEntryMessageSchema = z.object({
  messageType: websocketMessageTypeSchema,
  timeStamp: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
  modificationType: modificationTypeSchema,
  entryType: entryTypeSchema.optional().nullable(),
  status: entryStatusSchema.optional().nullable(),
  primaryDataView: dataViewSchema.optional().nullable(),
});

export const clientStatusUpdateMessageSchema = z.object({
  messageType: websocketMessageTypeSchema,
  timeStamp: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
  firstName: z.string().min(1).max(128),
  lastName: z.string().min(1).max(128),
  guid: z.string().uuid().optional().nullable(),
  roomId: z.string().uuid().optional().nullable(),
  status: chatterStatusSchema,
});

export const closePatientRequestSchema = z.object({
  prescriptionCheckMode: patientClosePrescriptionCheckModeSchema
    .optional()
    .nullable(),
});

export const confirmUserAgreementResponseSchema = z.object({
  organisationUnits: z.array(organizationUnitSchema),
  user: userInfoSchema,
  confirmation: userAgreementConfirmationSchema,
});

export const createExternalPatientsRequestSchema = z.object({
  patient: patientExternalReqSchema,
});

export const createGuestSessionRequestSchema = z.object({
  ssid: z.string().min(1).max(40000),
  firstName: z.string().min(1).max(40000),
  lastName: z.string().min(1).max(40000),
  personIdentifierType: personIdentifierTypeSchema,
});

export const createLabTestOrderRequestSchema = z.object({
  additionalInformation: z.string().max(1000).optional().nullable(),
  operations: z.array(labTestOperationSchema),
});

export const createServiceEventRequestSchema = z.object({
  startTime: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
  endTime: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .optional()
    .nullable(),
  includesHospitalization: z.boolean().optional().nullable(),
  responsibleOrganizationOID: z.string().max(40000).optional().nullable(),
  underageDataReleaseToGuardian: healthcareUnderageDataReleaseSchema
    .optional()
    .nullable(),
});

export const diagnosisStatisticsRequestSchema = z.object({
  startTime: z.string().date(),
  endTime: z.string().date(),
  includeUsers: z.array(z.number()).optional().nullable(),
  excludeUsers: z.array(z.number()).optional().nullable(),
  includeRootOrganizationOIDs: z.array(z.string()).optional().nullable(),
  groupByTime: statisticsTimeGroupingTypeSchema,
  separateBy: diagnosisStatisticsSeparatorSchema.optional().nullable(),
  includeDiagnosisCodes: z.array(z.string()).optional().nullable(),
  excludeDiagnosisCodes: z.array(z.string()).optional().nullable(),
  includeExternalReasonCodes: z.array(z.string()).optional().nullable(),
  excludeExternalReasonCodes: z.array(z.string()).optional().nullable(),
  includeCertainties: z.array(certaintySchema).optional().nullable(),
  excludeCertainties: z.array(certaintySchema).optional().nullable(),
  includePermanences: z.array(permanenceSchema).optional().nullable(),
  excludePermanences: z.array(permanenceSchema).optional().nullable(),
  includePrimarities: z.array(diagnosisPrimaritySchema).optional().nullable(),
  excludePrimarities: z.array(diagnosisPrimaritySchema).optional().nullable(),
  includeQueues: z.array(z.string()).optional().nullable(),
  excludeQueues: z.array(z.string()).optional().nullable(),
});

export const entrySectionSecondaryTitleSchema = z.object({
  class: secondaryTitleClassSchema,
  specialType: specialSecondaryTitleTypeSchema.optional().nullable(),
  code: z.string().max(40000).optional().nullable(),
  text: z.string().max(40000).optional().nullable(),
  codeSystem: z.string().max(40000).optional().nullable(),
  codeSystemName: z.string().max(40000).optional().nullable(),
});

export const entryStatisticsRequestSchema = z.object({
  startTime: z.string().date(),
  endTime: z.string().date(),
  includeUsers: z.array(z.number()).optional().nullable(),
  excludeUsers: z.array(z.number()).optional().nullable(),
  includeRootOrganizationOIDs: z.array(z.string()).optional().nullable(),
  groupByTime: statisticsTimeGroupingTypeSchema,
  separateBy: entrySectionStatisticsSeparatorSchema.optional().nullable(),
  includeSectionCodes: z.array(z.string()).optional().nullable(),
  excludeSectionCodes: z.array(z.string()).optional().nullable(),
  includeQueues: z.array(z.string()).optional().nullable(),
  excludeQueues: z.array(z.string()).optional().nullable(),
});

export const errorMessageSchema = z.object({
  messageType: websocketMessageTypeSchema,
  timeStamp: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
  error: z.string().min(1).max(40000),
  errorCode: chatErrorCodeSchema,
});

export const errorStatisticsRequestSchema = z.object({
  startTime: z.string().date(),
  endTime: z.string().date(),
  includeUsers: z.array(z.number()).optional().nullable(),
  excludeUsers: z.array(z.number()).optional().nullable(),
  includeRootOrganizationOIDs: z.array(z.string()).optional().nullable(),
  groupByTime: statisticsTimeGroupingTypeSchema,
  separateBy: errorStatisticsSeparatorSchema.optional().nullable(),
  includeErrorTypes: z.array(errorTypeSchema).optional().nullable(),
  excludeErrorTypes: z.array(errorTypeSchema).optional().nullable(),
  includeErrorSources: z.array(errorSourceSchema).optional().nullable(),
  excludeErrorSources: z.array(errorSourceSchema).optional().nullable(),
  includeSeverities: z.array(errorSeveritySchema).optional().nullable(),
  excludeSeverities: z.array(errorSeveritySchema).optional().nullable(),
  includeExternalErrorCodes: z.array(z.number()).optional().nullable(),
  excludeExternalErrorCodes: z.array(z.number()).optional().nullable(),
});

export const extendedPatientQueueInfoSchema = z.object({
  name: z.string().min(1).max(100),
  responseTimeSeconds: z.number().optional().nullable(),
  connectionMethod: z.string().max(100).optional().nullable(),
  isFifo: z.boolean(),
  isPublic: z.boolean(),
  description: z.string().max(1000).optional().nullable(),
  hasGenderRestriction: z.boolean(),
  genderRestriction: genderSchema.optional().nullable(),
  hasAgeLimit: z.boolean(),
  minAge: z.number().optional().nullable(),
  maxAge: z.number().optional().nullable(),
  costs: patientQueueCostsInfoSchema.optional().nullable(),
  chatEnabled: z.boolean().optional().nullable(),
  permittedUsers: z.array(patientQueueInfoUserSchema),
  permittedOrganizations: z.array(patientQueueInfoOrganizationSchema),
  adminUsers: z.array(patientQueueInfoUserSchema),
  guid: z.string().uuid(),
  owner: patientQueueInfoUserSchema,
  showPreliminaryInfo: z.boolean(),
  showPreliminaryDescription: z.boolean(),
});

export const getAddressRequestSchema = z.object({
  webPageType: webPageTypeSchema.optional().nullable(),
  specialViewType: specialViewTypeSchema.optional().nullable(),
  returnAddress: z.string().max(40000).optional().nullable(),
});

export const getAllPrescriptionsResponseSchema = z.object({
  prescriptions: z.array(prescriptionInfoSchema),
});

export const getAllSessionsRequestSchema = z.object({
  takeCount: z.number().optional().nullable(),
  skipCount: z.number().optional().nullable(),
  olderThan: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .optional()
    .nullable(),
  filterParameters: statisticsSessionFilterParametersSchema
    .optional()
    .nullable(),
});

export const statisticsSessionInfoSchema = z.object({
  isMobile: z.boolean(),
  platform: z.string().max(40000).optional().nullable(),
  browser: z.string().max(40000).optional().nullable(),
  mobileDeviceType: z.string().max(40000).optional().nullable(),
  browserVersion: z.string().max(40000).optional().nullable(),
  eraSessionId: z.string().max(40000).optional().nullable(),
  loginType: loginTypeSchema.optional().nullable(),
  terminationTS: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .optional()
    .nullable(),
  terminationReason: sessionTerminationReasonSchema.optional().nullable(),
  user: statisticsUserInfoSchema,
  serviceUnit: statisticsServiceUnitInfoSchema.optional().nullable(),
  createdTS: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
});

export const getAllowedPrescriptionOperationsRequestSchema = z.object({
  patientPermission: patientPermissionSchema.optional().nullable(),
  prescriptionDocumentSetOids: z.array(z.string()),
});

export const prescriptionOperationResultSchema = z.object({
  prescriptionOid: z.string().min(1).max(40000),
  result: allowedPrescriptionOperationResultSchema,
  allowedPrefillModes: z.array(prescriptionPrefillModeSchema),
});

export const getApiKeysResponseSchema = z.object({
  items: z.array(aPIKeySchema),
});

export const getCodesRequestSchema = z.object({
  type: codedSystemTypeSchema,
  flags: z.array(codedItemFlagSchema),
  options: offsetLimitSchema.optional().nullable(),
});

export const getCodesResponseSchema = z.object({
  codedSystemID: codedSystemIDSchema,
  result: simpleCodedItemsResultSchema,
});

export const iPValidationSettingsSchema = z.object({
  allowEdit: z.boolean().optional().nullable(),
  mode: accessIPValidationModeSchema,
  allowedIPs: z.array(z.string()).optional().nullable(),
});

export const nonIntegratedAccessSettingsSchema = z.object({
  allowEdit: z.boolean().optional().nullable(),
  mode: nonIntegratedAccessModeSchema,
});

export const getEmailsResponseSchema = z.object({
  emails: z.array(patientCommunicationEmailSchema),
});

export const getEntriesRequestSchema = z.object({
  query: entryQuerySchema,
});

export const getFlagsResponseSchema = z.object({
  codedSystemID: codedSystemIDSchema,
  item: simpleCodedItemSchema,
  extraCodes: simpleCodedItemsResultSchema,
});

export const healthEntrySchema = z.object({
  name: healthEntryNameSchema,
  status: healthStatusSchema,
  elapsedMilliseconds: z.number(),
});

export const getHealthcareFormResponseSchema = z.object({
  item: healthcareFormSchema,
});

export const getHealthcareFormsRequestSchema = z.object({
  patientIdentifier: z.string().max(40000).optional().nullable(),
  types: z.array(dPlatformHealthcareFormTypeSchema).optional().nullable(),
  createdTimeFrom: z.string().date().optional().nullable(),
  createdTimeTo: z.string().date().optional().nullable(),
});

export const getHealthcareFormsResponseSchema = z.object({
  items: z.array(healthcareFormSimpleSchema),
});

export const getNotificationsResponseSchema = z.object({
  appNotifications: z.array(appNotificationSchema),
});

export const getOrganizationsResponseSchema = z.object({
  oid: z.string().min(1).max(40000),
  name: z.string().max(40000).optional().nullable(),
  businessID: z.string().max(40000).optional().nullable(),
  areaOfOperation: organizationAreaOfOperationSchema,
  sector: organizationSectorTypeSchema.optional().nullable(),
  phoneNumber: z.string().max(40000).optional().nullable(),
  phoneNumberType: phoneNumberTypeSchema.optional().nullable(),
  address: addressSchema.optional().nullable(),
  publicServiceJoinModel: publicServiceJoinModelSchema,
  hostOID: z.string().max(40000).optional().nullable(),
  hostName: z.string().max(40000).optional().nullable(),
  activity: activitySettingsSchema,
  ipValidation: iPValidationSettingsSchema,
  nonIntegratedAccess: nonIntegratedAccessSettingsSchema,
  acceptsRenewalRequests: z.boolean(),
  deploymentTests: z.array(deploymentTestConfigSchema).optional().nullable(),
});

export const patientQueueSettingsSchema = z.object({
  queueInfo: simplePatientQueueInfoSchema,
  userSettings: patientQueueUserSettingsSchema.optional().nullable(),
});

export const getPatientTemplatesResponseSchema = z.object({
  templates: z.array(patientTemplateSchema),
});

export const getPhrasesResponseSchema = z.object({
  items: z.array(phraseSchema),
});

export const getPrescriptionHeadersRequestSchema = z.object({
  queryType: prescriptionDataQueryTypeSchema,
  reasonForQuery: reasonForPrescriptionDataQuerySchema,
  patientPermission: patientPermissionSchema.optional().nullable(),
  onlyPrescribedByUser: z.boolean().optional().nullable(),
  dispensationStatus: prescriptionDispensationStatusSearchModeSchema
    .optional()
    .nullable(),
  beginDate: z.string().date().optional().nullable(),
  endDate: z.string().date().optional().nullable(),
  prescriptionOid: z.string().max(40000).optional().nullable(),
});

export const getSettingsPatientQueuesResponseSchema = z.object({
  items: z.array(extendedPatientQueueInfoSchema),
});

export const smsMessageSchema = z.object({
  patientGuid: z.string().uuid(),
  sentTS: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .optional()
    .nullable(),
  updatedTS: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .optional()
    .nullable(),
  direction: smsMessageDirectionSchema,
  from: z.string().max(40000).optional().nullable(),
  to: z.string().min(1).max(40000),
  body: z.string().min(1).max(40000),
  sid: z.string().min(1).max(40000),
  status: smsMessageStatusSchema,
  batchID: z.string().uuid(),
  sender: smsMessageSenderSchema,
});

export const getUserAgreementConfirmationsResponseSchema = z.object({
  total: z.number(),
  confirmations: z.array(userAgreementConfirmationExtendedSchema),
});

export const getUserAgreementsResponseSchema = z.object({
  agreements: z.array(userAgreementExtendedSchema),
});

export const getUserProfileResponseSchema = z.object({
  profile: userProfileSchema,
});

export const getUserPublicInfoResponseSchema = z.object({
  regNumber: z.string().min(1).max(40000),
  independentPractitionerInfo: independentPractitionerInfoSchema
    .optional()
    .nullable(),
});

export const getUsersRequestSchema = z.object({
  takeCount: z.number().optional().nullable(),
  skipCount: z.number().optional().nullable(),
  olderThan: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .optional()
    .nullable(),
  filterParameters: getUsersFilterParametersSchema.optional().nullable(),
});

export const getUsersResponseSchema = z.object({
  users: z.array(userInfoAdminSchema),
});

export const healthcareFormForwardedMessageSchema = z.object({
  messageType: websocketMessageTypeSchema,
  timeStamp: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
  entryType: entryTypeSchema.optional().nullable(),
  formType: healthcareFormTypeSchema.optional().nullable(),
});

export const initializeEntryBatchSearchRequestSchema = z.object({
  query: entryBatchQuerySchema,
  excludedDocuments: z.array(z.string()).optional().nullable(),
  getDocumentList: z.boolean().optional().nullable(),
});

export const laboratoryOperationInfoSchema = z.object({
  description: z.string().min(1).max(40000),
  hl7Identifier: z.string().min(1).max(40000),
  type: jsonFieldTypeSchema,
  isRequired: z.boolean(),
  measurementUnit: z.string().max(40000).optional().nullable(),
  options: z.array(z.string()).optional().nullable(),
});

export const listReferralsResponseSchema = z.object({
  referrals: z.array(referralSummarySchema),
});

export const listRoomsRequestMessageSchema = z.object({
  messageType: websocketMessageTypeSchema,
  timeStamp: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
  filter: listRoomsFilterSchema,
});

export const mDHealthAndTreatmentPlanServiceConditionCollectionSchema =
  z.object({
    conditions: z.array(
      mDHealthAndTreatmentPlanServiceConditionIdentifierSchema,
    ),
  });

export const mDHealthAndTreatmentPlanServiceConditionSchema = z.object({
  conditionDescription: z.string().min(1).max(40000),
  alternativeConditions: z
    .array(mDHealthAndTreatmentPlanServiceConditionCollectionSchema)
    .optional()
    .nullable(),
});

export const openPatientRequestSchema = z.object({
  usePopulationRegistry: z.boolean().optional().nullable(),
  patientGuid: z.string().uuid(),
  outsourcing: openPatientRequestOutsourcingSettingsSchema
    .optional()
    .nullable(),
  authoring: openPatientRequestAuthoringSettingsSchema,
  registry: openPatientRequestRegistrySettingsSchema,
  accessReason: patientInfoAccessReasonSchema,
  accessReasonDescription: z.string().max(40000).optional().nullable(),
});

export const openServiceEventRequestSchema = z.object({
  oid: z.string().min(1).max(40000),
  natureOfVisit: healthcareNatureOfVisitSchema.optional().nullable(),
  serviceType: sCNamedCodeSchema.optional().nullable(),
  contactType: healthcareContactTypeSchema.optional().nullable(),
});

export const openedServiceEventStatisticsRequestSchema = z.object({
  startTime: z.string().date(),
  endTime: z.string().date(),
  includeUsers: z.array(z.number()).optional().nullable(),
  excludeUsers: z.array(z.number()).optional().nullable(),
  includeRootOrganizationOIDs: z.array(z.string()).optional().nullable(),
  groupByTime: statisticsTimeGroupingTypeSchema,
  separateBy: openedServiceEventStatisticsSeparatorSchema,
});

export const patientPreliminaryImageSizesSchema = z.object({
  fullHd: patientPreliminaryImageFullHdSchema,
  thumbnail: patientPreliminaryImageThumbnailSchema,
});

export const patientStateStatisticsRequestSchema = z.object({
  startTime: z.string().date(),
  endTime: z.string().date(),
  includeUsers: z.array(z.number()).optional().nullable(),
  excludeUsers: z.array(z.number()).optional().nullable(),
  includeRootOrganizationOIDs: z.array(z.string()).optional().nullable(),
  groupByTime: statisticsTimeGroupingTypeSchema,
  includeStates: z.array(patientStatusSchema).optional().nullable(),
  excludeStates: z.array(patientStatusSchema).optional().nullable(),
  separateBy: patientStateStatisticsSeparatorSchema.optional().nullable(),
  includeQueues: z.array(z.string()).optional().nullable(),
  excludeQueues: z.array(z.string()).optional().nullable(),
});

export const patientStateTimedStatisticsRequestSchema = z.object({
  startTime: z.string().date(),
  endTime: z.string().date(),
  includeUsers: z.array(z.number()).optional().nullable(),
  excludeUsers: z.array(z.number()).optional().nullable(),
  includeRootOrganizationOIDs: z.array(z.string()).optional().nullable(),
  groupByTime: statisticsTimeGroupingTypeSchema,
  startStates: z.array(patientStatusSchema),
  endStates: z.array(patientStatusSchema),
  separateBy: patientStateTimedStatisticsSeparatorSchema.optional().nullable(),
  includeQueues: z.array(z.string()).optional().nullable(),
  excludeQueues: z.array(z.string()).optional().nullable(),
});

export const patientTimeFromEventToStateStatisticsRequestSchema = z.object({
  startTime: z.string().date(),
  endTime: z.string().date(),
  includeUsers: z.array(z.number()).optional().nullable(),
  excludeUsers: z.array(z.number()).optional().nullable(),
  includeRootOrganizationOIDs: z.array(z.string()).optional().nullable(),
  groupByTime: statisticsTimeGroupingTypeSchema,
  startEvents: z.array(patientStatisticsEventTypeSchema),
  endStates: z.array(patientStatusSchema),
  separateBy: patientTimeToTreatedStatisticsSeparatorSchema
    .optional()
    .nullable(),
  includeQueues: z.array(z.string()).optional().nullable(),
  excludeQueues: z.array(z.string()).optional().nullable(),
});

export const prefillFormRequestSchema = z.object({
  mode: healthcareFormPrefillModeSchema,
  emptyFormType: healthcareFormTypeSchema.optional().nullable(),
  template: partialHealthcareFormContainerSchema.optional().nullable(),
  entryOID: z.string().max(40000).optional().nullable(),
  redirectOnSave: z.string().max(40000).optional().nullable(),
  redirectOnCancel: z.string().max(40000).optional().nullable(),
});

export const sV120ServiceSchema = z.object({
  tariffCode: tariffCodeSchema,
  receptionDate: z.string().date().optional().nullable(),
  icdCode: z.string().max(40000).optional().nullable(),
  costCents: z.number().optional().nullable(),
  compensationCents: z.number().optional().nullable(),
});

export const structuredDosageInstructionSchema = z.object({
  administrationRouteAndMethod: sCNamedCodeSchema.optional().nullable(),
  dosageInstructionAdditionalInfo: z.string().max(40000).optional().nullable(),
  dosingPeriodStartTime: z.string().date().optional().nullable(),
  dosingPeriodEndTime: z.string().date().optional().nullable(),
  dosingPeriodLength: valueUnitRangeSchema.optional().nullable(),
  dosingOnPauseStartTime: z.string().date().optional().nullable(),
  dosingOnPauseEndTime: z.string().date().optional().nullable(),
  dosingAsNeeded: z.boolean().optional().nullable(),
  doseCycleLength: valueUnitRangeSchema,
  drugDoses: z.array(drugDoseDataSchema),
});

export const prescriptionStatisticsRequestSchema = z.object({
  startTime: z.string().date(),
  endTime: z.string().date(),
  includeUsers: z.array(z.number()).optional().nullable(),
  excludeUsers: z.array(z.number()).optional().nullable(),
  includeRootOrganizationOIDs: z.array(z.string()).optional().nullable(),
  groupByTime: statisticsTimeGroupingTypeSchema,
  separateBy: prescriptionStatisticsSeparatorSchema.optional().nullable(),
  includeProducts: z.array(z.string()).optional().nullable(),
  excludeProducts: z.array(z.string()).optional().nullable(),
  includeQueues: z.array(z.string()).optional().nullable(),
  excludeQueues: z.array(z.string()).optional().nullable(),
});

export const prescriptionTemplateDataSchema = z.object({
  drug: prescriptionDrugDataSchema,
  drugIngredients: z
    .array(prescriptionDrugIngredientDataSchema)
    .optional()
    .nullable(),
  drugQuantity: prescriptionDrugQuantityDataSchema.optional().nullable(),
  dosageInstructions: z.string().max(40000).optional().nullable(),
  intendedUseDescription: z.string().max(40000).optional().nullable(),
  narcoticType: narcoticTypeSchema.optional().nullable(),
  isNarcotic: z.boolean().optional().nullable(),
  productType: prescriptionProductTypeSchema.optional().nullable(),
  dosageReportingType: prescriptionDosageReportingTypeSchema,
  structuredDosageInstruction: structuredDosageInstructionSchema
    .optional()
    .nullable(),
});

export const refreshTokenRequestSchema = z.object({
  accessToken: z.string().min(1).max(40000),
  tokenType: accessTokenTypeSchema,
  refreshToken: z.string().min(1).max(40000),
});

export const refreshTokenResponseSchema = z.object({
  accessToken: z.string().min(1).max(40000),
  expiresIn: z.number(),
  tokenType: accessTokenTypeSchema,
  refreshToken: z.string().min(1).max(40000),
  sessionStatus: sessionStatusSchema,
});

export const roomInfoSchema = z.object({
  roomId: z.string().uuid(),
  unreadMessageCount: z.number(),
  created: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
  lastActive: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .optional()
    .nullable(),
  queueId: z.string().uuid().optional().nullable(),
  patientQueueItemGuid: z.string().uuid(),
  status: chatRoomStatusSchema,
  members: z.array(roomMemberSchema),
});

export const roomListMessageSchema = z.object({
  messageType: websocketMessageTypeSchema,
  timeStamp: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
  rooms: z.array(roomInfoSchema).optional().nullable(),
});

export const roomMemberListChangeMessageSchema = z.object({
  messageType: websocketMessageTypeSchema,
  timeStamp: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
  roomId: z.string().uuid(),
  members: z.array(roomMemberSchema),
});

export const roomMessageListMessageSchema = z.object({
  messageType: websocketMessageTypeSchema,
  timeStamp: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
  roomId: z.string().uuid(),
  messages: z.array(listedMessageSchema).optional().nullable(),
});

export const sCSensitivityStudySchema = z.object({
  oid: z.string().max(40000).optional().nullable(),
  operationCode: sCNamedCodeSchema.optional().nullable(),
  name: z.string().min(1).max(40000),
  value: z.string().max(40000).optional().nullable(),
  valueUnit: z.string().max(40000).optional().nullable(),
  interpretationCode: archiveLabResultInterpretationCodeSchema
    .optional()
    .nullable(),
  method: microbeMethodSchema,
  methodExplanation: z.string().max(40000).optional().nullable(),
});

export const sCSensitivityStudy2Schema = z.object({
  microbeDrugCode: sCNamedCodeSchema,
  value: valueUnitSchema.optional().nullable(),
  valueAssessment: microbeSensitivitySchema.optional().nullable(),
  method: microbialDrugSensitivityTestMethodSchema,
  hygienicallySignificantResult: z.boolean().optional().nullable(),
  additionalInformation: z.string().max(40000).optional().nullable(),
});

export const sCTreatmentOperatingPersonSchema = z.object({
  author: contentAuthorSchema,
  organization: contentProducerOrganizationSchema,
  role: archiveTreatmentOperatingPersonRoleSchema,
});

export const sCTreatmentToothSchema = z.object({
  number: z.string().min(1).max(40000),
  supernumerary: z.boolean(),
  surfaces: z.array(sCToothFaceSchema),
  prostheticType: prostheticToothTypeSchema.nullable(),
});

export const sendEmailResponseSchema = z.object({
  email: patientCommunicationEmailSchema,
});

export const smsMessageErrorSchema = z.object({
  to: z.string().min(1).max(40000),
  details: errorResponseSchema,
});

export const sessionStatisticsRequestSchema = z.object({
  startTime: z.string().date(),
  endTime: z.string().date(),
  includeUsers: z.array(z.number()).optional().nullable(),
  excludeUsers: z.array(z.number()).optional().nullable(),
  includeRootOrganizationOIDs: z.array(z.string()).optional().nullable(),
  groupByTime: statisticsTimeGroupingTypeSchema,
  separateBy: sessionStatisticsSeparatorSchema.optional().nullable(),
  includeBrowsers: z.array(z.string()).optional().nullable(),
  excludeBrowsers: z.array(z.string()).optional().nullable(),
  includePlatforms: z.array(z.string()).optional().nullable(),
  excludePlatforms: z.array(z.string()).optional().nullable(),
  isMobile: z.boolean().optional().nullable(),
});

export const smSMessageStatisticsRequestSchema = z.object({
  startTime: z.string().date(),
  endTime: z.string().date(),
  includeUsers: z.array(z.number()).optional().nullable(),
  excludeUsers: z.array(z.number()).optional().nullable(),
  includeRootOrganizationOIDs: z.array(z.string()).optional().nullable(),
  groupByTime: statisticsTimeGroupingTypeSchema,
  separateBy: smSMessageStatisticsSeparatorSchema.optional().nullable(),
  includeStatuses: z.array(smsMessageStatusSchema).optional().nullable(),
  excludeStatuses: z.array(smsMessageStatusSchema).optional().nullable(),
  includeQueues: z.array(z.string()).optional().nullable(),
  excludeQueues: z.array(z.string()).optional().nullable(),
});

export const smartCardLoginConfirmRequestSchema = z.object({
  eventId: z.string().min(1).max(40000),
  userRegNumber: z.string().min(1).max(40000),
  organisationUnit: loginConfirmOrganisationUnitSchema.optional().nullable(),
  isMobile: z.boolean(),
  rememberOrganization: z.boolean().optional().nullable(),
});

export const userRestrictionSchema = z.object({
  category: userRestrictionCategorySchema.optional().nullable(),
  description: z.string().max(40000).optional().nullable(),
  startDate: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .optional()
    .nullable(),
  endDate: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .optional()
    .nullable(),
  drugGroup: narcoticTypeSchema.optional().nullable(),
  productGroup: drugOrProductTypeSchema.optional().nullable(),
  atcCode: sCNamedCodeSchema.optional().nullable(),
  workplace: workplaceRestrictionTypeSchema.optional().nullable(),
  drugPrescribing: drugPrescribingRestrictionTypeSchema.optional().nullable(),
  treatmentCode: sCNamedCodeSchema.optional().nullable(),
  treatmentMethodOrSubject: treatmentMethodOrSubjectRestrictionTypeSchema
    .optional()
    .nullable(),
  positionOrSupervision: sCNamedCodeSchema.optional().nullable(),
  otherRestriction: z.boolean().optional().nullable(),
});

export const smartCardLoginRequestSchema = z.object({
  loginType: loginTypeSchema.optional().nullable(),
  regNumber: z.string().max(40000).optional().nullable(),
  smartCardDriverPort: z.string().max(40000).optional().nullable(),
  scSessionID: z.string().max(40000).optional().nullable(),
});

export const smartCardLoginValidateResponseSchema = z.object({
  organisationUnits: z.array(organizationUnitSchema),
  user: userInfoSchema,
  userAgreementConfirmation: userAgreementConfirmationSchema
    .optional()
    .nullable(),
  agreement: userAgreementSchema.optional().nullable(),
});

export const statisticsResponseSchema = z.object({
  queues: z.array(patientQueueSchema).optional().nullable(),
  users: z.array(statisticsUserInfoSchema).optional().nullable(),
  results: z.array(groupedStatisticsResponseSchema),
});

export const sCTreatmentSchema = z.object({
  operationName: z.string().max(40000).optional().nullable(),
  code: sCNamedCodeSchema,
  primarity: diagnosisPrimaritySchema,
  additionalCodes: z.array(sCNamedCodeSchema).optional().nullable(),
  operatingPersons: z
    .array(sCTreatmentOperatingPersonSchema)
    .optional()
    .nullable(),
  time: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .nullable(),
  state: archiveTreatmentStateSchema,
  complicationDescription: z.string().max(40000).optional().nullable(),
  complicationCode: sCNamedCodeSchema.optional().nullable(),
  actionReport: z.string().max(40000).optional().nullable(),
});

export const sCTreatment2Schema = z.object({
  oid: z.string().max(40000).optional().nullable(),
  operationName: z.string().max(40000).optional().nullable(),
  code: sCNamedCodeSchema,
  primarity: diagnosisPrimaritySchema.nullable(),
  additionalCodes: z.array(sCNamedCodeSchema).optional().nullable(),
  operatingPersons: z
    .array(sCTreatmentOperatingPersonSchema)
    .optional()
    .nullable(),
  time: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
  state: archiveTreatmentStateSchema,
  type: archiveTreatmentTypeSchema.nullable(),
  complicationDescription: z.string().max(40000).optional().nullable(),
  complicationCode: sCNamedCodeSchema.optional().nullable(),
  sideEffectExternalReason: sCNamedCodeSchema.optional().nullable(),
  mainOperationDiagnosis: sCNamedCodeSchema.optional().nullable(),
  teeth: z.array(sCTreatmentToothSchema).optional().nullable(),
});

export const updateHealthcareFormResponseSchema = z.object({
  item: healthcareFormSchema,
});

export const updatePatientNamesRequestSchema = z.object({
  firstName: z.string().min(1).max(128).optional(),
  friendlyName: z.string().max(128).optional().nullable(),
  lastName: z.string().min(1).max(128).optional(),
  patientOpenParams: updatePatientNamesPatientOpenParamsSchema,
});

export const updatePatientQueueRequestSchema = z.object({
  name: z.string().min(1).max(100).optional(),
  responseTimeSeconds: z.number().optional().nullable(),
  connectionMethod: z.string().max(100).optional().nullable(),
  isFifo: z.boolean().optional(),
  isPublic: z.boolean().optional(),
  description: z.string().max(1000).optional().nullable(),
  hasGenderRestriction: z.boolean().optional(),
  genderRestriction: genderSchema.optional().nullable(),
  hasAgeLimit: z.boolean().optional(),
  minAge: z.number().optional().nullable(),
  maxAge: z.number().optional().nullable(),
  costs: patientQueueCostsInfoSchema.optional().nullable(),
  chatEnabled: z.boolean().optional().nullable(),
  showPreliminaryInfo: z.boolean().optional(),
  showPreliminaryDescription: z.boolean().optional(),
  permittedUsers: z.array(patientQueueInfoUserSchema).optional().nullable(),
  permittedOrganizations: z
    .array(patientQueueInfoOrganizationSchema)
    .optional()
    .nullable(),
  adminUsers: z.array(patientQueueInfoUserSchema).optional().nullable(),
});

export const updatePhrasesResponseSchema = z.object({
  phrase: phraseSchema,
});

export const uploadExternalFilesResponseSchema = z.object({
  files: z.array(databaseFileMetadataSchema),
});

export const userLoginInfoSchema = z.object({
  regNumber: z.string().min(1).max(40000),
  idNumber: z.string().max(40000).optional().nullable(),
  id: z.string().max(40000).optional().nullable(),
  pmsid: z.string().max(40000).optional().nullable(),
  firstName: z.string().min(1).max(40000),
  lastName: z.string().min(1).max(40000),
  healthProfessionalGroup: healthProfessionalGroupSchema.optional().nullable(),
  occupationCode: z.string().max(40000).optional().nullable(),
  occupationName: z.string().max(40000).optional().nullable(),
  degreeCode: z.string().max(40000).optional().nullable(),
  degreeName: z.string().max(40000).optional().nullable(),
  jobDescription: z.string().max(40000).optional().nullable(),
  address: addressSchema.optional().nullable(),
  organizationUnit: organizationUnitSchema.optional().nullable(),
  preferredLanguage: z.string().max(40000).optional().nullable(),
  userRestrictions: z.array(userRestrictionSchema),
  phoneNumber: z.string().max(40000).optional().nullable(),
  email: z.string().max(40000).optional().nullable(),
  webhookUrl: z.string().max(40000).optional().nullable(),
  userGuid: z.string().uuid(),
  systemRights: z.array(userRightExternalSystemSchema),
});

export const addReferralRequestSchema = z.object({
  formFields: referralFormFieldsSchema,
});

export const getAllSessionsResponseSchema = z.object({
  sessions: z.array(statisticsSessionInfoSchema),
});

export const getAllowedPrescriptionOperationsResponseSchema = z.object({
  results: z.array(prescriptionOperationResultSchema),
});

export const getConfigurationUsersResponseSchema = z.object({
  type: userConfigTypeSchema,
  organizationAccessMode: userOrganizationAccessModeSchema,
  regNumber: z.string().min(1).max(40000),
  idNumber: z.string().max(40000).optional().nullable(),
  id: z.string().max(40000).optional().nullable(),
  oid: z.string().max(40000).optional().nullable(),
  businessID: z.string().max(40000).optional().nullable(),
  firstName: z.string().max(40000).optional().nullable(),
  lastName: z.string().max(40000).optional().nullable(),
  occupationCode: z.string().max(40000).optional().nullable(),
  occupationName: z.string().max(40000).optional().nullable(),
  degreeCode: z.string().max(40000).optional().nullable(),
  degreeName: z.string().max(40000).optional().nullable(),
  jobDescription: z.string().max(40000).optional().nullable(),
  roles: z.array(userConfigRoleSchema),
  preferredLanguage: z.string().min(1).max(40000),
  address: addressSchema.optional().nullable(),
  isIndependent: z.boolean(),
  prescriptionFee: valueUnitSchema.optional().nullable(),
  worksUnderSupervision: z.boolean(),
  supervisorIDs: z.array(z.string()).optional().nullable(),
  activity: activitySettingsSchema,
  ipValidation: iPValidationSettingsSchema,
  nonIntegratedAccess: nonIntegratedAccessSettingsSchema,
  acceptsRenewalRequests: z.boolean(),
  deploymentTests: z.array(deploymentTestConfigSchema).optional().nullable(),
  phoneNumber: z.string().max(40000).optional().nullable(),
  email: z.string().max(40000).optional().nullable(),
  webhookUrl: z.string().max(40000).optional().nullable(),
});

export const getHealthResponseSchema = z.object({
  version: z.string().max(40000).optional().nullable(),
  commit: z.string().max(40000).optional().nullable(),
  url: z.string().min(1).max(40000),
  status: healthStatusSchema,
  elapsedMilliseconds: z.number(),
  entries: z.array(healthEntrySchema),
});

export const laboratoryOperationCodeSchema = z.object({
  code: z.string().min(1).max(40000),
  name: z.string().max(40000).optional().nullable(),
  description: z.string().max(40000).optional().nullable(),
  information: z.array(laboratoryOperationInfoSchema).optional().nullable(),
});

export const getPatientQueuesResponseSchema = z.object({
  items: z.array(patientQueueSettingsSchema),
});

export const getSmsMessagesResponseSchema = z.object({
  count: z.number(),
  messages: z.array(smsMessageSchema),
  otherMessages: z.array(smsMessageSchema),
});

export const mDHealthAndTreatmentPlanServiceContentSchema = z.object({
  content: z.string().max(40000).optional().nullable(),
  serviceAdditionalInformation: z.string().max(40000).optional().nullable(),
  serviceStatus: healthAndTreatmentPlanServiceStatusSchema
    .optional()
    .nullable(),
  serviceStatusChanges: z
    .array(mDHealthAndTreatmentPlanServiceStateChangeSchema)
    .optional()
    .nullable(),
  servicePlannedExecutionTime: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
  serviceConditions: z
    .array(mDHealthAndTreatmentPlanServiceConditionSchema)
    .optional()
    .nullable(),
});

export const patientPreliminaryImageSchema = z.object({
  id: z.string().uuid(),
  fileName: z.string().min(1).max(40000),
  mimeType: z.string().min(1).max(40000),
  filesize: z.number(),
  width: z.number().optional().nullable(),
  height: z.number().optional().nullable(),
  sizes: patientPreliminaryImageSizesSchema.optional().nullable(),
});

export const patientSchema = z.object({
  idType: personIdentifierTypeSchema,
  id: z.string().max(63).optional().nullable(),
  guid: z.string().uuid().optional().nullable(),
  idCreationYear: z.number().optional().nullable(),
  temporaryIDRoot: z.string().max(64).optional().nullable(),
  dateOfBirth: z.string().date().optional().nullable(),
  gender: genderSchema,
  pmsid: z.string().uuid().optional().nullable(),
  firstName: z.string().min(1).max(128),
  friendlyName: z.string().max(128).optional().nullable(),
  lastName: z.string().min(1).max(128),
  preferredLanguage: z.string().max(2).optional().nullable(),
  mobilePhoneNumber: z.string().max(19).optional().nullable(),
  address: addressSchema.optional().nullable(),
  municipalityOfResidence: sCNamedCodeSchema.optional().nullable(),
  profession: z.string().max(255).optional().nullable(),
  jobDescription: z.string().max(255).optional().nullable(),
  employerName: z.string().max(255).optional().nullable(),
  insuranceCompanyName: z.string().max(255).optional().nullable(),
  email: z.string().max(320).optional().nullable(),
  preliminaryInfo: z.string().max(255).optional().nullable(),
  preliminaryDescription: z.string().max(4000).optional().nullable(),
  preliminaryDescriptionNoQueue: z.string().max(4000).optional().nullable(),
  status: patientStatusSchema.optional().nullable(),
  primaryGuardian: patientRelatedPersonSchema.optional().nullable(),
  secondaryGuardian: patientRelatedPersonSchema.optional().nullable(),
  timestamps: stateChangeTimestampsSchema.optional().nullable(),
  sourceQueueGuid: z.string().uuid().optional().nullable(),
  sourceQueueName: z.string().max(40000).optional().nullable(),
  queueGuids: z.array(z.string()).optional().nullable(),
  archiveEntryCreated: z.boolean().optional().nullable(),
  prescriptionSent: z.boolean().optional(),
  formForwarded: z.boolean().optional(),
  messageSentToPatient: z.boolean().optional(),
  messageReceivedFromPatient: z.boolean().optional(),
  underageDataReleaseToGuardianSet: z.boolean().optional().nullable(),
  laboratoryReferralStatus: labTestRequestStatusSchema.optional(),
  otherReferralSent: z.boolean().optional(),
  registryInfo: patientRegistryInfoSchema.optional().nullable(),
  chatEnabled: z.boolean().optional(),
  chatRoomId: z.string().uuid().optional().nullable(),
  lastOpenedAt: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .optional()
    .nullable(),
  externalData: z.string().max(40000).optional().nullable(),
  sendingCompanyId: z.string().uuid().optional().nullable(),
  preliminaryImages: z
    .array(patientPreliminaryImageSchema)
    .optional()
    .nullable(),
});

export const prefillPrescriptionRequestSchema = z.object({
  mode: prescriptionPrefillModeSchema,
  originalPrescriptionOID: z.string().max(40000).optional().nullable(),
  originalPrescriptionIsNarcotic: z.boolean().optional().nullable(),
  emptyPrescriptionType: drugProductTypeSchema.optional().nullable(),
  template: prescriptionTemplateDataSchema.optional().nullable(),
  medicationChangeReason: medicalTreatmentChangeReasonSchema
    .optional()
    .nullable(),
  medicationChangeExplanation: z.string().max(40000).optional().nullable(),
  medicationChangePermission: patientPermissionSchema.optional().nullable(),
  redirectOnSave: z.string().max(40000).optional().nullable(),
  redirectOnCancel: z.string().max(40000).optional().nullable(),
});

export const prefillSV120HealthcareFormRequestSchema = z.object({
  services: z.array(sV120ServiceSchema).optional().nullable(),
  placeAndTime: z.string().max(40000).optional().nullable(),
});

export const prescriptionDataSchema = z.object({
  state: prescriptionStateSchema.optional().nullable(),
  documentOID: z.string().max(40000).optional().nullable(),
  version: z.number().optional().nullable(),
  documentSetOID: z.string().max(40000).optional().nullable(),
  renewalFromPrescription: z.string().max(40000).optional().nullable(),
  reservationStatus: prescriptionReservationStatusSchema.optional().nullable(),
  dispensationStatus: prescriptionDispensationStatusSchema
    .optional()
    .nullable(),
  lockStatus: prescriptionLockStatusSchema.optional().nullable(),
  renewalStatus: prescriptionRenewalStatusSchema.optional().nullable(),
  serviceEventOID: z.string().max(40000).optional().nullable(),
  documentCreationTime: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .optional()
    .nullable(),
  prescriptionCreationTime: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .optional()
    .nullable(),
  languageCode: z.string().min(1).max(40000),
  author: contentAuthorSchema.optional().nullable(),
  organization: contentProducerOrganizationSchema.optional().nullable(),
  originalAuthor: contentAuthorSchema.optional().nullable(),
  originalOrganization: contentProducerOrganizationSchema.optional().nullable(),
  reservingOrganization: contentProducerOrganizationSchema
    .optional()
    .nullable(),
  patient: patientSchema.optional().nullable(),
  drug: prescriptionDrugDataSchema,
  drugIngredients: z
    .array(prescriptionDrugIngredientDataSchema)
    .optional()
    .nullable(),
  drugQuantity: prescriptionDrugQuantityDataSchema,
  changeForbidden: z.boolean().optional().nullable(),
  dosageInstructionsOnlyAsText: z.boolean(),
  dosageInstructions: z.string().max(40000).optional().nullable(),
  intendedUseDescription: z.string().max(40000).optional().nullable(),
  patientWeight: valueUnitSchema.optional().nullable(),
  iterationCount: z.string().max(40000).optional().nullable(),
  iterationInterval: z.string().max(40000).optional().nullable(),
  increasedCompensationReasonCode: z.string().max(40000).optional().nullable(),
  increasedCompensationReasonText: z.string().max(40000).optional().nullable(),
  messageToPharmacy: z.string().max(40000).optional().nullable(),
  expirationDate: z.string().date().optional().nullable(),
  treatmentTypeIllness: z.boolean().optional().nullable(),
  treatmentTypeAccident: z.boolean().optional().nullable(),
  treatmentTypeOther: z.boolean().optional().nullable(),
  employerName: z.string().max(40000).optional().nullable(),
  insuranceCompanyName: z.string().max(40000).optional().nullable(),
  portionedFulfillment: z.boolean().optional().nullable(),
  portionedFulfillmentExplanation: z.string().max(40000).optional().nullable(),
  renewalForbiddanceReason: medicalTreatmentChangeReasonSchema
    .optional()
    .nullable(),
  renewalForbiddanceExplanation: z.string().max(40000).optional().nullable(),
  sic: z.boolean().optional().nullable(),
  narcoticType: narcoticTypeSchema.optional().nullable(),
  isNarcotic: z.boolean().optional().nullable(),
  patientIdentificationType: patientIdentificationTypeSchema
    .optional()
    .nullable(),
  patientIdentificationExplanation: z.string().max(40000).optional().nullable(),
  drugDatabaseVersion: z.string().min(1).max(40000),
  permanentMedication: z.boolean().optional().nullable(),
  isNewMedication: z.boolean().optional().nullable(),
  productType: prescriptionProductTypeSchema,
  prescriptionType: prescriptionTypeSchema,
  underageDataReleaseToGuardian: healthcareUnderageDataReleaseSchema
    .optional()
    .nullable(),
  invalidationReason: medicalTreatmentChangeReasonSchema.optional().nullable(),
  invalidationPermission: patientPermissionSchema.optional().nullable(),
  invalidationExplanation: z.string().max(40000).optional().nullable(),
  correctionReason: medicalTreatmentChangeReasonSchema.optional().nullable(),
  correctionExplanation: z.string().max(40000).optional().nullable(),
  remainingQuantityDescription: z.string().max(40000).optional().nullable(),
  prescribersFee: valueUnitSchema.optional().nullable(),
  isPrescribedAsSpecialist: z.boolean().optional().nullable(),
  isDrugForTreatmentOfDangerousDisease: z.boolean().optional().nullable(),
  pharmacyCreatedPrescriptionType: pharmacyCreatedPrescriptionTypeSchema
    .optional()
    .nullable(),
  pharmacyCreatedPrescriptionReason: pharmacyCreatedPrescriptionReasonSchema
    .optional()
    .nullable(),
  pharmacyCreatedPrescriptionExplanation: z
    .string()
    .max(40000)
    .optional()
    .nullable(),
  hospitalPharmacy: contentProducerOrganizationSchema.optional().nullable(),
  prescribedDrugIdentifier: z.string().max(40000).optional().nullable(),
  prescribedDrugPartIdentifier: z.number().optional().nullable(),
  specialExplanationCodeItem: sCNamedCodeSchema.optional().nullable(),
  specialExplanationDate: z.string().date().optional().nullable(),
  structuredDosageInstruction: structuredDosageInstructionSchema
    .optional()
    .nullable(),
  requiresSpecialObservation: yesNoNotKnownValueSchema.optional().nullable(),
  biologicalDrug: yesNoNotKnownValueSchema.optional().nullable(),
  biosimilar: yesNoNotKnownValueSchema.optional().nullable(),
  reasoningForMoreExpensiveBiological: z
    .string()
    .max(40000)
    .optional()
    .nullable(),
  drugGroup: z.string().max(40000).optional().nullable(),
  bloodOrPlasmaProduct: yesNoNotKnownValueSchema.optional().nullable(),
});

export const prescriptionHeaderDataSchema = z.object({
  state: prescriptionStateSchema.optional().nullable(),
  documentOid: z.string().max(40000).optional().nullable(),
  version: z.number().optional().nullable(),
  documentSetOid: z.string().max(40000).optional().nullable(),
  reservationStatus: prescriptionReservationStatusSchema.optional().nullable(),
  reservingOrganization: z.string().max(40000).optional().nullable(),
  dispensationStatus: prescriptionDispensationStatusSchema
    .optional()
    .nullable(),
  lockStatus: prescriptionLockStatusSchema.optional().nullable(),
  lockOwner: z.string().max(40000).optional().nullable(),
  renewalStatus: prescriptionRenewalStatusSchema.optional().nullable(),
  documentCreationTime: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .optional()
    .nullable(),
  languageCode: z.string().max(40000).optional().nullable(),
  contentAuthor: contentAuthorSchema.optional().nullable(),
  organization: contentProducerOrganizationSchema.optional().nullable(),
  patient: patientSchema.optional().nullable(),
  drug: prescriptionDrugDataSchema.optional().nullable(),
  drugQuantity: prescriptionDrugQuantityDataSchema.optional().nullable(),
  drugQuantityText: z.string().max(40000).optional().nullable(),
  dosageInstructions: z.string().max(40000).optional().nullable(),
  dosageInstructionsOnlyAsText: z.boolean().optional().nullable(),
  intendedUseDescription: z.string().max(40000).optional().nullable(),
  iterationCount: z.string().max(40000).optional().nullable(),
  iterationInterval: z.string().max(40000).optional().nullable(),
  prescriptionCreationTime: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .optional()
    .nullable(),
  expirationDate: z.string().date().optional().nullable(),
  portionedFulfillment: z.boolean().optional().nullable(),
  renewalForbidden: z.boolean().optional().nullable(),
  sic: z.boolean().optional().nullable(),
  narcoticType: narcoticTypeSchema.optional().nullable(),
  isNarcotic: z.boolean().optional().nullable(),
  permanentMedication: z.boolean().optional().nullable(),
  prescriptionType: prescriptionTypeSchema.optional().nullable(),
  underageDataReleaseToGuardian: healthcareUnderageDataReleaseSchema
    .optional()
    .nullable(),
  validityStatus: prescriptionValidityStatusSchema.optional().nullable(),
  invalidityReason: prescriptionInvalidityReasonSchema.optional().nullable(),
  remainingQuantity: dispensationDrugQuantityDataSchema.optional().nullable(),
  lastDispensationTime: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .optional()
    .nullable(),
  lastDispensationDescription: z.string().max(40000).optional().nullable(),
  prescribedDrugIdentifier: z.string().max(40000).optional().nullable(),
  prescribedDrugPartIdentifier: z.number().optional().nullable(),
  pharmacyCreatedPrescriptionType: pharmacyCreatedPrescriptionTypeSchema
    .optional()
    .nullable(),
  structuredDosageInstruction: structuredDosageInstructionSchema
    .optional()
    .nullable(),
  changeForbidden: z.boolean().optional().nullable(),
  patientWeight: valueUnitSchema.optional().nullable(),
});

export const sCMicrobeCultivationResultSchema = z.object({
  oid: z.string().max(40000).optional().nullable(),
  code: sCNamedCodeSchema,
  operationCode: sCNamedCodeSchema.optional().nullable(),
  value: z.string().min(1).max(40000),
  valueUnit: z.string().max(40000).optional().nullable(),
  type: microbeStudyTypeSchema,
  typeExplanation: z.string().max(40000).optional().nullable(),
  description: z.string().max(40000).optional().nullable(),
  sensitivityStudies: z.array(sCSensitivityStudySchema).optional().nullable(),
});

export const sCMicrobeCultivationResult2Schema = z.object({
  findingCode: sCNamedCodeSchema,
  value: valueUnitNullableSchema.optional().nullable(),
  valueAssessment: microbeQuantitySchema.optional().nullable(),
  additionalInformation: z.string().max(40000).optional().nullable(),
  sensitivityStudies: z.array(sCSensitivityStudy2Schema).optional().nullable(),
});

export const searchPatientsResponseSchema = z.object({
  queues: z.array(patientQueueSchema),
  patients: z.array(patientSchema),
});

export const sendSmsMessageResponseSchema = z.object({
  message: smsMessageSimpleSchema,
  receivers: z.array(smsMessageReceiverSchema),
  errors: z.array(smsMessageErrorSchema),
});

export const healthcareDocumentInfoSchema = z.object({
  id: documentIdentificationDataSchema.optional().nullable(),
  creationTime: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .optional()
    .nullable(),
  dataViews: z.array(dataViewSchema).optional().nullable(),
  underageDataReleaseToGuardian: healthcareUnderageDataReleaseSchema
    .optional()
    .nullable(),
  patient: patientSchema.optional().nullable(),
  otherPersons: z.array(patientSchema).optional().nullable(),
  serviceEventOID: z.string().max(40000).optional().nullable(),
  registry: registrySettingsSchema.optional().nullable(),
  serviceProducer: contentProducerOrganizationSchema.optional().nullable(),
  organizations: z
    .array(contentProducerOrganizationSchema)
    .optional()
    .nullable(),
  authors: z.array(contentAuthorSchema).optional().nullable(),
});

export const smartCardLoginConfirmResponseSchema = z.object({
  accessToken: z.string().min(1).max(40000),
  expiresIn: z.number(),
  tokenType: accessTokenTypeSchema,
  refreshToken: z.string().min(1).max(40000),
  userRestrictions: z.array(userRestrictionSchema),
  undeliveredPrescriptionsCount: z.number(),
  userRights: z.array(loginConfirmUserRightSchema),
  systemRights: z.array(userRightExternalSystemSchema),
});

export const sCLabTestResultSchema = z.object({
  state: labTestResultStateSchema,
  time: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
  performer: contentAuthorSchema.optional().nullable(),
  organization: contentProducerOrganizationSchema.optional().nullable(),
  operation: sCLabTestOperationSchema,
  type: archiveLabResultTypeSchema,
  result: z.string().max(40000).optional().nullable(),
  resultUnit: z.string().max(40000).optional().nullable(),
  radiation: z.string().max(40000).optional().nullable(),
  radiationUnit: z.string().max(40000).optional().nullable(),
  referenceValueDeviation: archiveLabResultInterpretationCodeSchema.nullable(),
  referenceValues: z.array(sCLabTestReferenceValueSchema).optional().nullable(),
  resultFinishedTime: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .nullable(),
  additionalInformation: z.string().max(40000).optional().nullable(),
  microbeCultivationResults: z
    .array(sCMicrobeCultivationResultSchema)
    .optional()
    .nullable(),
  reportText: z.string().max(40000).optional().nullable(),
});

export const sCLabTestResult2Schema = z.object({
  resultStatus: labTestResultStateSchema,
  resultIdentifier: z.string().max(40000).optional().nullable(),
  samplingTime: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .nullable(),
  performerName: z.string().max(40000).optional().nullable(),
  operation: sCNamedCodeSchema.optional().nullable(),
  codeSystemOperation: sCCodeSystemCodeSchema.optional().nullable(),
  type: archiveLabResultTypeSchema.nullable(),
  resultValue: valueUnitSchema.optional().nullable(),
  resultText: z.string().max(40000).optional().nullable(),
  referenceValueDeviation: archiveLabResultInterpretationCodeSchema.nullable(),
  referenceValues: z.array(sCLabTestReferenceValueSchema).optional().nullable(),
  specimenType: sCNamedCodeSchema.optional().nullable(),
  resultFinishedTime: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .nullable(),
  method: measurementMethodSchema.nullable(),
  additionalInformation: z.string().max(40000).optional().nullable(),
  informationSource: informationSourceSchema.optional().nullable(),
  microbeCultivationResults: z
    .array(sCMicrobeCultivationResult2Schema)
    .optional()
    .nullable(),
  requestIdentitier: z.string().max(40000).optional().nullable(),
  requestDocumentData: documentIdentificationDataSchema.optional().nullable(),
  reportStatus: reportStatusSchema.optional().nullable(),
  reportText: z.string().max(40000).optional().nullable(),
  reportTime: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .optional()
    .nullable(),
  reportAuthor: contentAuthorSchema.optional().nullable(),
  reportOrganization: contentProducerOrganizationSchema.optional().nullable(),
});

export const updatePatientNamesResponseSchema = z.object({
  patient: patientSchema,
});

export const updatePatientResponseSchema = z.object({
  patient: patientSchema,
});

export const userInfoResponseSchema = z.object({
  user: userLoginInfoSchema,
});

export const addPatientRequestSchema = z.object({
  patient: patientSchema,
  enableChat: z.boolean().optional().nullable(),
});

export const addPatientResponseSchema = z.object({
  patientGuid: z.string().uuid(),
  patient: patientSchema,
});

export const createExternalPatientsResponseSchema = z.object({
  patientGuid: z.string().uuid(),
  patient: patientSchema,
  chatRoomGuid: z.string().uuid().optional().nullable(),
});

export const createPrescriptionRequestSchema = z.object({
  mode: prescriptionCreateModeSchema,
  originalPrescriptionOID: z.string().max(40000).optional().nullable(),
  originalPrescriptionIsNarcotic: z.boolean().optional().nullable(),
  prescription: prescriptionDataSchema,
});

export const createPrescriptionResponseSchema = z.object({
  prescription: prescriptionDataSchema.optional().nullable(),
});

export const serviceEventSchema = z.object({
  oid: z.string().min(1).max(40000),
  startTime: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
  endTime: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .nullable(),
  includesHospitalization: z.boolean(),
  patient: patientSchema,
  author: contentAuthorSchema,
  registry: registrySettingsSchema,
  serviceProducer: contentProducerOrganizationSchema,
  responsibleOrganization: contentProducerOrganizationSchema,
  status: serviceEventStatusSchema,
  patientRelationshipValidated: z.boolean(),
  underageDataReleaseToGuardian: healthcareUnderageDataReleaseSchema
    .optional()
    .nullable(),
  documents: z.array(healthcareDocumentInfoSchema).optional().nullable(),
});

export const editServiceEventResponseSchema = z.object({
  serviceEvent: serviceEventSchema,
});

export const structuredContentContainerSchema = z.object({
  oid: z.string().max(40000).optional().nullable(),
  pmsid: z.string().max(40000).optional().nullable(),
  editMode: contentEditModeSchema.optional().nullable(),
  type: structuredContentTypeSchema,
  text: sCTextContentSchema.optional().nullable(),
  labTestRequest: sCLabTestRequestSchema.optional().nullable(),
  labTestRequest2: sCLabTestRequest2Schema.optional().nullable(),
  labTestResult: sCLabTestResultSchema.optional().nullable(),
  labTestResult2: sCLabTestResult2Schema.optional().nullable(),
  labTestReport: sCLabTestReportSchema.optional().nullable(),
  labTestReport2: sCLabTestReport2Schema.optional().nullable(),
  diagnosis: sCDiagnosisSchema.optional().nullable(),
  diagnosis2: sCDiagnosis2Schema.optional().nullable(),
  treatment: sCTreatmentSchema.optional().nullable(),
  treatment2: sCTreatment2Schema.optional().nullable(),
  riskInfo: sCRiskInfoSchema.optional().nullable(),
  riskInfo2: sCRiskInfo2Schema.optional().nullable(),
  vaccination: sCVaccinationSchema.optional().nullable(),
  vaccination2: sCVaccination2Schema.optional().nullable(),
  hiddenContent: sCHiddenContentSchema.optional().nullable(),
});

export const livingWillSchema = z.object({
  time: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
  patient: patientSchema,
  author: contentAuthorSchema,
  serviceProducer: contentProducerOrganizationSchema,
  given: z.boolean(),
  text: z.string().min(1).max(40000),
});

export const organDonationWillSchema = z.object({
  time: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
  patient: patientSchema,
  author: contentAuthorSchema,
  serviceProducer: contentProducerOrganizationSchema,
  donationAllowed: z.boolean(),
  comments: z.string().min(1).max(40000),
});

export const getLaboratoryOperationCodesResponseSchema = z.object({
  items: z.array(laboratoryOperationCodeSchema),
});

export const getLivingWillResponseSchema = z.object({
  livingWill: livingWillSchema.optional().nullable(),
});

export const getPatientResponseSchema = z.object({
  patient: patientSchema,
});

export const getPatientsResponseSchema = z.object({
  queues: z.array(patientQueueSchema),
  patients: z.array(patientSchema),
});

export const getPrescriptionHeadersResponseSchema = z.object({
  prescriptions: z.array(prescriptionHeaderDataSchema),
  partialSuccess: z.boolean(),
});

export const initializeEntryBatchSearchResponseSchema = z.object({
  batchID: z.string().min(1).max(40000).nullable(),
  batchCount: z.number(),
  protectedContentDataViews: z.array(dataViewSchema).optional().nullable(),
  excludedDocuments: z.array(z.string()).optional().nullable(),
  nonExistingDocuments: z.array(z.string()).optional().nullable(),
  serviceEvents: z.array(serviceEventSchema).optional().nullable(),
  otherRegistryContentExcluded: queryResponseSpecifierSchema
    .optional()
    .nullable(),
});

export const mDHealthAndTreatmentPlanServiceSchema = z.object({
  serviceType: sCNamedCodeSchema,
  serviceContents: z
    .array(mDHealthAndTreatmentPlanServiceContentSchema)
    .optional()
    .nullable(),
});

export const mDHealthAndTreatmentPlanSchema = z.object({
  treatmentNeed: z.string().max(40000).optional().nullable(),
  workCapacityNeeds: z.string().max(40000).optional().nullable(),
  treatmentReasons: z
    .array(mDHealthAndTreatmentPlanTreatmentReasonSchema)
    .optional()
    .nullable(),
  treatmentObjectives: z.string().max(40000).optional().nullable(),
  workCapacityObjectives: z.string().max(40000).optional().nullable(),
  objectivesSetter: z.string().max(40000).optional().nullable(),
  objectiveIdentifiers: z
    .array(mDHealthAndTreatmentPlanObjectiveConditionSchema)
    .optional()
    .nullable(),
  treatmentExecution: z.string().max(40000).optional().nullable(),
  healthcareOrganizations: z
    .array(mDHealthAndTreatmentPlanHealthcareOrganizationSchema)
    .optional()
    .nullable(),
  services: z
    .array(mDHealthAndTreatmentPlanServiceSchema)
    .optional()
    .nullable(),
  supportMonitoringAndEvaluation: z.string().max(40000).optional().nullable(),
  healthcareProfessionals: z.array(
    mDHealthAndTreatmentPlanHealthcareProfessionalSchema,
  ),
  additionalInformation: z.string().max(40000).optional().nullable(),
  diagnosisList: z.string().max(40000).optional().nullable(),
  medicationList: z.string().max(40000).optional().nullable(),
});

export const entrySectionSchema = z.object({
  phase: healthcareProcessPhaseSchema,
  title: entrySectionTitleSchema,
  secondaryTitle: entrySectionSecondaryTitleSchema.optional().nullable(),
  contents: z.array(structuredContentContainerSchema),
});

export const outsourcingAuthorizationSchema = z.object({
  oid: z.string().min(1).max(40000),
  creationTime: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
  type: outsourcingTypeSchema,
  startTime: z.string().date(),
  endTime: z.string().date(),
  patient: patientSchema.optional().nullable(),
  author: contentAuthorSchema,
  organization: contentProducerOrganizationSchema,
  serviceOrganizer: contentProducerOrganizationSchema,
  serviceOrganizerResponsibleUnit: contentProducerOrganizationSchema
    .optional()
    .nullable(),
  serviceProducer: contentProducerOrganizationSchema,
  registry: registrySettingsSchema,
  producerWriteAccess: outsourcingAuthorizationProducerWriteAccessSchema,
  serviceEventOID: z.string().max(40000).optional().nullable(),
  producerReadAccess: outsourcingAuthorizationProducerReadAccessSchema,
  releasedServiceEvents: z.array(z.string()).optional().nullable(),
  releasedDocumentsStartTime: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .optional()
    .nullable(),
  releasedDocumentsEndTime: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .optional()
    .nullable(),
});

export const openServiceEventResponseSchema = z.object({
  serviceEvent: serviceEventSchema,
});

export const patientInfoResponseSchema = z.object({
  patient: patientSchema.optional().nullable(),
  serviceEvent: serviceEventSchema.optional().nullable(),
});

export const newEntrySchema = z.object({
  status: entryStatusSchema,
  releaseDelayType: releaseDelayTypeSchema,
  releaseDateToPatient: z.string().date().optional().nullable(),
  otherPerson: patientSchema.optional().nullable(),
  primaryDataView: dataViewSchema,
  secondaryDataViews: z.array(dataViewSchema).optional().nullable(),
  sections: z.array(entrySectionSchema),
  time: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .optional()
    .nullable(),
});

export const createServiceEventResponseSchema = z.object({
  serviceEvent: serviceEventSchema,
});

export const maintainedDocumentContainerSchema = z.object({
  type: maintainedDocumentTypeSchema,
  healthAndTreatmentPlan: mDHealthAndTreatmentPlanSchema.optional().nullable(),
});

export const entrySchema = z.object({
  status: entryStatusSchema,
  releaseDelayType: releaseDelayTypeSchema,
  releaseDateToPatient: z.string().date().optional().nullable(),
  otherPerson: patientSchema.optional().nullable(),
  primaryDataView: dataViewSchema,
  secondaryDataViews: z.array(dataViewSchema).optional().nullable(),
  sections: z.array(entrySectionSchema),
  oid: z.string().max(40000).optional().nullable(),
  entryType: entryTypeSchema.optional().nullable(),
  time: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .nullable(),
  registry: registrySettingsSchema.optional().nullable(),
  serviceProducer: contentProducerOrganizationSchema.optional().nullable(),
  author: contentAuthorSchema.optional().nullable(),
  organization: contentProducerOrganizationSchema.optional().nullable(),
  editors: z.array(contentEditorSchema).optional().nullable(),
  patient: patientSchema.optional().nullable(),
  serviceEventOID: z.string().max(40000).optional().nullable(),
  partialForm: partialHealthcareFormContainerSchema.optional().nullable(),
  maintainedDocument: maintainedDocumentContainerSchema.optional().nullable(),
  htmlData: z.string().optional().nullable(),
  underageDataReleaseToGuardian: healthcareUnderageDataReleaseSchema
    .optional()
    .nullable(),
  documentID: documentIdentificationDataSchema.optional().nullable(),
});

export const getEntryBatchSearchResultsResponseSchema = z.object({
  entries: z.array(entrySchema),
  partialSuccess: z.boolean(),
});

export const openPatientResponseSchema = z.object({
  consentSummary: consentSummarySchema.optional().nullable(),
  authoringSettings: authoringSettingsSchema,
  registrySettings: registrySettingsSchema.optional().nullable(),
  outsourcingAuthorization: outsourcingAuthorizationSchema
    .optional()
    .nullable(),
  serviceEvent: serviceEventSchema.optional().nullable(),
});

export const updateEntryStatusResponseSchema = z.object({
  entry: entrySchema,
});

export const createEntryRequestSchema = z.object({
  entry: newEntrySchema,
});

export const createEntryResponseSchema = z.object({
  entry: entrySchema,
});

export const createLabTestOrderResponseSchema = z.object({
  placerOrderNumberId: z.string().min(1).max(40000),
  entry: entrySchema,
});

export const editEntryRequestSchema = z.object({
  entry: entrySchema,
});

export const editEntryResponseSchema = z.object({
  entry: entrySchema,
});

export const getEntriesResponseSchema = z.object({
  protectedContentRemoved: z.boolean().nullable(),
  entries: z.array(entrySchema).optional().nullable(),
  htmlData: z.string().max(40000).optional().nullable(),
  pdfData: z.string().max(40000).optional().nullable(),
  livingWill: livingWillSchema.optional().nullable(),
  organDonationWill: organDonationWillSchema.optional().nullable(),
  otherRegistryContentExcluded: queryResponseSpecifierSchema
    .optional()
    .nullable(),
  partialSuccess: z.boolean(),
});
