import { Center, Container } from '@chakra-ui/react';
import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router';
import { MenuItemAction } from '~src/api';
import ErrorCard from '~src/components/ErrorCard';
import Loading from '~src/components/Loading';

import { useHandleFlowInitClick } from '~src/hooks/useHandleFlowInitClick';

const ChatLogout: React.FC = () => {
  const { chatRoomId } = useParams<{ chatRoomId: string }>();
  const {
    startSurveyError: error,
    handleClick,
    clickLoading,
  } = useHandleFlowInitClick();
  const surveyStartRequested = useRef<boolean>(false);

  useEffect(() => {
    if (chatRoomId && !surveyStartRequested.current && !clickLoading) {
      handleClick(MenuItemAction.Question, 'chat-logout', {
        chatroomid: chatRoomId,
      });
      surveyStartRequested.current = true;
    }
  }, [chatRoomId, clickLoading, handleClick]);

  if (error) {
    return (
      <Container padding={[4, 8, 16]}>
        <ErrorCard
          title={'Pahus... haettua kyselyä ei löytynyt'}
          message={
            'Sinut on kirjattu chat -keskustelusta ulos. Voit jatkaa keskustelua uudelleen kirjautumalla sisään.'
          }
        />
      </Container>
    );
  }

  return (
    <div>
      <Center>
        <Loading size={'md'} />
      </Center>
    </div>
  );
};

export { ChatLogout };
