import React, { useContext, useEffect } from 'react';
import { Center, Flex } from '@chakra-ui/react';
import { ChatContext } from '~src/chat/chat-context';
import useChatScroll from '~src/chat/useChatScroll';
import TypingIndicator from '../TypingIndicator';
import Loading from '~src/components/Loading';
import { MessageBox } from '../MessageBox/MessageBox';
import DiscussionHeader from '../DiscussionHeader';
import { ScrollToBottomButton } from './ScrollToBottomButton';
import {
  ChatMessageDeliveryStatus,
  ChatterRole,
  ChatterStatus,
  ListedMessage,
} from '~src/chat/copied-schemas/backend-api-types';

const MessageThread: React.FC = () => {
  const {
    activeRoomId: contextActiveRoomId,
    getRoomMessages,
    roomsMessagesLoaded,
    getChatRoomById,
    sendUpdateMessageStatusMessage,
    userInfo,
  } = useContext(ChatContext);

  const activeRoomId = contextActiveRoomId ?? '';
  const { userGuid } = userInfo;
  const roomMessagesIsLoading = !roomsMessagesLoaded.includes(activeRoomId);

  const messages = getRoomMessages({ roomId: activeRoomId });
  const chatRoom = getChatRoomById({ roomId: activeRoomId });
  const doctorMember = chatRoom?.members.find(
    (member) => member.role === ChatterRole.Doctor,
  );

  const { unreadMessageCount } = chatRoom ?? { unreadMessageCount: 0 };

  const isDoctorWriting =
    !!doctorMember && doctorMember?.status === ChatterStatus.Writing;
  const { containerRef, messagesEndRef, scrollToBottom, initialScrollDone } =
    useChatScroll(messages, isDoctorWriting);

  const updateMessageStatus = ({ messageId }: { messageId: string }) =>
    sendUpdateMessageStatusMessage({
      roomId: activeRoomId,
      messageId,
      status: ChatMessageDeliveryStatus.Read,
    });

  const sortedMessages = messages.sort((a: ListedMessage, b: ListedMessage) =>
    a?.createdTimestamp &&
    b?.createdTimestamp &&
    a.createdTimestamp < b.createdTimestamp
      ? -1
      : 1,
  );
  const messageList = sortedMessages.map((message: ListedMessage) => {
    return (
      <MessageBox
        key={message.messageId}
        userGuid={userGuid}
        doctorGuid={doctorMember?.guid}
        message={message}
        updateMessageStatus={updateMessageStatus}
      />
    );
  });

  // Scroll to bottom when user sends a new message
  const isLastMsgUsers = sortedMessages.at(-1)?.senderGuid === userGuid;
  useEffect(() => {
    isLastMsgUsers && scrollToBottom(initialScrollDone ? 'auto' : 'smooth');
  }, [
    scrollToBottom,
    sortedMessages.length,
    isLastMsgUsers,
    initialScrollDone,
  ]);

  return (
    <Flex
      ref={containerRef}
      grow={1}
      position="relative"
      direction="column"
      overflowY="auto"
      height="calc(100% - 80px)"
      paddingX="18px"
      style={{
        scrollbarWidth: 'thin',
        scrollbarColor: '#C7C8CA transparent',
      }}
      gap={6}
      bg="white"
    >
      <DiscussionHeader />
      {roomMessagesIsLoading && (
        <Center>
          <Loading size={'md'} />
        </Center>
      )}
      {messageList}
      {isDoctorWriting && <TypingIndicator />}
      <ScrollToBottomButton
        unreadMessageCount={unreadMessageCount}
        scrollToBottom={scrollToBottom}
      />
      <div ref={messagesEndRef} />
    </Flex>
  );
};

export default MessageThread;
