import { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { surveyFlowRoot } from '~/src/constants';
import { MenuItemAction, useStartSurveyLazyQuery } from '~/src/api';
import { AppContext } from '~/src/state';
import '~/src/theme/stylesheets/flowinitStyles.scss';
import { useSetLogo } from './useAddAnswer';

type UseHandleFlowInitClickProps = {
  redirectToQuestion?: boolean;
};

export const useHandleFlowInitClick = (
  { redirectToQuestion }: UseHandleFlowInitClickProps = {
    redirectToQuestion: true,
  },
) => {
  const history = useHistory();
  const { setLogo } = useSetLogo();
  const { dispatch } = useContext(AppContext);

  const [startSurvey, { loading: surveyLoading, error }] =
    useStartSurveyLazyQuery({
      fetchPolicy: 'no-cache',
      onCompleted: ({ startSurvey }) => {
        dispatch({
          type: 'SET_CURRENT_FLOW_ITEM',
          payload: startSurvey,
        });
        setLogo(startSurvey.flowItem?.logo);

        redirectToQuestion &&
          history.push(
            `/${surveyFlowRoot}/${startSurvey.flowItem?.name}/${startSurvey.id}`,
          );

        return dispatch({
          type: 'SET_APP_LOADING_STATE',
          payload: false,
        });
      },
    });

  const handleClick = useCallback(
    (
      action: MenuItemAction,
      actionParameter: string,
      customVariables?: Record<string, string | number>,
    ) => {
      if (action !== MenuItemAction.Disabled) {
        dispatch({
          type: 'SET_APP_LOADING_STATE',
          payload: true,
        });
      }

      switch (action) {
        case MenuItemAction.Disabled:
          return null;

        case MenuItemAction.Chat:
          history.push('/chat');
          break;

        case MenuItemAction.Url:
          if (
            actionParameter.startsWith('http://') ||
            actionParameter.startsWith('https://')
          )
            window.location.href = actionParameter;
          else history.push(actionParameter);
          break;

        case MenuItemAction.Question:
          return startSurvey({
            variables: {
              surveyName: actionParameter,
              rootSurveyVariables: customVariables,
            },
          });
      }

      return dispatch({
        type: 'SET_APP_LOADING_STATE',
        payload: false,
      });
    },
    [dispatch, history, startSurvey],
  );

  const clickLoading = surveyLoading;

  return { clickLoading, startSurveyError: error, handleClick };
};
